<template>
  <div>
    <article class="interviewCard" v-if="interviewCard.type == 'nomination'">
      <div class="interviewCard__wrapper nomination">
        <div class="interviewCard__header">
          <figure>
            <img
              :src="talentProfilePic"
              :alt="interviewCard.source.talent.basicInfo.full_name"
              @error="imageLoadOnError"
            />

            <div
              v-if="interviewCard.source.talent?.basicInfo.country?.code"
              class=""
            >
              <country-flag
                :countryCode="
                  interviewCard.source.talent?.basicInfo.country?.code
                "
                class="country"
              />
            </div>
          </figure>
          <div class="interviewCard__headings">
            <h3>
              {{ interviewCard.source.talent?.basicInfo.full_name }}
            </h3>
            <div class="interviewCard__role">
              <span class="tw-text[#3d3d3d]">
                {{ interviewCard.source.talent.basicInfo.role?.name }}
              </span>
              <span class="tw-text-2xl tw-px-2">•</span>
              <template
                v-if="
                  Number(
                    interviewCard.source.talent.basicInfo.experience_years
                  ) > 0 &&
                  interviewCard.source.talent.basicInfo.experience_years != null
                "
              >
                <span>
                  {{ interviewCard.source.talent.basicInfo.experience_years }}
                  {{
                    interviewCard.source.talent.basicInfo.experience_years == 1
                      ? $t("nomination.yearOfExperience")
                      : $t("nomination.yearsExperience")
                  }}
                </span>
              </template>
              <span
                v-else-if="
                  Number(
                    interviewCard.source.talent.basicInfo.experience_years
                  ) == 0 &&
                  interviewCard.source.talent.basicInfo.experience_years != null
                "
              >
                {{ $t("matchedTalents.fresh") }}
              </span>
            </div>

            <div class="interviewCard__details">
              <img src="@/assets/images/ic_clock.svg" alt="CLock" />
              <h4>
                <span>{{ $t("interviewsList.createdAt") }}:</span>
                <span dir="ltr">{{
                  meetingTime(
                    interviewCard.source.interview.created_at,
                    interviewCard.source.interview.timezone
                  )
                }}</span>
              </h4>
            </div>
            <div class="interviewCard__details">
              <img src="@/assets/images/ic_clock.svg" alt="CLock" />
              <h4>
                <span>{{ $t("interviewsList.meetingTime") }}:</span>
                <span
                  class="interviewCard__ltr tw-me-1"
                  v-if="interviewCard.source.interview.meeting_start_time"
                  >{{
                    meetingTime(
                      interviewCard.source.interview.meeting_start_time,
                      interviewCard.source.interview.timezone
                    )
                  }}</span
                >
                <span class="interviewCard__ltr" v-else>{{
                  $t("interviewsList.loading")
                }}</span>
              </h4>
            </div>
            <div
              v-if="
                interviewCard.source.interview.created_by &&
                user.id !== interviewCard.source.interview.created_by.id
              "
              class="interviewCard__details"
            >
              <img src="@/assets/images/user-pen.svg" alt="CLock" />
              <h4>
                <span>{{ $t("interviewsList.createdBy") }}:</span>
                <span
                  class="tw-me-1"
                  v-if="interviewCard.source.interview.created_by.first_name"
                >
                  {{ interviewCard.source.interview.created_by.first_name }}
                  {{ interviewCard.source.interview.created_by.last_name }}
                </span>
              </h4>
            </div>
          </div>
          <div class="interviewCard__meetingCreated interviewCard__ltr">
            <span v-if="interviewCard.source.interview.meeting_start_time">
              {{
                whenMeetingBooked(
                  interviewCard.source.interview.meeting_start_time
                )
              }}
            </span>
            <span v-else>
              {{ $t("interviewsList.loading") }}
            </span>
          </div>
        </div>
        <div class="interviewCard__actions">
          <div class="interviewCard__copy !tw-my-2 md:!tw-my-0">
            <span> {{ $t("interviewsList.interviewLink") }}: </span>
            <div>
              <span
                dir="ltr"
                v-if="interviewCard.source.interview.meeting_link"
              >
                {{ interviewCard.source.interview.meeting_link | truncate }}
              </span>
              <span v-else>
                {{ $t("interviewsList.loading") }}
              </span>
              <button @click="copyInterviewLink">
                <img src="@/assets/images/copy.svg" alt="Copy url" />
              </button>
            </div>
          </div>
          <a
            :href="interviewCard.source.talent.basicInfo.sharable_link"
            target="_blank"
          >
            <span>
              {{ $t("interviewsList.viewProfile") }}
            </span>
            <img src="@/assets/images/ic_arrow-dropdown_1.svg" alt="Arrow" />
          </a>
        </div>
      </div>
    </article>
    <article class="interviewCard" v-if="interviewCard.type == 'selection'">
      <div class="interviewCard__wrapper">
        <div class="tw-flex tw-w-full tw-gap-4">
          <div class="interviewCard__header !tw-hidden md:!tw-flex tw-w-[17%] ">
            <figure class="md:tw-w-auto tw-w-[30%]">
              <img
                :src="talentProfilePic"
                :alt="interviewCard.source.talent.email"
                @error="imageLoadOnError"
              />
              <div v-if="interviewCard.source.talent?.country?.code" class="">
                <country-flag
                  :countryCode="interviewCard.source.talent?.country?.code"
                  class="country"
                />
              </div>
            </figure>
          </div>
          <div class="interviewCard__header tw-flex-col tw-w-full">
            <div v-if="isMobile" class="tw-flex">
              <div class="tw-flex tw-justify-center tw-w-5/6 tw-mx-auto">
                <discountBanner />
              </div>
            </div>

            <div class="tw-flex tw-items-start tw-w-full tw-flex-row tw-gap-2">
              <figure class="md:!tw-hidden !tw-flex tw-h-20 tw-w-20">
                <img
                  :src="talentProfilePic"
                  :alt="interviewCard.source.talent.email"
                  @error="imageLoadOnError"
                />
                <div v-if="interviewCard.source.talent?.country?.code" class="">
                  <country-flag
                    :countryCode="interviewCard.source.talent?.country?.code"
                    class="country"
                  />
                </div>
              </figure>
              <div class="interviewCard__headings tw-flex-col tw-flex md:tw-mt-4">
                <div
                  class="md:tw-hidden tw-flex tw-flex-col tw-w-full md:tw-w-auto tw-justify-start tw-items-start"
                  v-if="interviewCard.type === 'selection'"
                >
                  <div
                    class="tw-flex tw-justify-between md:tw-justify-end tw-items-center tw-w-auto md:tw-w-auto tw-rounded-full md:tw-px-4 tw-py-2 md:tw-bg-[#F8F8F8]"
                  >
                    <span
                      class="tw-text-[#979797] !tw-text-[10px] tw-font-medium"
                    >
                      {{ whenMeetingBooked(interviewCard.source.created_at) }}
                    </span>
                  </div>
                </div>
                <h3>
                  {{ interviewCard.source.talent?.full_name }}
                </h3>

                <div class="interviewCard__role">
                  <span class="tw-text[#3d3d3d]">
                    {{ interviewCard.source.talent.role?.name }}
                  </span>
                  <span class="tw-text-2xl tw-px-2">•</span>
                  <template
                    v-if="
                      Number(interviewCard.source.talent.experience_years) >
                        0 &&
                      interviewCard.source.talent.experience_years != null
                    "
                  >
                    <span>
                      {{ interviewCard.source.talent.experience_years }}
                      {{
                        interviewCard.source.talent.experience_years == 1
                          ? $t("nomination.yearOfExperience")
                          : $t("nomination.yearsExperience")
                      }}
                    </span>
                  </template>
                  <span
                    v-else-if="
                      Number(interviewCard.source.talent.experience_years) ==
                        0 &&
                      interviewCard.source.talent.experience_years != null
                    "
                  >
                    {{ $t("matchedTalents.fresh") }}
                  </span>
                </div>
              </div>

              <div
                class="tw-hidden md:tw-flex tw-flex-col tw-w-full md:tw-w-auto tw-justify-end tw-items-end tw-mt-2"
                v-if="interviewCard.type === 'selection'"
              >
                <div class="tw-flex tw-justify-center tw-w-4/5 tw-mb-1">
                  <discountBanner />
                </div>
                <div
                  class="tw-flex tw-justify-between md:tw-justify-end tw-items-center tw-w-full md:tw-w-auto tw-rounded-full tw-px-4 tw-py-2 "
                >
                  <span
                    class="tw-text-[#2E3536] !tw-text-[12px] tw-font-medium"
                  >
                    {{ whenMeetingBooked(interviewCard.source.created_at) }}
                  </span>
                </div>
              </div>
            </div>

            <div v-if="interviewCard.type === 'selection'" class="tw-w-full">
              <div class="nominated-list-card__availability">
                <ul>
                  <li
                    v-if="
                      interviewCard.source.full_time_status &&
                      interviewCard.source.full_time_status.id != 0 &&
                      interviewCard.source.full_time_salary?.total_salary != 0
                    "
                  >
                    <span class="nominated-list-card__availability__type">
                      {{ $t("nomination.fullTime") }}
                    </span>
                    <span class="nominated-list-card__availability__at">{{
                      getAvailabilityTimeName(
                        interviewCard.source.full_time_status.id
                      )
                    }}</span>
                    <span
                      v-if="interviewCard.source.full_time_salary?.total_salary"
                      class="nominated-list-card__availability__salary md:tw-flex md:tw-flex-row-reverse"
                    >
                      <div
                        class="tw-text-[#bf3543] tw-text-[1.3rem] tw-font-semibold tw-leading-tight"
                      >
                        {{
                          interviewCard.source.full_time_salary?.total_salary
                        }}$
                      </div>
                      <div
                        class="tw-text-[#7f7f7f] tw-text-[1rem] tw-font-normal discount-total-salay tw-leading-relaxed md:!tw-me-2"
                      >
                        {{
                          interviewCard.source.original_full_time_salary
                            ?.total_salary
                        }}$
                      </div>
                    </span>
                  </li>
                  <li
                    v-if="
                      interviewCard.source.part_time_status &&
                      interviewCard.source.part_time_status.id != 0 &&
                      interviewCard.source.part_time_salary?.total_salary != 0
                    "
                  >
                    <span class="nominated-list-card__availability__type">
                      {{ $t("nomination.partTime") }}
                    </span>
                    <span class="nominated-list-card__availability__at">{{
                      getAvailabilityTimeName(
                        interviewCard.source.part_time_status.id
                      )
                    }}</span>
                    <span
                      v-if="
                        interviewCard.source.part_time_salary?.total_salary ||
                        interviewCard.source.part_time_salary?.total_salary == 0
                      "
                      class="nominated-list-card__availability__salary md:tw-flex md:tw-flex-row-reverse"
                    >
                      <div
                        class="tw-text-[#bf3543] tw-text-[1.3rem] tw-font-semibold tw-leading-tight"
                      >
                        {{
                          interviewCard.source.part_time_salary?.total_salary
                        }}$
                      </div>
                      <div
                        class="tw-text-[#7f7f7f] tw-text-[1rem] tw-font-normal discount-total-salay tw-leading-relaxed md:!tw-me-2"
                      >
                        {{
                          interviewCard.source.original_part_time_salary
                            ?.total_salary
                        }}$
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="interviewCard__details"
              v-if="
                interviewCard.type === 'selection' &&
                interviewCard.source.status.id === 'scheduled'
              "
            >
              <img
                src="@/assets/images/ic_clock.svg"
                class="tw-me-2"
                alt="CLock"
              />
              <h4>
                <span>{{ $t("interviewsList.meetingTime") }}:</span>
                <span
                  class="interviewCard__ltr tw-me-1"
                  v-if="interviewCard.source.interview.meeting_start_time"
                >
                  {{
                    meetingTime(
                      interviewCard.source.interview.meeting_start_time,
                      interviewCard.source.interview.timezone
                    )
                  }}
                </span>
                <span class="interviewCard__ltr" v-else>{{
                  $t("interviewsList.loading")
                }}</span>
              </h4>
            </div>

            <div
              class="interviewCard__actions tw-w-full"
              v-if="
                interviewCard.source?.interview?.meeting_link &&
                interviewCard.type === 'selection' &&
                interviewCard.source.status.id === 'scheduled'
              "
            >
              <div class="interviewCard__copy">
                <span> {{ $t("interviewsList.interviewLink") }}: </span>
                <div>
                  <span dir="ltr">
                    {{ interviewCard.source.interview.meeting_link | truncate }}
                  </span>
                  <!--<span v-else>
                                {{ $t('interviewsList.loading') }}
                                </span> -->
                  <button @click="copyInterviewLink">
                    <img
                      class="!tw-w-4 !tw-h-4 !tw-rounded-none"
                      src="@/assets/images/copy.svg"
                      alt="Copy url"
                    />
                  </button>
                </div>
              </div>
            </div>

            <div
              class="tw-flex tw-w-full tw-justify-between tw-items-start tw-flex-col md:tw-flex-row tw-gap-3"
            >
              <div class="tw-flex tw-w-full">
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-locked interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type == 'selection' &&
                    interviewCard.source.status.id == 'talent_pending_update'
                  "
                >
                  <span>
                    {{ $t("requestInterview.pendingTalentConfirmation") }}
                  </span>
                </div>
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-locked interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type == 'selection' &&
                    interviewCard.source.status.id == 'talent_pending'
                  "
                >
                  <span>
                    {{
                      $t("requestInterview.pendingTalentMeetingConfirmation")
                    }}
                  </span>
                </div>
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-unlocked interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type == 'selection' &&
                    interviewCard.source.status.id == 'client_pending'
                  "
                >
                  <span>
                    {{
                      $t("requestInterview.pendingClientConfirmationOnNewSlots")
                    }}
                  </span>
                </div>
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-unlocked interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type === 'selection' &&
                    interviewCard.source.status.id == 'client_pending_update'
                  "
                >
                  <span>
                    {{ $t("requestInterview.pendingClientTimeSlots") }}
                  </span>
                </div>
                <div
                  v-if="
                    interviewCard.type === 'selection' &&
                    interviewCard.source.status.id == 'talent_unavailable'
                  "
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-unavailable interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                >
                  <span> {{ $t("requestInterview.talentUnavailable") }} </span>
                </div>
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-canceled interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type === 'selection' &&
                    interviewCard.source.status.id === 'canceled'
                  "
                >
                  <span> {{ $t("requestInterview.canceled") }} </span>
                </div>
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-scheduled interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type === 'selection' &&
                    interviewCard.source.status.id === 'scheduled'
                  "
                >
                  <span> {{ $t("requestInterview.interviewScheduled") }} </span>
                </div>
                <div
                  class="interviewCard__meetingCreated interviewCard__meetingCreated-scheduled interviewCard__ltr"
                  :class="$i18n.locale == 'en' ? 'md:tw-me-2' : 'md:tw-ms-2'"
                  v-if="
                    interviewCard.type === 'selection' &&
                    interviewCard.source.status.id === 'completed'
                  "
                >
                  <span> {{ $t("requestInterview.interviewCompleted") }} </span>
                </div>
              </div>
              <div
                class="tw-flex tw-w-full tw-justify-end"
                v-if="interviewCard.type === 'selection'"
              >
                <div class="interviewCard__actions !tw-mt-0">
                  <a
                    :href="
                      '/sq/client/interview-details/' + interviewCard.source.id
                    "
                    target="_blank"
                  >
                    <span> {{ $t("requestInterview.viewDetails") }} </span>
                    <img
                      src="@/assets/images/ic_arrow-dropdown_1.svg"
                      alt="Arrow"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import momentTimezone from "moment-timezone";
import toast from "@/services/toast.js";
import CountryFlag from "@/components/shared/countryFalg/countryFalg.vue";
import { availabilityOptions } from "@/static/AvailabilityOptions.js";
import discountBanner from "@/pages/Client/clientViewTalentProfile/components/salary/discountBanner.vue";

export default {
  name: "TalentInterviewsCard",
  components: {
    CountryFlag,
    discountBanner,
  },
  props: {
    interviewCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      isMobile: window.innerWidth < 768,
    };
  },
  filters: {
    truncate(value) {
      return value.length > 35 ? value.substring(0, 35) + "..." : value;
    },
  },
  created() {
    this.handleResize();
    this.addEventListeners();
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  methods: {
    addEventListeners() {
      window.addEventListener("resize", this.handleResize);
    },
    removeEventListeners() {
      window.removeEventListener("resize", this.handleResize);
    },
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    getAvailabilityTimeName(id) {
      if (id !== null) {
        return this.$t(
          `availabilityOptions.${[
            availabilityOptions.find((ele) => ele.id === id)?.name,
          ]}`
        );
      } else {
        return this.$t(`availabilityOptions.locked`);
      }
    },
    meetingTime(time, timezone) {
      if (this.$i18n.locale == "en") {
        moment.locale("en");
        return `${moment(time).format("DD/MM/YYYY")} at ${moment(time).format(
          "h:mm A"
        )} ${this.timezones.find((tz) => tz.value == timezone)?.name}`;
      } else {
        moment.locale("ar");
        return ` ${
          this.timezones.find((tz) => tz.value == timezone)?.name
        } ${moment(time).format("YYYY/MM/DD")} فى ${moment(time).format(
          "h:mm A"
        )}`;
      }
    },
    whenMeetingBooked(time) {
      this.$i18n.locale == "en" ? moment.locale("en") : moment.locale("ar");
      return moment(new Date(time)).fromNow();
    },
    copyInterviewLink() {
      navigator.clipboard.writeText(
        this.interviewCard.source.interview?.meeting_link
      );
      toast.success(this.$t("interviewsList.copied"));
    },
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    timezones() {
      let timezones = momentTimezone.tz.names();
      timezones = timezones.map((timezone) => {
        return {
          name: `${timezone.split("/")[1]} Time`,
          value: timezone,
        };
      });
      return timezones;
    },
    talentProfilePic() {
      if (
        this.interviewCard.source.talent.image &&
        !this.interviewCard.source.talent.image.includes("talent-avatar")
      )
        return `${this.interviewCard.source.talent.image}`;
      else return require("@/assets/images/talent-avatar.png");
    },
  },
};
</script>

<style lang="scss">
.interviewCard {
  display: flex;
  align-items: center;
  width: 100%;

  .discount-total-salay {
    position: relative;
    width: fit-content;
    margin-inline-start: auto;
  }

  .discount-total-salay::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    /* Thickness of the strike-through */
    background: linear-gradient(45deg, #bf3543, #bf3543);
    /* Angled effect */
    transform: rotate(10deg);
    /* Adjust the angle */
    transform-origin: center;
  }

  .nominated-list-card__availability {
    display: flex;
    align-items: flex-end;
    position: relative;

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-left: 0;
      width: 100%;

      li {
        display: flex;
        flex-wrap: wrap;
        background-color: #f8f8f8;
        border-radius: 23px;
        color: #292929;
        padding: 5px 16px;
        margin-inline-end: 10px;
        align-items: center;
        margin-bottom: 0.25rem;
        min-height: 40px;
        justify-content: space-evenly;

        @media (max-width: 576px) {
          padding: 5px 10px;
          margin-top: 5px;
          margin-bottom: 5px;
          margin-inline-end: 0;
          width: 100%;
          text-align: center;
           justify-content: space-evenly;
          display: flex;
        }

        .nominated-list-card__availability__type {
          margin-right: 5px;
          font-weight: 500;
          font-size: 15.5px;
          color: #292929;

          @media (max-width: 576px) {
            font-size: 12px;
          }
        }

        .nominated-list-card__availability__at {
          font-size: 14px;
          color: #808285;
          margin: 0 5px;

          @media (max-width: 576px) {
            font-size: 12px;
            margin-right: 0 0.5rem;
          }
        }

        .nominated-list-card__availability__salary {
          font-size: 20px;
          color: #373a3b;
          font-weight: 600;
          align-items: center;

          @media (max-width: 576px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  &__wrapper {
    width: 100%;
    border: 1px solid #eeeeee;
    padding: 26px;
    border-radius: 20px;
    box-shadow: 9px 7px 45px 0 rgba(66, 46, 135, 0.09);
    margin: 0.75rem 0;

    @media (min-width: 768px) {
      padding: 26px;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    figure {
      position: relative;
    }

    img {
      width: 125px;
      height: 125px;
      object-fit: contain;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 4.25rem;
        height: 4.25rem;
        flex-shrink: 0;
      }
    }

    .country {
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: 10px;
      background-color: #ccc;
      right: 12px;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 50%;

        @media (max-width: 768px) {
          width: 20px;
          height: 20px;
        }
      }

      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
        left: 0.5rem;
        bottom: 10px;
      }
    }
  }

  &__mainInfo {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 12px;

    figure {
      position: relative;
    }
  }

  &__headings {
    margin-inline-end: auto;

    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
    }

    h3 {
      display: flex;
      font-size: 18px;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &__meetingCreated {
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
    border-radius: 20px;
    padding: 7px 12px;
    white-space: nowrap;
    align-items: center;
    display: flex;
    height: 2.6875rem;

    span {
      font-size: 0.875rem;
      color: #9f9d9d;
      padding: 8px 16px;
      font-weight: 500;
    }

    @media (max-width: 768px) {
      align-self: flex-end;
      width: 100%;
      text-align: center;
      justify-content: center;
      display: flex;

      span.tw-px-2 {
        display: none;
      }
    }
  }

  &__meetingCreated-locked {
    background-color: rgba(31, 57, 210, 0.1);
    border: 1px solid rgba(31, 57, 210, 0.1);
    border-radius: 20px;
    padding: 7px 12px;
    white-space: nowrap;

    span {
      font-size: 0.875rem;
      color: #1f39d2;
      padding: 8px 16px;
      font-weight: 500;
    }
  }

  &__meetingCreated-scheduled {
    background-color: rgba(59, 212, 174, 0.1);
    border: 1px solid rgba(59, 212, 174, 0.1);
    border-radius: 20px;
    padding: 7px 12px;
    white-space: nowrap;

    span {
      font-size: 12px;
      color: #3bd4ae;
      padding: 8px 16px;
      font-weight: 500;
    }
  }

  &__meetingCreated-unlocked {
    background-color: rgba(248, 118, 20, 0.1);
    border: 1px solid rgba(248, 118, 20, 0.1);
    border-radius: 20px;
    padding: 7px 12px;
    white-space: nowrap;

    span {
      font-size: 12px;
      color: #f87614;
      padding: 8px 16px;
      font-weight: 500;
    }
  }

  &__meetingCreated-canceled,
  &__meetingCreated-unavailable {
    background-color: rgba(255, 74, 85, 0.1);
    border: 1px solid rgba(255, 74, 85, 0.1);
    border-radius: 20px;
    padding: 7px 12px;
    white-space: nowrap;

    span {
      font-size: 12px;
      color: #ff4a55;
      padding: 8px 16px;
      font-weight: 500;
    }
  }

  &__role {
    margin-top: 8px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 4px;

      span.tw-px-2 {
        display: none;
      }
    }

    span {
      color: #422e87;
    }

    span:first-child {
      color: #3d3d3d;
      font-weight: 600;
      font-size: 14px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    span:last-child {
      color: #9f9d9d;
      margin-top: 2px;
      font-size: 14px;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__ltr {
    direction: ltr;
  }

  .nomination &__details {
    margin: 1rem 0;
  }

  &__details {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    @media (max-width: 768px) {
      margin-top: 1.125rem;
      align-items: flex-start;
    }

    h4 {
      width: 100%;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #494d55;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        font-size: 0.875rem;
        font-weight: 400;
        color: #494d55;

        span:last-child {
          font-size: 0.875rem;
          margin-top: 4px;
          font-weight: 500;
          color: #3e3e3e;
          margin-top: 0.75rem;
        }
      }
    }

    span:first-child {
      color: #494d55;
      padding-inline-end: 8px;
    }

    span:last-child {
      color: #3e3e3e;
    }

    img {
      width: 15px;
      height: 15px;

      @media (max-width: 768px) {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      margin-top: 0;
      width: 100%;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px solid #f1f1f1;
      background-color: #fff;
      padding: 7px 20px;
      border-radius: 0.36rem;
      transition: box-shadow 0.3s ease;
      height: 2.6875rem;
      color: #422e87;
      font-weight: 500;

      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }

      span {
        white-space: nowrap;
      }
    }

    img {
      width: 8px;
      height: 13px;
    }
  }

  &__copy {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
    padding: 7px 20px;
    border-radius: 27px;
    height: 2.8rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      border-radius: 0.625rem;
    }

    span {
      font-size: 14px;
      color: #3d3d3d;
      white-space: nowrap;

      @media (max-width: 768px) {
        white-space: pre-line;
        width: 75%;
        word-break: break-word;
        color: #1b1b1b;
        font-size: 0.75rem;
      }
    }

    & > span {
      font-size: 14px;
      color: #422e87;
      font-weight: 600;

      @media (max-width: 768px) {
        font-size: 0.75rem;
      }
    }

    div {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
      }
    }

    button {
      width: 18px;
      height: 20px;
      background-color: transparent;
      outline: none;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.country-flag {
  position: absolute;
  bottom: -5px;
  right: -5px;

  .country-flag-wrapper {
    width: 32px;
    height: 32px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

html[dir="rtl"] {
  .interviewCard {
    &__contractDateArrow {
      transform: rotate(180deg);
    }

    &__actions {
      a img {
        transform: rotate(180deg);
      }
    }
  }
}
</style>