<template>
  <div class="tw-rounded-[20px] tw-bg-white tw-flex tw-flex-col tw-p-4 tw-relative tw-mt-20 tw-w-[220px] tw-min-w-[220px]">
    <!-- Skeleton Profile Image -->
    <div
      class="tw-absolute tw-top-0 tw-left-[50%] tw-w-28 tw-h-28 tw-rounded-full tw-bg-gray-300 tw-animate-pulse"
      style="transform: translate(-50%, -60%);"
    ></div>

    <!-- Skeleton Header -->
    <div class="tw-flex tw-flex-row tw-w-full tw-justify-around tw-mt-12 tw-items-center">
      <!-- Skeleton Verified Badge -->
      <div class="tw-w-6 tw-h-6 tw-rounded-full tw-bg-gray-300 tw-animate-pulse"></div>

      <!-- Skeleton Name -->
      <div class="tw-w-2/4 tw-h-4 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>

      <!-- Skeleton Country Flag -->
      <div class="tw-w-6 tw-h-4 tw-rounded tw-bg-gray-300 tw-animate-pulse"></div>
    </div>

    <!-- Skeleton Role -->
    <div class="tw-flex tw-flex-row tw-w-full tw-justify-center tw-text-sm tw-text-[#292929] tw-mt-4">
      <div class="tw-w-2/3 tw-h-4 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
    </div>

    <!-- Skeleton Previous Companies -->
    <div class="tw-flex tw-flex-col tw-items-center tw-w-full tw-justify-center">
      <!-- "Worked Previously At" Placeholder -->
      <div class="tw-text-[#969696] tw-text-xs tw-my-4">
        <div class="tw-w-3/4 tw-h-4 tw-bg-gray-300 tw-rounded tw-animate-pulse"></div>
      </div>

      <!-- Skeleton Company Logo -->
      <div class="tw-mb-2">
        <div class="tw-w-12 tw-h-12 tw-rounded tw-bg-gray-300 tw-animate-pulse"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TalentCardSkeleton",
};
</script>
