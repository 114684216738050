// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/podcast/Highlight_1.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/podcast/Arrow_07.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".podcast2[data-v-484996ba]{background:#f0eef8!important}.podcast__hero[data-v-484996ba]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");content:\"\";position:absolute;height:88px;width:146px;right:7%;background-repeat:no-repeat;background-position:50%}@media(max-width:768px){.podcast__hero[data-v-484996ba]:before{height:55px;width:93px;right:3%}}.podcast__hero.rtl[data-v-484996ba]:before{right:auto;left:7%;transform:rotateY(180deg)}.podcast__hero[data-v-484996ba]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");content:\"\";position:absolute;height:88px;width:146px;background-repeat:no-repeat;background-position:50%;bottom:-2rem;left:-4rem}@media(max-width:768px){.podcast__hero[data-v-484996ba]:after{height:44px;width:98px;bottom:-3rem;left:0}}.podcast__hero.rtl[data-v-484996ba]:after{left:auto;right:-4rem;transform:rotateY(180deg)}", ""]);
// Exports
module.exports = exports;
