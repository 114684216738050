<template>
  <div class="tw-flex tw-justify-center tw-container tw-w-full tw-m-auto ">
    <div class="tw-m-0 sm:tw-m-10 tw-flex tw-justify-center tw-flex-1 md:tw-gap-20 md:tw-mt-40 md:tw-mb-0 tw-mb-4">
      <div class="md:tw-w-1/2 lg:tw-w-1/2 tw-w-full tw-mx-4 md:tw-mx-0">
        <h2 class=" tw-text-2xl md:tw-text-4xl tw-text-primary tw-font-semibold tw-mb-7 tw-mt-12 md:tw-mt-0">
             {{ $t("signUp.signIn") }} 
        </h2>
        <Login  />
      </div>
      <div class="md:tw-w-1/2 lg:tw-w-1/2 tw-text-center md:tw-flex tw-justify-center tw-items-start tw-hidden ">
        <img loading="lazy"
          class="tw-w-auto tw-max-h-[536.82px] tw-object-contain"
          src="@/assets/images/login-design.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Login from "./login.vue";

export default {
  components: {
    Login,
  },
};
</script>
