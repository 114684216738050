<template>
  <article class="talentCard">
    <div class="talentCard__selectable" :class="{ ready: selectable, selected }">
      <button @click="$emit('checked', talentCard)">
        <img loading="lazy" class="tw-w-[30px] tw-p-[3px]" :src="require('@/assets/images/contract-renewal/ok.svg')" />
      </button>
    </div>
    <div class="talentCard__wrapper">
      <div class="talentCard__header">
        <figure>
          <img loading="lazy" :src="talentProfilePic" :alt="talentCard.talent.email" />

          <div v-if="talentCard.talent?.nationality?.code" class="">
            <country-flag :countryCode="talentCard.talent.nationality.code" class="country" />
          </div>
          <div v-else-if="talentCard.talent?.country?.code" class="">
            <country-flag :countryCode="talentCard.talent.country.code" class="country" />
          </div>
        </figure>
        <div class="talentCard__headings">
          <h3 v-if="activeStatus == 'talent-contract-renewal'">
            Squad: {{ talentCard?.project_name }}
          </h3>
          <h3 v-else>
            {{ talentCard.talent?.full_name }}
            <div class="talentCard__badge">
              <img loading="lazy" class="" :src="
                  talentCard.talent.is_qualifed
                    ? require('@/assets/images/ic_verifed.svg')
                    : require('@/assets/images/ic_unverifed.svg')
                " />
            </div>
          </h3>
          <div class="talentCard__role">
            <span>
              {{ talentCard.talent.role?.name }}
            </span>
            <template v-if="Number(talentCard.talent.experience_years) > 0 && talentCard.talent.experience_years != null">
              <span>
                {{ talentCard.talent.experience_years }} {{ talentCard.talent.experience_years == 1 ? $t('nomination.yearOfExperience') : $t('nomination.yearsExperience') }}
              </span>
            </template>
            <span v-else-if="Number(talentCard.talent.experience_years) == 0 && talentCard.talent.experience_years != null">
              {{ $t('matchedTalents.fresh') }}
            </span>
          </div>
        </div>
        <div class="tw-hidden md:tw-flex">
          <div class="talentCard__stutes" v-if="activeStatus == 'pending_talent'">
            <button class="talentCard__stutes-waiting tw-hidden">
              {{ $t("contractsRenewal.waitingResponse") }}
            </button>
            <button class="talentCard__stutes-pending">
              {{ $t("contractsRenewal.pending") }}
            </button>
          </div>
          <div class="talentCard__stutes" v-if="activeStatus == 'rejected'">
            <button class="talentCard__stutes-rejected-talent" v-if="talentCard.renewal_state === 'talent_rejected'">
              {{ $t("contractsRenewal.rejectedByTalent") }}
            </button>
            <button class="talentCard__stutes-rejected-clinet" v-if="talentCard.renewal_state === 'client_rejected'">
              {{ $t("contractsRenewal.rejectedByClient") }}
            </button>
            
          </div>
          <div class="talentCard__stutes" v-if="activeStatus == 'renewed' || activeStatus == 'talent-contract-renewal' && talentCard.status == 'future'">
            <button class="talentCard__stutes-renewed">
              {{ $t("contractsRenewal.renewed") }}
            </button>
          </div>
          <div class="talentCard__talent-actions" v-if="activeStatus == 'talent-contract-renewal' && talentCard.status !== 'future'">
            <button class="talentCard__talentActions__accept" @click="handleTalentContractRenewal('Accept')">
              <img loading="lazy" src="@/assets/images/icons/ic_right.svg" alt="Accept" />
              {{ $t("contractsRenewal.accept") }}
            </button>
            <button class="talentCard__talentActions__reject" @click="handleTalentContractRenewal('Reject')">
              <img loading="lazy" src="@/assets/images/icons/ic_wrong.svg" alt="Reject" />
              {{ $t("contractsRenewal.reject") }}
            </button>
          </div>
        </div>
      </div>
        <div class="talentCard__details">
          <div class="talentCard__contractPeriod">
            <div>
              <img loading="lazy" src="@/assets/images/contract-renewal/calendar.svg" alt="Calendar" />
              <h4>
                <template v-if="activeStatus === 'talent-contract-renewal'">
                 {{ talentCard.status == 'future' ?  talentCard.start_date : talentCard.renewal_terms.start_date }}
              </template>
              <template v-else-if="activeStatus === 'needs_renewal' || activeStatus === 'renewed'">
                 {{ talentCard.start_date }}
              </template>
              <template v-else>
                  {{  talentCard.renewal_terms.start_date }}
              </template>
                <span>
                  {{ $t("contractsRenewal.contractStart") }}
                </span>
              </h4>
            </div>
          <img loading="lazy" class="talentCard__contractDateArrow" src="@/assets/images/contract-renewal/arrow-right.svg" alt="Arrow" />
            <div>
              <img loading="lazy" src="@/assets/images/contract-renewal/calendar.svg" alt="Calendar" />
              <h4>
              <template v-if="activeStatus === 'talent-contract-renewal'">
                 {{ talentCard.status == 'future' ?  talentCard.end_date : talentCard.renewal_terms.end_date  }}
              </template>
              <template v-else-if="activeStatus === 'needs_renewal' || activeStatus === 'renewed'">
                 {{ talentCard.end_date }}
              </template>
              <template v-else>
                  {{  talentCard.renewal_terms.end_date }}
              </template>
                <span>
                  {{ $t("contractsRenewal.contractEnd") }}
                </span>
              </h4>
            </div>
          </div>
          <div>
            <img loading="lazy" src="@/assets/images/contract-renewal/remote-working.svg" alt="Remote working" />
            <h4>
              
               <template v-if="activeStatus === 'talent-contract-renewal'">
                 {{   talentCard.status == 'future' ? getJobTitle(talentCard.job_type) : getJobTitle(talentCard.renewal_terms.job_type) }}
              </template>
              <template v-else-if="activeStatus === 'needs_renewal' || activeStatus === 'renewed'">
                 {{ getJobTitle(talentCard.job_type) }}
              </template>
              <template v-else>
                  {{ getJobTitle(talentCard.renewal_terms.job_type) }}
              </template>
              <span>
                {{ $t("contractsRenewal.contractType.main") }}
              </span>
            </h4>
          </div>
          <div>
            <img loading="lazy" src="@/assets/images/contract-renewal/time-quarter-to.svg" alt="Remote working" />
            <h4>
             
               <template v-if="activeStatus === 'talent-contract-renewal'">
                 {{  talentCard.status == 'future' ? talentCard.contract_type == 1 ? $t('contractsRenewal.contractType.partTime') :  talentCard.contract_type == 2? $t('contractsRenewal.contractType.fullTime') : $t('contractsRenewal.contractType.monthlyHours') :   talentCard.renewal_terms.contract_type == 1 ? $t('contractsRenewal.contractType.partTime') :  talentCard.renewal_terms.contract_type == 2? $t('contractsRenewal.contractType.fullTime') : $t('contractsRenewal.contractType.monthlyHours') }}
              </template>
              <template v-else-if="activeStatus === 'needs_renewal' || activeStatus === 'renewed'">
                 {{ talentCard.contract_type == 1 ? $t('contractsRenewal.contractType.partTime') : talentCard.contract_type == 2? $t('contractsRenewal.contractType.fullTime') : $t('contractsRenewal.contractType.monthlyHours') }}
              </template>
              <template v-else>
                  {{  talentCard.renewal_terms.contract_type == 1 ? $t('contractsRenewal.contractType.partTime') :  talentCard.renewal_terms.contract_type == 2? $t('contractsRenewal.contractType.fullTime') : $t('contractsRenewal.contractType.monthlyHours') }}
              </template>
              <span>
                {{ $t("contractsRenewal.teamAvailability") }}
              </span>
            </h4>
          </div>
          <div>
            <img loading="lazy" src="@/assets/images/contract-renewal/finance.svg" alt="Remote working" />
            <h4>
              <template v-if="activeStatus === 'talent-contract-renewal'">
                {{  talentCard.status == 'future' ? talentCard.salary.basic_salary : talentCard.renewal_terms.salary.basic_salary  }}
              </template>
              <template v-else-if="activeStatus === 'needs_renewal' || activeStatus === 'renewed'">
                {{  talentCard.salary.total_salary }}
              </template>
              <template v-else>
                 {{  talentCard.renewal_terms.salary.total_salary }}
              </template>

              {{ $t("contractsRenewal.usd") }}
              <span>
                {{ $t("contractsRenewal.salary") }}
              </span>
            </h4>
          </div>
        </div>
      <div class="talentCard__actions" v-if="activeStatus == 'needs_renewal'">
        <button @click="renewTalent" :disabled="selectable">
          <img loading="lazy" src="@/assets/images/contract-renewal/renew.svg" alt="Renew" />
          <span>{{ $t("contractsRenewal.renew") }}</span>
        </button>
        <button @click="changeTalentContract" :disabled="selectable">
          <img loading="lazy" src="@/assets/images/contract-renewal/change.svg" alt="Change contract terms" />
          <span>{{ $t("contractsRenewal.changeContract") }}</span>
        </button>
        <button @click="rejectTalent" :disabled="selectable">
          <img loading="lazy" src="@/assets/images/contract-renewal/reject.svg" alt="Reject" />
          <span>{{ $t("contractsRenewal.reject") }}</span>
        </button>
      </div>
      <div class="md:tw-hidden tw-flex">
        <div class="talentCard__stutes" v-if="activeStatus == 'pending_talent'">
          <button class="talentCard__stutes-waiting tw-hidden">
            {{ $t("contractsRenewal.waitingResponse") }}
          </button>
          <button class="talentCard__stutes-pending">
            {{ $t("contractsRenewal.pending") }}
          </button>
        </div>
        <div class="talentCard__stutes" v-if="activeStatus == 'rejected'">
          <button class="talentCard__stutes-rejected-clinet">
            {{ $t("contractsRenewal.rejectedByClient") }}
          </button>
          <button class="talentCard__stutes-rejected-talent">
            {{ $t("contractsRenewal.rejectedByTalent") }}
          </button>
        </div>
        <div class="talentCard__stutes" v-if="activeStatus == 'renewed' || activeStatus == 'talent-contract-renewal' && talentCard.status == 'future'">
          <button class="talentCard__stutes-renewed tw-w-full">
            {{ $t("contractsRenewal.renewed") }}
          </button>
        </div>
        <div class="talentCard__talent-actions" v-if="activeStatus == 'talent-contract-renewal' && talentCard.status !== 'future'">
          <button class="talentCard__talentActions__accept" @click="handleTalentContractRenewal('Accept')">
            <img loading="lazy" src="@/assets/images/icons/ic_right.svg" alt="Accept" />
            {{ $t("contractsRenewal.accept") }}
          </button>
          <button class="talentCard__talentActions__reject" @click="handleTalentContractRenewal('Reject')">
            <img loading="lazy" src="@/assets/images/icons/ic_wrong.svg" alt="Reject" />
            {{ $t("contractsRenewal.reject") }}
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import CountryFlag from "@/components/shared/countryFalg/countryFalg.vue";

export default {
  name: "TalentRenewalCard",
  components: {
    CountryFlag,
  },
  props: {
    talentCard: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    activeStatus: {
      type: String,
      default: "needs_renewal",
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
    };
  },
  methods: {
    renewTalent() {
      this.$emit("renewTalent", this.talentCard);
    },
    changeTalentContract() {
      this.$emit("changeTalentContract", this.talentCard);
    },
    rejectTalent() {
      this.$emit("rejectTalent", this.talentCard);
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: this.$t("contractsRenewal.contractType.remote"),
          value: "remote"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-accommodation"),
          value: "onsite-accommodation"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite"),
          value: "onsite"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-business-visa"),
          value: "onsite-business-visa"
        },
        {
          text: this.$t("contractsRenewal.contractType.hybrid"),
          value: "hybrid"
        }
      ];

      return jobs.find(j => j.value == type)?.text;
    },
    handleTalentContractRenewal(stutes) {
      if (stutes == "Accept") {
        this.$emit("renewTalentAccept", this.talentCard);
      } else {
        this.$emit("renewTalentRejected", this.talentCard);
      }
    },
  },
  computed: {
    talentProfilePic() {
      if (this.talentCard.talent.image && this.talentCard.talent.image && !this.talentCard.talent.image.includes("talent-avatar"))
        return `${this.talentCard.talent.image}`;
      else return require("@/assets/images/talent-avatar.png");
    },
  },
};
</script>

<style lang="scss">
.talentCard {
  display: flex;
  align-items: center;
  width: 100%;

  &__selectable {
    width: 0;
    transition: width 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;

    button {
      color: white;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 2px solid #c5c5c5;
      opacity: 0;
      transition: opacity 0.4s ease;
    }

    &.ready {
      width: 40px;

      button {
        opacity: 1;
      }
    }

    &.selected {
      button {
        border-color: #422e87;
        background-color: #422e87;
      }
    }
  }

  &__wrapper {
    width: 100%;
    padding: 26px 16px;
    border-radius: 20px;
    box-shadow: 9px 7px 45px 0 rgba(66, 46, 135, 0.09);
    margin: 20px 0;

    @media (min-width: 768px) {
      padding: 32px 26px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 12px;

    figure {
      position: relative;
    }

    img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 50%;
    }

    .country {
      width: 24px;
      height: 24px;
     
        img {
      width: 24px;
      height: 24px;
      object-fit: cover;
      border-radius: 50%;
    }
      position: absolute;
      bottom: 0;
      background-color: #ccc;
    }
  }

  &__headings {
     margin-inline-end: auto;
    h3 {
      display: flex;
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__talent-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    @media (min-width: 768px) {
        flex-direction: row;
        margin-inline-start: auto;
    }



    button {
       min-width: 100%;
       @media (min-width: 768px) {
        min-width: 211px;
        padding: 13px 32px;
       }
      
      padding: 13px 32px;
      border-radius: 8px;
      background-color: #f5f5f5;
      color: #9f9d9d;
      font-size: 12px;
      font-weight: 600;
      border: 0.95px solid rgba(225, 225, 225, 1);
      border-radius: 11.4px;
      color: rgba(248, 118, 20, 1);
      line-height: 25.51px;
      background-color: #fff;
      font-size: 16.15px;
      color: rgba(0, 0, 0, 1);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-inline-end: 0.5rem;
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    }
  }

    &__stutes{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;

    &-waiting {
      min-width: 211px;
      padding: 13px 32px;
      border-radius: 8px;
      background-color: #f5f5f5;
      color: #9f9d9d;
      font-size: 12px;
      font-weight: 600;
      border: none;
      font-size: 15.68px;
      color: rgba(248, 118, 20, 1);
      line-height: 25.51px;
      background-color: rgba(248, 118, 20, 0.1);
      border-radius: 25.5px;
      cursor: default !important;

    }
    &-pending {
       min-width: 100%;
       @media (min-width: 768px) {
        min-width: 211px;
        padding: 13px 32px;
       }
      padding: 8px 32px;
      border-radius: 8px;
      background-color: #f5f5f5;
      color: #9f9d9d;
      font-size: 12px;
      font-weight: 600;
      border: none;
      font-size: 15.68px;
      color: #422e87;
      line-height: 25.51px;
      background-color: rgba(237, 233, 251, 1);
      border-radius: 25.5px;
      cursor: default !important;

    }
    &-renewed {
      min-width: 211px;
      padding: 13px 32px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 600;
      border: none;
      font-size: 15.68px;
      color: #26daa6;
      line-height: 25.51px;
      background-color: rgba(59, 212, 174, 0.1);
      border-radius: 25.5px;
    }
    &-rejected-clinet {
       min-width: 211px;
      padding: 13px 32px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 600;
      border: none;
      font-size: 15.68px;
      color: #ff6f59;
      line-height: 25.51px;
      background-color: rgba(255, 111, 89, 0.1);
      border-radius: 25.5px;
    }
    &-rejected-talent {
       min-width: 211px;
      padding: 13px 32px;
      border-radius: 8px;
      background-color: #f5f5f5;
      color: #9f9d9d;
      font-size: 12px;
      font-weight: 600;
      border: none;
      font-size: 15.68px;
      color: #ffc629;
      line-height: 25.51px;
      background-color: rgba(255, 198, 41, 0.1);
      border-radius: 25.5px;
    }
  }

  

  &__role {
    margin-top: 8px;
    display: flex;
    align-items: center;

    span:first-child {
      color: #422e87;
      font-size: 14px;
      font-weight: 600;
    }
    span:last-child {
      color: #9f9d9d;
      font-size: 12px;
      margin-inline-start: 6px;
      margin-top: 2px;
      @media (max-width: 768px) {
        margin-inline-start: 0;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 16px;
   

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 992px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
        @media (max-width: 768px) {
          align-items: center;
      }
    }

    & > div {
      padding: 12px 10px;
    }

    & > div:not(:last-child) {
          @media (min-width: 768px) {
            border-inline-end: 1px solid rgba(241, 241, 241, 1);
          }
          
          border-inline-end: transparent 1px solid;
    }

    h4 {
      width: 100%;
      font-size: 14px;
      margin-bottom: 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: 768px) {
        font-size: 12px;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }
    }

    span {
      font-size: 12px;
      color: #9f9d9d;

      @media (max-width: 768px) {
        font-size: 10px;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__contractPeriod {
    justify-content: space-around !important;

    @media (min-width: 768px) {
      grid-column: span 2 / span 2;
    }
    background-color: rgba(250, 250, 250, 1);
    border-radius: 8px;
    border: 1px solid rgba(241, 241, 241, 1);

    h4 {
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 18px 0 0;
    border-top: 1px solid rgba(241, 241, 241, 1);
    gap: 25px;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      padding: 16px;
      border: 1px solid rgba(241, 241, 241, 1);
      border-radius: 12px;
      transition: box-shadow 0.3s ease;

      @media (min-width: 992px) {
        width: 33.3333%;
      }

      img {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }

      &[disabled] {
        cursor: not-allowed;
      }
    }
  }

  &__badge {
    img {
      margin: 0 5px;
      height: 25px;
      display: flex;
      width: 25px;
    }
  }
}

.country-flag {
  position: absolute;
  bottom: -5px;
  right: -5px;
  .country-flag-wrapper {
    width: 32px;
    height: 32px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

html[dir="rtl"] {
  .talentCard {
    &__contractDateArrow {
      transform: rotate(180deg);
    }
  }
}
</style>
