<template>
  <transition name="modal-fade">
    <div class="modal align-items-center" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <!--  <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div> -->

          <div class="tw-pb-4 modal-body no-border">
            <div class="row">
              <div class="tw-text-start col-12 meetBox">
                <div class="p-0 tw-mb-4 tw-flex tw-flex-col tw-justify-center tw-items-center">

                    <img class=" tw-w-[22rem] tw-h-[12rem] tw-mb-6 " src="@/assets/images/talent/undraw_meeting_re_i53h.svg" alt="meeting">

                    <h2 class="tw-text-[#292929]  md:tw-text-[24px] tw-text-[18px] tw-font-semibold tw-leading-10 tw-text-center tw-mb-2 ">
                      {{ $t('requestInterview.confirmUnavailable') }}
                    </h2>
                   
                </div>
                <div class="modal-footer tw-p-0 tw-justify-center tw-flex md:tw-flex-row tw-flex-col-reverse">
                  <button type="button" class="btn poppins-font !tw-px-12 tw-rounded-xl  tw-w-full md:tw-w-auto tw-border-2 tw-border-solid tw-border-secondary tw-text-secondary hover:tw-border-secondary hover:tw-text-secondary" 
                    data-bs-dismiss="modal" @click="close">
                    {{ $t('requestInterview.cancel') }}
                  </button>
                  <button type="button" class="btn btn-primary saveBtn !tw-px-12  tw-w-full md:tw-w-auto"  @click="confirm">
                    {{ $t('requestInterview.confirm') }}
                  </button>
                </div>
              </div>
            </div>
           
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "ChooseInterviewSlotConfirmModel",
  props: ["isOpen"],
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
  },
  methods: {
    confirm() {
      this.$emit("confirm", "decline");
      this.close()
    },
    close() {
      document.body.classList.remove("modal-open");
      this.isRequestAnInterviewLoading = false;
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 45rem;
    width: 45rem;
    margin: 1.75rem auto;
  }
}

.modal-body.no-border {
       overflow: scroll;
        overflow-x: hidden;
}
</style>


<style lang="scss">
.modal-open {
  overflow: hidden;
}

.book-meeting-mb {
  min-height: 500px;

  @media (max-width: 786px) {
    margin-bottom: 5rem;
  }
}

.book-meeting-mb .calendly {
  min-height: 500px;
  height: 500px !important;
}

.book-meeting-mb .calendly iframe {
  min-height: 500px;
}

.meetBox .w-full {
  width: 100% !important;
}
</style>
