<template>
  <div class="container px-0 z-index-1 position-relative">
    <nav class="navbar navbar-expand-xl landing-nav ps-2">
      <router-link class="navbar-brand" to="/">
        <img loading="lazy" v-if="activeTab == 'talent'" src="@/assets/images/logo/2Asset 1.svg" alt="" width="120"/>
        <img loading="lazy" v-else src="@/assets/images/logo/Asset 1.svg" alt="" width="120" />
      </router-link>

      <div class="btn-group loggedin-dropdown" style="order: 2">
        <button
          v-if="activeTab == 'client'"
          type="button"
          class="ml-auto text-white btn login dark"
          @click="() => handleRedirection('/login')"
        >
          {{ $t("home.login") }}
        </button>
        <button
          v-else
          type="button"
          class="ml-auto text-white btn login"
          @click="() => handleRedirection('/login')"
        >
          {{ $t("home.login") }}
        </button>

        <LanguageSwitcher v-if="activeTab == 'client'" has-divider class="language-switcher-large" black bold />
        <LanguageSwitcher v-else has-divider class="language-switcher-large" />
      </div>
      <!--  <div class="login-btn" style="order: 3">
      
      </div> -->
      
     <LanguageSwitcher separator-light has-divider divider-at-start :black="activeTab == 'client'" class="me-2 ms-auto" /> 

      <button
        class="navbar-toggler"
        :class="{ light: activeTab == 'talent' }"
        type="button"
        @click="triggerMobileMenu = !triggerMobileMenu"
      >
        <!-- <span class="navbar-toggler-icon"> -->
        <svg data-v-0315f6de="" width="20px" height="23px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g data-v-0315f6de="" id="Client" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0315f6de="" id="02.01.squadio2-final-design" transform="translate(-1462.000000, -39.000000)"><g data-v-0315f6de="" id="Group-5" transform="translate(1462.000000, 39.000000)"><rect data-v-0315f6de="" id="Rectangle" x="0" y="0" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="8" width="20" height="2" rx="1"></rect><rect data-v-0315f6de="" id="Rectangle" x="0" y="16" width="20" height="2" rx="1"></rect></g></g></g></svg>
        <!-- </span> -->
      </button>

       <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul
          class="mb-2 nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
        >
          <template v-if="activeTab == 'client'">
             <li>
              <router-link to="/" class="px-3 nav-link dark">
                {{ $t("home.home") }}</router-link
              >
            </li>
           
           
            <li class="language-switcher-small">
              <LanguageSwitcher class="ml-2" />
            </li>
          </template>
        </ul>
        <button
          v-if="activeTab == 'client'"
          type="button"
          class="nav-button btn btn-primary purple-btn btn-md"
          @click="() => handleRedirection('/client-signup')"
        >
          {{ $t("home.hireTechTeam") }}
        </button>
        <button
          v-else
          type="button"
          class="nav-button btn btn-primary btn-md"
          @click="() => handleRedirection('/talent-signup')"
        >
          {{ $t("home.applyAsATalent") }}
        </button>
      </div>

      <mobile-menu
        :is-open="triggerMobileMenu"
        not-registered
        @close-menu="menuClosed"
      >
        <template v-if="activeTab == 'client'">
          <li class="mobileMenu__item">
            <router-link  to="/">
              {{ $t("home.home") }}
            </router-link>
          </li>

           
        
        </template>
        <template v-else>
          <li class="mobileMenu__item">
            <a href="#">
              {{ $t("talent.talentHome") }}
            </a>
          </li>
          <li class="mobileMenu__item">
            <a href="#bringYourBuddy">
              {{ $t("talent.bringYourBuddy") }}</a
            >
          </li>
        </template>
      </mobile-menu>
    </nav>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
import MobileMenu from "@/components/MobileMenu";

export default {
  props: {
    activeTab: {
      type: String,
      default: "client"
    },
  },
  components: {
    LanguageSwitcher,
    MobileMenu,
  },
  data() {
    return {
      triggerMobileMenu: false,
    };
  },
  mounted() {
    const body = document.querySelector('body');
    body.classList.remove('is-fixed');
  },
  methods: {
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector('body');
      body.classList.remove('is-fixed');
    },
    handleRedirection(path) {
      this.$router.push({ path });
    },
  },
};
</script>


<style scoped>
.nav-button {
  border-radius: 30px;
}

.language-switcher-large {
  display: flex;
  @media (max-width: 786px) {
    display: none !important;
  }
}

.language-switcher-small {
  display: none;

  @media (max-width: 786px) {
    display: flex;
  }
}

.dark {
  color: #414042 !important;
}

.loggedin-dropdown * {
  font-weight: 600 !important;
}
</style>
