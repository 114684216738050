<template>
  <div :class="`shared-select ${wrapperStyle}`">
    <label
      class="mb-1"
      :class="isAstrik ? 'form-label required' : ''"
      v-if="!hideLabel"
    >
      {{ label }}
    </label>
    <v-select
      v-model="validationField.$model"
      :reduce="(name) => (keySelected ? name[keySelected] : name.id)"
      :class="`style-chooser ${inputStyle}`"
      :placeholder="placeholder"
      :options="selectOptions"
      :disabled="isDisabled"
      label="name"
      value="id"
      @input="(e) => handleInputChange(e)"
    >
      <div slot="no-options">
        {{ $t("talent.sorryNoMatchingOptions") }}
      </div>
     <!--  <template v-slot:option="option">
        {{ option.name }}
      </template> -->
      <template #selected-option="selected">
        <div>
          <!-- Access selected.label and manipulate it as needed -->
          <span
            >{{ selected.name  }}
          </span>
        </div>
      </template>
    </v-select>
    <VulidateError :validationField="validationField" :params="params" />
  </div>
</template>

<script>
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";

import "@/components/shared/formControls/select/select.scss";
export default {
  components: { VulidateError },
  props: [
    "validationField",
    "wrapperStyle",
    "selectOptions",
    "placeholder",
    "keySelected",
    "isDisabled",
    "inputStyle",
    "className",
    "isAstrik",
    "params",
    "label",
    "hideLabel",
    "iscountry",
  ],
  computed: {
    console: () => console,
  },
  methods: {
    handleInputChange(e) {
      this.$emit("handleChange", e);
    },
  },
};
</script>
