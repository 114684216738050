// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/talent/ic_edit-large.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tabHeader .headerBorder{font-size:21px;position:relative;display:inline-block;margin-bottom:2rem}.tabHeader .headerBorder:after{position:absolute;z-index:0;width:100%;height:24px;content:\"\";background-color:#f1edff;top:5px;left:0}.tabHeader .headerBorder span{position:relative;z-index:1}.tabHeader .edit-tab-icon{position:absolute;top:0;right:0;border:1px solid #f2f2f2;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;border-radius:9px;display:inline-block;padding:20px;background-position:50%;transition:all .3s ease}.tabHeader .edit-tab-icon:hover{background-color:#f4f4f4;cursor:pointer;transform:scale(1.1)}", ""]);
// Exports
module.exports = exports;
