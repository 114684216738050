<template>
  <div class="">
    <div class="blur" v-if="talent.status === 'expired'">
      <div class="nominated-list-card__expired">
        {{ $t("nomination.expired") }}
      </div>
    </div>
    <div class="nominated-list-card">
      <div
        class="nominated-list-card__header"
        :class="{
          'align-items-center':
            currentSkillId !== talent.role.id,
        }"
      >
        <div class="d-flex align-items-center">
          <div class="nominated-list-card__img">
            <img loading="lazy"
              class="avater"
              src="@/assets/images/talent-avatar.png"
              v-if="!talent.talent.image"
              alt=""
            />
            <img loading="lazy"
              class="avater"
              :src="`${baseUrl}${talent.talent.image.path}`"
              v-if="talent.talent.image"
              @error="imageLoadOnError"
              alt=""
            />
            <div class="country-flag" v-if="talent.talent.basicInfo.nationality">
              <CountryFlag
                class="nominated-country-flag"
                :countryCode="talent.talent.basicInfo.nationality.code"
              />
            </div>
            <div class="country-flag" v-else-if="talent.talent.basicInfo.country">
              <CountryFlag
                class="nominated-country-flag"
                :countryCode="talent.talent.basicInfo.country.code"
              />
            </div>
          </div>
          <div class="nominated-list-card__talent">
            <div class="nominated-list-card__info">
              <div class="nominated-list-card__name">
                <a
                  :class="{
                    'nominated-list-card-name-width':
                      talent.talent.basicInfo.full_name?.length > 20,
                  }"
                  target="_blank"
                  :href="talent.talent.basicInfo.sharable_link"
                  >{{ splitName(talent.talent.basicInfo.full_name) }}</a
                >
                <img loading="lazy"
                  v-tooltip.top="{
                    content: `${
                      talent.talent.basicInfo.is_verified
                        ? $t('nomination.verified')
                        : $t('nomination.unverified')
                    }`,
                  }"
                  :src="
                    talent.talent.basicInfo.is_verified
                      ? require('@/assets/images/ic_verifed.svg')
                      : require('@/assets/images/ic_unverifed.svg')
                  "
                  alt=""
                />
              </div>
              <div
                class="nominated-list-card__role"
                v-if="currentSkillId === talent.role.id"
              >
                {{ talent.role.name }}
              </div>
            </div>
            <div
              class="nominated-list-card__experince"
              v-if="
                talent.role.name !== 'Full Stack Developer' &&
                currentSkillId === talent.role.id
              "
            > 
              <template v-if="talent.talent.basicInfo.experience_years">
                {{ talent.talent.basicInfo.experience_years }} 
              {{
                talent.talent.basicInfo.experience_years > 1
                  ? $t("nomination.yearsExperience")
                  : $t("nomination.yearOfExperience")
              }}
              </template>
              <template v-else>
                {{ $t('matchedTalents.fresh') }}
              </template>
              
            </div>
            
          </div>
        </div>

        <div class="nominated-list-card__availability">
          <ul>
            <li v-if="talent.talent.basicInfo.full_time_status">
              <span class="nominated-list-card__availability__type">
                {{ $t("nomination.fullTime") }}
              </span>
              <span class="nominated-list-card__availability__at">{{
                getAvailabilityTimeName(
                  talent.talent.basicInfo.full_time_status
                )
              }}</span>
              <span
                v-if="talent.talent.basicInfo.salary"
                class="nominated-list-card__availability__salary"
                >{{ talent.talent.basicInfo.salary }} $</span
              >
            </li>
            <li v-if="talent.talent.basicInfo.part_time_status">
              <span class="nominated-list-card__availability__type">
                {{ $t("nomination.partTime") }}
              </span>
              <span class="nominated-list-card__availability__at">{{
                getAvailabilityTimeName(
                  talent.talent.basicInfo.part_time_status
                )
              }}</span>
              <span
                v-if="talent.talent.basicInfo.part_time_salary"
                class="nominated-list-card__availability__salary"
                >{{ talent.talent.basicInfo.part_time_salary }} $</span
              >
            </li>
          </ul>
        </div>
        <div
          class="lg:tw-flex tw-hidden tw-ms-auto nominated-list-card__veiw-profile"
        >
          <a
            :href="talent.talent.basicInfo.sharable_link"
            target="_blank"
            >{{ $t("matchedTalents.viewProfile") }}</a
          >
        </div>
      </div>
      <div
        class="nominated-list-card__description"
        v-if="talent.talent.basicInfo.bio"
      >
        {{ bio(talent.talent.basicInfo.bio) }}
        <span
          @click="showMore = true"
          v-if="!showMore && talent.talent.basicInfo.bio?.length > 125"
        >
          {{ $t("nomination.readMore") }}
        </span>
        <span
          @click="showMore = false"
          v-if="showMore && talent.talent.basicInfo.bio?.length > 125"
        >
          {{ $t("nomination.readLess") }}
        </span>
      </div>

      <div
        class="nominated-list-card__assessments"
        v-if="talent.talent.basicInfo.assessments?.length"
      >
        <Assessments :talent="talent" />
      </div>

      <div class="nominated-list-card__skills" v-if="skills?.length">
        <div class="skill-tags-wrapper">
          <div
            class="skill-tag"
            :class="{
              'skill-tag-without-experience': !skill.years && !skill.months,
            }"
            v-for="skill in skills"
            :key="skill.id"
          >
            <span class="skill-name">{{ skill.name }}</span>
            <span
              class="skill-experience"
              v-if="skill.years > 0 || skill.months > 0"
            >
              {{ skill.years > 0 ? skill.years + " Y" : "" }}
              <span
                style="padding: 0"
                v-if="skill.years > 0 && skill.months > 0"
              >
                &
              </span>
              {{ skill.months > 0 ? skill.months + " M" : "" }}

              of experience
            </span>
          </div>
        </div>
      </div>

      <div
        class="nominated-list-card__actions"
        :style="
          !skills.length
            ? 'border-top: 1px solid #edebeb; padding-top: 14px; margin-top: 14px;'
            : ''
        "
      >
        <div class="nominated-list-card__questions">
          <InquirySendBox
            :currentTalent="talent.id"
            @inquirySent="inquirySent(talent.id)"
          />
        </div>
        <div class="nominated-list-card__take-action">
          <a
            class="lg:tw-hidden md:tw-hidden tw-flex nominated-list-card__veiw-profile-sm"
            :href="talent.talent.basicInfo.sharable_link"
            target="_blank"
            >{{ $t("matchedTalents.viewProfile") }}</a
          >
          <button
            :disabled="talent.status === 'interview_requested'"
            @click="requestAnInterview(talent)"
            class="btn"
            :class="
              talent.status === 'interview_requested'
                ? 'btn-interested-disabled'
                : 'btn-interested'
            "
          >
            {{
              talent.status === "interview_requested"
                ? $t("nomination.requested")
                : $t("nomination.requestAnInterview")
            }}
          </button>

          <button
            :disabled="talent.status === 'interview_requested'"
            @click="notInterested(talent)"
            class="btn btn-not-interested"
          >
            <img loading="lazy" src="@/assets/images/heart.svg" alt="" />

            {{ $t("nomination.notInterested") }}
          </button>
        </div>
      </div>

      <div class="nominated-list-card__support">
        <Support
          :talent="talent"
          :inquiryList="inquiryList"
          :isLoading="getInquiryIsLoading"
          @inquirySent="inquirySent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Assessments from "@/pages/Client/nominatedProfiles/components/assessments/assessments.vue";
import Support from "@/pages/Client/nominatedProfiles/components/support/support.vue";
import { availabilityOptions } from "@/static/AvailabilityOptions.js";
import CountryFlag from "@/components/shared/countryFalg/countryFalg.vue";
import InquirySendBox from "@/pages/Client/nominatedProfiles/components/inquirySendBox/inquirySendBox.vue";

export default {
  props: {
    talent: {
      type: Object,
      required: true,
    },
    currentSkillId: {
      type: Number,
    },
  },
  components: {
    Assessments,
    Support,
    CountryFlag,
    InquirySendBox,
  },
  data() {
    return {
      showMore: false,
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      getInquiryIsLoading: false,
      inquiryList: []
    };
  },
  mounted() {
   this.inquiryList =  this.talent.comments
            ? this.talent.comments.slice(0, 2).reverse()
            : [];
  },
  computed: {
    skills() {
      if (this.talent.role.name === "Full Stack Developer") {
        let backend = {
          name: this.talent.talent.basicInfo.backend_lang,
          id: new Date() + 1,
          years: this.talent.talent.basicInfo.experience_years,
          months: 0,
        };

        let frontend = {
          name: this.talent.talent.basicInfo.frontend_lang,
          id: new Date() + 2,
          years: this.talent.talent.basicInfo.second_experience_years,
          months: "",
        };

        if (this.currentSkillId !== this.talent.role.id) {
          let currentSkill = {
            name: this.talent.role.name,
            id: new Date() + 3,
            years: this.talent.talent.basicInfo.experience_years,
            months: "",
          };
          return [
            currentSkill,
            backend,
            frontend,
            ...this.talent.talent.basicInfo.skills,
          ];
        }

        return [backend, frontend, ...this.talent.talent.basicInfo.skills];
      } else {
        if (this.currentSkillId !== this.talent.role.id) {
          let currentSkill = {
            name: this.talent.role.name,
            id: new Date() + 3,
            years: this.talent.talent.basicInfo.experience_years,
            months: "",
          };
          return [currentSkill, ...this.talent.talent.basicInfo.skills];
        }
        return [...this.talent.talent.basicInfo.skills];
      }
    },
  },
  methods: {
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
    },
    splitName(name) {
      if (name?.length > 20) {
        const str = name;
        const mid = Math.floor(str.length / 2);
        const result = [str.slice(0, mid), str.slice(mid)];
        return result.join("\n");
      } else {
        return name;
      }
    },
    bio(bio) {
      let text = bio;

      return text.length > 125 && !this.showMore
        ? text.substring(0, 125) + "..."
        : text;
    },
    getAvailabilityTimeName(id) {
      if (id !== null) {
        return this.$t(
          `availabilityOptions.${[
            availabilityOptions.find((ele) => ele.id === id)?.name,
          ]}`
        );
      } else {
        return this.$t(`availabilityOptions.locked`);
      }
    },
    notInterested(talent) {
      this.$emit("notInterested", talent);
    },
    requestAnInterview(talent) {
      this.$emit("requestAnInterview", talent);
    },
    inquirySent() {
       this.axios
        .get(`/api/client/nomination/${this.talent.id}/ticket`)
        .then((res) => {
          this.inquiryList = res.data.data.slice(0, 2).reverse()
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nominated-list {
  .blur {
    background: rgba(
      255,
      255,
      255,
      0.2
    );
    backdrop-filter: blur(2.5px);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    border-radius: 26.4px;
  }

  .nominated-list-card__expired {
    display: flex;
    font-size: 19.98px;
    color: #ff6f59;
    line-height: 19.98px;
    font-weight: 600;
    background-color: rgb(255 212 206 / 68%);
    border-radius: 27.5px;
    height: 55px;
    width: 146px;
    align-items: center;
    justify-content: center;
    right: 5rem;
    position: absolute;
    top: 3rem;
    @media (max-width: 576px) {
      right: 2rem;
      top: 2rem;
    }
  }

  .nominated-list-card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 26.4px;
    box-shadow: 11px 8px 56px 0 #422e8717;
    padding: 30px 40px;
    margin-bottom: 2rem;
    position: relative;

    .nominated-list-card__veiw-profile {
      margin-bottom: auto;
      font-weight: 600;
      background: #fff;
      color: #422e87;
      text-decoration: underline;
      margin-top: 1rem;
      font-size: 18px;
    }

    .nominated-list-card__veiw-profile-sm {
      border: 1px solid #422e87;
      border-radius: 12.49px;
      padding: 16px 30px;
      font-size: 18px;
      font-weight: 600;
      background: #fff;
      color: #422e87;
      width: 50%;
      text-align: center;
      max-height: 72px;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      width: 100%;
      padding: 10px 30px;
      width: 100%;
      margin-bottom: 1rem;
      font-size: 15px;
    }

    @media (max-width: 576px) {
      padding: 20px;
      margin-bottom: 1rem;
    }

    &__talent {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__header {
      display: flex;
      align-items: flex-end;
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__img {
      position: relative;

      .country-flag {
        position: absolute;
        bottom: 0;
        right: 5%;
        .country-flag-wrapper.nominated-country-flag {
          height: 22px;
          width: 22px;
          border-radius: 50%;
        }

        .country-flag-wrapper.nominated-country-flag img {
          width: 100% !important;
          height: 100% !important;
          border-radius: 50% !important;
          -o-object-fit: fill !important;
          object-fit: fill !important;
        }
      }
      .avater {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 576px) {
          height: 68px;
          width: 68px;
        }
      }
    }

    &__info {
      margin-left: 1rem;

      margin-right: 1rem;
      text-align: left;
      display: flex;
      flex-direction: column;
    }

    .nominated-list-card-name-width {
      width: 200px;
    }

    &__name {
      text-align: left;
      margin-bottom: 0.5rem;
      margin-top: 0.6rem;
      display: flex;
      align-items: center;

      @media (max-width: 576px) {
        margin-bottom: 0;
        margin-top: 0;
        align-items: center;
      }

      a {
        font-size: 20px;
        color: #292929;
        line-height: 25px;
        font-weight: 600;
        clear: both;
        overflow: hidden;
        text-decoration: none;
        word-break: break-all;
        display: flex;
        height: 100%;
        cursor: pointer;

        @media (max-width: 576px) {
          font-size: 16px;
          color: #292929;
          text-align: center;
          line-height: 40.9px;
          width: unset;
          clear: both;
          overflow: hidden;
          text-decoration: none;
          word-break: break-all;
          text-align: left;
          line-height: 30px;
        }
      }

      img {
        height: 26px;
        width: 25px;
        margin-left: 0.5rem;
      }
    }
    &__role {
      font-size: 18px;
      color: #414042;
      line-height: 30px;
      font-weight: 600;
      text-align: left;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 576px) {
        font-size: 14.15px;
        color: #414042;
        text-align: center;
        line-height: 22.72px;
        text-align: left;
      }
    }

    &__availability {
      display: flex;
      align-items: flex-end;
      position: relative;

      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-left: 0;

        li {
          display: flex;
          flex-wrap: wrap;
          background-color: #f8f8f8;
          border-radius: 23px;
          color: #292929;
          padding: 5px 16px;
          margin-right: 10px;
          align-items: center;
          margin-bottom: 0.25rem;
          min-height: 40px;

          @media (max-width: 576px) {
            padding: 5px 10px;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .nominated-list-card__availability__type {
            margin-right: 5px;
            font-weight: 500;
            font-size: 15.5px;
            color: #292929;
            @media (max-width: 576px) {
              font-size: 12px;
            }
          }

          .nominated-list-card__availability__at {
            font-size: 14px;
            color: #808285;
            margin-right: 5px;
            @media (max-width: 576px) {
              font-size: 10px;
            }
          }

          .nominated-list-card__availability__salary {
            font-size: 20px;
            color: #373a3b;
            font-weight: 600;

            @media (max-width: 576px) {
              font-size: 15px;
            }
          }
        }
      }
    }

    &__description {
      color: #9f9d9d;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      word-break: break-all;
      margin-top: 25px;
      @media (max-width: 576px) {
        font-size: 11px;
        margin-top: 10px;
      }

      span {
        font-size: 16px;
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 576px) {
          font-size: 11px;
        }
      }
    }
  }

  .nominated-list-card__experince {
    font-size: 16px;
    color: #422e87;
    text-align: center;
    line-height: 25.78px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 1rem;
    white-space: nowrap;

    @media (max-width: 576px) {
      font-size: 15px;
    }

    &:before {
      display: flex;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: #d7d3e6;
      content: "";
      margin-right: 5px;
      @media (max-width: 576px) {
        display: none;
      }
    }

    &:after {
      display: flex;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: #d7d3e6;
      content: "";
      margin-left: 5px;
      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  .nominated-list-card__skills {
    margin: 1rem 0;
    padding: 1rem 0;
    padding-bottom: 8px;
    border-top: 1px solid #edebeb;
    border-bottom: 1px solid #edebeb;

    .skill-tags-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-items: baseline;

      .skill-tag {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #eee;
        border-radius: 18px;
        color: #646464;
        padding: 6px 14px;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: #fafafa;
        align-items: center;
        background-color: #7d67c91a;
        border-radius: 20px;
        direction: ltr;
        .skill-name {
          font-size: 15px;
          margin-right: 5px;
          color: #422e87;
          font-weight: 500;
          @media (max-width: 576px) {
            font-size: 14px;
          }
        }

        .skill-experience {
          font-size: 12px;
          padding: 4px 15px;
          border-radius: 5px;
          color: #494d55;
          font-weight: 500;
          background-color: #7d67c91a;
          border-radius: 15px;
          color: #373a3b;
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }

      .skill-tag-without-experience {
        background-color: #fafafa;
        color: #494d55;
        .skill-name {
          color: #494d55;
        }
      }
    }
  }

  .nominated-list-card__actions {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #edebeb;
    padding-bottom: 14px;
    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }

    .nominated-list-card__salaries {
      display: flex;
      flex-direction: row;

      .nominated-list-card__salay {
        display: flex;
        align-items: center;
        margin-right: 2rem;

        .nominated-list-card__salay-icon {
          margin-right: 0.5rem;

          img {
            height: 23px;
            width: 22px;
          }
        }
        .nominated-list-card__availability {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .nominated-list-card__type {
            font-size: 18px;
            color: #292929;
            font-weight: 600;
            margin-bottom: 0.3rem;
          }

          .nominated-list-card__when-to-start {
            font-size: 14px;
            color: #808285;
          }
        }

        .nominated-list-card__amount {
          font-size: 22px;
          color: #373a3b;
          font-weight: 700;
          margin-left: 3rem;
        }
      }
    }

    .nominated-list-card__take-action {
      display: flex;
      width: 50%;
      margin-left: 2rem;
      @media (max-width: 576px) {
        width: 100%;
        flex-direction: column;
        margin-left: 0;
      }

      .btn-not-interested {
        border: 1px solid #422e8726;
        border-radius: 12.49px;
        padding: 16px 30px;
        font-size: 18px;
        font-weight: 600;
        background: #fff;
        color: #422e87;
        margin-left: 1rem;
        width: 50%;
        text-align: center;
        max-height: 72px;
        justify-content: center;
        align-items: center;
        display: flex;

        @media (max-width: 576px) {
          padding: 10px 30px;
          width: 100%;
          margin-bottom: 1rem;
          margin-left: 0;
        }
        font-size: 15px;

        img {
          height: 21px;
          width: 21px;
          margin-inline-end: 0.5rem;
        }
      }

      .btn-interested-disabled {
        border: 1px solid #eee9ff;
        border-radius: 12.49px;
        padding: 16px 10px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        background-color: #eee9ff;
        color: #422e87;
        width: 50%;
        text-align: center;

        @media (max-width: 576px) {
          padding: 10px 30px;
          width: 100%;
          margin-bottom: 1rem;
          font-size: 15px;
          width: 100%;
        }
      }

      .btn-interested {
        border: 1px solid #422e8726;
        border-radius: 12.49px;
        padding: 16px 10px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        background: #422e87;
        width: 50%;
        text-align: center;
        max-height: 72px;

        @media (max-width: 576px) {
          padding: 10px 30px;
          width: 100%;
          margin-bottom: 1rem;
          font-size: 15px;
          width: 100%;
        }
      }
    }

    .nominated-list-card__questions {
      display: flex;
      width: 50%;
      justify-content: space-between;
      align-items: center;
      background-color: #f3f3f3;
      border-radius: 35.5px;

      @media (max-width: 576px) {
        width: 100%;
        padding: 0;
      }

      &-text {
        display: flex;
        align-content: center;
        flex-wrap: wrap;

        p {
          margin-bottom: 0;
          @media (max-width: 576px) {
            font-size: 11px;
          }
        }
      }

      &-send {
        img {
          height: 22px;
          width: 22px;
          @media (max-width: 576px) {
            height: 18px;
            width: 18px;
          }
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
</style>
