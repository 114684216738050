<template>
  <div class="mobileMenu__wrapper">
    <transition name="slide">
      <div v-if="isOpen" class="mobileMenu__wrapper__container" @click="$emit('close-menu')">
        <div class="mobileMenu__wrapper__header">
            <img loading="lazy" src="../../assets/images/colored_logo.svg" alt="logo" />
            <button class="mobileMenu__wrapper__close btn-close">
            </button>
        </div>
        <!-- Menu content goes here -->
        <article v-if="user.first_name && user.type == 1 " class="mobileMenu__wrapper__profile" @click.stop>
            <figure class="mobileMenu__wrapper__profile__img">
                <img loading="lazy"
                    :src="`${baseUrl}${user.image.path}`"
                    class="w-100 profileImg"
                    @error="imageLoadOnError"
                    v-if="user.image"
                />
                <img loading="lazy"
                    src="@/assets/images/talent-avatar.png"
                    class="w-100 profileImg"
                    v-else
                />
            </figure>
            <h5>
                <p>{{ `${user.first_name} ${user.last_name}` }}</p>
                <small>{{ user.email }}</small>
            </h5>
            <div class="btn-group" @click.stop>
              <button
                type="button"
                class="border-0 btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <button
                    class="border-white dropdown-item"
                    type="button"
                    @click="logOut"
                  >
                  {{ $t("home.logout") }}
                  </button>
                </li>
              </ul>
            </div>
        </article>
        
        <ClientDropdownMenu 
        v-if="user.type == 2"
        :client-name="user.first_name"
        :projects="projects"
        :active-project="activeProject"
        @project-changed="$emit('changeCurrentProject', $event)"
        />


        <div v-if="projects.length > 1 && false" class="projects">
            <div class="mobileMenu__item">
                <a
                class="px-3 nav-link"
                 @click.stop="showProjects = !showProjects"
                >
                    {{ activeProject.name }}
                </a>
            </div>
            <transition
                @beforeEnter="beforeEnter"
                @afterEnter="afterEnter"
                @beforeLeave="beforeLeave"
                @afterLeave="afterLeave"
            >
                <ul v-show="showProjects" class="projects__list">
                    <li v-for="project in projects" :key="project.id">
                        <a @click="$emit('changeCurrentProject', project)" >{{ project.name }}</a>
                    </li>
                </ul>
            </transition>
        </div>
        <ul class="mobileMenu__wrapper__list">
          <slot />
        </ul>
        <template v-if="loggedInClient || clientWizard || loggedInTalent || wizard">
            <button
                class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--logged"
                :class="{'mb-10': (!loggedInTalent && wizard) || (loggedInClient || clientWizard)}"
                @click="setLocale($i18n.locale)"
                type="button"
            >
                {{ $i18n.locale == "en" ? "عربي" : "English" }}
            </button>
            <ApplyViewBtn v-if="loggedInTalent || wizard" class="px-1 mx-auto mb-10" wide />
        </template>
        <template v-if="notRegistered">
            <div class="mobileMenu__wrapper__ctaWrapper">
                <button
                    class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--simple"
                    @click="() => handleRedirection('/login')"
                    type="button"
                >
                    {{ $t('home.login') }}
                </button>
                <div class="separator"></div>
                <button
                    class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--simple"
                    @click="setLocale($i18n.locale)"
                    type="button"
                >
                    {{ $i18n.locale == "en" ? "عربي" : "English" }}
                </button>
            </div>
            <button
                type="button"
                class="mb-10 mobileMenu__wrapper__cta mobileMenu__wrapper__cta--yellow"
                @click="$emit('show-register-modal')"
            >
                {{ $t("home.getStarted") }}
            </button>
        </template>
        <template v-if="applyAsTalent">
            <div class="mobileMenu__wrapper__ctaWrapper">
                <router-link to="/login" class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--simple">
                    {{ $t('home.login') }}
                </router-link>
                <div class="separator"></div>
                <button
                    class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--simple"
                    @click="setLocale($i18n.locale)"
                    type="button"
                >
                    {{ $i18n.locale == "en" ? "عربي" : "English" }}
                </button>
            </div>
            <ApplyViewBtn class="px-1 mx-auto mb-10" wide />
        </template>
        <template v-if="auth">
            <button
                class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--logged"
                @click="setLocale($i18n.locale)"
                type="button"
            >
                {{ $i18n.locale == "en" ? "عربي" : "English" }}
            </button>
            <button
                type="button"
                class="mobileMenu__wrapper__cta mobileMenu__wrapper__cta--yellow"
                @click="() => handleRedirection('/client-signup')"
            >
                {{ $t("home.buildTechTeam") }}
            </button>
            <button
                type="button"
                class="mb-10 mobileMenu__wrapper__cta"
                @click="() => handleRedirection('/talent-signup')"
            >
                {{ $t("home.applyAsATalent") }}
            </button>
        </template>
      </div>
    </transition>
    <div
        v-if="isOpen"
        class="blur-wrapper"
        :class="{active: isOpen}"
        @click="$emit('close-menu')"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { axiosInstance } from "@/network/interceptors.js";
import ApplyViewBtn from "../../pages/Talent/Home/components/applyViewBtn/applyViewBtn.vue";
import ClientDropdownMenu from "@/components/MobileMenu/ClientDropdownMenu";

export default {
    components: {
        ApplyViewBtn,
        ClientDropdownMenu
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        notRegistered: {
            type: Boolean,
            default: false,
        },
        applyAsTalent: {
            type: Boolean,
            default: false,
        },
        loggedInClient: {
            type: Boolean,
            default: false,
        },
        loggedInTalent: {
            type: Boolean,
            default: false,
        },
        auth: {
            type: Boolean,
            default: false,
        },
        wizard: {
            type: Boolean,
            default: false,
        },
        clientWizard: {
            type: Boolean,
            default: false,
        },
        activeProject: {
            type: Object,
        },
        projects: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_AWS_BASE_URL,
            showProjects: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/getUser",
        }),
    },
    watch: {
        isOpen(value) {
            const body = document.querySelector('body');
            if (value)
                body.classList.add('is-fixed');
            else
                body.classList.remove('is-fixed');
        }
    },
    methods: {
        beforeEnter(el) {
            el.classList.remove("collapse");
            el.style.display = "block";
            el.classList.add("collapsing");
            el.style.height = `${el.scrollHeight}px`;
        },
        afterEnter(el) {
            el.classList.remove("collapsing");
            el.classList.add("collapse", "in");
        },
        beforeLeave(el) {
            el.classList.add("collapsing");
            el.classList.remove("collapse", "in");
            el.style.height = 0;
        },
        afterLeave(el) {
            el.classList.remove("collapsing");
            el.classList.add("collapse");
            el.style.display = "none";
        },
        handleRedirection(path) {
            this.$router.push({ path });
        },
        setLocale(locale) {
            this.$i18n.locale = locale == "en" ? "ar" : "en";
            localStorage.setItem("lang", this.$i18n.locale);
            axiosInstance.defaults.headers.common["Accept-Language"] = `${this.$i18n.locale}`;

        },
        imageLoadOnError(e) {
            e.target.src = require("@/assets/images/talent-avatar.png");
            e.target.style.border = "5px solid transparent";
        },
        logOut() {
            this.$store.dispatch("auth/logOut").then(() => {
                this.$router.push({ path: "/login" });
                // window.location.reload();
            });
        },
        toggleExpand(project) {
            project.isExpand = !project.isExpand;
        },
        getComputedHeight() {
            this.projects.forEach(project => {
                var content = this.$refs["content" + project.id][0];

                content.style.height = 'auto';
                content.style.position = 'absolute';
                content.style.visibility = 'hidden';
                content.style.display = 'block';

                var height = getComputedStyle(content).height;
                project.computedHeight = height;

                content.style.height = 0;
                content.style.position = null;
                content.style.visibility = null;
                content.style.display = null;
            });
        }
    },
};
</script>

<style lang="scss">
.mobileMenu__wrapper {
  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    max-width: 350px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    transition: transform 0.15s;
    z-index: 1000;
    overflow-y: scroll;
  }

  &__header {
    width: calc(100% - 40px);
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    padding-top: 10px;

    img {
        width: 110px;
    }
  }

  &__close {
    border: 0;
    padding: 0;
    color: #000;
    background-color: transparent;
    font-size: 1.5em;
  }

  &__profile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 10px;
    background-color: #fafafa;
    box-shadow: 0 6px 13px 0 #7372750d;
    width: calc(100% - 40px);
    margin: 20px;
    border-radius: 12px;
    cursor: pointer;

    h5 {
        margin: 0;
    }

    p {
        text-transform: capitalize;
        user-select: none;
        margin: 0 0 -5px;
    }

    small {
        color: #1a202c;
        font-size: 10px;
    }

    &__img {
        width: 50px;
        height: 50px;
        margin-inline-end: 10px;
        margin-block: 0;
    }

    .btn-group {
        margin-inline-start: auto;
    }
  }

  &__list {
    width: calc(100% - 40px);
    list-style: none;
    padding: 0;
    margin: 20px;
  }

  &__item {
    a {
        display: block;
        width: 100%;
        padding: 30px 10px;
    }
  }

  &__ctaWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
  }

  &__cta {
    text-decoration: none;
    border: 2px solid #ffc628;
    color: #ffc628;
    width: calc(100% - 40px);
    margin: 0 20px 10px;
    border-radius: 3px;
    background-color: transparent;
    padding: 13px;
    font-size: 18px;

    &--yellow {
        background-color: #ffc628;
        color: #000;
    }

    &--simple {
        color: #000;
        border: 0;
        width: auto;
        font-family: "Tajawal", sans-serif;
        font-weight: bold;
    }

    &--logged {
        margin-top: auto;
        color: #000;
        font-family: "Tajawal", sans-serif;
        border: 1px solid #f1f1f1;
        border-radius: 3px;
        font-weight: bold;
    }
  }

  > .blur-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
    z-index: 141;
    opacity: 0;
    transition: opacity 1s ease;
  }

  > .blur-wrapper.active {
    opacity: 1;
  }
}

.separator {
    width: 1px;
    height: 26px;
    background-color: rgba(210, 210, 210, 1);
}

.slide-enter-active {
  transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
  transform: translate(100%, 0) !important;
  -webkit-transform: translate(100%, 0) !important;
}

.slide-leave-active {
  transform: translate(100%, 0) !important;
  -webkit-transform: translate(100%, 0) !important;
}

html[dir="rtl"] {
    .mobileMenu__wrapper {
        &__container {
            right: auto;
            left: 0;
        }
    }

    .slide-enter-active {
        transform: translate(-100%, 0) !important;
        -webkit-transform: translate(-100%, 0) !important;
    }

    .slide-leave-active {
        transform: translate(-100%, 0) !important;
        -webkit-transform: translate(-100%, 0) !important;
    }
}

.mx-auto {
    margin-inline: auto;
}

.mobileMenu__item a {
    display: block;
    padding: 10px;
}

.mb-10 {
    margin-bottom: 8rem;
}

.projects {
    width: 100%;
    user-select: none;
    cursor: pointer;
    padding: 0 20px;
    
    &__list {
        margin-inline-start: 15px;
        will-change: height;
    }

    &__list a {
        display: block;
        padding: 5px;
        margin: 10px 0;
        border-inline-start: 3px solid #ddd;
    }
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .377s ease;
  ul {
    margin: 0;
  }
}

</style>