// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/talent/homeScreen/small-shape-1.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".talent-home-stepsSec h4{font-weight:500;font-size:35px}.talent-home-stepsSec .steps{background:#422e87}.talent-home-stepsSec .steps-ul p,.talent-home-stepsSec .steps p{margin-bottom:1rem}.talent-home-stepsSec .pt-100{padding-top:100px}.talent-home-stepsSec .pb-100{padding-bottom:100px}.talent-home-stepsSec .text-yellow,.talent-home-stepsSec .text-yellow:hover{color:#ffce44}.talent-home-stepsSec .steps-ul{list-style:none;gap:100px}.talent-home-stepsSec .step-number{color:#ffc629;font-size:96px;font-weight:700}.talent-home-stepsSec .steps-ul h4{font-size:20px;position:relative;margin-bottom:40px}.talent-home-stepsSec .steps-ul h4:after{position:absolute;content:\"\";left:0;bottom:-15px;background:#ffc629;height:4px;width:90px;border-radius:10px}.talent-home-stepsSec .steps-ul p,.talent-home-stepsSec .steps p{color:#aba2c9!important}.talent-home-stepsSec .steps-ul li{position:relative;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.talent-home-stepsSec .right-arrow{position:absolute;right:-50px;top:50%;width:40px;height:40px;margin-top:-25px;display:inline-block;background-color:rgba(255,198,41,.1);border-radius:40px;text-align:center;line-height:40px;display:flex;justify-content:center;align-items:center}@media(max-width:786px){.talent-home-stepsSec .right-arrow{transform:rotate(90deg);left:10px;right:auto;top:auto;bottom:-40px}.talent-home-stepsSec .steps-ul li{background:none}.talent-home-stepsSec .wp-100 img{width:100%}.talent-home-stepsSec .logo-div{min-height:90px;display:flex!important}}@media(max-width:991px){.talent-home-stepsSec h4{font-size:25px}}", ""]);
// Exports
module.exports = exports;
