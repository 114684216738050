<template>
  <div class="assessment-report__basic-info d-flex">
    <div class="assessment-report__avter">
      <img loading="lazy"
        v-if="isResponse && !profile.image"
        src="@/assets/images/talent-avatar.png"
        class="img-fluid placeholeder"
      />
      <img loading="lazy"
        v-if="isResponse && profile.image"
        :src="`${baseUrl}${profile.image.path}`"
        class="talent-real-img img-fluid"
        @error="imageLoadOnError"
      />
      <skeleton-loader-vue
        v-if="!isResponse"
        type="circle"
        :width="96"
        :height="96"
        animation="wave"
      />
    </div>
    <div class="assessment-report__data">
      <div class="assessment-report__data__name" v-if="isResponse">
        {{ profile.firsts_name }} {{ profile.serial_number }}
      </div>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
      <div class="assessment-report__data__role" v-if="isResponse">
        {{ profile.BasicInfo.role.name }}
      </div>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>
    <div class="assessment-report__action">
      <button
        type="button"
        class="btn btn-primary btn"
        @click="goToTalentProfile"
        v-if="isResponse"
      >
        {{ $t("assesmentReport.viewProfile") }}
      </button>
      <div
        class="mb-3 d-flex align-items-center justify-content-center"
        v-if="!isResponse"
      >
        <skeleton-loader-vue
          type="rect"
          :width="200"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
    },
    isResponse: {
      type: Boolean,
    },
    assessments: {
      type: Object,
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
    };
  },
  methods: {
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent !important";
    },
    goToTalentProfile() {
      window.open(
        "/sq/client/talent-profile/" + this.assessments.talent_sharable_code,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" >
.assessment-report__basic-info {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;
  width: 100%;
  flex-direction: row;
  align-items: center;

  .assessment-report__avter {
    .img-fluid {
      height: 96px;
      width: 96px;
      border-radius: 50%;
      object-fit: cover;
      padding: 3px;
      border: 4px solid #e4e4e4;
    }
    img {
      height: 96px;
      width: 96px;
      border-radius: 50%;
      object-fit: cover;
      padding: 3px;
      border: 4px solid #e4e4e4;
    }
  }

  .assessment-report__data {
    margin-left: 2rem;

    .assessment-report__data__name {
      width: 100%;
      font-weight: 600;
      font-weight: 600;
      font-size: 28px;
      color: #000000;
    }
    .assessment-report__data__role {
      font-weight: 500;
      color: #412d86;
      font-size: 22px;
    }
  }

  .assessment-report__action {
    margin-left: auto;

    button {
      font-weight: 700;
      height: 56px;
      align-items: center;
      display: flex;
      width: 211px;
      justify-content: center;
      border: 1px solid #ffc629;
      border-radius: 8.95px;

      &:focus {
        color: transparent;
        background-color: transparent;
        border-color: transparent;
        outline: 0;
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 576px) {
  .assessment-report__basic-info {
    flex-direction: column;
  }

  .assessment-report__basic-info .assessment-report__action {
    margin: 0 5px;
    width: 100%;
    justify-content: center;
    display: flex;
    button {
      width: 80%;
      padding: 15px 30px;
    }
  }

  .assessment-report__basic-info
    .assessment-report__data
    .assessment-report__data__name {
    font-size: 23px;
  }

  .assessment-report__basic-info
    .assessment-report__data
    .assessment-report__data__role {
    font-size: 16px;
  }

  .assessment-report__basic-info .assessment-report__data {
    margin-left: 0;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .assessment-report__basic-info .assessment-report__action {
  }
}
</style>