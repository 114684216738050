
<template>
  <div v-if="isLoading" class="invoice-skeleton">
    <div class="mb-3 d-flex align-items-center justify-content-start">
      <skeleton-loader-vue type="rect" :width="150" :height="15" animation="fade" rounded />
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-start">
      <skeleton-loader-vue type="rect" :width="600" :height="30" animation="fade" rounded />
    </div>
    <div class="gap-20 d-flex align-items-center justify-content-between">
      <skeleton-loader-vue type="rect" :width="200" :height="20" animation="fade" rounded />
      <skeleton-loader-vue type="rect" :width="200" :height="20" animation="fade" rounded />
      <skeleton-loader-vue type="rect" :width="200" :height="20" animation="fade" rounded />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.invoice-skeleton {
  background-color: #fff;
  max-width: 900px;
  padding: 20px;
  border-radius: 15px;
  margin: 0 auto;
  box-shadow: 5px 5px 15px 0 #eee;
}

.gap-20 {
  gap: 20px;
}
</style>
  