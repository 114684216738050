<template>
  <section id="benefits" class="benefits-section" :class="featureId4?  'benefits-section2' : ''">
    <div class="container">
      <h2 v-if="featureId4" class="h2 tw-flex tw-justify-center tw-flex-wrap tw-pt-8 ">
        <span>
          {{ $t("home.SquadioBenefits2") }}
        </span>
        <span class=" tw-text-[#ffc639]" :class=" $i18n.locale == 'ar' ? 'tw-mx-1' : '' ">
          {{ $t("home.values2") }}
        </span>
      </h2>
      <h2 v-else>
        <span>
          {{ $t("home.SquadioBenefits") }}
        </span>
        <span>
          {{ $t("home.values") }}
        </span>
      </h2>
      <div>
        <ul v-if="featureId4" class="p-0  benefits-items2 tw-grid tw-grid-cols-2 !tw-gap-4 lg:tw-hidden">
          <li>
            <div class="tw-mb-4 box">
              <div class="tw-flex tw-border-b tw-border-solid tw-border-[#efefef] tw-mb-2">
                <h3>
                  {{ $t("home.clientsBenefitsItem1Title") }}
                </h3>
                <span class="benefits-icon">

                  <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-1.svg" />
                </span>
              </div>

              <p>
                {{ $t("home.clientsBenefitsItem1Paragraph") }}
              </p>
            </div>


            <div class="tw-mb-4 box">
              <div class="tw-flex tw-border-b tw-border-solid tw-border-[#efefef] tw-mb-2">
                <h3>
                  {{ $t("home.clientsBenefitsItem3Title") }}
                </h3>
                <span class="benefits-icon">

                  <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-3.svg" />
                </span>
              </div>

              <p>
                {{ $t("home.clientsBenefitsItem3Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4 box">
              <div class="tw-flex tw-border-b tw-border-solid tw-border-[#efefef] tw-mb-2">
                <h3>
                  {{ $t("home.clientsBenefitsItem6Title") }}
                </h3>
                <span class="benefits-icon">

                  <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-6.svg" />
                </span>
              </div>

              <p>
                {{ $t("home.clientsBenefitsItem6Paragraph") }}
              </p>
            </div>
          </li>
          <li>
            <div class="tw-mb-4 box">
              <div class="tw-flex tw-border-b tw-border-solid tw-border-[#efefef] tw-mb-2">
                <h3>
                  {{ $t("home.clientsBenefitsItem2Title") }}
                </h3>
                <span class="benefits-icon">

                  <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-2.svg" />
                </span>
              </div>

              <p>
                {{ $t("home.clientsBenefitsItem2Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4 box">
              <div class="tw-flex tw-border-b tw-border-solid tw-border-[#efefef] tw-mb-2">
                <h3>
                  {{ $t("home.clientsBenefitsItem4Title") }}
                </h3>
                <span class="benefits-icon">

                  <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-4.svg" />
                </span>
              </div>
              <p>
                {{ $t("home.clientsBenefitsItem4Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4 box">
              <div class="tw-flex tw-border-b tw-border-solid tw-border-[#efefef] tw-mb-2">
                <h3>
                  {{ $t("home.clientsBenefitsItem5Title") }}
                </h3>
                <span class="benefits-icon">

                  <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-5.svg" />
                </span>
              </div>

              <p>
                {{ $t("home.clientsBenefitsItem5Paragraph") }}
              </p>
            </div>
          </li>
        </ul>
        <ul v-else class="p-0 benefits-items  tw-grid tw-grid-cols-2 !tw-gap-4 lg:tw-hidden">
          <li>
            <div class="tw-mb-4">
              <span class="benefits-icon">

                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-1.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem1Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem1Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-3.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem3Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem3Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-6.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem6Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem6Paragraph") }}
              </p>
            </div>
          </li>
          <li>
            <div class="tw-mb-4">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-2.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem2Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem2Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-4.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem4Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem4Paragraph") }}
              </p>
            </div>

            <div class="tw-mb-4">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-5.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem5Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem5Paragraph") }}
              </p>
            </div>
          </li>
        </ul>
        <ul class="p-0 benefits-items lg:tw-flex tw-hidden">
          <li class="col">
            <div>
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-1.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem1Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem1Paragraph") }}
              </p>
            </div>
            <div class="mt-5 long">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-4.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem4Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem4Paragraph") }}
              </p>
            </div>
          </li>
          <li class="col">
            <div>
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-2.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem2Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem2Paragraph") }}
              </p>
            </div>
            <div class="mt-5">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-5.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem5Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem5Paragraph") }}
              </p>
            </div>
          </li>
          <li class="col">
            <div>
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-3.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem3Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem3Paragraph") }}
              </p>
            </div>
            <div class="mt-5">
              <span class="benefits-icon">
                <img loading="lazy" src="../../../../assets/images/client/Benfits-icon-6.svg" />
              </span>
              <h3>
                {{ $t("home.clientsBenefitsItem6Title") }}
              </h3>
              <p>
                {{ $t("home.clientsBenefitsItem6Paragraph") }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    featureId4: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.benefits-section {
  margin-top: 150px;

  @media (max-width: 700px) {
    margin-top: 50px;
  }
}

.benefits-section2 {
  background: linear-gradient(to right, #ededed, #ece8ff, #e3d3fb, #e1e1e1);
  margin-top: 0;
}

.benefits-section h2 {
  font-size: 50px;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    font-size: 35px !important;
  }
}

.benefits-section h2.h2 {
  font-size: 50px;
  font-weight: 600;
  display: flex;
  flex-direction: row;

  @media (max-width: 992px) {
    font-size: 26px !important;
  }
}

.benefits-items {
  gap: 40px;
  align-items: flex-start;

  .long {
    padding-bottom: 100px;
  }
}

.benefits-items li {
  list-style: none;
}

.benefits-items li div {
  background: #fff;
  list-style: none;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 1rem;
  }
}

.benefits-items li:first-child {
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
}

.benefits-items {
  gap: 40px;
  align-items: flex-start;

  .long {
    padding-bottom: 100px;
  }
}

.benefits-items li {
  list-style: none;
}

.benefits-items2 li .box {
  background: #fff;
  list-style: none;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 1rem;
    min-height: 14.8rem;
  }
}
.rtl .benefits-items2 li .box {
  background: #fff;
  list-style: none;
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 20px;
    margin-bottom: 1rem;
    min-height: 12rem;
  }
}

.benefits-items2 li:first-child {
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
}

.benefits-items2 li:first-child {
  margin-top: 0;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.benefits-items li:nth-child(2) {
  margin-top: 100px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.benefits-items2 li:nth-child(2) {
  margin-top: 0;

  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.benefits-items li h3 {
  font-size: 22px;
  font-weight: 500;
  padding-top: 30px;
  border-top: 1px solid #efefef;

  @media (max-width: 768px) {
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 8px;
  }
}

.benefits-items2 li h3 {
  font-size: 22px;
  font-weight: 500;
  padding-top: 30px;
  width: 65%;

  @media (max-width: 768px) {
    font-size: 13px;
    padding-top: 15px;
    padding-bottom: 8px;
  }
}


p {
  line-height: 1.9;

  @media (max-width: 768px) {
    font-size: 12px !important;
    line-height: 18px !important;
  }
}

.benefits-icon {
  display: flex;
  border-radius: 50%;
  margin-bottom: 30px;
  background: #fbfaf9;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;

  @media (max-width: 786px) {
    margin-bottom: 15px;

    height: 55.95px;
    width: 55.99px;
  }
}

.benefits-icon img {
  max-width: 60px;
  max-height: 80px;
  object-fit: contain;

  @media (max-width: 786px) {
    height: 21.66px;
    width: 20.32px;
  }
}

@media (max-width: 786px) {
  .benefits-items {
    margin-bottom: 0px;
  }
}

@media (max-width: 786px) {
  .benefits-items {
    flex-direction: column;
  }
}
</style>
