<template>
  <section id="podcast" class="container podcast" :class="featureId4 ? 'podcast2  tw-py-16' : ' tw-my-16'">
    <div
      class="tw-flex tw-flex-col lg:tw-flex-row-reverse tw-w-full tw-items-center"
    >
      <div class="tw-flex tw-flex-col tw-justify-start tw-w-full lg:tw-hidden">
        <h4
          class="tw-text-[#ffc629] !tw-tw-text-[25.2px] !tw-font-semibold tw-mb-2"
        >
          {{ $t('podcast.podcast') }}
        </h4>

        <h3
          class="tw-text-[#181818] tw-leading-[52.29px] !tw-text-[45.36px] !tw-font-normal tw-mb-2 tw-whitespace-nowrap"
        >
          {{ $t('podcast.podcast1') }} <br />  {{ $t('podcast.podcast2') }}
        </h3>
      </div>
      <div class="podcast__hero tw-relative tw-mb-12 lg:tw-w-1/2 tw-w-full" :class="{ 'rtl': $i18n.locale == 'ar' }">
        <img loading="lazy"
          class="tw-w-[85%] lg:tw-w-full lg:tw-h-[541px] tw-object-contain"
          src="@/assets/images/podcast/podcasts-img.png"
          alt="podcasts"
          width="500"
          height="300"
        />

        <div
          class="tw-flex tw-absolute tw-bg-white tw-shadow-2xl tw-top-[40%] tw-flex-col tw-rounded-xl tw-py-[17.64px] tw-px-[15px] lg:tw-py-[28px] lg:tw-px-[24px] tw-space-y-4 lg:tw-space-y-6"
          :class="{ 'tw-right-[2%]':  $i18n.locale  == 'en', 'tw-left-[2%]': $i18n.locale == 'ar' }"
        >
          <div class="tw-flex tw-flex-row tw-items-center">
            <div>
              <img loading="lazy"
                class="tw-h-[30px] tw-w-[30px] lg:tw-h-[48px] lg:tw-w-[48px]"
                src="@/assets/images/podcast/Icon-apple.png"
                alt=""
              />
            </div>
            <div class="tw-flex tw-flex-col tw-ms-2">
              <span
                class="tw-text-[#898998] tw-text-[8.19px] lg:tw-text-[13px] tw-font-medium"
                > {{ $t('podcast.podcast5') }}</span
              >
              <strong
                class="tw-text-[#13132c] tw-text-[10.71px] lg:tw-text-[17px] tw-font-medium"
                >{{ $t('podcast.podcast7') }}</strong
              >
            </div>
          </div>
          <div class="tw-flex tw-flex-row tw-items-center">
            <div>
              <img loading="lazy"
                class="tw-h-[30px] tw-w-[30px] lg:tw-h-[48px] lg:tw-w-[48px]"
                src="@/assets/images/podcast/Icon-youtube.svg"
                alt=""
              />
            </div>
            <div class="tw-flex tw-flex-col tw-ms-2">
              <span
                class="tw-text-[#898998] tw-text-[8.19px] lg:tw-text-[13px] tw-font-medium"
                > {{ $t('podcast.podcast5') }}</span
              >
              <strong
                class="tw-text-[#13132c] tw-text-[10.71px] lg:tw-text-[17px] tw-font-medium"
                >{{ $t('podcast.podcast8') }}</strong
              >
            </div>
          </div>
          <div class="tw-flex tw-flex-row tw-items-center">
            <div>
              <img loading="lazy"
                class="tw-h-[30px] tw-w-[30px] lg:tw-h-[48px] lg:tw-w-[48px]"
                src="@/assets/images/podcast/Icon-spotify.png"
                alt=""
              />
            </div>
            <div class="tw-flex tw-flex-col tw-ms-2">
              <span
                class="tw-text-[#898998] tw-text-[8.19px] lg:tw-text-[13px] tw-font-medium"
                > {{ $t('podcast.podcast5') }}</span
              >
              <strong
                class="tw-text-[#13132c] tw-text-[10.71px] lg:tw-text-[17px] tw-font-medium"
                >{{ $t('podcast.podcast9') }}</strong
              >
            </div>
          </div>
        </div>
      </div>
      <div class="lg:tw-w-1/2 tw-w-full">
        <div class="lg:tw-flex tw-hidden tw-flex-col">
          <h4
            class="tw-text-[#ffc629] !tw-tw-text-[25.2px] lg:!tw-text-[44px] !tw-font-semibold tw-mb-2 lg:tw-mb-4"
          >
             {{ $t('podcast.podcast') }}
          </h4>

          <h3
            class="tw-text-[#181818] !tw-leading-[52.29px] lg:!tw-leading-[83px] !tw-text-[45.36px] lg:!tw-text-[76px] !tw-font-normal tw-mb-2 lg:tw-mb-4 tw-whitespace-nowrap"
          >
             {{ $t('podcast.podcast1') }} {{ $t('podcast.podcast2') }} 
          </h3>
        </div>
        <p
          class="tw-text-[#f1f1f4] tw-mb-4 lg:tw-mb-6 tw-text-[17px] lg:tw-text-[18px] tw-leading-[22.68px] lg:tw-leading-[36px]"
        >
          {{ $t('podcast.podcast3') }}
        </p>

        <div class="tw-flex tw-flex-row tw-items-center tw-mb-2">
          <span
            class="tw-w-[12px] tw-h-[12px] lg:tw-w-[18px] lg:tw-h-[18px] tw-bg-[#FF6F59] tw-rounded-full tw-me-2"
          ></span>
          <p
            class="tw-text-[#181818] tw-text-xs lg:tw-text-[19px] tw-font-normal"
          >
          
             {{ $t('podcast.podcast4') }}
          </p>
        </div>

        <div class="tw-flex tw-flex-row tw-items-center">
          <div>
            <img loading="lazy"
              class="tw-h-[30px] tw-w-[30px] lg:tw-h-[48px] lg:tw-w-[48px]"
              src="@/assets/images/podcast/twitter-Icon.svg"
              alt=""
            />
          </div>
          <div class="tw-flex tw-flex-col tw-ms-2">
            <span
              class="tw-text-[#898998] tw-text-[8.19px] lg:tw-text-[13px] tw-font-medium"
              > {{ $t('podcast.podcast5') }}</span
            >
            <strong
              class="tw-text-[#13132c] tw-text-[10.71px] lg:tw-text-[17px] tw-font-medium"
              >{{ $t('podcast.podcast6') }}</strong
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
      featureId4: {
            type: Boolean,
            default: false,
      },
  },
  
};

</script>

<style lang="scss" scoped>
.podcast2 {
    background: #F0EEF8 !important;
}
.podcast {
  &__hero {
    &::before {
      background-image: url("../../../../assets/images/podcast/Highlight_1.svg");
      content: "";
      position: absolute;

      height: 88px;
      width: 146px;
      right: 7%;
      background-repeat: no-repeat;
      background-position: center;

      @media (max-width: 768px) {
        height: 55px;
        width: 93px;
        right: 3%;
      }
    }

    &.rtl {
      &::before {
        right: auto;
        left: 7%;
        transform: rotateY(180deg);
      }
    }

    &::after {
      background-image: url("../../../../assets/images/podcast/Arrow_07.svg");
      content: "";
      position: absolute;
      height: 88px;
      width: 146px;
      background-repeat: no-repeat;
      background-position: center;
      bottom: -2rem;
      left: -4rem;

      @media (max-width: 768px) {
        height: 44px;
        width: 98px;
        bottom: -3rem;
        left: 0rem;
      }
    }
    &.rtl {
      &::after {
        left: auto;
        right: -4rem;
        transform: rotateY(180deg);
      }
    }
  }
}
</style>
