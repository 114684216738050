<template>
  <div :class="`shared-select ${wrapperStyle}`">
    <label class="mb-1" :class="isAstrik ? 'form-label required' : ''" v-if='!hideLabel'>
      {{ label }}
    </label>
    <v-select
      v-model="validationField.$model"
      :reduce="(name) => keySelected ? name[keySelected] : name.id"
      :class="`style-chooser ${inputStyle}`"
      :placeholder="placeholder"
      :options="selectOptions"
      label="name"
      :disabled="isDisabled"
      @input="(e) => handleInputChange(e)"
    >
       <div slot="no-options">
        {{ $t('talent.sorryNoMatchingOptions')}}
      </div>
    </v-select>
    <VulidateError :validationField="validationField" :params="params" />
  </div>
</template>

<script>
import VulidateError from "../../VulidateError/vulidateError.vue";

import "./select.scss"
export default {
  components: { VulidateError },
  props: [
    "validationField",
    "wrapperStyle",
    "selectOptions",
    "placeholder",
    "keySelected",
    "isDisabled",
    "inputStyle", 
    "className",
    "isAstrik",
    "params",
    "label",
    "hideLabel",
  ],
  computed: {
    console: () => console
  },
    methods: {
    handleInputChange(e) {
      this.$emit("handleChange", e);
    }
    }
};
</script>
