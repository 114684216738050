import { render, staticRenderFns } from "./RenewalStatusUpdate.vue?vue&type=template&id=133955ec&scoped=true"
import script from "./RenewalStatusUpdate.vue?vue&type=script&lang=js"
export * from "./RenewalStatusUpdate.vue?vue&type=script&lang=js"
import style0 from "./RenewalStatusUpdate.vue?vue&type=style&index=0&id=133955ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "133955ec",
  null
  
)

export default component.exports