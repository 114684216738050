<template>
  <transition name="modal-fade">
    <div class="modal align-items-center contract-bulk-renewal-modal" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header removeTitleBorder">
            <div class="w-100 tw-flex tw-flex-col">
              <h2 class="tw-mt-2 tw-text-[19px] md:tw-text-[35px] tw-font-bold tw-text-primary tw-text-center">
                {{ $t("contractsRenewal.contractsRenewalRejection") }}
              </h2>
            </div>
          </div>
          <div class="modal-body no-border !tw-p-0 tw-text-center">
            <hr />
            
            <div class="tw-flex tw-items-center tw-justify-center">
              <div class="imagesWrapper tw-flex tw-mx-auto tw-rounded-full tw-bg-[#ececec] tw-p-[5px]">
                <img loading="lazy"
                    v-for="contract in selectedContracts.slice(0, 3)"
                    :key="contract.id"
                    class="tw-w-[50px] tw-h-[50px] tw-rounded-full tw-object-cover tw-border tw-border-[#ececec]"
                    :src="talentProfilePic(contract.talent)"
                    :alt="contract.talent.name"
                    @error="imageLoadOnError"
                />
                <div class="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-[#ff6f59] tw-text-[#ffffff] tw-h-[50px] tw-font-semibold tw-text-[18px] tw-px-2">
                  {{ contractsCount }} {{ $t("contractsRenewal.talents") }}
                </div>
              </div>
            </div>
            
            <p class="tw-text-[#202020] tw-text-[23px] md:tw-text-[28px] tw-text-center tw-mt-4 tw-font-semibold">
              {{ $t("contractsRenewal.areYouSureRejectContracts") }}
            </p>

            <div class="tw-mt-3 tw-mb-4">
                <div class=" tw-flex tw-flex-wrap tw-justify-center">
                    <span class=" tw-border-[#e5e5e5] tw-border-[0.8px] tw-border-solid tw-rounded-[23.8px] tw-text-[16px]  tw-me-2 tw-mb-2 tw-px-[28px] tw-py-[10px] tw-h-auto tw-cursor-pointer " 
                    :class="option.checked ? ' tw-text-primary tw-bg-[#422e8717] !tw-border-[#77777763] tw-font-medium' : 'tw-bg-[#F8F8F8] tw-text-[#414042] tw-font-medium '"
                    @click="handleCheck(option)"
                    v-for="option in selectOptions" :key="option.id">{{ option.name }}</span>
                </div>
                <ValidationError errMsgStyle="tw-flex tw-w-full tw-justify-center"  :validationField="$v.reason"  />
            </div>

             <div>
                <Textarea
                  className="tw-bg-[#F8F8F8] tw-border-[#979797] tw-rounded-[14px] !tw-h-[80px] tw-placeholder-[#414042] tw-text-[16px] tw-px-[20px] tw-py-[13px] "
                  :validationField="$v.comment"
                  :placeholder="$t('contractsRenewal.WriteReason')"
                  :rows="2"
                  :params="[{ maxLength: { number: '5000' } }]"
                />
            </div>
          </div>

          <hr />
          <div class="modal-footer tw-flex tw-p-0">
            <button type="button"
              class="btn cancelbtn btnstyleUpdate  tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[150px]"
              data-bs-dismiss="modal" @click="close">
              {{ $t("talentWizard.cancel") }}
            </button>
            <button :disabled="isLoading" @click="confirm" type="submit"
              class="btn btn-primary saveBtn tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[150px] ">
              {{ $t("nomination.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";
import toast from "../../services/toast.js";
import Textarea from "@/components/shared/formControls/textarea/textarea.vue";
import ValidationError from "@/components/shared/VulidateError/vulidateError.vue";

export default {
  name: 'ContractBulkRejectModal',
  components: {Textarea, ValidationError},
  props: ["isOpen", "selectedContracts"],
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      isLoading: false,
      selectOptions: [],
      reason: null,
      comment: null,
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  validations: {
    reason: {
      required,
    },
    comment: {
      maxLength: maxLength(5000),
    },
  },
  computed: {
    contractsCount() {
      return this.selectedContracts.length;
    }
  },
  mounted () {
    this.getContractRejectionReason();
  },
  methods: {
    talentProfilePic(talent) {
      if (talent.image) {
        return `${talent.image}`;
      }
      else return require("@/assets/images/talent-avatar.png");
    },
    handleCheck(option) {
      this.selectOptions = this.selectOptions.map((item) => {
        if (item.id === option.id) {
          item.checked = true;
        } else {
          item.checked = false
        }
        
        return item;
      });
      this.reason = this.selectOptions
        .filter((item) => item.checked)
        .find((item) =>  item.checked).id;
    },
    async getContractRejectionReason() {
      if (this.isLoading) return;
      this.isLoading = true;
      try {
        await this.axios
          .get(`/api/staticContent/contractRejectionReason?type=2`)
          .then((res) => {
            this.selectOptions = res.data.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                checked: false,
              };
            })
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async confirm() {
      this.isLoading = true;
      try {
        await this.axios
          .post(`/api/client/contract-renewal/reject`, {
            contract_ids: this.selectedContracts.map(c => c.id),
            reason: this.reason,
            comment: this.comment,
          })
          .then(() => {
            this.$emit('refresh')
            this.close()
          });
      } catch (error) {
        console.error({error});
        toast.error(error.response.data?.message || error.message);
      } finally {
        this.isLoading = false;
      }
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: this.$t("contractsRenewal.contractType.remote"),
          value: "remote"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-accommodation"),
          value: "onsite-accommodation"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite"),
          value: "onsite"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-business-visa"),
          value: "onsite-business-visa"
        },
        {
          text: this.$t("contractsRenewal.contractType.hybrid"),
          value: "hybrid"
        }
      ];

      return jobs.find(j => j.value == type).text;
    },
    getProfilePlaceholderImage() {
      if (this.miniProfile)
        return require("@/assets/images/ic_avatar-border.png");
      else return require("@/assets/images/talent-avatar.png");
    },
    imageLoadOnError(e) {
      e.target.src = this.miniProfile
        ? require("@/assets/images/ic_avatar-border.png")
        : require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
.modal-dialog-scrollable .modal-content {
  max-height: 90%;
  overflow: auto;
}
.imagesWrapper {
  img:not(:first-child),
  & > div {
    margin-inline-start: -15px;
  }
}
</style>


<style lang="scss">
.modal-open {
  overflow: hidden;
}

.contract-bulk-renewal-modal {
  hr {
    border: 1px solid #ebebeb;
    margin: 25px 0;
  }
}

.contract-bulk-renewal-modal .modal-dialog {
  width: 95%;
  margin: 4rem auto;

  @media (min-width: 576px) {
    width: 60%;
  }

  margin: 0 auto;

  .modal-body.no-border {
    overflow: auto;
  }

  .modal-content {
    @media (min-width: 576px) {
      padding: 20px 30px;
    }
  }
}

@media (min-width: 576px) {
  .contract-bulk-renewal-modal .modal-dialog {
    max-width: 811px;
  }
}

@media (max-width: 800px) {
  .contract-bulk-renewal-modal .modal-content {
    padding: 20px 19px;
  }
}
</style>

<style scoped>
@media (min-width: 992px) {
  .talentCard__details {
    grid-template-columns: repeat(3, minmax(0, 1fr));
     gap: 3rem;
  }
}
</style>