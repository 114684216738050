// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/grey-shape.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/talent-shape-stroke.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".logedin-talent-layout{position:relative;font-family:Poppins,sans-serif;background:#fafafa url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 0;min-height:100vh;display:flex;flex-direction:column}@media(min-width:768px){.logedin-talent-layout:before{position:absolute;content:\"\";background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");left:-150px;height:400px;width:400px;top:10%;background-size:contain;overflow:hidden}}", ""]);
// Exports
module.exports = exports;
