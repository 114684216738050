<template>
    <div class="wizard ">
        <div class=" position-relative client-layout">
            <!---------------------------------------------------------->
            <router-view></router-view>

        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import './client.css';
import '../style.css'
export default {
    data() {
        return {
            isCongratModalVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "auth/getUser"
        })
    },
    methods: {
        logOut() {
            this.$store.dispatch("auth/logOut").then(() => {
                this.$router.push({ path: "/login" });
               // window.location.reload()   
            });
        },
    }
};
</script>
