// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/bg-signin-2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".signup-page[data-v-4fdcd665]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.signup-page .language-switcher-large[data-v-4fdcd665]{display:flex!important}.tw-ms-auto[data-v-4fdcd665]{margin-inline-start:auto}", ""]);
// Exports
module.exports = exports;
