<template>
  <section
    class="container px-0 mx-auto login-wrapper sign-in d-flex tw-w-full"
  >
    <div class="sign-in-form tw-w-full">
      <form @submit.prevent="submit">
        <div class="mb-4">
          <label class="mb-1 tw-text-gray-800"> {{ $t("signUp.emailAddress") }} </label>
          <input
            :placeholder="$t('signUp.emailAddress')"
            class="px-2 w-100"
            v-model="$v.creditionals.email.$model"
          />
          <!-- ********* Validation Error*********** -->
          <VulidateError
            :validationField="$v.creditionals.email"
            :params="[]"
          />
          <!-- ************************************* -->
        </div>

        <div class="mt-2">
          <button class="w-100 sign-in-btn log-btn" :disabled="$v.$invalid">
            {{ $t("signUp.signIn") }}
          </button>
        </div>
      </form>
      <OTPModal
        v-show="isModalVisible"
        @close="isModalVisible = false"
        @handleOtpResend="handleOtpResend($event)"
        :email="creditionals.email"
        :remainingOTP="remainingOTP"
      />
      <div class="my-4 d-flex justify-content-between align-items-baseline">
        <div class="horizontal-line"></div>
        <div class="tw-text-[#808285] small">
          {{ $t("signUp.orSigninWith") }}
        </div>
        <div class="horizontal-line"></div>
      </div>

      <div class="mt-2 row justify-content-center">
        <GoogleAuthBtn />
      </div>
      <p class="text-center tw-mt-6">
        <span class="dont-have-account !tw-text-[#5c5c5c]">
          {{ $t("signUp.dontHaveAnAccountYet") }}
        </span>
        <router-link :to="{ name: currentActiveUserType()  }" class="tw-text-primary create-account tw-font-semibold">
          {{ $t("signUp.createAnAccount") }}
        </router-link>
      </p>
    </div>
  </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import VulidateError from "../../components/shared/VulidateError/vulidateError.vue";
import OTPModal from "../../components/OTPModal/index.vue";
import GoogleAuthBtn from "../../components/shared/googleAuthBtn/googleAuthBtn.vue";

export default {
  name: "Login",
  data: function () {
    return {
      isModalVisible: false,
      creditionals: {
        email: "",
      },
      otp: "",
      remainingOTP: null
    };
  },
  validations: {
    creditionals: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    handleOtpResend($event) {
      this.remainingOTP = $event;
    },
    submit() {
      this.$store
        .dispatch("auth/otpGenerate", this.creditionals.email)
        .then((res) => {
          this.remainingOTP = res
          this.isModalVisible = true;
        }).catch((err) => {
          if (err.response.status == 422 && err.response.data.errors[0] && err.response.data.errors[0].type == "otp") {
            this.remainingOTP = 0
            this.isModalVisible = true;
          }
        })
        
    },
    currentActiveUserType() {
      // check if url has a word talent and return talent-signup
      if (window.location.href.includes("talent")) {
        return "talent-signup";
      }

      return localStorage.getItem("activeUserType") ? localStorage.getItem("activeUserType") == 'client'? 'client-signup': 'talent-signup' : 'client-signup'
    }

  },
  components: { VulidateError, OTPModal, GoogleAuthBtn },
};
</script>

<style scoped src="./login.css"></style>