// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/talent/ic_edit-large.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".languagesTab .edit-tab-icon{position:absolute;top:0;right:0;border:1px solid #f2f2f2;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;border-radius:9px;display:inline-block;padding:20px;background-position:50%;transition:all .3s ease}.languagesTab .edit-tab-icon:hover{background-color:#f4f4f4;cursor:pointer;transform:scale(1.1)}.languagesTab .talent-language-edit-wrapper .vs__dropdown-menu{font-size:13px!important;max-height:100px}.languagesTab label{font-size:16px;font-weight:500}@media(max-width:768px){.languagesTab label{font-size:12px}}.languagesTab h4{font-size:35px}@media(max-width:768px){.languagesTab h4{font-size:24px}}@media(min-width:768px){.languagesTab .modal-dialog{width:90%}}@media(max-width:700px){.languagesTab .modal-dialog{max-width:95%}}.languagesTab .error{color:#ff6f59;height:0;display:block}@media(max-width:768px){.languagesTab ::-moz-placeholder{font-size:12px}.languagesTab .vs__selected,.languagesTab ::placeholder,.languagesTab input,.languagesTab label,.languagesTab textarea{font-size:12px}}.languagesTab .marginT{margin-top:4px}.languagesTab ::-moz-placeholder{color:#93969a}.languagesTab ::placeholder{color:#93969a}.languagesTab .time-skill h4{font-size:16px}.languagesTab .time-skill p{color:#212529}.languagesTab .modal-dialog-scrollable .modal-body{overflow-y:unset!important}.languagesTab .tabHeader{height:42px!important}.language-modal{z-index:100000001}", ""]);
// Exports
module.exports = exports;
