<template>
  <div class="talent-inner-box talent-profile-card-wrapper">
    <!-- <span class="edit-inner-icon" @click="handleEditRedirection"> </span> -->
    <div class="mb-6 d-flex justify-content-between" v-if="!isLoaded"></div>
    <div
      v-if="isLoaded"
      :class="`d-flex  ${
        profile.basicInfo.country
          ? 'justify-content-between'
          : 'justify-content-end'
      }`"
    >
      <div class="country-flag" v-if="isLoaded && profile.basicInfo.nationality">
        <CountryFlag :countryCode="profile.basicInfo.nationality.code" />
      </div>
      <div class="country-flag" v-else-if="isLoaded && profile.basicInfo.country">
        <CountryFlag :countryCode="profile.basicInfo.country.code" />
      </div>
      <span id="edit-profile" class="edit-icon" @click="handleEditRedirection" v-if="isLoaded">
      </span>
    </div>
    <div class="mt-5 talent-details">
      <h4 class="mb-3 d-flex align-items-center justify-content-center" v-if="isLoaded">
        <!-- <span class="pe-2">{{ profile.basicInfo.full_name }}</span> -->

        <span class="pe-2">{{
          truncate(profile.basicInfo.full_name, 12)
          }}</span>

        <!-- <p>\u0001</p>
        <p>&#128512;</p> -->
      </h4>
      <div class="mb-3 d-flex align-items-center justify-content-center">
        <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="200" :height="30" animation="fade" rounded />
      </div>

      <span class="mb-2 talent-job d-block" v-if="isLoaded">{{
        profile.basicInfo.role.name
        }}</span>
      <div class="mb-3 d-flex align-items-center justify-content-center">
        <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="200" :height="30" animation="fade" rounded />
      </div>
    </div>

    <div class="circle-bg-color-anim score-circular" id="profile-picture">
      <div class="circle-bg-color-anim score-inner">
        <skeleton-loader-vue v-if="!isLoaded" type="circle" :width="180" :height="180" animation="fade" />
        <img loading="lazy" :src="`${baseUrl}${profile.image.path}`" class="w-100 profileImg" @error="imageLoadOnError"
          v-if="isLoaded && profile.image" />
        <img loading="lazy" src="@/assets/images/talent-avatar.png" class="w-100 profileImg" v-else />
      </div>
      <div class="cameraPhoto" v-if="isLoaded">
        <img loading="lazy" src="../../../../../assets/images/talent/photo-camera.svg" class="cameraImg" />
        <FileUploader :isImage="true" inputStyle="" maxSize="1" formDataKey="image" isEXternalErr="true"
          id="talent-profile-image" :fileType="['.jpg', '.jpeg', '.png', '.svg']" @fileUploadEvent="handleUploadImg"
          :extensionErrMsg="$t('validation.AllowedExtenstionsAre')" :sizeErrMsg="$t('validation.allowedMaximum')" />
      </div>
    </div>
    <div>
      <p class="errMsg" v-if="imgUploadData.sizeErr">
        {{ $t("validation.allowedMaximum") }}
      </p>
      <p class="errMsg" v-if="imgUploadData.extensionErr">
        {{ $t("validation.AllowedExtenstionsAre") }}
      </p>
    </div>
    <div v-if="isLoaded && profile.completeProfile.rate < 100" class="strengthSections">
      <div class="strengthSections__wrapper tw-relative tw-rounded-2xl">
        <h5 class="regularFont tw-bg-white" :class="{'tw-cursor-pointer': profileStrengthIsOpen}" @click="profileStrengthIsOpen = false">
          <span class="ps-0">
            {{ $t("talent.profileStrength") }}:
            <b>{{ profile.completeProfile.rate }}%</b>
          </span>
          <img v-if="profileStrengthIsOpen" src="@/assets/images/profile-strengths/arrow-down.svg" class="tw-transform tw-rotate-180" alt="arrow up">
        </h5>
  
        <transition name="expand">
          <ul v-if="profileStrengthIsOpen" class="strengthSections__list">
            <li
              v-for="section in strengthSections"
              :key="section.id"
              class="tw-flex tw-items-center tw-gap-2 tw-border-b"
              >
              <div>
                <img v-if="section.completed" src="@/assets/images/profile-strengths/completed.svg" alt="Completed">
                <img v-else :src="getSectionImage(section.id)" :alt="section.name">
              </div>
              <span>{{ section.name }}</span>
            </li>
          </ul>
        </transition>
        <div class="strengthSections__toggler">
          <button v-if="!profileStrengthIsOpen" @click="profileStrengthIsOpen = true">
            <img src="@/assets/images/profile-strengths/info-icon-button.svg" alt="info icon">
            <span class="strengthSections__button-text">{{ $t('talent.improveYourProfile') }}</span>
            <img src="@/assets/images/profile-strengths/arrow-down.svg" alt="arrow down">
          </button>
          <div v-else>
            <img src="@/assets/images/profile-strengths/info.svg" alt="info icon">
            <small>{{ $t('talent.profileStrengthHint') }}</small>
          </div>
        </div>
      </div>
    </div>
    <h5  v-else-if="isLoaded" class="mt-5 regularFont">
      {{ $t("talent.profileStrength") }}:<span class="bold ps-0">
        {{ profile.completeProfile.rate }}%
      </span>
    </h5>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="200" :height="30" animation="fade" rounded />
    </div>
    <hr class="smallHr" />
    <h5 v-if="isLoaded && profile.basicInfo.BE_lang && !profile.basicInfo.FE_lang">
      {{ profile.basicInfo.BE_lang }}
    </h5>
    <h5 v-if="isLoaded && profile.basicInfo.FE_lang && !profile.basicInfo.BE_lang">
      {{ profile.basicInfo.FE_lang }}
    </h5>

    <p class="mb-3" v-if="isLoaded && profile.basicInfo.role.id !== 3">
      {{ profile.basicInfo.experience_years }}
      {{
        profile.basicInfo.experience_years == 1
          ? $t("signUp.yearOfExperience")
          : $t("signUp.yearsOfExperience")
      }}
    </p>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="200" :height="30" animation="fade" rounded />
    </div>

    <div class="text-center full-exp d-flex justify-content-around" v-if="isLoaded && profile.basicInfo.role.id == 3">
      <div>
        <h5>{{ profile.basicInfo.BE_lang }}</h5>
        <p class="mb-0 talent-experience">
          {{ profile.basicInfo.experience_years }}
          {{
            profile.basicInfo.experience_years == 1
              ? $t("signUp.yearOfExperience")
              : $t("signUp.yearsOfExperience")
          }}
        </p>
      </div>
      <div>
        <h5>{{ profile.basicInfo.FE_lang }}</h5>
        <p class="mb-0 talent-experience">
          {{ profile.basicInfo.second_experience_years }}
          {{
            profile.basicInfo.second_experience_years == 1
              ? $t("signUp.yearOfExperience")
              : $t("signUp.yearsOfExperience")
          }}
        </p>
      </div>
    </div>

    <hr class="smallHr" />
    
    <Availability
      v-if="isLoaded"
      :full_time_status="profile.basicInfo.full_time_status"
      :part_time_status="profile.basicInfo.part_time_status"
      :part_time_availability="profile.basicInfo.part_time_availability"
      :allow_edit_availability="profile.basicInfo.allow_edit_availability"
      :talent-email="profile.basicInfo.email"
    />
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="360" :height="30" animation="fade" rounded />
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="360" :height="30" animation="fade" rounded />
    </div>

    <hr class="smallHr" />
    <Salary
      v-if="isLoaded"
      id="salary"
      :full_time_salary="profile.basicInfo.salary"
      :part_time_salary="profile.basicInfo.part_time_salary"
      :talent-email="profile.basicInfo.email"
    />
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="360" :height="30" animation="fade" rounded />
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="360" :height="30" animation="fade" rounded />
    </div>
    <hr class="smallHr" />
    <div id="social-links">
      <span v-if="isLoaded && profile.accounts.length !== 0">
        <ul class="socialLinks">
          <li v-for="account in profile.accounts" :key="account.id">
            <a :href="getAccount(account.url)" target="_blank">
              <img loading="lazy" :src="require('../../../../../assets/images/' + links[account.type])
                " />
            </a>
          </li>
        </ul>

        <hr class="smallHr" />
      </span>
    </div>

    <div class="mb-3 d-flex align-items-center justify-content-center">
      <skeleton-loader-vue v-if="!isLoaded" type="rect" :width="360" :height="30" animation="fade" rounded />
    </div>

    <div id="uploaded-cv">
      <a target="_blank" :href="`${baseUrl}${profile.cv.path}`" :download="profile.cv.name"
        class="text-start align-items-center d-flex cv-Box download-cv" v-if="profile.cv && isLoaded">
        <img loading="lazy" src="../../../../../assets/images/ic_pdf.svg" class="me-3" />
        <div class="mt-1 cvName">{{ profile.cv.name }}<br /></div>
      </a>

      <a class="mt-4 btn btn-outline-blue d-block" v-if="isLoaded">
        <div class="position-relative justify-content-center d-flex align-items-center">
          <img loading="lazy" src="../../../../../assets/images/ic_upload.svg" class="me-2" />
          <span v-if="profile.cv">
            {{ $t("talent.reUploadCv") }}
          </span>
          <span v-if="!profile.cv">
            {{ $t("talent.uploadCv") }}
          </span>
          <FileUploader
            formDataKey="cv"
            inputStyle="height-40"
            id="talent-profile-cv"
            maxSize="4.8"
            fileType=".csv, .pdf, .doc, .docx, .txt"
            :extensionErrMsg="$t('validation.AllowedExtenstionsAre')"
            :sizeErrMsg="$t('validation.allowedMaximum')"
            errMsgWrapperStyle="cvErrMsgWrapperStyle"
            @fileUploadEvent="handleUploadCv"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import 'intro.js/introjs.css';
import FileUploader from "../../../../../components/shared/formControls/fileUploader/fileUploader.vue";
import CountryFlag from "../../../../../components/shared/countryFalg/countryFalg.vue";
import Availability from "../availability/availability.vue";
import levels from "../../../../../static/LanguageLevel";
import ACCOUNTS from "../../../../../enums/UserAccounts";
import { getUrl } from "../../../../../functions/url";
import { truncate } from "../../../../../utils/utils";
import Salary from "../salary/salary.vue";

import "./profileCard.scss";
export default {
  props: ["profile", "isLoaded"],
  components: { Availability, Salary, FileUploader, CountryFlag },
  data: () => {
    return {
      profileStrengthIsOpen: false,
      imgUploadData: {
        sizeErr: false,
        extensionErr: false,
      },
      levels: [],
      links: {
        [ACCOUNTS.LINKED_IN]: "ic_linkedin.svg",
        [ACCOUNTS.GITHUB]: "ic_github.svg",
        [ACCOUNTS.BEHANCE]: "ic_behance.svg",
        [ACCOUNTS.DRIBBBLE]: "ic_dribbble.svg",
        [ACCOUNTS.WEBSITE]: "ic_website-icon.svg",
      },
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      fileValue: "",
    };
  },
  computed: {
    strengthSections() {
      return this.profile.completeProfile.sections;
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$emit('reload-profile');
    },
  },
  mounted() {
    this.levels = levels.map((level) => ({
      ...level,
      name: this.$t(`languageLevel.${[level.name]}`),
    }));
  },
  methods: {
    truncate,
    getSectionImage(id) {
      const imagesMapById = {
        1: "Availability",
        2: "Skills",
        3: "Salary",
        4: "Cv",
        5: "personal-info",
        6: "Projects",
        7: "Experiences",
        8: "Educations",
        9: "Certificates",
        10: "Language",
        11: "technical-assessments",
        12: "Nationality",
        13: "profile-picture",
        14: "business-accounts",
      };
      if (!imagesMapById[id]) return require("@/assets/images/profile-strengths/info.svg");
      return require(`@/assets/images/profile-strengths/${imagesMapById[id]}.svg`);
    },
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    getLevel(id) {
      const filteredLevel = this.levels.filter((level) => level.id == id);
      return filteredLevel[0].name;
    },
    getAccount(account) {
      return getUrl(account);
    },
    handleUploadCv(payload) {
      if (payload?.fileformate) {

        if (window.gtag) {
          window.gtag("event", "talent_uploaded_cv", {
            talent_email: this.profile.basicInfo.email,
          });
        }

        this.$store.dispatch(
          "talentProfile/uploadProfileCV",
          payload.fileformate
        );
      }
    },
    handleUploadImg(payload) {
      if (payload?.fileformate) {
        this.imgUploadData = {
          sizeErr: false,
          extensionErr: false,
        };
        this.$store.dispatch(
          "talentProfile/uploadProfileImage",
          payload.fileformate
        );
      } else {
        this.imgUploadData = { ...payload };
      }
    },
    handleEditRedirection() {
      if (this.$route.query.status && this.$route.query.status == "tour") {
        this.$emit("route-changed", "/sq/talent/editProfile?status=tour");
      } else {
        this.$emit("route-changed", "/sq/talent/editProfile");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.strengthSections {
  height: 140px;
  position: relative;
  z-index: 2;

  &__wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 2px 4px 13px rgba(0, 0, 0, 0.09);
    overflow: hidden;
    border-radius: 20px;

    h5 {
      width: 100%;
      position: relative;
      padding: 20px 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;

      img {
        position: absolute;
        right: 25px;
        top: 57%;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &__list {
    list-style: none;
    max-height: 326px;
    overflow-y: scroll;
    background-color: #FAFAFA;
    margin-inline-end: 5px;
    padding: 0 15px;

    li {
      padding: 15px 10px;
    }

    div {
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 23px;
      max-height: 23px;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #F1F1F1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FFC629;
      border-radius: 3px;
    }
  }

  &__toggler {
    padding: 10px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      border: 2px solid #FFC629;
      background-color: #FFC629;
      border-radius: 30px;
      padding: 10px 10px;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    div {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-top: 10px;
      text-align: start;
      padding: 0 10px;
      img {
        width: 20px;
      }
    }
  }

  &__button-text {
    font-weight: 500;
  }
}

.expand-enter-active, .expand-leave-active {
  transition: max-height 0.3s ease;
}
.expand-enter, .expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
