// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/hero-section-new-3.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".supporters[data-v-94702eb6]{padding:30px 0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}@media(max-width:700px){.supporters[data-v-94702eb6]{height:200px;background-size:cover;background-repeat:no-repeat;background-position:50%}}.supporters ul[data-v-94702eb6]{gap:10px;justify-content:center}.supporters h5[data-v-94702eb6]{font-size:30px;font-weight:700;background:linear-gradient(90deg,#775bbc 0,#3bd4ae);-webkit-background-clip:text;-webkit-text-fill-color:transparent}.supporters li[data-v-94702eb6]{background:#fff;width:180px;height:90px;border-radius:10px;display:flex;align-items:center;box-shadow:0 2px 12px 0 rgba(0,0,0,.06);padding:35px}.supporters li img[data-v-94702eb6]{max-height:60px;margin:0 auto}", ""]);
// Exports
module.exports = exports;
