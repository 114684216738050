<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div
        class="inquiry-modal modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-end">
            <img loading="lazy"
              class="close-icon"
              src="@/assets/images/ic_close.svg"
              @click="close"
              height="20px"
              width="20px"
            />
          </div>

          <div class="modal-body no-border">
            <div class="row">
              <div class="mb-4 text-center col-12 inquiry-box">
                <h2 class="bold title">
                  {{ $t('nomination.conversationHistory')  }}
                </h2>

                <hr />

                <div class="p-0 mb-3 inquiry-chat">
                  <div v-if="getInquiryIsLoading">
                    <div class="support__body">
                      <div class="mb-4 support__body-user-massege">
                        <div class="support__body-user-massege-box">
                          <div
                            class="mb-2 d-flex align-items-center justify-content-start"
                          >
                            <skeleton-loader-vue
                              type="rect"
                              :width="500"
                              :height="30"
                              animation="wave"
                              rounded
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-4 support__body-user-massege">
                        <div class="support__body-user-massege-box">
                          <div
                            class="mb-2 d-flex align-items-center justify-content-start"
                          >
                            <skeleton-loader-vue
                              type="rect"
                              :width="500"
                              :height="30"
                              animation="wave"
                              rounded
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-4 support__body-user-massege">
                        <div class="support__body-user-massege-box">
                          <div
                            class="mb-2 d-flex align-items-center justify-content-start"
                          >
                            <skeleton-loader-vue
                              type="rect"
                              :width="500"
                              :height="30"
                              animation="wave"
                              rounded
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-4 support__body-user-massege">
                        <div class="support__body-user-massege-box">
                          <div
                            class="mb-2 d-flex align-items-center justify-content-start"
                          >
                            <skeleton-loader-vue
                              type="rect"
                              :width="500"
                              :height="30"
                              animation="wave"
                              rounded
                            />
                          </div>
                        </div>
                      </div>

                      <div class="mb-4 support__body-user-massege">
                        <div class="support__body-user-massege-box">
                          <div
                            class="mb-2 d-flex align-items-center justify-content-start"
                          >
                            <skeleton-loader-vue
                              type="rect"
                              :width="500"
                              :height="30"
                              animation="wave"
                              rounded
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="inquiryList.length && !getInquiryIsLoading">
                    <div class="support__body">
                      <div
                        v-for="inquiry in inquiryList"
                        :key="inquiry.created_at"
                      >
                        <div
                          class="support__body-user-massege"
                          v-if="!inquiry.system"
                        >
                          <div class="support__body-user-massege-box">
                            <div class="support__body-user-massege-name" v-if="inquiry.author">{{inquiry.author}}</div>
                            <p>
                              {{ inquiry.body }}
                            </p>
                          </div>
                        </div>

                        <div
                          class="support__body-user-massege"
                          v-if="inquiry.system"
                        >
                          <div class="support__body-user-massege-img">
                            <img loading="lazy"
                              src="@/assets/images/ic_squadio-logo-lg-2x.png"
                              alt=""
                            />
                          </div>
                          <div
                            class="support__body-user-massege-box support__body-user-massege-system"
                          >
                            <div class="support__body-user-massege-name">
                              Squadio Team
                            </div>
                            <p class="support__body-user-massege-text">
                              {{ inquiry.body }}
                            </p>
                          </div>
                        </div>
                        <span class="support__body-user-massege-date">{{
                          getData(new Date(inquiry.created_at * 1000))
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-4 send-box">
                  <InquirySendBox
                    :currentTalent="currentTalent"
                    @inquirySent="inquirySent()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import InquirySendBox from "@/pages/Client/nominatedProfiles/components/inquirySendBox/inquirySendBox.vue";

export default {
  name: "inquiryModal",
  props: ["isOpenInquiry", "currentTalent"],
  components: {
    InquirySendBox,
  },

  data() {
    return {
      inquiryList: [],
      getInquiryIsLoading: false,
    };
  },
  watch: {
    isOpenInquiry: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
          this.getInquiry();
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    inquirySent() {
      this.axios
        .get(`/api/client/nomination/${this.currentTalent}/ticket`)
        .then((res) => {
          this.inquiryList = res.data.data.reverse();
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    },
    getInquiry() {
      this.getInquiryIsLoading = true;
      this.inquiryList = [];
      this.axios
        .get(`/api/client/nomination/${this.currentTalent}/ticket`)
        .then((res) => {
          this.getInquiryIsLoading = false;
          this.inquiryList = res.data.data.reverse();
        })
        .catch((err) => {
          console.log("err :>> ", err);
          this.getInquiryIsLoading = false;
        });
    },
    getData(date) {
      if (date)
        return `${moment(date).format("dddd, MMMM Do, YYYY [at] h:mm A")}`;
    },
    inquiry() {
      this.close();
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style>
.modal-open {
  overflow: hidden;
}
</style>


<style lang="scss" scoped>
.inquiry-chat {
  max-height: 360px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;

  .modal-body.no-border {
    overflow: hidden !important;
  }

  @media (max-width: 768px) {
    max-height: 260px;
  }
}

.close-icon {
  cursor: pointer;
}

.inquiry-modal.modal-dialog {
  margin: 1.75rem auto;
  max-width: 900px;
  width: 900px;
  @media (max-width: 768px) {
    margin: 0.75rem auto;
    max-width: 100%;
    width: 100%;
  }
}
.inquiry-box {
  .title {
    font-size: 30px;
    color: #422e87;
    font-weight: 800;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .text {
    font-size: 16px;
    font-style: normal;
    color: #a6a6a6;
  }

  hr {
    border-top: 1px solid #7f7f7f;
  }

  label {
    font-size: 15px;
    color: #4b4b4b;
    letter-spacing: -0.15px;
    font-weight: 600;
    text-align: left;
    display: block;
    margin-bottom: 5px;
  }
}
</style>


<style lang="scss" scope>
.support {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;

  .inquiry-chat::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  .inquiry-chat::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: darkgrey;
  }

  &__body {
    margin-top: 1rem;
    margin-right: 5px;

    .support__body-user-massege-system {
      background-color: rgb(245 245 245);
    }

    .support__body-user-massege-name {
      color: #202020;
      font-weight: 700;
    }

    &-user-massege {
      display: flex;
      flex-direction: row;

      &-img {
        margin-right: 1rem;

        img {
          height: 62px;
          width: 62px;
        }
      }
      &-box {
        width: 100%;
        background-color: #ece7fc;
        padding: 1rem 1.5rem;
        border-radius: 14.74px 14.74px 14.74px 0;
        font-size: 14.74px;
        font-style: normal;
        color: #1e2022;
        text-align: left;
        p {
          font-size: 14.74px;
          font-style: normal;
          color: #1e2022;
          line-height: 26px;
          text-align: left;
          word-break: break-all;
          @media (max-width: 576px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .support__body-user-massege-date {
    font-size: 12.64px;
    font-style: normal;
    color: #a5a5a5;
    text-align: right;
    display: block;
    margin: 0.5rem 0;
  }
}
</style>