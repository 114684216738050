<template>
  <div class="assessment-list">
    <div class="assessment-list__header">
      <div class="tw-flex tw-justify-between">
        <span class="assessment-list__header__title tw-flex tw-items-center">
          {{ $t("signUp.passedOurAssesment") }}
          <img loading="lazy" src="@/assets/images/ic_success.svg" alt="" />
        </span>

        <span
          class="assessment-list__header__arrow"
          :class="
            openAssessment
              ? 'list__header__arrow--open'
              : 'list__header__arrow--close'
          "
          @click="openAssessment = !openAssessment"
        >
          <img loading="lazy" src="@/assets/images/icons/ic_arrow-dropdown_8.svg" alt="" />
        </span>
      </div>
    </div>

    <div class="assessment-list__body" v-if="openAssessment">
      <div class="list">
        <div class="tw-flex tw-ms-12 tw-my-8 " v-if="isloading">
          <skeleton-loader-vue
            type="rect"
            :width="900"
            :height="30"
            animation="wave"
            rounded
          />
        </div>
        <div v-for="(assessment, i) in assessments" :key="assessment.id">
          <div class="list-item">
            <a
              v-if="seletAssessment(assessment.assessments).view_status"
              :href="assessment.assessments[0].report"
              target="_blank"
              class="assessment__name"
            >
              <span>{{ handleAssessmentName(assessment) }} </span>
            </a>
            <span
              class="text-black"
              v-if="!seletAssessment(assessment.assessments).view_status"
              >{{ handleAssessmentName(assessment) }}
            </span>

            <span v-if="seletAssessment(assessment.assessments).view_status">
              <span class="assessment__score">{{
                seletAssessment(assessment.assessments).percentage
              }}</span>
              /
              <span class="assessment__total">{{ 100 }}</span>
            </span>
          </div>
          <hr v-if="i != assessments.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    talent: {
      type: Object,
    },
  },
  data() {
    return {
      openAssessment: false,
      assessments: [],
      isloading: false,
    };
  },
  watch: {
    openAssessment: {
      handler() {
        if (this.openAssessment) this.getAssessmens();
        else this.assessments = [];
      },
      immediate: true,
    },
  },
  methods: {
    getAssessmens() {
      this.isloading = true;
      this.axios
        .get(`/api/client/nomination/${this.talent.id}/assessments`)
        .then((res) => {
          this.assessments = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isloading = false;
        });
    },
    seletAssessment(assessments) {
      return [...assessments].sort((a, b) =>
        a.percentage < b.percentage ? 1 : -1
      )[0];
    },
    handleAssessmentName(assessmentObject) {
      let { assessments, name } = assessmentObject;
      let assessmentName = "";
      let selectTechStack = assessments.find(
        (assessment) => assessment.type == "mcq"
      );
      if (assessments[0]) {
        if (
          assessmentObject.type == 1 &&
          (assessmentObject.id == 1 ||
            assessmentObject.id == 2 ||
            assessmentObject.id == 3)
        ) {
          assessmentName = `${name} ( ${selectTechStack.tech_stack?.join(
            ","
          )} )  `; // "V${assessments[0].version}"
          return assessmentName;
        } else {
          assessmentName = `${name} `; //"V${assessments[0].version}"
          return assessmentName;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assessment-list {
  &__header {
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    flex-direction: column;
    @media (max-width: 576px) {
      padding: 10px 20px;
    }
  }

  &__header__title {
    font-size: 15px;
    color: #292929;
    font-weight: 500;
    @media (max-width: 576px) {
      font-size: 12px;
      align-items: center;
      display: flex;
    }

    img {
      margin-left: 0.5rem;
      height: 30px;
      width: 30px;
      @media (max-width: 576px) {
        height: 20px;
        width: 20px;
      }
    }
  }

  &__header__arrow {
    height: 30px;
    width: 30px;
    cursor: pointer;
    align-items: center;
    display: flex;
  }

  .list__header__arrow--close {
    transform: rotate(0deg);
  }

  .list__header__arrow--open {
    transform: rotate(180deg);
  }

  &__body {
    .list {
      list-style: none;
      padding: 0;
      margin: 0 23px;
      border: 1px solid #f1f1f1;
      border-radius: 0 0 8px 8px;
      @media (max-width: 576px) {
        margin: 0;
      }

      hr {
        border: 1px solid #edebeb;
        margin: 0 23px;
      }
    }

    .list-item {
      padding: 20px 50px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 576px) {
        padding: 15px 25px;
      }
    }
    .assessment__name {
      color: #422e87;
      font-size: 14px;
      font-weight: 600;
    }

    .assessment__score {
      font-weight: bold;
      font-size: 22px;
    }

    .assessment__total {
      font-size: 11px;
      font-weight: 500;
    }
  }
}
</style>