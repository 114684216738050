<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable otp-modal">
        <div class="modal-content">
          <div class="border-0 modal-header otp-modal-header">
            <div class="text-center w-100">
              <h3 class="mt-3 modal-title" id="exampleModalLabel">
                {{ $t("signUp.checkYourEmail") }}
              </h3>
              <p class="mb-0 gray-color">
                {{ $t("signUp.weSentAConfirmationTo") }}
                <br />
                {{ email }}
              </p>
            </div>
          </div>
          <div class="pt-0 border-0 modal-body otp-modal-body">
            <div class="row justify-content-center">
              <div class="mb-2 col-12">
                <v-lottie-player
                  :path="'https://assets7.lottiefiles.com/packages/lf20_qttka1wr.json'"
                  class="margin0-auto"
                  background="transparent"
                  :speed="1"
                  style="width: 200px; height: 200px"
                  loop
                  autoplay
                />
              </div>

              <div class="mb-4 row">
                <OTPInput :otpCode.sync="otpCode" ref="otpInput" />
              </div>

              <div class="mb-4 text-center col-12">
                {{ $t("signUp." + remainingOtpText) }}
              </div>
              <div class="mb-4 text-center col-12">
                {{ $t("signUp.didntReceiveEmail") }}
                <button
                  v-if="remainingOTP !== null"
                  class="text-yellow text-decoration-none"
                  @click="resendCode"
                  :disabled="remainingOTP.remaining === 0 || remainingOTP === 0"
                  :style="
                    remainingOTP.remaining === 0 || remainingOTP === 0
                      ? 'cursor: not-allowed;'
                      : ''
                  "
                >
                  {{ $t("signUp.resendCode") }}
                </button>
              </div>

              <div class="mb-4 col-sm-9 col-12 d-grid">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-custom font-weight-600 d-flex justify-content-center align-items-center"
                  @click="close"
                >
                  <img loading="lazy"
                    class="hiring-arrow-rotate"
                    src="../../assets/images/icons/arrow-back.svg"
                  />
                  <span align="center" class="ms-3">
                    {{ $t("signUp.editEmail") }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLottiePlayer from "vue-lottie-player";
import OTPInput from "../OTPInput/index.vue";
import Steps from "../../enums/WizzardSteps";
import ClientSteps from "../../enums/ClientWizzardSteps";
import toast from "../../services/toast";
import "./style.scss";

export default {
  components: {
    OTPInput,
    vLottiePlayer: VueLottiePlayer
  },
  props: ["email", "wizard", "in_competition", "remainingOTP"],

  data() {
    return {
      otpCode: "",
      featureId3: false,
    };
  },
  mounted () {
  },
  methods: {
    ...mapActions({
      setTalenttHasContractRenewal: "auth/setTalentHasContractRenewal",
      setClientCounters: "auth/setClientCounters"
    }),
    handleClearInput() {
      this.$refs.otpInput.$children?.[0].clearInput();
    },
    close() {
      this.$emit("close");
      if (window.location.href.includes("code-challenge")) {
        this.handleClearInput();
      }
    },
    resendCode() {
      this.$store.dispatch("auth/otpGenerate", this.email).then((res) => {
        this.$emit("handleOtpResend", res);
        toast.success(this.$t("signUp.WeResendCode"));
      });
    },
  },
  computed: {
    ...mapGetters({
      isClientHasContractRenewal: "auth/getClientHasContractRenewal",
      isTalenttHasContractRenewal: "auth/getTalentHasContractRenewal",
      isClientHasNomination: "auth/getClientNominationState",
    }),
    remainingOtpText() {
      if (this.remainingOTP == null) {
        return "";
      } else if (this.remainingOTP.remaining == 2) {
        return "2trialsRemaining";
      } else if (this.remainingOTP.remaining == 1) {
        return "1trialsRemaining";
      } else if (this.remainingOTP.remaining == 0) {
        return "0trialsRemaining";
      } else {
        return "0trialsRemaining";
      }
    },
  },
  watch: {
    otpCode: function (val) {
      if (window.location.href.includes("code-challenge")) {
        if (val.length === 4) {
          this.$store.dispatch("auth/login", this.otpCode).then(() => {
            if (this.$store.getters["auth/getUser"].role) {
              //sucess popup should appear
              this.$store.dispatch("shared/handleModalStatus", "success");
              this.$emit("handleOpenSuccessModal", "success");
            } else {
              //role popup should appear
              this.$emit("handleRoleModal");
            }
          });
        }
      } else {
        val.length === 4
          ? this.$store
              .dispatch("auth/login", {
                otpCode: this.otpCode,
                email: this.email,
              })
              .then(() => {
                if (window.gtag) window.gtag("event", "otp submitted");
                  const redirectPath = localStorage.getItem("redirect");

                  if (redirectPath) {
                    this.$router.push({ path: redirectPath });
                  }

                  else if (this.$route.query.redirect) {
                    this.$router.push(this.$route.query.redirect)
                  }

                else {
                  const { type, step } = this.$store.getters["auth/getUser"];

                  if (type === 1) {
                    // talent
                    if (step == Steps.PROFILE) {
                      this.setTalenttHasContractRenewal()
                        .then(() => {
                          if (this.isTalenttHasContractRenewal) {
                            this.$router.push({
                              path: "/sq/talent/contracts-renewal",
                            });
                          } else {
                            this.$router.push({ path: "/" });
                          }
                        })
                        .catch(() => {
                          this.$router.push({ path: "/" });
                        });
                    } else {
                      this.$router.push({ name: "Wizard" });
                    }
                  } else if (type == 2) {
                    // client
                    if (step === ClientSteps.PROFILE) {
                      this.$router.push({ name: "clientProfile" });
                    } else if (
                      this.$route.name === "Login" ||
                      this.$route.name === "client-signup" ||
                      this.$route.name === "talent-signup"
                    ) {
                      this.setClientCounters().then(() => {
                      if (this.isClientHasNomination) {
                        this.$router.push({ path: `/sq/client/nomination` });
                      } else {
                        if (this.isClientHasContractRenewal) {
                            this.$router.push({
                              path: "/sq/client/contracts-renewal",
                            });
                        } else {
                         this.$store.dispatch("auth/setStep", 5);

                          
                          this.$router.push({
                            path: !this.$store.getters["auth/isLoggedIn"] ? "/client-signup" : "/sq/client/matching",
                          });
                        }
                      }
                      }).catch(() => {

                        this.$router.push({ path: "/sq/client/matching" });
                      
                      });
                      

                    }
                  }
                }

              })
              .then(() => {
                if (this.$store.getters["auth/getUser"].type == 2) {
                  if (
                    this.$store.getters["auth/getBookMeeting"] &&
                    this.$store.getters["auth/getUserStep"] !== ClientSteps.DONE
                  ) {
                    this.$store.dispatch("auth/setStep", 5);
                    if (
                      Object.keys(this.$store.getters["client/getStepOneData"])
                        .length > 0
                    ) {
                      this.axios
                        .post( this.featureId3? "/api/client/projects/services" :
                          "/api/client/projects/about",
                          this.$store.getters["client/getStepOneData"]
                        )
                        .then((res) => {
                          if (
                            res &&
                            Object.keys(
                              this.$store.getters["client/getStepTwoData"]
                            ).length > 0
                          ) {
                            this.axios
                              .post(  this.featureId3 ? "/api/client/projects/technologies": 
                                "/api/client/projects/services",
                                this.$store.getters["client/getStepTwoData"]
                              )
                              .then(() => {
                                // this.$store.dispatch("auth/setStep", 3);
                              });
                          }
                        });
                    }
                  } else if (
                    Object.keys(this.$store.getters["client/getStepOneData"])
                      .length > 0
                  ) {
                    this.$store.dispatch("auth/setStep", 3);
                    this.axios
                      .post( this.featureId3? "/api/client/projects/services" :
                        "/api/client/projects/about",
                        this.$store.getters["client/getStepOneData"]
                      )
                      .then((res) => {
                        // this.$store.dispatch("auth/setStep", 2);
                        if (
                          res &&
                          Object.keys(
                            this.$store.getters["client/getStepTwoData"]
                          ).length > 0
                        ) {
                          this.axios
                            .post(  this.featureId3 ? "/api/client/projects/technologies": 
                              "/api/client/projects/services",
                              this.$store.getters["client/getStepTwoData"]
                            )
                            .then(() => {
                              // this.$store.dispatch("auth/setStep", 3);
                            });
                        }
                      });
                  }
                }
              })
          : null;
      }
    },
  },
};
</script>
