<template>
  <div class="registration-talents-donut">
    <apexchart
      class="apexchart"
      :options="options"
      :series="data"
      type="donut"
    ></apexchart>
  </div>
</template>

<script>
//import "@/utils/apexcharts";
//import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  props: {
    title: {
      type: String,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
    rightAns: {
      type: Number,
      default: 0,
    },
    wrongAns: {
      type: Number,
      default: 0,
    },
  },
  components: {
    apexchart: () => import(/* webpackChunkName: "VueApexCharts" */ "vue-apexcharts")
  },
  data: function () {
    return {};
  },
  computed: {
    data() {
      return [this.rightAns, this.wrongAns];
    },
    options() {
      return {
        dataLabels: {
          enabled: false,
        },
        chart: {
          height: "100%",
          width: "100%",
          type: "donut",
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: this.$t("assesmentReport.questions"),
                  fontSize: "15.6px",
                  fontWeight: "500",
                  color: "#999999",
                },
              },
            },
          },
        },
        colors: ["#412d86", "#fac328"],
        noData: {
          text: this.loading ? "Loading..." : "No Data",
        },
        labels: [
          `${this.$t("assesmentReport.rightAns")}`,
          `${this.$t("assesmentReport.wrongAns")}`,
        ],
      };
    },
  },
};
</script>

<style>
.registration-talents-donut .apexcharts-legend {
  display: none !important;
}

.registration-talents-donut .apexcharts-datalabels-group {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.registration-talents-donut .apexcharts-datalabel-value {
  font-size: 38.4px;
  color: #333333;
  font-weight: 700;
}

.registration-talents-donut .apexchart {
  width: 240px;
  height: 240px;
}

@media (max-width: 576px) {
  .registration-talents-donut .apexchart {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
  }
}
</style>
