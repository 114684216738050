<template>
  <div class="matching-wrapper position-relative">
    <div class="container text-center innerPage !tw-pt-12 position-relative">
      <discountBanner />
      <Heading :skills="skills" :isloading="isloading" />
      <NoResultFound v-if="skills.length == 0 && isResponse == true" />
      <Carousel
        @handleSkillClick="handleSkillClick($event)"
        :skills="skills"
        :isResponse="isResponse"
      />
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="text-center text-md-start">
          <h3
            v-if="paginator.total > 0 && !isloading"
            class="tw-text-[27px] tw-font-medium"
          >
            {{ $t("matchedTalents.noResults", { number: paginator.total }) }}
          </h3>
          <div
            class="d-flex align-items-center justify-content-center"
            v-if="isloading && paginator.total > 0"
          >
            <skeleton-loader-vue
              type="rect"
              :width="300"
              :height="30"
              animation="fade"
              rounded
            />
          </div>
        </div>

        <div class="tw-flex" v-if="!isloading &&  skills.length !== 0">
          <button
            @click="openFilter"
            class="tw-flex tw-flex-row tw-items-center tw-bg-white tw-text-[#494d55] tw-font-medium tw-text-[14px] tw-rounded-lg tw-px-10 tw-py-[14px]"
            style="box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.03)"
          >
            {{ $t("matchingFilter.filter") }}
            <img
              class="tw-mx-[3px] tw-w-[10px]"
              src="@/assets/images/icons/ic_arrow-dropdown_8.svg"
            />
          </button>
        </div>
      </div>

      <div class="tw-flex tw-w-full" v-if="filterApplyied">
        <filterTags
          :industries="industries"
          :frontEndLanguagesList="frontEndLanguagesList"
          :backEndLanguagesList="backEndLanguagesList"
          :skillsOptions="skillsOptions"
          :availabilityList="availabilityList"
          :seniorityLevel="seniorityLevel"
          :LanguagesList="LanguagesList"
          :levelsList="levelsList"
          :filterData="getMatchingFilter"
          :countryList="countriesList"
          :role="currentSkillId"
          @clearFilter="resetFilter"
          @removeFilter="removeFilter"
        />
      </div>

      <talentsSkeltons v-if="isloading" />
      <Talents :talents="talents" v-else-if="talents.length > 0 && !isloading" />
      <NoResultFound
        v-else-if="talents.length == 0 && !isloading && skills.length !== 0"
      />
      

      <div v-if="paginator.total / paginator.per_page > 1">
        <Pagination
          :paginator="paginator"
          @handlePaginate="handlePaginate($event)"
        />
      </div>

      <div class="filter__wrapper" v-if="currentSkillId">
        <transition name="slide">
          <div v-show="isOpenFilter" class="filter__wrapper__container">
            <div class="filter__wrapper__header">
              <h5 class="tw-text-[28px] tw-font-bold tw-text-[#1e2022]">
                {{ $t("matchingFilter.filter") }}
              </h5>
              <button
                class="filter__wrapper__close"
                @click="isOpenFilter = false"
              >
                <img
                  src="@/assets/images/ic_close.svg"
                  height="14px"
                  width="14px"
                />
              </button>
            </div>
            <hr class="smallHr" />
            <div class="filter__wrapper__profile">
              <matchingFilter
                ref="matchingFilter"
                :industries="industries"
                :frontEndLanguagesList="frontEndLanguagesList"
                :backEndLanguagesList="backEndLanguagesList"
                :skillsOptions="skillsOptions"
                :availabilityList="availabilityList"
                :seniorityLevel="seniorityLevel"
                :LanguagesList="LanguagesList"
                :levelsList="levelsList"
                :role="currentSkillId"
                :countryList="countriesList"
                :isOpenFilter="isOpenFilter"
                :isSkillsLoading="isSkillsLoading"
                :isCountriesLoading="isCountriesLoading"
                @updateMeta="updateMeta"
                @getSkills="getTalentSkills"
                @getCountries="getCountriesRequest"
                @runFilter="applyFilter"
              />
            </div>
            <div class="filter__wrapper__footer">
              <div
                class="d-flex tw-justify-between tw-w-full md:tw-items-center tw-flex-col md:tw-flex-row tw-items-start"
              >
                <span
                  class="tw-font-bold tw-text-[18px] tw-mb-4 md:tw-mb-0"
                  v-if="metaData"
                >
                  {{ $t("matchingFilter.totalNumber") }}:
                  {{ metaData.meta.total }}</span
                >
                <div v-else></div>

                <div class="d-flex tw-ms-auto md:tw-ms-0">
                  <Button
                    :text="$t('matchingFilter.reset')"
                    varient="btn"
                    btnStyle="py-3 px-5 tw-h-[50px] !tw-flex tw-items-center me-3 tw-text-[14.32px] tw-font-medium reset-btn"
                    @click.native="resetFilter"
                  />
                  <Button
                    :text="$t('matchingFilter.apply')"
                    varient="btnSecondary"
                    btnStyle="py-3 px-5 tw-h-[50px] !tw-flex tw-items-center tw-text-[14.32px] tw-font-medium"
                    @click.native="applyFilter(true)"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
        <div
          v-if="isOpenFilter"
          class="blur-wrapper"
          :class="{ active: isOpenFilter }"
          @click="isOpenFilter = false"
        ></div>
      </div>

      <MeetModal v-if="isOpen" :isOpen="isOpen" @update="isOpen = $event" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import axiosCancelMixin from "@/mixins/axiosCancelMixin.js";

import Pagination from "./../../../components/shared/pagination/pagination.vue";
import Carousel from "./components/carousel/carousel.vue";
import NoResultFound from "./components/noResultFound/noResultFound.vue";
import Heading from "./components/heading/heading.vue";
import Talents from "./components/talents/talents.vue";
import talentsSkeltons from "./components/talentSkeltons/talentSkeltons.vue";
import MeetModal from "./components/meetModal/meetModal.vue";
import matchingFilter from "./components/matchingFilter/matchingFilter.vue";
import Button from "@/components/shared/button/button.vue";
import filterTags from "@/pages/Client/matching/components/filterTags/filterTags.vue";
import { scrollToTop } from "../../../utils/utils";
import Languages from "@/static/Languages";
import levels from "@/static/LanguageLevel";
import "./matching.scss";
import discountBanner from "@/components/matching/discountBanner";

export default {
  mixins: [axiosCancelMixin],
  components: {
    Heading,
    Carousel,
    Talents,
    Pagination,
    NoResultFound,
    talentsSkeltons,
    MeetModal,
    matchingFilter,
    Button,
    filterTags,
    discountBanner
  },
  data: () => {
    return {
      talents: [],
      talentsSkeltons: [],
      paginator: { total: 0 },
      skills: [],
      page: 1,
      currentSkillId: null,
      isResponse: false,
      isloading: true,
      isOpen: false,
      isOpenFilter: false,
      metaData: {
        meta: {
          total: 0,
        },
        fiterData: {
          qualified: null,
          "language[name]": null,
          "language[level]": null,
          seniority_level: null,
          industry: null,
          skills: [],
          countries: [],
          primary_technical_language: null,
          secondary_technical_language: null,
          availability: [],
        },
      },
      filterApplyied: false,
      industries: [],
      frontEndLanguagesList: [],
      backEndLanguagesList: [],
      skillsOptions: [],
      countriesList: [],
      isCountriesLoading: false,
      isSkillsLoading: false,
    };
  },
  mounted() {
    const roleID = Number(this.$route.query.role);
    this.currentSkillId = roleID ? roleID : this.currentSkillId;
    this.getSkills();
    this.fetchFilterDate();
    this.scrollToTop();
  },
  computed: {
    ...mapGetters({
      activeProject: "client/getActiveProject",
      user: "auth/getUser",
    }),
    isSafari() {
      return !!window.GestureEvent;
    },
    getMatchingFilter() {
      return this.$store.getters['client/getMatchingFilter'][this.currentSkillId];
    },
    seniorityLevel() {
      return [
        {
          text: `${this.$t("matchingFilter.junior")}`,
          value: "junior",
        },
        {
          text: this.$t("matchingFilter.mid"),
          value: "mid",
        },
        {
          text: this.$t("matchingFilter.senior"),
          value: "senior",
        },
      ];
    },
    availabilityList() {
      return [
        {
          name: `${this.$t("matchingFilter.Full-time")} `,
          id: "full_time",
        },
        {
          name: `${this.$t("matchingFilter.Part-time")}`,
          id: "part_time",
        },
        {
          name: `${this.$t("matchingFilter.Not-defined")}`,
          id: "undefined",
        },
      ];
    },
    LanguagesList() {
      const LanguagesList = Languages?.map((lang) => ({
        id: lang,
        name: this.$t(`languages.${[lang]}`),
      }));
      return LanguagesList;
    },
    levelsList() {
      return levels.map((level) => {
        return { ...level, name: this.$t(`languageLevel.${[level.name]}`) };
      });
    },
  },
  watch: {
    isOpenFilter(value) {
      const body = document.querySelector("body");
      if (value) body.classList.add("is-fixed");
      else body.classList.remove("is-fixed");
    },
    activeProject() {
      this.talents = [];
      this.paginator = { total: 0 };
      this.isloading = true;
      this.isResponse = false;
      this.getSkills();
      this.scrollToTop();
    },
    skills(newSkills) {
      if (newSkills.length > 0) {
        this.currentSkillId = this.skills[0].id;
        //this.getTalents(this.skills[0].id);
      } else {
        this.isloading = false;
      }
    },
    currentSkillId() {
      this.getCountriesRequest();
      this.getTalentSkills();
      
    },
  },
  methods: {
    ...mapActions({
      getProjectTypesRequest: "talentProfile/getProjectTypesRequest",
      loadMatchingFilter: "client/loadMatchingFilter",
      saveMatchingFilter: "client/saveMatchingFilter",
    }),
    scrollToTop,
     openFilter() {
      this.isOpenFilter = true;
    /*   if (!this.industries.length) {
        this.fetchFilterDate();
      } */
    },
    fetchFilterDate() {
      this.getProjectTypesRequest().then((result) => {
        this.industries = result;
      });
      //this.getCountriesRequest();
      //this.getTalentSkills();
      this.getFrontEndLanguages();
      this.getBackEndLanguages();
    },
    updateMeta($event) {
      this.metaData = $event;
    },
    removeFilter(key) {
      this.$refs.matchingFilter.removeFilter(key);
    },
    async getTalentSkills() {
      if (!this.currentSkillId  )  return;
      try {
        this.isSkillsLoading = true
        await this.axios
          .get(
            `/api/staticContent/roles/${this.currentSkillId}/skills/matching`,
            {
              params: {
                ...this.metaData?.fiterData,
              },
            }
          )
          .then((res) => {
            this.skillsOptions = res.data.data

          }).finally(() => {
            this.isSkillsLoading = false
          });
      } catch (error) {
        console.log(error);
      }
    },
    getCountriesRequest() {
       if (!this.currentSkillId  ) return;

      this.isCountriesLoading = true;
      this.axios
        .get(
          `/api/staticContent/populated/countries/matching/${this.currentSkillId}`,
          {
            params: {
              ...this.metaData?.fiterData,
            },
          }
        )
        .then((res) => {
          this.countriesList = res.data.data;
        }).finally(() => {
          this.isCountriesLoading = false;
        });
    },
    getFrontEndLanguages() {
      this.axios.get("/api/staticContent/frontendLanguages").then((res) => {
        this.frontEndLanguagesList = res.data.data;
      });
    },
    getBackEndLanguages() {
      this.axios.get("/api/staticContent/backendLanguages").then((res) => {
        this.backEndLanguagesList = res.data.data;
      });
    },
    resetFilter() {
      this.metaData = {
        meta: {
          total: this.metaData.meta.total,
        },
        fiterData: {
          qualified: null,
          "language[name]": null,
          "language[level]": null,
          seniority_level: null,
          industry: null,
          skills: [],
          countries: [],
          primary_technical_language: null,
          secondary_technical_language: null,
          availability: []
        },
      };
      this.filterApplyied = false;
      this.saveMatchingFilter({roleId: this.currentSkillId, filter: this.metaData?.fiterData})
      this.$refs.matchingFilter.reset();
    },
    applyFilter(sendEvent = false) {
      this.page = 1;
      this.getTalents(this.currentSkillId);

       const params = {
              page: this.page,
              ...this.metaData?.fiterData,
       }

         if (window.gtag && sendEvent) window.gtag("event",  "clinet filter in matched talents", {
            clinet_id: this.user.id,
            clinet_project_id: this.activeProject.id,
            filter_params: params
          });
            
      
      
      this.isOpenFilter = false;
      this.filterApplyied = true;
    },
    openMeetModal() {
      if (
        !localStorage.getItem("first_open_meeting_modal") &&
        this.talents.length != 0
      ) {
        this.isOpen = true;
        localStorage.setItem("first_open_meeting_modal", true);
      } else {
        localStorage.setItem("first_open_meeting_modal", true);
      }
    },
    handleSkillClick(id) {
      this.currentSkillId = id;
      this.page = 1;
    },
    handlePaginate(pageNum) {
      this.scrollToTop();
      this.page = pageNum;
      this.getTalents(this.currentSkillId);
    },
    async getTalents(id) {
      try {
        this.isloading = true;

        const cancelToken = this.createCancelToken();

        const vetted = await this.axios
          .get(`/api/client/projects/matching/v2/${id}/talents`, {
            cancelToken,
            params: {
              page: this.page,
              ...this.metaData?.fiterData,
            },
          })
          .then((res) => {
            this.saveMatchingFilter({roleId: id, filter: this.metaData?.fiterData})
            this.talents = res.data.data;
            this.paginator = res.data.meta;
            this.isloading = false;
            if (!this.isOpenFilter) {
              this.metaData.meta.total = res.data.meta.total;
            }
          });
        /* 
        const unvetted = await this.axios
          .get(`/api/client/projects/matching/${id}/unvetted/talents`)
          .then((res) => {
            console.log(res);
          }); */

        Promise.all([vetted])
          .then(() => {

            this.openMeetModal();
          })
          .catch(() => {});
      } catch (error) {
        this.isloading = false;
        console.log(error);
      }
    },
    async getSkills() {
      try {
        await this.axios
          .get("/api/client/projects/matching/roles",)
          .then((res) => {
            this.skills = res.data.data;
            this.isResponse = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.filter__wrapper {
  .reset-btn {
    border: 1px solid rgba(0, 0, 0, 1) !important;
  }
}
</style>
<style lang="scss" scoped>
.filter__wrapper {
  position: relative;

  .smallHr {
    margin: 0 auto;
    border-bottom: 1px solid rgba(226, 226, 226, 1);
    width: calc(100% - 46px);
    display: block;
  }

  &__profile {
    max-width: 650px;
    width: 100%;
    padding: 20px 20px 18px;
  }

  &__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 96%;
    max-width: 650px;
    height: 100dvh;;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f8f8f8;
    transition: transform 0.15s;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 36px 0 0 36px;
    padding: 0;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #f8f8f8;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 27px 20px 18px;

    h5 {
      margin-inline-end: auto;
      font-size: 19px;
      font-weight: 600;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 27px 20px 18px;
  }

  &__close {
    border: 1px solid #e2e2e2;
    border-radius: 12px;
    padding: 12px;
    color: #000;
    background-color: transparent;
    font-size: 1.1em;
  }

  > .blur-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
    opacity: 0;
    transition: opacity 1s ease;
  }

  > .blur-wrapper.active {
    opacity: 1;
  }
}

.slide-enter-active {
  transition-duration: 100ms;
  transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
  transform: translate(100%, 0) !important;
  -webkit-transform: translate(100%, 0) !important;
}

.slide-leave-active {
  transition-duration: 100ms;
  transform: translate(100%, 0) !important;
  -webkit-transform: translate(100%, 0) !important;
}

html[dir="rtl"] {
  .filter__wrapper {
    &__container {
      right: auto;
      left: 0;
      border-radius: 0 30px 30px 0;
    }
  }

  .slide-enter-active {
    transition-duration: 100ms;
    transform: translate(-100%, 0) !important;
    -webkit-transform: translate(-100%, 0) !important;
  }

  .slide-leave-active {
    transition-duration: 100ms;
    transform: translate(-100%, 0) !important;
    -webkit-transform: translate(-100%, 0) !important;
  }
}
</style>
