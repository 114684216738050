<template>
  <div class="nominated-list">
    <div class="nominated-list-card">
      <div
        v-if="status !== 'talent_pending_update'"
        class="tw-w-full md:tw-px-[29px] tw-py-2 tw-px-3 tw-mb-4 md:tw-py-[13px] tw-bg-[#3bd4ae10] tw-rounded-xl tw-flex-col tw-justify-center tw-items-start tw-gap-2.5 tw-flex"
      >
        <div class="tw-justify-between tw-items-center tw-w-full tw-flex">
          <div
            class="tw-text-[#1e2022] tw-text-sm md:tw-text-lg tw-font-semibold tw-leading-tight"
          >
            {{ $t("requestInterview.talentUpdateHisInfo") }}
          </div>
          <img
            src="@/assets/images/check.svg"
            class="tw-w-6 tw-h-6"
            alt="ic_success"
          />
        </div>
      </div>

      <div class="nominated-list-card__header align-items-center">
        <div class="d-flex tw-items-start tw-w-full">
          <div class="nominated-list-card__img">
            <img
              loading="lazy"
              class="avater"
              src="@/assets/images/talent-avatar.png"
              v-if="!talent.image"
              alt=""
            />
            <img
              loading="lazy"
              class="avater"
              :src="`${talent.image}`"
              v-if="talent.image"
              @error="imageLoadOnError"
              alt=""
            />
            <div class="country-flag" v-if="talent.nationality">
              <CountryFlag
                class="nominated-country-flag"
                :countryCode="talent.nationality.code"
              />
            </div>
            <div class="country-flag" v-else-if="talent.country">
              <CountryFlag
                class="nominated-country-flag"
                :countryCode="talent.country.code"
              />
            </div>
          </div>
          <div class="nominated-list-card__talent tw-w-full">
            <div class="md:!tw-flex-row tw-flex tw-w-full" >
              <div class="nominated-list-card__info md:!tw-flex-row tw-flex">
                <div>
                  <div class="nominated-list-card__name">
                    <a target="_blank" :href="talent.sharable_link">{{
                      splitName(talent.full_name)
                    }}</a>
                    <img
                      loading="lazy"
                      v-tooltip.top="{
                        content: `${
                          talent.is_qualified
                            ? $t('nomination.verified')
                            : $t('nomination.unverified')
                        }`,
                      }"
                      :src="
                        talent.is_qualified
                          ? require('@/assets/images/ic_verifed.svg')
                          : require('@/assets/images/ic_unverifed.svg')
                      "
                      alt=""
                    />
                  </div>
                  <div class="nominated-list-card__role">
                    {{ talent.role.name }}
                  </div>

                  <div
                    class="nominated-list-card__experince md:tw-flex tw-hidden"
                  >
                    <template v-if="talent.experience_years">
                      {{ talent.experience_years }}
                      {{
                        talent.experience_years > 1
                          ? $t("nomination.yearsExperience")
                          : $t("nomination.yearOfExperience")
                      }}
                    </template>
                    <template v-else>
                      {{ $t("matchedTalents.fresh") }}
                    </template>
                  </div>
                </div>
              </div>

              <div class="tw-w-max tw-hidden md:tw-flex nominated-list-card__veiw-profile tw-ms-auto">
                <a :href="talent.sharable_link" target="_blank">{{
                  $t("matchedTalents.viewProfile")
                }}</a>
              </div>
            </div>

            <div class="nominated-list-card__availability tw-hidden md:tw-flex">
              <ul class="tw-flex tw-gap-2">
                <li v-if="fullTimeStatus && fullTimeSalary != 0">
                  <span class="nominated-list-card__availability__type">
                    {{ $t("nomination.fullTime") }}
                  </span>
                  <span class="nominated-list-card__availability__at">{{
                    getAvailabilityTimeName(fullTimeStatus)
                  }}</span>
                  <span
                    v-if="fullTimeSalary"
                    class="nominated-list-card__availability__salary md:tw-flex tw-ms-auto md:tw-flex-row-reverse"
                  >
                    <div
                      class="tw-text-[#bf3543] tw-text-2xl tw-font-semibold tw-leading-tight"
                    >
                      {{ fullTimeSalary }}$
                    </div>
                    <div
                      class="tw-text-[#7f7f7f] tw-text-lg tw-font-normal discount-total-salay tw-leading-relaxed md:!tw-me-2"
                    >
                      {{ originalFullTimeSalary }}$
                    </div>
                  </span>
                </li>
                <li v-if="partTimeStatus && partTimeSalary != 0">
                  <span class="nominated-list-card__availability__type">
                    {{ $t("nomination.partTime") }}
                  </span>
                  <span class="nominated-list-card__availability__at">{{
                    getAvailabilityTimeName(partTimeStatus)
                  }}</span>
                  <span
                    v-if="partTimeSalary"
                    class="nominated-list-card__availability__salary md:tw-flex tw-ms-auto md:tw-flex-row-reverse"
                  >
                    <div
                      class="tw-text-[#bf3543] tw-text-2xl tw-font-semibold tw-leading-tight"
                    >
                      {{ partTimeSalary }}$
                    </div>
                    <div
                      class="tw-text-[#7f7f7f] tw-text-lg tw-font-normal discount-total-salay tw-leading-relaxed md:!tw-me-2"
                    >
                      {{ originalPartTimeSalary }}$
                    </div>
                  </span>
                </li>
              </ul>
            </div>

            <div class="nominated-list-card__experince md:tw-hidden tw-flex">
              <template v-if="talent.experience_years">
                {{ talent.experience_years }}
                {{
                  talent.experience_years > 1
                    ? $t("nomination.yearsExperience")
                    : $t("nomination.yearOfExperience")
                }}
              </template>
              <template v-else>
                {{ $t("matchedTalents.fresh") }}
              </template>
            </div>
          </div>
        </div>

        <div
          class="nominated-list-card__availability tw-w-full md:tw-hidden tw-flex"
        >
          <ul class="tw-w-full">
            <li
              class="tw-w-full md:tw-w-3/4 tw-justify-center"
              v-if="fullTimeStatus"
            >
              <span class="nominated-list-card__availability__type">
                {{ $t("nomination.fullTime") }}
              </span>
              <span class="nominated-list-card__availability__at">{{
                getAvailabilityTimeName(fullTimeStatus)
              }}</span>
              <span
                v-if="fullTimeSalary"
                class="nominated-list-card__availability__salary tw-ms-auto"
              >
                <div
                  class="tw-text-[#bf3543] tw-text-2xl tw-font-semibold tw-leading-tight"
                >
                  {{ fullTimeSalary }}$
                </div>
                <div
                  class="tw-text-[#7f7f7f] tw-text-lg tw-font-normal discount-total-salay tw-leading-relaxed md:!tw-me-2"
                >
                  {{ originalFullTimeSalary }}$
                </div>
              </span>
            </li>
            <li
              class="tw-w-full md:tw-w-3/4 tw-justify-center"
              v-if="partTimeStatus"
            >
              <span class="nominated-list-card__availability__type">
                {{ $t("nomination.partTime") }}
              </span>
              <span class="nominated-list-card__availability__at">{{
                getAvailabilityTimeName(partTimeStatus)
              }}</span>
              <span
                v-if="partTimeSalary"
                class="nominated-list-card__availability__salary tw-ms-auto"
              >
                <div
                  class="tw-text-[#bf3543] tw-text-2xl tw-font-semibold tw-leading-tight"
                >
                  {{ partTimeSalary }}$
                </div>
                <div
                  class="tw-text-[#7f7f7f] tw-text-lg tw-font-normal discount-total-salay tw-leading-relaxed md:!tw-me-2"
                >
                  {{ originalPartTimeSalary }}$
                </div>
              </span>
            </li>
          </ul>
        </div>

         <div
          class=" tw-w-max md:tw-hidden tw-flex nominated-list-card__veiw-profile"
        >
          <a
            :href="talent.sharable_link"
            target="_blank"
            >{{ $t("matchedTalents.viewProfile") }}</a
          >
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
import { availabilityOptions } from "@/static/AvailabilityOptions.js";
import CountryFlag from "@/components/shared/countryFalg/countryFalg.vue";

export default {
  props: {
    talent: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
    },
    fullTimeStatus: {
      type: Number,
    },
    fullTimeSalary: {
      type: Number,
    },
    partTimeStatus: {
      type: Number,
    },
    partTimeSalary: {
      type: Number,
    },
    originalFullTimeSalary: {
      type: Number,
    },
    originalPartTimeSalary: {
      type: Number,
    },
  },
  components: {
    CountryFlag,
  },
  data() {
    return {
      showMore: false,
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      isMobile: window.innerWidth < 768,
    };
  },
  created() {
    this.handleResize();
    this.addEventListeners();
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  computed: {},
  methods: {
    addEventListeners() {
      window.addEventListener("resize", this.handleResize);
    },
    removeEventListeners() {
      window.removeEventListener("resize", this.handleResize);
    },
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
    imageLoadOnError(e) {
      e.target.src = require("@/assets/images/talent-avatar.png");
    },
    splitName(name) {
      if (name?.length > 15) {
        return name.slice(0, 15) + "...";
      } else {
        return name;
      }
    },

    getAvailabilityTimeName(id) {
      if (id !== null) {
        return this.$t(
          `availabilityOptions.${[
            availabilityOptions.find((ele) => ele.id === id)?.name,
          ]}`
        );
      } else {
        return this.$t(`availabilityOptions.locked`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-total-salay {
  position: relative;
  width: fit-content;
  margin-inline-start: auto;
}

.discount-total-salay::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px; /* Thickness of the strike-through */
  background: linear-gradient(45deg, #bf3543, #bf3543); /* Angled effect */
  transform: rotate(10deg); /* Adjust the angle */
  transform-origin: center;
}

.nominated-list {
  .nominated-list-card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 26.4px;
    box-shadow: 11px 8px 56px 0 #422e8717;
    padding: 30px 40px;
    margin-bottom: 1rem;
    position: relative;

    .nominated-list-card__veiw-profile {
      margin-bottom: auto;
      font-weight: 600;
      background: #fff;
      color: #422e87;
      text-decoration: underline;
      margin-top: 1rem;
      font-size: 18px;
      @media (max-width: 576px) {
        margin-top: 0;
        font-size: 0.9375rem;
        margin: 0;
      }
    }

    .nominated-list-card__veiw-profile-sm {
      border: 1px solid #422e87;
      border-radius: 12.49px;
      padding: 16px 30px;
      font-size: 18px;
      font-weight: 600;
      background: #fff;
      color: #422e87;
      width: 50%;
      text-align: center;
      max-height: 72px;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      width: 100%;
      padding: 10px 30px;
      width: 100%;
      margin-bottom: 1rem;
      font-size: 15px;
    }

    @media (max-width: 576px) {
      padding: 20px;
      margin-bottom: 1rem;
    }

    &__talent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__img {
      position: relative;

      .country-flag {
        position: absolute;
        bottom: 0;
        right: 5%;
        .country-flag-wrapper.nominated-country-flag {
          height: 22px;
          width: 22px;
          border-radius: 50%;
        }

        .country-flag-wrapper.nominated-country-flag img {
          width: 100% !important;
          height: 100% !important;
          border-radius: 50% !important;
          -o-object-fit: fill !important;
          object-fit: fill !important;
        }
      }
      .avater {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 576px) {
          width: 4.25rem;
          height: 4.25rem;
          object-fit: contain;
        }
      }
    }

    &__info {
      margin-left: 1rem;

      margin-right: 1rem;
      text-align: left;
      display: flex;
      flex-direction: column;
    }

    .nominated-list-card-name-width {
      width: 200px;
    }

    &__name {
      text-align: left;
      margin-bottom: 0.5rem;
      margin-top: 0.6rem;
      display: flex;
      align-items: center;

      @media (max-width: 576px) {
        margin-bottom: 0;
        margin-top: 0;
        align-items: center;
      }

      a {
        font-size: 1.125rem;
        color: #292929;
        line-height: 18px;
        font-weight: 700;
        clear: both;
        overflow: hidden;
        text-decoration: none;
        word-break: break-all;
        display: flex;
        height: 100%;
        cursor: pointer;

        @media (max-width: 576px) {
          font-size: 1rem;
          color: #292929;
          text-align: center;
          line-height: 20px;
          width: unset;
          clear: both;
          overflow: hidden;
          text-decoration: none;
          word-break: break-all;
          text-align: left;
          line-height: 30px;
        }
      }

      img {
        height: 26px;
        width: 25px;
        margin-left: 0.5rem;
      }
    }
    &__role {
      font-size: 1.2375rem;
      color: #414042;
      line-height: 30px;
      font-weight: 600;
      text-align: left;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 576px) {
        font-size: 14.15px;
        color: #422e87;
        text-align: center;
        line-height: 22.72px;
        text-align: left;
      }
    }

    &__availability {
      align-items: flex-end;
      position: relative;
      margin-inline-start: 1rem;
      margin-top: 1rem;

      @media (max-width: 576px) {
        margin-inline-start: 0;
        margin-bottom: 1rem;
      }

      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        padding-left: 0;

        li {
          display: flex;
          flex-wrap: wrap;
          background-color: #f8f8f8;
          border-radius: 23px;
          color: #292929;
          padding: 5px 16px;
          align-items: center;
          margin-bottom: 0.25rem;
          min-height: 40px;

          @media (max-width: 576px) {
            padding: 5px 10px;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .nominated-list-card__availability__type {
            margin-inline-end: 5px;
            font-weight: 500;
            font-size: 15.5px;
            color: #292929;
            @media (max-width: 576px) {
              font-size: 0.875rem;
              font-weight: 500;
              margin-inline-end: 8px;
            }
          }

          .nominated-list-card__availability__at {
            font-size: 14px;
            color: #808285;
            margin-inline-end: 5px;
            @media (max-width: 576px) {
              font-size: 0.77775rem;
              font-weight: 400;
              margin-inline-end: 8px;
            }
          }

          .nominated-list-card__availability__salary {
            font-size: 20px;
            color: #373a3b;
            font-weight: 600;

            @media (max-width: 576px) {
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 600;
            }
          }
        }
      }
    }

    &__description {
      color: #9f9d9d;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      word-break: break-all;
      margin-top: 25px;
      @media (max-width: 576px) {
        font-size: 11px;
        margin-top: 10px;
      }

      span {
        font-size: 16px;
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
        @media (max-width: 576px) {
          font-size: 11px;
        }
      }
    }
  }

  .nominated-list-card__experince {
    font-size: 16px;
    color: #9f9d9d;
    text-align: center;
    line-height: 25.78px;
    font-weight: 500;
    align-items: center;
    margin: 0;
    white-space: nowrap;

    @media (max-width: 576px) {
      font-size: 15px;
      margin: 0 1rem;
    }
  }
}
</style>
