<template>
  <div class="terms-and-conditions">
    <slot name="terms-and-conditions">
      <div class="steps" id="steps">
        <div class="container">
          <div class="row">
            <div class="heading text-center">
              <h2 class="text-center">
                {{ $t("bringBuddy.termsAndConditions") }}
                <span class="text-yellow">
                  {{ $t("bringBuddy.forBonusApplication") }}
                </span>
              </h2>
              <p class="text-center col-lg-6 mx-auto subtitle">
                {{ $t("bringBuddy.termsAndConditionsToBeApplied") }}
              </p>
              <div class="d-flex align-items-center justify-content-center mt-3">
                <img loading="lazy" src="../../../../assets/images/ic_line-title.svg" />
              </div>
            </div>
            <ul class="row d-flex steps-ul pl-0 text-start pt-3">
              <li class="col-lg-5">
                <span class="step-number"> 1 </span>
                <p>
                  {{ $t("bringBuddy.talentCVMustBeSentOnlyThrough") }}
                  <span>“Referral@Squadio.com“</span>
                </p>
              </li>
              <li class="col-lg-2 d-flex align-items-center steps-arrow">
                <div
                  class="right-arrow"
                  :class="{ 'hiring-arrow-rotate': $i18n.locale == 'ar' }"
                >
                  <img loading="lazy"
                    src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                  />
                </div>
              </li>
              <li class="col-lg-5">
                <span class="step-number"> 2 </span>
                <p class="mb-0">
                  <span> {{ $t("bringBuddy.theEngineeringRecruiters") }}</span>
                  {{
                    $t(
                      "bringBuddy.willCheckThatTheReferredTalentIsNotAlreadyEnrolledInTheSquadioTalentPool"
                    )
                  }}
                </p>

                <div
                  class="right-arrow right-arrow-md"
                  :class="{ 'hiring-arrow-rotate': $i18n.locale == 'ar' }"
                >
                  <img loading="lazy"
                    src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                  />
                </div>
              </li>

              <li class="col-lg-5">
                <span class="step-number"> 3 </span>
                <p>
                  {{ $t("bringBuddy.inCase") }}
                  <span>
                    {{ $t("bringBuddy.talentAlreadyAppliedToSquadioBefore") }}
                  </span>

                  {{
                    $t(
                      "bringBuddy.receivingHisCVFromRefererTheBonusWillBeDisregarded"
                    )
                  }}
                </p>
              </li>
              <li class="col-lg-2 d-flex align-items-center steps-arrow">
                <div
                  class="right-arrow"
                  :class="{ 'hiring-arrow-rotate': $i18n.locale == 'ar' }"
                >
                  <img loading="lazy"
                    src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                  />
                </div>
              </li>
              <li class="col-lg-5">
                <span class="step-number"> 4 </span>
                <p>
                  {{ $t("bringBuddy.bonusIs") }}
                  <span>
                    {{
                      $t("bringBuddy.paidAfterTheEndOfTheProjectForShortTerm")
                    }}</span
                  >

                  {{ $t("bringBuddy.engagementsOf3MonthsAnd") }}
                  <span>
                    {{
                      $t(
                        "bringBuddy.afterTheProbationaryPeriodForLongTermProjects"
                      )
                    }}
                  </span>
                </p>

                <div
                  class="right-arrow right-arrow-md right-last-arrow"
                  :class="{ 'hiring-arrow-rotate': $i18n.locale == 'ar' }"
                >
                  <img loading="lazy"
                    src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import "./termsAndConditionsModal.scss";
export default {};
</script>

<style lang="scss" scoped></style>
