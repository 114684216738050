<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mt-4 mb-3">
      <h3 class="headerBorder mb-0">
        <span>
          {{ $t("signUp.projectTypes") }}
        </span>
      </h3>
      <span class="add-tab-icon" @click="openModal(null)"> </span>
    </div>

    <div
      class="project-box mb-4"
      v-for="(project, id) in Object.keys(projects)"
      :key="id"
    >
      <div class="position-relative text-start">
        <h3>{{ project }}</h3>
      </div>
      <div v-for="(projectDetails, i) in projects[project]" :key="i">
        <div class="row time-skill skill-16">
          <div class="col-6 text-start text-grey">
            <h4>{{ projectDetails.name }}</h4>
          </div>
          <div class="col-6 text-end">
            <div class="d-flex align-items-center justify-content-end">
              <a
                :href="getProjectUrl(projectDetails.link)"
                target="_blank"
                v-if="projectDetails.link"
                >{{ $t('sharebleLink.viewProject') }}</a
              >
              <div class="ms-2">
                <button
                  type="button"
                  class="more-tab-icon"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></button>
                <ul class="dropdown-menu dropdown-menu-end small-more-pop">
                  <li @click="openModal(projectDetails.id)">
                    <button class="dropdown-item" type="button">
                    
                     {{ $t('matchedTalents.edit') }}
                    </button>
                  </li>
                  <li @click="openDeleteModal(projectDetails.id)">
                    <button class="dropdown-item" type="button">
                    {{ $t('talentWizard.delete') }}
                    
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr v-if="projects[project].length !== i + 1" />
      </div>
    </div>
    <Modal
      v-if="isModalOpen"
      @update="isModalOpen = $event"
      :heading="modalHeading"
      className="projects-modal modal-forms-responsive"
      :description="$t('talentWizard.enterProjectDetails')"
      :submitText="$t('talentWizard.save')"
      :handleSubmit="() => handleSubmitProjectForm()"
      :isSubmitDisabled="isDisabled"
    >
      <template :v-slot="project - form"
        ><ProjectForm
          ref="ProjectForm"
          :projectDetails="projectDetails"
          @handleCloseModal="handleCloseModal($event)"
          @handleIsDisabled="handleIsDisabled($event)"
      /></template>
    </Modal>

    <Modal
      v-if="isDeleteModalOpen"
      @update="isDeleteModalOpen = $event"
      className="delete-projects-modal"
      :submitText="$t('talentWizard.delete')"
      :handleSubmit="() => handleDeleteProject()"
    >
      <template :v-slot="delete -modal - body"
        ><deleteModalBody
          ref="DeleteProject"
          @handleCloseModal="handleCloseModal($event)"
      /></template>
    </Modal>
    <p class="no-data-text" v-if="projects.length == 0">
      {{ $t("talent.noData") }}
    </p>
  </div>
</template>

<script>
import { getUrl } from "../../../../../functions/url";
import deleteModalBody from "../deleteModalBody/deleteModalBody.vue";
import Modal from "../../../../../components/shared/modal/modal.vue";
import ProjectForm from "../projectForm/projectForm.vue";
export default {
  props: ["projects"],
  components: { Modal, ProjectForm, deleteModalBody },
  computed: {
    console: () => console,
  },
  data() {
    return {
      modalHeading: this.$t('talentWizard.editProject'),
      isModalOpen: false,
      isDeleteModalOpen: false,
      targetProjectId: null,
      projectDetails: { id: "", name: "", project_type_id: "", link: "" },
      isDisabled: false,
    };
  },
  methods: {
    getProjectUrl(url) {
      return getUrl(url);
    },
    openModal(id = null) {
      if (id !== null) {
        this.modalHeading = this.$t('talentWizard.editProject');
        this.getProjectDetails(id);
        this.targetProjectId = id;
      } else {
        if (Object.keys(this.projects).length < 25) {
          this.modalHeading = this.$t('talentWizard.addProject')
          this.projectDetails = { name: "", project_type_id: "", link: "" };
          this.isModalOpen = true;
        }
      }
    },
    openDeleteModal(id) {
      this.targetProjectId = id;
      this.isDeleteModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
      this.isDeleteModalOpen = false;
    },
    handleSubmitProjectForm() {
      this.$refs.ProjectForm.handleSubmitProjectForm();
    },
    handleDeleteProject() {
      this.$refs.DeleteProject.handleDelete(this.targetProjectId);
    },
    getProjectDetails(id) {
      this.$store
        .dispatch("talentProfile/getProjectDetailsRequest", id)
        .then((res) => {
          this.projectDetails = {
            id: res.data.data.id,
            name: res.data.data.name,
            link: res.data.data.link,
            project_type_id: res.data.data.project_type.id,
          };
          this.isModalOpen = true;
        });
    },
    handleIsDisabled(status) {
      this.isDisabled = status;
    },
  },
};
</script>

<style lang="scss" scoped></style>
