// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/bg-blur-lg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/images/bg-blur-sm.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".no-result-wrapper{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-color:#f9f9f9;background-size:cover;background-repeat:no-repeat;padding:70px 40px;border-radius:20px;min-height:800px}@media(max-width:768px){.no-result-wrapper{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");padding:50px 20px}}.no-result-wrapper .locked-popUp{border-radius:20px;background-color:#fff;padding:60px 20px;z-index:33;box-shadow:9px 7px 47px 0 rgba(66,46,135,.09)}", ""]);
// Exports
module.exports = exports;
