<template>
  <div class="tw-flex tw-justify-center tw-container tw-w-full tw-m-auto tw-pb-8">
    <div class="tw-m-0 sm:tw-m-4 tw-flex tw-justify-center tw-flex-1 md:tw-gap-20 tw-mx-2 tw-bg-white tw-max-w-5xl tw-rounded-lg tw-pb-8">
      <div class="tw-w-full tw-max-w-3xl tw-mx-4 md:tw-mx-0 tw-mt-0 md:tw-mt-8 tw-px-4">
        <h2 class="tw-text-xl md:tw-text-3xl tw-text-center tw-text-primary tw-font-bold tw-mt-12 md:tw-mt-0">
          {{ $t('signUp.signupClient') }}
        </h2>
        <h3 class="tw-text-xl tw-text-center tw-text-[#1b1b1b] tw-mt-2 tw-mb-12">
          {{ $t('signUp.signupClientSlogan') }}
        </h3>
        <client-signup-form />
      </div>
    </div>
  </div>
</template>
<script>
import clientSignupForm from "./new-client.vue";

export default {
  components: {
    clientSignupForm,
  },
/*   watch: {
    campaign: {
      immediate: true,
      deep: true,
      handler() {
        if (this.campaign == 15 && window.location.href.includes("squadio.com")) {
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PKT8578B');
        }
      }
    }
  }, */
};
</script>