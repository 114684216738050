<template>
  <div class="logedin-talent-layout">
    <Navbar class=" tw-z-10" />
     <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/navbar/navbar.vue";
import Footer from "./components/footer/footer.vue";
import '../Client/client.css';
import '../style.css'
import "./logedinTalentLayout.scss"

export default {
  components:{
     Navbar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>


<style scoped>
.logedin-talent-layout{ 
  overflow: hidden;
}
</style>