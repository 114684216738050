<template>
  <div class="shareable-link__availability">
    <div>
      <div class="position-relative">
        <h3 class="mb-4 headerBorder" v-if="isResponse">
          <span> {{ $t('sharebleLink.availability') }} </span>
        </h3>
        <div class="mb-3 d-flex align-items-center justify-content-start" v-if="!isResponse">
          <skeleton-loader-vue v-if="!isResponse" type="rect" :width="300" :height="30" animation="fade" rounded />
        </div>
      </div>
      <div v-if="isResponse &&  !profile.BasicInfo.is_unlocked" >
        <div
          class="tw-flex tw-w-11/12 tw-m-auto tw-px-8 tw-py-4 tw-bg-white tw-rounded-3xl tw-flex-col tw-justify-center tw-items-center tw-gap-1"
            style="box-shadow: 0px 2px 20px -1px rgba(66, 46, 135, 0.20);"
          >
          <img class="tw-w-8 tw-h-8 tw-mb-1" src="@/assets/images/talent/locked-3 1.svg" alt="locked" />
          <div class="tw-text-black tw-text-sm tw-font-medium tw-leading-7 tw-mb-1">
            {{ $t('sharebleLink.salaryLocked') }}
          </div>
          <span class="tw-text-center tw-text-[#a5a5a5] tw-text-xs tw-font-normal">
            {{ $t('sharebleLink.requestInterviewToUpdate') }}
          </span>
        </div>
      </div>
      <div>
        <Availability v-if="isResponse && profile.BasicInfo.is_unlocked"
          :full_time_status="profile && profile.BasicInfo && profile.BasicInfo.full_time_status"
          :part_time_status="profile && profile.BasicInfo && profile.BasicInfo.part_time_status" />
        <div class="mb-3 d-flex align-items-center justify-content-start">
          <skeleton-loader-vue v-if="!isResponse" type="rect" :width="400" :height="30" animation="fade" rounded />
        </div>

        <Salary v-if="isResponse && profile.BasicInfo.is_unlocked"
          :part_time_salary="profile && profile.BasicInfo && profile.BasicInfo.total_part_time_salary"
          :full_time_salary="profile && profile.BasicInfo && profile.BasicInfo.total_full_time_salary"
          :full_time_status="profile && profile.BasicInfo && profile.BasicInfo.full_time_status"
          :part_time_status="profile && profile.BasicInfo && profile.BasicInfo.part_time_status" 
          :full_time_totalSalay="profile && profile.BasicInfo && profile.BasicInfo.original_full_time_salary"
          :part_time_totalSalay="profile && profile.BasicInfo && profile.BasicInfo.original_part_time_salary"

          />
        <div class="mb-3 d-flex align-items-center justify-content-start" v-if="!isResponse">
          <skeleton-loader-vue v-if="!isResponse" type="rect" :width="400" :height="30" animation="fade" rounded />
        </div>
        <div class="mb-3 d-flex align-items-center justify-content-start" v-if="!isResponse">
          <skeleton-loader-vue v-if="!isResponse" type="rect" :width="400" :height="30" animation="fade" rounded />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Availability from "@/pages/Client/clientViewTalentProfile/components/availability/availability.vue";
import Salary from "@/pages/Client/clientViewTalentProfile/components/salary/salary.vue";
export default {
  props: ["profile", "isResponse"],
  components: { Availability, Salary },
};
</script>

<style lang="scss">
.shareable-link__availability {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 3%);
  position: relative;
  padding: 20px;
  margin: 0px;
  margin-top: 30px;

  hr {
    margin: 1rem 0;
    color: #edebeb;
    border: 0;
    border-top: 1px solid;
    opacity: 1;
  }




  .accordion-item {
    border-radius: 30px !important;
    overflow: hidden;
  }

  @media (max-width: 576px) {
    padding: 15px;
    margin: 0;
    margin-top: 15px;
  }
}
</style>