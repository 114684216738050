<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable d-flex justify-content-center align-items-center">
        <div class="modal-content">
          <div class="border-0 modal-header removeTitleBorder">
            <div class="w-100">
              <h4 class="p-0 modal-title main-color tw-text-center bold md:tw-text-[30px] tw-text-[25px]">
                {{ $t('contractsRenewal.rejectContractRenewal') }}
              </h4>
              <hr class="smallHr" />
            </div>
            
          </div>
          <div class="pt-0 border-0 modal-body">
            
          
            
            <h6 class=" tw-text-[28px] tw-text-[#202020] tw-font-semibold tw-text-center tw-mb-8">{{ $t('contractsRenewal.areYouSureReject') }}</h6>

                        <div
              class="tw-bg-[#F4F1FD] tw-rounded-[15.75px] tw-p-[18px] tw-my-4 md:tw-mx-6 tw-mx-0 tw-flex"
            >
              <div class="tw-flex">
                <img loading="lazy"
                  :src="talentProfilePic"
                  class="img-fluid md:tw-w-14 md:tw-h-14 tw-w-9 tw-h-9 tw-rounded-full tw-object-cover"
                 :class="this.talentContract && this.talentContract.talent.image && this.talentContract.talent.image.path? 'tw-border-[rgba(228, 228, 228, 1)] tw-border-[5.63px]' : ''"
                />
              </div>
              <div class="tw-flex tw-flex-col tw-justify-center tw-ms-4">
                <div
                  class="tw-flex tw-font-bold tw-text-[#292929] tw-text-[12.91px] md:tw-text-[15.59px]"
                >
                  {{ talentContract.talent.full_name }} 
                  <span class="tw-ms-2">
                    <img loading="lazy"
                      class="tw-w-5 tw-h-5"
                      :src="
                        talentContract.talent.is_qualifed
                          ? require('@/assets/images/ic_verifed.svg')
                          : require('@/assets/images/ic_unverifed.svg')
                      "
                    />
                  </span>
                </div>
                <div class="tw-flex tw-items-center">
                  <div
                    class="  tw-text-[11] md:tw-text-[12.99px] tw-text-primary tw-font-semibold"
                  >
                    {{talentContract.talent.role?.name}}
                  </div>
                  <template v-if="Number(talentContract.talent.experience_years) > 0 && talentContract.talent.experience_years != null">
                    <span class="tw-ms-2 tw-text-[11px] md:tw-text-[12.99px]] tw-text-[#9f9d9d] tw-font-medium">
                      {{ talentContract.talent.experience_years }} {{ talentContract.talent.experience_years == 1 ? $t('nomination.yearOfExperience') : $t('nomination.yearsExperience') }}
                    </span>
                  </template>
                  <span v-else-if="Number(talentContract.talent.experience_years) == 0 && talentContract.talent.experience_years != null" class="tw-ms-2 tw-text-[11px] md:tw-text-[12.99px]] tw-text-[#9f9d9d] tw-font-medium">
                    {{ $t('matchedTalents.fresh') }}
                  </span>
                </div>
              </div>
            </div>

            
            <div class=" tw-mb-4">
                <div class=" tw-flex tw-flex-wrap tw-justify-center">
                    <span class=" tw-border-[#e5e5e5] tw-border-[0.8px] tw-border-solid tw-rounded-[23.8px] tw-text-[16px]  tw-me-2 tw-mb-2 tw-px-[28px] tw-py-[10px] tw-h-auto tw-cursor-pointer " 
                    :class="option.checked ? ' tw-text-primary tw-bg-[#422e8717] !tw-border-[#77777763] tw-font-medium' : 'tw-bg-[#F8F8F8] tw-text-[#414042] tw-font-medium '"
                    @click="handleCheck(option)"
                    v-for="option in selectOptions" :key="option.id">{{ option.name }}</span>
                </div>
                <VulidateError errMsgStyle="tw-flex tw-w-full tw-justify-center"  :validationField="$v.reason"  />
            </div>

             <div>
                <Textarea
                className="tw-bg-[#F8F8F8] tw-border-[#979797] tw-rounded-[14px] tw-placeholder-[#414042] tw-text-[16px] tw-px-[20px] tw-py-[13px] "
                :validationField="$v.comment"
                :placeholder="$t('contractsRenewal.WriteReason')"
                rows="4"
                :params="[{ maxLength: { number: '5000' } }, 'required' ]"
                />
            </div>
           
          </div>
          <div class="pt-0 border-0 modal-footer">
            <div class=" d-flex tw-justify-end">
              <button
              type="button"
              class="btn cancelbtn btnstyleUpdate  tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px] tw-rounded-xl tw-border-2 tw-border-solid tw-border-[#c5c5c5] "
              data-bs-dismiss="modal"
              @click="close"
            >
              {{ $t("talentWizard.cancel") }}
            </button>
            <button @click="handleTalentRejectRenewContractRequest" :disabled="$v.$invalid" type="submit" class="btn btn-primary saveBtn tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-min-w-[162.75px] ">
              {{ $t("contractsRenewal.confirm") }}
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
// import toast from "../../services/toast.js";
import { maxLength, required, requiredIf } from "vuelidate/lib/validators";
import Textarea from "@/components/shared/formControls/textarea/textarea.vue";
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";

export default {

  name: "ClientRejectRenewContractRequest",
  components: {Textarea, VulidateError},
  props: ["isOpen", "talentContract"],
  data() {
      return {
        isLoading: false,
        selectOptions: [],
        reason: null,
        comment: null,
      }
    },
  mounted () {
    this.getContractRejectionReason();
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
    reason:{
        immediate: true,
        deep: true,
        handler() {
          this.$v.$touch();
        },
      },
    },
    validations: {
        reason: {
            required
    },
    comment: {
      maxLength: maxLength(5000),
       required: requiredIf(function () {
              return this.reason === 0;
      }),
    },
    },
    computed: {
    talentProfilePic() {
      if (this.talentContract && this.talentContract.talent.image && this.talentContract.talent.image) {
        return `${this.talentContract.talent.image}`;
      }
      else return require("@/assets/images/talent-avatar.png");
    }
  },
    methods: {
        resetForm() {
            this.reason = null;
            this.comment = null;
        },
        handleCheck(option) {
            this.selectOptions = this.selectOptions.map((item) => {
              if (item.id === option.id) {
                item.checked = true;
              } else {
                item.checked = false
              }
              
              return item;
            });
            this.reason = this.selectOptions
              .filter((item) => item.checked)
              .find((item) =>  item.checked).id;
          },
   async getContractRejectionReason() {
        this.isLoading = true;
      try {
        await this.axios
          .get(`/api/staticContent/contractRejectionReason?type=2`)
          .then((res) => {
            this.selectOptions = res.data.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                checked: false,
              };
            })
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
        },
    async handleTalentRejectRenewContractRequest() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
        try {
        let contract = this.talentContract
        await this.axios
          .post(`/api/client/contract-renewal/${contract.id}/reject`, {
            reason: this.reason,
            comment: this.comment,
          })
          .then(() => {
            this.$emit("refresh");
            this.close()
          });
      } catch (error) {
        console.error(error);
        // toast.error(error.response.data?.message || error.message);
      }
    },
    close() {
        this.resetForm()
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
.modal-dialog-scrollable .modal-content {
    max-height: 90%;
    overflow: auto;
}
</style>

<style lang="scss" scoped>
.ltr {
  direction: ltr;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-content {
        padding: 20px 85px;
}

.modal-body.no-border {
      overflow: scroll;
      overflow-x: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-dialog {
  width: 811px;
}

.modal-body.no-border {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

@media (max-width: 700px) {
     
  .modal-dialog {
    width: 90%;
  }

  .modal-content {
    padding: 20px 19px;
  }

  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }

}
</style>

