<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class="tw-pb-4 modal-body no-border">
            <div class="row tw-flex tw-justify-center">
              <img
                class=" tw-w-40 tw-mb-8"
                loading="lazy"
                src="@/assets/images/client/ic_profile-popup.svg"
                alt="profile-popup"
              />
              <h2
                class="tw-text-center tw-text-[#1e2022] tw-text-2xl tw-font-bold tw-leading-[33px] tw-mb-4"
              >
                {{ $t('requestInterview.requestSubmittedSuccessfully') }}
              </h2>
              <p
                class="tw-text-center tw-text-[#9f9d9d] tw-text-[21px] tw-font-medium tw-leading-[33px] tw-mb-6"
              >
             
                  {{ $t('requestInterview.waitingTalentConfirmation') }}
              </p>

              <div class="tw-flex tw-justify-between tw-gap-4 ">
                <div class="w-flex tw-w-1/2 tw-bg-[#d8d8d82e] tw-border-[#dfdfdf]  tw-border-solid tw-rounded-lg tw-py-3 tw-px-7  tw-border ">
                  <span
                    class="tw-text-[#77838f] tw-text-base tw-font-medium tw-leading-snug"
                    >
                      {{ $t('requestInterview.firstSlot') }}
                    <br /></span
                  ><span
                    class="tw-text-[#484d54] tw-text-base tw-font-semibold tw-font-['Poppins'] tw-leading-snug"
                    >{{slotFormat(interviewSlots.slots[0])}}</span
                  >
                </div>
                 <div class="w-flex  tw-w-1/2 tw-bg-[#d8d8d82e] tw-border-[#dfdfdf]  tw-border-solid tw-rounded-lg tw-py-3 tw-px-7  tw-border ">
                  <span
                    class="tw-text-[#77838f] tw-text-base tw-font-medium tw-leading-snug"
                    >
                      {{ $t('requestInterview.secondSlot') }}
                    <br /></span
                  ><span
                    class="tw-text-[#484d54] tw-text-base tw-font-semibold tw-font-['Poppins'] tw-leading-snug "
                    >{{slotFormat(interviewSlots.slots[1])}}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";

export default {
  name: "SelectedInterviewSlotsModal",
  props: ["isOpen", "interviewSlots"],
  data() {
    return {
      isLoading: false,
      isLoadingRequest: false,
      selectedDate: null,
      days: [],
      time: new Date(),
      error: false,
    };
  },
  mounted() {
    this.isLoading = true;
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
    methods: {
    slotFormat(slot) {
        return moment.utc(slot).format("D MMM YYYY, h:mm A");
    },
    dataFormat(date) {
      return moment(date).format("D MMM");
    },
    close() {
      document.body.classList.remove("modal-open");
      this.isRequestAnInterviewLoading = false;
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
@media (min-width: 576px) {
    .modal-dialog[data-v-a526e966] {
        max-width: 55rem;
        margin: 1.75rem auto;
        width: 45rem;
    }
}
</style>


<style>
.modal-open {
  overflow: hidden;
}

.book-meeting-mb {
  min-height: 500px;

  @media (max-width: 786px) {
    margin-bottom: 5rem;
  }
}

.book-meeting-mb .calendly {
  min-height: 500px;
  height: 500px !important;
}

.book-meeting-mb .calendly iframe {
  min-height: 500px;
}

.meetBox .w-full {
  width: 100% !important;
}
</style>
