<template>
    <transition name="modal-fade">
        <div class="modal align-items-center" role="dialog" aria-labelledby="modalTitle"
            aria-describedby="modalDescription" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <!-- <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>  -->

                    <div class="tw-pb-4 modal-body no-border">
                        <div class="row">
                            <div class="tw-text-start col-12 meetBox">
                                <div class="p-0 tw-mb-4 tw-flex tw-flex-col tw-justify-center tw-items-center">
                                    <h2
                                        class=" tw-text-center tw-text-[#422e87] tw-text-xl md:tw-text-3xl tw-font-bold tw-pt-4 md:tw-pt-0 tw-leading-7  mdtw-leading-10 md:tw-mx-20">
                                    {{  $t('requestInterview.cancelTitle')  }}
                                    </h2>
                                    <hr
                                        class="tw-h-px tw-opacity-10 tw-border tw-border-solid tw-border-[#7F7F7F] tw-w-full" />
                                    <div class="p-0 mb-3 tw-w-full">
                                        <Textarea
                                            className=" tw-border-[#979797] tw-rounded-[14px] tw-text-[16px] tw-px-[20px] tw-py-[13px] "
                                            :validationField="$v.reason" :label="$t('requestInterview.cancelRreason')"
                                            :placeholder="$t('requestInterview.cancelRreason')" :rows="3"
                                            :params="[{ maxLength: { number: '1000' } }, 'required']" />
                                    </div>


                                </div>
                                <div
                                    class=" tw-w-full tw-p-0 tw-pb-4 md:tw-pb-0 tw-justify-end tw-flex tw-flex-row tw-gap-4">
                                    <button type="button"
                                        class="btn tw-p-0 poppins-font   !tw-flex !tw-items-center  md:!tw-px-16 tw-w-full tw-justify-center  md:tw-w-auto md:tw-h-14  tw-h-12 tw-rounded-xl tw-border-2 tw-border-solid tw-border-secondary tw-text-secondary hover:tw-border-secondary hover:tw-text-secondary"
                                        data-bs-dismiss="modal" @click="close">
                                        {{  $t('requestInterview.no')  }}
                                    </button>
                                    <button type="button"
                                        class="btn tw-p-0  btn-primary saveBtn !tw-flex !tw-items-center tw-justify-center  md:!tw-px-16 tw-w-full md:tw-w-auto md:tw-h-14 tw-h-12" 
                                        @click="submit">
                                        {{  $t('requestInterview.yes')  }}
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Textarea from "@/components/shared/formControls/textarea/textarea.vue";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
    name: "CancelInterviewModel",
    props: ["isOpen",],
    components: {
        Textarea
    },
    data() {
        return {
            reason: null,
        };
    },
    validations: {
        reason: {
            required,
            maxLength: maxLength(1000),
        },
    },
    mounted() {
    },
    watch: {
        isOpen: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    document.body.classList.add("modal-open");
                } else {
                    document.body.classList.remove("modal-open");
                }
            },
        },
    },
    computed: {
    },
    methods: {
        resetForm() {
            this.reason = null;
        },
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.axios.patch(`/api/talent-selection/${this.$route.params.id}`, {
                "status": "canceled",
                'cancelation_reason': this.reason,
            }).then((response) => {
                this.$emit("cancelled", response.data);
                this.close();
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.resetForm();
            });
        },
        close() {
            document.body.classList.remove("modal-open");
            this.$emit("update", false);
        },
    },
};
</script>

<style scoped>
.modal-body.no-border {
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 700px) {
    .modal-dialog {
        width: 95%;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 45rem;
        width: 45rem;
        margin: 1.75rem auto;
    }
}
</style>


<style lang="scss">
.modal-open {
    overflow: hidden;
}

.book-meeting-mb {
    min-height: 500px;

    @media (max-width: 786px) {
        margin-bottom: 5rem;
    }
}

.book-meeting-mb .calendly {
    min-height: 500px;
    height: 500px !important;
}

.book-meeting-mb .calendly iframe {
    min-height: 500px;
}

.meetBox .w-full {
    width: 100% !important;
}
</style>
