<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      :class="className"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable d-flex justify-content-center align-items-center">
        <div class="modal-content">
          <div class="modal-header border-0 removeTitleBorder">
            <div class="w-100">
              <h4 class="modal-title main-color bold font-size-35 p-0">{{ heading }}</h4>
              <p class="mb-0 description">{{ description }}</p>
              <hr class="smallHr" v-if="!removeTitleBorder"/>
            </div>
            <button
              v-if='!hideCloseIcon'
              type="button"
              class="btn-close"
              @click="$emit('update', false)"
            ></button>
          </div>
          <div class="modal-body border-0 pt-0">
            <slot></slot>
          </div>
          <div v-if="!hideCta" class="modal-footer border-0 pt-0">
            <div class=" d-flex justify-content-end">
              <Button
                :text="$t('talentWizard.cancel')"
                varient="btn"
                :action="() => $emit('update', false)"
                btnStyle="py-3 px-4 mx-1 border-0"
              />
              <Button
                :text="text"
                varient="btnSecondary"
                :action="()=>handleSubmit()"
                 btnStyle="py-3 px-5"
                 :disabled="isSubmitDisabled"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Button from "../../../components/shared/button/button.vue";
export default {
  components: { Button },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    handleSubmit: {
      type: Function,
      default: () => {},
    },
    submitText: {
      type: String,
      default() {
        return this.$t('talentWizard.save');
      }
    },
    isSubmitDisabled: {
      type: Boolean,
      default: false,
    },
    removeTitleBorder: {
      type: Boolean,
      default: false,
    },
    hideCloseIcon: {
      type: Boolean,
      default: false,
    },
    hideCta: {
      type: Boolean,
      default: false,
    },
  },
  data(){
return(
 {
    text: this.submitText || this.$t('talentWizard.save')
 }
)
  },
  mounted(){
    this.$store.dispatch("shared/handleModalStatus", true);
  },
  destroyed(){
    this.$store.dispatch("shared/handleModalStatus", false);
  },
  methods: {},
};
</script>
