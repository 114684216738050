<template>
    <div class="tw-space-y-4">


        <div class="tw-flex tw-flex-col tw-justify-center tw-gap-2 md:tw-gap-4 tw-p-4 tw-space-y-2 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4"
            style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)">
            <div class="">
                <div class="row tw-flex tw-justify-center ">
                    <div class=" tw-flex tw-flex-col md:tw-flex-row tw-justify-items-start tw-gap-4 tw-items-start">
                        <img class=" tw-w-20 md:tw-mb-8" loading="lazy"
                            src="@/assets/images/talent/undraw_schedule_meeting.svg" alt="undraw_schedule_meeting" />
                        <div>

                            <h2
                                class=" tw-text-[#1e2022] tw-text-lg md:tw-text-xl tw-font-bold tw-leading-5 md:tw-leading-[33px] ">
                                {{ $t('requestInterview.requestSubmittedSuccessfully') }}
                            </h2>
                            <p class=" tw-text-[#422e87] tw-text-6 tw-font-semibold tw-leading-[33px] tw-mb-6">

                                {{ $t('requestInterview.waitingTalentConfirmation') }}
                            </p>
                        </div>
                    </div>


                    <div class="tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-gap-4 ">
                        <div
                            class="w-flex tw-w-full md:tw-w-1/2 tw-bg-[#d8d8d82e] tw-border-[#dfdfdf]  tw-border-solid tw-rounded-lg tw-py-3 tw-px-7  tw-border ">
                            <span class="tw-text-[#77838f] tw-text-base tw-font-medium tw-leading-snug">
                                {{ $t('requestInterview.firstSlot') }}
                                <br /></span><span
                                class="tw-text-[#484d54] tw-text-base tw-font-semibold tw-font-['Poppins'] tw-leading-snug">{{
                                    meetingTime(interview.interview_slots[0].start, interview.interview_slots[0].timezone ) }}</span>
                        </div>
                        <div
                            class="w-flex tw-w-full  md:tw-w-1/2 tw-bg-[#d8d8d82e] tw-border-[#dfdfdf]  tw-border-solid tw-rounded-lg tw-py-3 tw-px-7  tw-border ">
                            <span class="tw-text-[#77838f] tw-text-base tw-font-medium tw-leading-snug">
                                {{ $t('requestInterview.secondSlot') }}
                                <br /></span><span
                                class="tw-text-[#484d54] tw-text-base tw-font-semibold tw-font-['Poppins'] tw-leading-snug ">{{
                                    meetingTime(interview.interview_slots[1].start, interview.interview_slots[1].timezone ) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tw-flex tw-items-center tw-justify-start md:tw-justify-end tw-w-full tw-my-auto">
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' || interview.status.id === 'talent_pending' || interview.status.id === 'talent_pending_update'"
              @click="$emit('cancel')"
              class="tw-py-3 md:tw-py-3.5 tw-w-auto md:tw-w-auto    tw-rounded-lg  tw-justify-center tw-items-center tw-underline tw-gap-2.5 tw-flex tw-text-center tw-text-[#ff6f59] tw-text-[12px]  md:tw-text-base tw-font-medium">
              {{ $t('requestInterview.cancelProcess')}}
            </button>
           
          </div>
        </div>

    </div>
</template>

<script>
import moment from "moment";
import momentTimezone from "moment-timezone";

export default {
    name: "InterviewCycleSubmitted",
    props: {
        interview: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            durationsList: [
                {
                    id: 6,
                    name: this.$t("clientWizard.sixMonths"),
                },
                {
                    id: 12,
                    name: this.$t("clientWizard.twelveMonths"),
                },
                {
                    id: 24,
                    name: this.$t("clientWizard.twentyFourMonths"),
                },
                {
                    id: 36,
                    name: this.$t("clientWizard.thirtySixMonths"),
                },
            ],
        }
    },
    watch: {
        "$i18n.locale"() {
            this.durationsList = [
                {
                    id: 6,
                    name: this.$t("clientWizard.sixMonths"),
                },
                {
                    id: 12,
                    name: this.$t("clientWizard.twelveMonths"),
                },
                {
                    id: 24,
                    name: this.$t("clientWizard.twentyFourMonths"),
                },
                {
                    id: 36,
                    name: this.$t("clientWizard.thirtySixMonths"),
                },
            ];
        },
    },
    computed: {
        timezones() {
            let timezones = momentTimezone.tz.names();
            timezones = timezones.map((timezone) => {
                return {
                    name: `${timezone.split("/")[1]} Time`,
                    value: timezone,
                };
            });
            return timezones;
        },
    },
    methods: {
        meetingTime(time, timezone) {
            if (this.$i18n.locale == "en") {
                moment.locale("en");
                return `${moment(time).format("DD/MM/YYYY")} at ${moment(time).format("h:mm A")} 
                ${timezone == 'UTC'? 'UTC time' :  this.timezones.find((tz) => tz.value == timezone )?.name }`;
            } else {
                moment.locale("ar");
                return `${moment(time).format("YYYY/MM/DD")} فى ${moment(time).format("h:mm A")} 
                ${timezone == 'UTC'? 'UTC time' :  this.timezones.find((tz) => tz.value == timezone )?.name }`;
            }
        },
        slotFormat(slot) {
            return moment.utc(slot).format("D MMM YYYY, h:mm A");
        },
        dataFormat(date) {
            return moment(date).format("D MMM");
        },
    },
};
</script>

<style lang="scss" scoped></style>