// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/client/bg_words-shadow-2x.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/caseStudy/case-study-bg-2x.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/banner-shape.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".case-study[data-v-43982583]{overflow:hidden;padding-bottom:50px;background-color:#fff!important}.case-study .case-study__center-header[data-v-43982583]{min-height:80vh;background-size:contain;background-repeat:no-repeat}.case-study .case-study__center-header[data-v-43982583],.case-study .case-study__center-header-card[data-v-43982583]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%}.case-study .case-study__center-header-card[data-v-43982583]{background-size:cover;padding:20px}.case-study .case-study__header[data-v-43982583]{position:relative}.case-study .case-study__header[data-v-43982583]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");content:\"\";position:absolute;top:0;right:0;bottom:0;background-size:cover;background-repeat:no-repeat;background-position:50%;height:1255px;width:1255px;z-index:0}@media(max-width:768px){.case-study .case-study__header[data-v-43982583]:before{height:500px;width:500px}}.case-study .case-study__header[data-v-43982583]:after{position:absolute;left:-120px;top:30%;content:\"\";width:600px;height:600px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:top;background-size:cover}.case-study .landing_wrapper[data-v-43982583]{position:relative;z-index:40}.case-study .landing_wrapper .topIntro[data-v-43982583]{background-color:#fff}.case-study .landing_wrapper .topIntro[data-v-43982583]:after,.case-study .landing_wrapper .topIntro[data-v-43982583]:before{display:none}.case-study .list-pr li[data-v-43982583]{padding-inline-start:1rem;display:flex;position:relative}.case-study .list-pr li[data-v-43982583]:before{content:\"\";height:7px;width:7px;background-color:rgba(66,46,135,.2);border-radius:2px;position:absolute;left:0;top:.6rem}", ""]);
// Exports
module.exports = exports;
