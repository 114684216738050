var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"investors"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"mb-3 col-lg-3 col-md-12"},[_c('h5',{staticClass:"pe-4 tw-leading-9",class:_vm.featureId4 ? ' tw-text-[25px] tw-text-center !tw-pe-0  ' : ''},[_vm._v(_vm._s(_vm.$t("home.investors")))])]),_c('div',{staticClass:"col-lg-9 col-md-12 ps-lg-0 tw-mb-8 lg:tw-mb-0 max-h-100"},[_c('carousel',{attrs:{"items":3,"loop":true,"margin":10,"nav":false,"dots":false,"autoplay":true,"speed":1000,"autoplayTimeout":1000,"autoplayHoverPause":true,"responsive":{
            0: {
              items: 2,
            },
            400: {
              items: 3,
            },
            700: {
              items: 3,
            },
            1300: {
              items: 4,
            },
          }}},[_c('li',[_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":require("../../../../assets/images/seedra-Logo.png"),"width":"160px","height":"100px"}})]),_c('li',[_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":require("../../../../assets/images/waed.png"),"width":"160px","height":"100px"}})]),_c('li',[_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":require("../../../../assets/images/500-logo.png"),"width":"160px","height":"100px"}})]),_c('li',[_c('img',{staticClass:"img-fluid",attrs:{"loading":"lazy","src":require("../../../../assets/images/nour-nouf.png"),"width":"160px","height":"100px"}})])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }