<template>
  <div>
    <div
      class="landing_wrapper"
      :class="{
        'landing_wrapper-talent': activeTab == 'talent',
        'landing_wrapper-client': activeTab == 'client',
      }"
    >
      <div
        class="intro topIntro nav-banner-wrapper lg:!tw-h-full"
        :class="{
          '!tw-h-[68rem]': activeTab == 'talent',
          '!tw-h-full': activeTab == 'client',
          
        }"
      >
        <Navbar class="z-index-20" :active-tab="activeTab" />
        <Banner :talent="activeTab == 'talent'" @toggle="toggle" />
      </div>
      <div v-if="activeTab == 'client'" class="container tw-mt-14">
        <DiscountsBanner clickable />
      </div>
      <template v-if="activeTab == 'client'">
        <template v-if="featureId4">
          <MeetOurTalents :featureId4="featureId4" />
          <Benefits :featureId4="featureId4"/>
          <Investors :featureId4="featureId4" />
          <Testimonials :featureId4="featureId4" />
          <CaseStudies :featureId4="featureId4" />
          <Supporters :featureId4="featureId4"/>
          <Podcasts :featureId4="featureId4"/>
        </template>
        <template v-else>
          <MeetOurTalents />
          <Investors />
          <CaseStudies />
          <Podcasts />
          <Supporters />
          <Benefits />
          <Testimonials />
        </template>
      </template>
      <template v-else>
        <ServiceSection talents-home />
        <StepsSection />
        <bringBuddy />
      </template>
      <Footer :active-tab="activeTab" />
    </div>
  </div>
</template>

<script>
// import AOS from "aos";

// import Partners from "./components/partners/partners.vue"

//import Projects from "./components/projects/projects.vue";
import Navbar from "./components/navbar/navbar.vue";
import DiscountsBanner from "@/components/matching/discountBanner/index.vue";
/* const Navbar = () => import(webpackChunkName: "Navbar"  "./components/navbar/navbar.vue") */
import Banner from "./components/banner/banner.vue";
const Investors = () =>
  import(/* webpackChunkName: "investors" */ "./components/investors");
const Supporters = () =>
  import(/* webpackChunkName: "supporters" */ "./components/supporters");
const Benefits = () =>
  import(/* webpackChunkName: "benefits" */ "./components/benefits");
const MeetOurTalents = () =>
  import(
    /* webpackChunkName: "meetOurTalents" */ "./components/meetOurTalents"
  );
const Footer = () =>
  import(/* webpackChunkName: "footer" */ "./components/footer/footer.vue");
const ServiceSection = () =>
  import(
    /* webpackChunkName: "serviceSection" */ "../Talent/Home/components/serviceSec/serviceSec.vue"
  );
const StepsSection = () =>
  import(
    /* webpackChunkName: "stepsSection" */ "../Talent/Home/components/stepsSec/stepsSec.vue"
  );
const bringBuddy = () =>
  import(
    /* webpackChunkName: "bringBuddy" */ "../Talent/Home/components/bringBuddy"
  );
const CaseStudies = () =>
  import(/* webpackChunkName: "caseStudies" */ "./components/caseStudies/");
const Podcasts = () =>
  import(/* webpackChunkName: "podcasts" */ "./components/podcasts/");
const Testimonials = () =>
  import(/* webpackChunkName: "testimonials" */ "./components/testimonials/");
// import "aos/dist/aos.css";
import(/* webpackChunkName: "landing" */ "./landing.scss");

export default {
  components: {
    Navbar,
    Banner,
    DiscountsBanner,
    Investors,
    Supporters,
    Benefits,
    MeetOurTalents,
    Footer,
    ServiceSection,
    StepsSection,
    bringBuddy,
    CaseStudies,
    Podcasts,
    Testimonials,
  },
  data() {
    return {
      activeTab: "client",
      featureId4: false,
    };
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler() {
        localStorage.setItem("activeUserType", this.activeTab);
      }
    }
  },
  methods: {
    initializeGtag(user_type) {
      if (typeof window.gtag === "function") {
        window.gtag("event", "user type selected", { user_type });
      }
    },
    toggle($event) {
      this.activeTab = $event ? "talent" : "client";
      this.initializeGtag(
        this.activeTab.charAt(0).toUpperCase() + this.activeTab.slice(1)
      );
      const targetPath = `/home/${this.activeTab}`;
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
      }
      localStorage.setItem("activeUserType", this.activeTab);
    },
    checkFeatureflag() {
      this.featureId4 =
        localStorage.getItem("CRO_feature_flag") ===
        "ID4_homepage_redesign_below_fold";
    },
  },
  created() {
    this.checkFeatureflag();

    localStorage.setItem("activeUserType", this.activeTab);
    const { type } = this.$route.params;
    if (["talent", "client"].includes(type)) this.activeTab = type;
    else {
      this.activeTab = "client";
      this.$router.push("/home/client");
    }
  },
};
</script>

<style lang="scss" scoped>
.z-index-20 {
  z-index: 20 !important;
}
</style>
