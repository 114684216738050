var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-confirm-dialog'),_c('div',{staticClass:"mb-4 row"},[_c('div',{staticClass:"gap-2 mb-5 d-flex align-items-center"},[_c('h3',{staticClass:"mb-0 me-auto"},[_vm._v(" "+_vm._s(_vm.$t('talentWizard.experience')))]),_c('button',{staticClass:"btn add-btn-not-dashed float-end wizard-btn-actions",attrs:{"type":"button","disabled":_vm.checkNumberOfExperiances()},on:{"click":function($event){return _vm.showModal()}}},[_c('i',{staticClass:"bi bi-plus-circle"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('talentWizard.addExperience'))+" ")])])]),_vm._l((_vm.experiances),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"mb-4 col-12 align-items-center"},[_c('div',{staticClass:"experience-div position-relative",class:{
            'experience-div__error': _vm.experienceHasAnEmptyRow.find(
              (e) => e.id == item.id && e.parsing_id == item.parsing_id
            ),
          }},[_c('div',{staticClass:"float-start me-3 experience-numer"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"experienceText"},[_c('div',{staticClass:"dateOfWork"},[_c('span',[_vm._v(" ("),(_vm.getStartDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getStartDate(item))+" ")]),_vm._v(" - "),(_vm.getEndDate(item) == 'requiredField')?_c('span',[_vm._v(" - ")]):_c('span',[_vm._v(_vm._s(_vm.getEndDate(item)))]),_vm._v(") ")]),(
                  _vm.experienceHasAnEmptyRow.find(
                    (e) => e.id == item.id && e.parsing_id == item.parsing_id
                  )
                )?_c('span',{staticClass:"company-name opacity"},[_c('RequiredField',{attrs:{"text":_vm.$t('talent.requiredField')}})],1):_vm._e()]),_c('h5',[(item.title != null)?_c('span',[_vm._v(" "+_vm._s(item.title))]):_vm._e(),(!item.title)?_c('span',[_vm._v(" - ")]):_vm._e(),(item.company != null)?_c('span',{staticClass:"company-name"},[_vm._v(_vm._s(item.company))]):_vm._e(),(!item.company)?_c('span',{},[_vm._v(" - ")]):_vm._e()]),_c('p',{staticClass:"mb-0"},[(item.description !== null)?_c('span',[_vm._v(_vm._s(item.description))]):_vm._e(),(!item.description)?_c('span',[_vm._v(" - ")]):_vm._e()])]),_c('div',{staticClass:"gap-2 d-grid d-flex align-items-center absolute-btns"},[_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteItem(item.is_parsed_from_cv ? item.parsing_id : item.id)}}},[_c('i',{staticClass:"flaticon-trash"})]),_c('button',{staticClass:"btn experience-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showModal(item)}}},[_c('i',{staticClass:"flaticon-edit"})])])])])])})],2),(_vm.isModalVisible)?_c('FormModelExperiance',{ref:"experienceModalForm",attrs:{"item":_vm.modelItem},on:{"close":_vm.closeModal,"getList":_vm.getExperianceList}}):_vm._e(),_c('div',{staticClass:"mt-5 mb-2"},[_c('NextPrevBtn',{attrs:{"className":'d-grid gap-3 d-flex justify-content-md-end',"step":_vm.stepNum,"hasError":_vm.experienceHasAnEmptyRow.length ? true : false,"experiances":_vm.experiances}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }