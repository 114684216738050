<template>
  <div class="submit-subscribe-view-talent__wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-12">
                <v-lottie-player
                  :src="'https://assets7.lottiefiles.com/packages/lf20_qttka1wr.json'"
                  class="margin0-auto "
                  background="transparent"
                  :speed="1"
                  style="width: 200px; height: 200px; background-color: transparent; margin-bottom: 1rem;"
                  loop
                  autoplay
                />
    
          <div>
            <h3>Your request has been sent successfully</h3>
            <p>We will contact you soon</p>

            <div class="row">
              <div class="applyViewBtnWrapper">
                <div class="baseBtn">
                  <button type="button" class="btn btnstyleUpdate btnSecondary" @click="$router.push({ path: '/sq/talent/profile'}) ">
                    Go to profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";
export default {
  created() {
         // this.subscribe()
  },
  components: {
    vLottiePlayer: VueLottiePlayer,
  },
  methods: {
    
  }
};
</script>

<style>
.submit-subscribe-view-talent__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5rem;

}

.submit-subscribe-view-talent__wrapper h3 {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.submit-subscribe-view-talent__wrapper  .btnSecondary, .btnSecondary:disabled {
    background-color: rgb(255, 198, 41) !important;
    border: 1px solid transparent !important;
    font-weight: 700;
    padding-left: 4rem;
    padding-right: 4rem;

}
</style>