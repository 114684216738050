<template>
  <transition name="modal-fade">
    <div class="modal align-items-center" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div> 

          <div class="tw-pb-4 modal-body no-border">
            <div class="row">
              <div class="tw-text-start col-12 meetBox">
                <div class="p-0 tw-mb-4 tw-flex tw-flex-col tw-justify-center tw-items-center">

                    <img class=" tw-w-[22rem] tw-h-[12rem] tw-mb-6 " src="@/assets/images/talent/undraw_schedule_meeting.svg" alt="meeting">

                    <h2 class="tw-text-[#292929] tw-text-[24px] tw-font-semibold tw-leading-10 tw-text-center tw-mb-2 "> {{ $t('requestInterview.confirmSuccessfully')  }} </h2>
                    <h3 class="tw-text-[#292929] tw-text-[20px] tw-font-normal tw-leading-10  tw-text-center">{{ interviewSlots.find(slot => slot.id == selected?.id ).value }}  {{ timezones.find((tz) => tz.value == selectedTimezone).name }}</h3>
                    <p class="tw-text-center tw-text-[#a5a5a5] tw-text-lg tw-font-normal tw-leading-9"> {{ $t('requestInterview.thanks') }}</p>

                   
                </div>
               
              </div>
            </div>
           
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: "SelectedSlotSuccessfully",
  props: ["isOpen", "interviewSlots", "selected", "selectedTimezone", "timezones"],
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
  },
  methods: {
    close() {
      document.body.classList.remove("modal-open");
      this.$router.push({ path: "/sq/talent/profile" });
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 45rem;
    width: 45rem;
    margin: 1.75rem auto;
  }
}
</style>


<style lang="scss">
.modal-open {
  overflow: hidden;
}

.book-meeting-mb {
  min-height: 500px;

  @media (max-width: 786px) {
    margin-bottom: 5rem;
  }
}

.book-meeting-mb .calendly {
  min-height: 500px;
  height: 500px !important;
}

.book-meeting-mb .calendly iframe {
  min-height: 500px;
}

.meetBox .w-full {
  width: 100% !important;
}
</style>
