var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ClientWizardContainer',{attrs:{"step":_vm.stepNum,"hasError":_vm.validForm()}},[_c('div',{staticClass:"container position-relative z-index-2 tw-pt-8",class:{ 'tw-pt-8': _vm.onStepWizard },attrs:{"slot":"form","id":"stepper-items"},slot:"form"},[_c('h3',{staticClass:"my-1 text-center font-weight-bold main-step-title"},[_vm._v(" "+_vm._s(_vm.$t("clientWizard.bookAMeeting"))+" ")]),_c('p',{staticClass:"text-center gray-color-text"},[_vm._v(" "+_vm._s(_vm.$t("clientWizard.selectADateTime"))+" ")]),(_vm.isLoadingCalendly)?_c('VueCalendly',{class:{
          'tw-mt-3 adaptive-height': !_vm.onStepWizard,
          'onestepwizard-height': _vm.onStepWizard,
        },attrs:{"url":`${
          _vm.calendars.link + '?hide_event_type_details=1&hide_gdpr_banner=1'
        }?name=${_vm.user.first_name}&email=${_vm.user.email}&a1=${encodeURIComponent(
          _vm.user.phone_number
        )}`}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }