<template>
  <div class="main-color-bg">
    <div class="innerSquadPage position-relative">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-dark customize-navbar">
          <a class="navbar-brand top-logo cursor-pointer">
            <img loading="lazy"
              src="../../assets/images/logo/2Asset 1.svg"
              alt="logo"
              width="120"
              @click="goToHome()"
            />
          </a>
        </nav>
      </div>

      <div class="container mt-md-4">
        <div class="row align-items-center login-form">
          <div
            class="col-md-6 offset-md-1 order-md-2 text-center position-relative wow zoomIn mb-4"
            data-wow-delay="0.1s"
          >
            <img loading="lazy" src="../../assets/images/Oops.svg" class="teamImg" alt />
          </div>

          <div class="col-md-5 order-md-1 text-center text-md-start">
            <h2
              class="font-PoppinsSemiBold text-white mt-md-4 mb-2 font-h2-size"
            >
              {{ isNominationLinkExpired ? $t("talent.pageNotFoundNominationLinkExpired") :  $t("talent.pageNotFound") }}
            </h2>
            <p class="p-custom">
              {{
               isNominationLinkExpired? $t(
                  "talent.weReSorryThePageYouRequestedCouldNotBeFoundPleaseGoBackToTheHomepageNominationLinkExpired"
                )  : $t(
                  "talent.weReSorryThePageYouRequestedCouldNotBeFoundPleaseGoBackToTheHomepage"
                )
              }}
            </p>
            <a
              class="btn-custom btn-primary-custom btn-block text-decoration-none cursor-pointer"
              @click="goToHome()"
            >
              {{ $t("talent.goHome") }}
            </a>
          </div>
        </div>
        <!-- row end-->
      </div>
      <!--container end-->
    </div>
    <!--innerSquadPager end-->
  </div>
</template>

<script>
import "../../layouts/style.css";
import "./style.scss";
export default {
  name: "notFound",
  data() {
    return {
      isNominationLinkExpired: false,
    };
  },
  mounted() {
    if (this.$route.query.nomination == "expired") {
      this.isNominationLinkExpired = true;
    } else {
      this.isNominationLinkExpired = false;
    }
  },
  methods: {
    goToHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

