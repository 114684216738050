<template>
  <div>
    <hr v-if="full_time_salary || part_time_salary" />
    <div class=" tw-flex tw-justify-center tw-w-full tw-my-4" v-if="handleSalaryDataView.filter((item) => item.totalSalay).length > 0">
          <discountBanner   />
    </div>

    <div v-for="(item, i) in handleSalaryDataView" :key="i">
      <div class="mb-3 tw-mt-3 d-flex justify-content-between skill-14" v-if="item.status">
        <div class="text-start d-flex align-items-center">
          <h4 class="mb-0 d-flex flex-grow-1 salary-text tw-items-center">
            <img loading="lazy"
              src="../../../../../assets/images/ic_salary.svg"
              class="me-2 ic_salary"
            />
            <span class="tw-text-{#292929} tw-text-sm tw-font-medium tw-leading-relaxed">
            
               {{$t(`sharebleLink.${[item.salaryType]}`)}}
            </span>
          </h4>
        </div>

        <div  class="text-end" v-if="handleSalaryDataView.filter((item) => item.totalSalay).length > 0">
           <div  v-if="item.salaryValue">
              <div class="tw-text-[#bf3543] tw-text-2xl tw-font-semibold tw-leading-tight"> {{ item.salaryValue  }} $</div>
              <div class="tw-text-[#7f7f7f]   tw-text-lg tw-font-normal discount-total-salay tw-leading-relaxed"> {{  item.totalSalay  }} $</div>
           </div>
          <p class="mb-0" v-else>
            <span class="d-flex justify-content-end tw-text-[18px] tw-font-bold " 
              >
                  -
              </span
            >
          </p>
        </div>

        <div class="text-end text-grey" v-else>
          <p class="mb-0">
            <span
              v-if="item.salaryValue"
              class="d-flex justify-content-end"
              >{{ item.salaryValue }} $</span
            >
          </p>
          <p class="mb-0">
            <span class="d-flex justify-content-end tw-text-[18px] tw-font-bold " v-if="!item.salaryValue"
              >
                  -
              </span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import discountBanner from "@/pages/Client/clientViewTalentProfile/components/salary/discountBanner.vue";
export default {
  components: {
    discountBanner,
  },
  props: ["full_time_salary", "part_time_salary" ,"full_time_status", "part_time_status", "full_time_totalSalay", "part_time_totalSalay"],
  computed: {
    handleSalaryDataView() {
      const salaryDataList = [
        {
          salaryType: "fullTimeSalary",
          salaryKey: "full_time_salary",
          salaryValue: this.full_time_salary,
          status: this.full_time_status,
          totalSalay: this.full_time_totalSalay,
        },
        {
          salaryType: "partTimeSalary",
          salaryKey: "part_time_salary",
          salaryValue: this.part_time_salary,
          status: this.part_time_status,
          totalSalay: this.part_time_totalSalay,
        },
      ];
      return salaryDataList;
    },
  },
};
</script>

<style lang="scss" scoped>
.salary-text {
  span {
    font-size: 16px;
  }
}
.ic_salary {
  height: 20px;
  width: 20px;
}

.discount-total-salay {
    position: relative;
    width: fit-content;
    margin-inline-start: auto;
}

.discount-total-salay::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px; /* Thickness of the strike-through */
  background: linear-gradient(45deg, #BF3543, #BF3543); /* Angled effect */
  transform: rotate(10deg); /* Adjust the angle */
  transform-origin: center;
}
</style>
