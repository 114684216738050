<template>
  <div class="talent-home-serviceSec" :class="{'talents-home': talentsHome}">
    <div
      class="services-section pt-100 pb-100 position-relative"
      id="services-section"
      data-bs-spy="scroll"
    >
      <div class="container z-index-1">
        <div class="row mb-3">
          <div class="col-lg-6 poppins-font">
            <h2 class="text-white">
              {{ $t("talent.moreThan") }}<br />

              {{ $t("talent.moreThanResume") }}
            </h2>
            <h4 class="h5 text-white">
              {{ $t("talent.moreThanResumetext") }}
            </h4>
          </div>
        </div>

        <div>
          <ul class="services-items p-0 d-flex">
            <li class="col">
              <div>
                <span class="serv-icon">
                  <img loading="lazy"
                    src="../../../../../assets/images/talent/homeScreen/ic_Opportunities.svg"
                  />
                </span>
                <h3>
                  {{ $t("talent.exclusiveOpportunities") }}
                </h3>
                <p>
                  {{ $t("talent.exclusiveOpportunitiesText") }}
                </p>
              </div>
              <div class="mt-5">
                <span class="serv-icon">
                  <img loading="lazy"
                    src="../../../../../assets/images/talent/homeScreen/ic_technical-support.svg"
                  />
                </span>
                <h3>
                  {{ $t("talent.upToDateTechnologies") }}
                </h3>
                <p>
                  {{ $t("talent.upToDateTechnologiesText") }}
                </p>
              </div>
            </li>
            <li class="col">
              <div>
                <span class="serv-icon">
                  <img loading="lazy"
                    src="../../../../../assets/images/talent/homeScreen/ic_Top-notch.svg"
                  />
                </span>
                <h3>
                  {{ $t("talent.smartRoleMatching") }}
                </h3>
                <p>
                  {{ $t("talent.smartRoleMatchingText") }}
                </p>
              </div>
              <div class="mt-5">
                <span class="serv-icon">
                  <img loading="lazy"
                    src="../../../../../assets/images/talent/homeScreen/ic_Working_Hours.svg"
                  />
                </span>
                <h3>
                  {{ $t("talent.professionalFlexibility") }}
                </h3>
                <p>
                  {{ $t("talent.rightClientRole") }}
                </p>
              </div>
            </li>
            <li class="col">
              <div>
                <span class="serv-icon">
                  <img loading="lazy"
                    src="../../../../../assets/images/talent/homeScreen/ic_Compensation.svg"
                  />
                </span>
                <h3>
                  {{ $t("talent.competitiveCompensation") }}
                </h3>
                <p>
                  {{ $t("talent.competitiveCompensationText") }}
                </p>
              </div>
              <div class="mt-5">
                <span class="serv-icon">
                  <img loading="lazy"
                    src="../../../../../assets/images/talent/homeScreen/ic_Working_Remotely.svg"
                  />
                </span>
                <h3>
                  
                  {{ $t("talent.workRemotely") }}
                </h3>
                <p>
                  {{ $t("talent.workRemotelyText") }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="topbg"></div>
    </div>
  </div>
</template>
<script>
import "./serviceSec.scss";
export default {
  props: {
    talentsHome: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.talents-home .container {
  @media (min-width: 1200px) {
    padding-inline: 100px;
  }
}
</style>