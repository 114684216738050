<template>
  <transition name="modal-fade">
    <div class="modal align-items-center contract-renewal-modal" role="dialog" aria-labelledby="modalTitle"
      aria-describedby="modalDescription" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header removeTitleBorder">

           <div class="w-100 tw-flex tw-flex-col">
            <h2 class="tw-mt-2 tw-text-[19px] md:tw-text-[25px] tw-font-bold tw-text-primary tw-text-center">
               {{ $t('contractsRenewal.changeContractTerms') }}
             </h2>
             <hr class="smallHr" />
           </div>
          </div>
          <div class="modal-body no-border !tw-p-0 md:tw-pe-2">
            <div class="tw-bg-[#F4F1FD] tw-rounded-[15.75px] tw-p-[10px] tw-my-4 tw-mx-0 tw-flex">
              <div class="tw-flex">
                <img loading="lazy" :src="talentProfilePic"
                  class="img-fluid md:tw-w-14 md:tw-h-14 tw-w-9 tw-h-9 tw-rounded-full tw-object-cover"
                  :class="talentContract && talentContract.talent.image? 'tw-border-[rgba(228, 228, 228, 1)] tw-border-[5.63px]' : ''" />
                <img loading="lazy" v-if="false" class="talent-real-img tw-w-14 tw-h-14" @error="imageLoadOnError" />
              </div>
              <div class="tw-flex tw-flex-col tw-justify-center tw-ms-4">
                <div class="tw-flex tw-font-bold tw-text-[#292929] tw-text-[12.91px] md:tw-text-[15.59px]">
                  {{ talentContract.talent.full_name }}
                  <span class="tw-ms-2">
                    <img loading="lazy" class="tw-w-5 tw-h-5" :src="
                        talentContract.talent.qualified
                          ? require('@/assets/images/ic_verifed.svg')
                          : require('@/assets/images/ic_unverifed.svg')
                      " />
                  </span>
                </div>
                <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center">
                  <div class="tw-text-[11] md:tw-text-[12.99px] tw-text-primary tw-font-semibold">
                    {{talentContract.talent.role?.name}}
                  </div>
                  <div v-if="talentContract.talent.role && talentContract.talent.experience_years != null" class="tw-hidden md:tw-block tw-ms-2 tw-w-[5px] tw-h-[5px] tw-bg-[#422e87]"></div>
                  <div class="md:tw-ms-2 tw-text-[12px] tw-text-[#9f9d9d] tw-font-medium">
                    <template v-if="Number(talentContract.talent.experience_years) > 0 && talentContract.talent.experience_years != null">
                      {{ talentContract.talent.experience_years }}
                      <span>
                        {{ talentContract.talent.experience_years == 1 ? $t('nomination.yearOfExperience') : $t('nomination.yearsExperience') }}
                      </span>
                    </template>
                    <span v-else-if="Number(talentContract.talent.experience_years) == 0 && talentContract.talent.experience_years != null">
                      {{ $t('matchedTalents.fresh') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <form @submit.prevent="submit()" class="tw-grid tw-grid-cols-2 tw-gap-3 tw-items-center">
              <div class="tw-col-span-2 tw-flex tw-flex-col">
                <label for="startDate" class="tw-text-[#292929] tw-font-bold tw-text-[12.91px]">
                  {{ $t('contractsRenewal.contractStart') }}
                </label>
                <VueDatePicker
                  :locale="{lang: 'en'}"
                  id="startDate"
                  class="!tw-w-full"
                  v-model="startDate"
                  format="YYYY-MM-DD"
                  value-type="format"
                  disabled
                />
              </div>
              <div class="floating-label">
                <label for="duration" class="tw-text-[#292929] tw-font-bold tw-text-[12.91px]">
                  {{  $t('contractsRenewal.duration')  }}
                </label>
                <input id="duration" class="px-2 w-100 form-control" :class="{'has-error': duration < 1}"
                  v-model="duration" type="number" @keyup="changeEndDate" @wheel.prevent />
                <span class="info-message">
                  {{ $t('contractsRenewal.month') }}
                </span>
                <span v-if="duration < 1 || duration > 120" class="tw-text-[#FF6F59]">
                  {{ $t('contractsRenewal.cantBeLessThan1Month') }}
                </span>
              </div>
              <div class="tw-relative tw-flex tw-flex-col">
                <label for="endDate" class="tw-text-[#292929] tw-font-bold tw-text-[12.91px]">
                  {{ $t('contractsRenewal.contractEnd') }}
                </label>
                <VueDatePicker
                  :locale="{lang: 'en'}"
                  id="endDate"
                  class="!tw-w-full"
                  v-model="endDate"
                  format="YYYY-MM-DD"
                  value-type="format"
                  :min-date="minDate"
                  @change="changeDuration()"
                />
              </div>
              <div class="tw-col-span-2 md:tw-col-span-1">
                <span class="tw-text-[#292929] tw-font-bold tw-text-[12.91px]">
                  {{ $t('contractsRenewal.contractType.main') }}
                </span>
              </div>
              <div class="tw-col-span-2 md:tw-col-span-1 tw-flex tw-gap-3">
                <button type="button" class="tw-w-[200px] tw-text-[16px] tw-border tw-rounded-[4px] tw-py-2"
                  :class="{ 'tw-text-[#292929] tw-border-[#422e87]': contractType == 1, 'tw-text-[#6a6a6a]': contractType != 1}"
                  @click="contractType = 1">
                  {{ $t('contractsRenewal.contractType.partTime') }}
                </button>
                <button type="button" class="tw-w-[200px] tw-text-[16px] tw-border tw-rounded-[4px] tw-py-2"
                  :class="{ 'tw-text-[#292929] tw-border-[#422e87]': contractType == 2, 'tw-text-[#6a6a6a]': contractType != 2}"
                  @click="contractType = 2">
                  {{ $t('contractsRenewal.contractType.fullTime') }}
                </button>
              </div>
              <template v-if="talentContract.renewal_terms.contract_type == contractType">
                <div class="tw-col-span-2 md:tw-col-span-1">
                  <span class="tw-text-[#292929] tw-font-semibold tw-text-[14px]">
                    {{ $t('contractsRenewal.hasRaise') }}
                  </span>
                </div>
                <div class="tw-col-span-2 md:tw-col-span-1 tw-flex tw-gap-3">
                  <button type="button" class="tw-w-[200px] tw-text-[16px] tw-border tw-rounded-[4px] tw-py-2"
                    :class="{ 'tw-text-[#292929] tw-border-[#422e87]': hasRaise, 'tw-text-[#6a6a6a]': !hasRaise }"
                    @click="hasRaise = true">
                    {{ $t('contractsRenewal.yes') }}
                  </button>
                  <button type="button" class="tw-w-[200px] tw-text-[16px] tw-border tw-rounded-[4px] tw-py-2"
                    :class="{ 'tw-text-[#292929] tw-border-[#422e87]': !hasRaise, 'tw-text-[#6a6a6a]': hasRaise }"
                    @click="hasRaise = false">
                    {{ $t('contractsRenewal.no') }}
                  </button>
                </div>
              </template>
              <div v-if="hasRaise" class="tw-col-span-2 floating-label">
                <label for="raise" class="tw-text-[#292929] tw-font-bold tw-text-[12.91px]">
                  {{ $t('contractsRenewal.raise') }}
                </label>
                <input
                  id="raise"
                  class="px-2 w-100 form-control"
                  :class="{ 'has-error': (raise <= 0 || raise == null) && hasRaise && raiseChanged }"
                  @keydown="raiseChanged = true"
                  required
                  step="10"
                  v-model="raise"
                  type="number"
                />
                <span class="info-message">
                  {{ $t('contractsRenewal.usd') }}
                </span>
                <span v-if="(raise <= 0 || raise == null) && hasRaise && raiseChanged" class="tw-text-[#FF6F59]">
                  {{ $t('contractsRenewal.cantBeLessThan1Usd') }}
                </span>
              </div>
              <div v-if="contractType == talentContract.renewal_terms.contract_type"
                class="tw-col-span-2 floating-label">
                <label for="raise" class="tw-text-[#292929] tw-font-bold tw-text-[12.91px]">
                  {{ $t('contractsRenewal.price') }}
                  <small v-if="price != talentContract.renewal_terms.salary.total_salary" class="tw-text-[#422e87] tw-text-[10px]">
                    {{ $t('contractsRenewal.includingWht') }}
                  </small>
                </label>
                <p id="raise" class="px-2 w-100 form-control disabled">
                  {{ price }}
                </p>
                <span class="info-message">
                  {{ $t('contractsRenewal.usd') }}
                </span>
              </div>
              <div v-if="talentContract.renewal_terms.contract_type != contractType" class="tw-col-span-2 tw-bg-[#F4F1FD] tw-py-2 tw-px-4 tw-flex tw-gap-3 tw-rounded-[12px]">
                <img loading="lazy" src="@/assets/images/contract-renewal/info-icon.svg" alt="Info icon" width="22">
                <span class="tw-text-[16px] tw-text-[#414042]">
                  {{ $t('contractsRenewal.accountManagerWillContact') }}
                </span>
              </div>
              <div class="tw-col-span-2">
                <textarea
                  v-model.trim="notes"
                  class="form-control textarea"
                  :class="{ 'has-error': notes.length > 1000 }"
                  rows="3"
                  :placeholder="$t('contractsRenewal.addNote')"
                ></textarea>
                <span v-if="notes.length > 1000" class="tw-text-[12.91px] tw-text-[#FF6F59]">
                  {{ $t('contractsRenewal.cantBeMoreThan1000Letters') }}
                </span>
              </div>
            </form>

            <hr />

          </div>
          <div class="modal-footer tw-p-0 tw-flex tw-justify-center">
            <button type="button" class="btn tw-h-[35px] !tw-flex tw-justify-center tw-items-center tw-w-[162.75px]"
              data-bs-dismiss="modal" @click="close">
              {{ $t('contractsRenewal.cancel') }}
            </button>
            <button :disabled="this.duration < 1 || (this.raise <= 0 && this.hasRaise) || this.notes.length > 1000 || (this.duration < 1 || this.duration > 120)" @click="confirm" type="submit"
              class="btn btn-primary tw-h-[35px] !tw-flex tw-justify-center tw-items-center tw-w-[162.75px] ">
              {{ $t('contractsRenewal.renew') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import toast from "../../services/toast.js";
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "ContractRenewalChangeTermsModal",
  components: {
    VueDatePicker,
  },
  props: ["isOpen", "talentContract"],
  data: () => {
    return {
      baseUrl: process.env.VUE_APP_AWS_BASE_URL,
      isLoading: false,
      hasDatePickerLocationHandled: false,
      raiseChanged: false,
      duration: null,
      endDate: null,
      totalSalary: 0,
      contractType: null,
      raise: 0,
      hasRaise: false,
      notes: '',
    };
  },
  computed: {
    talentProfilePic() {
      if (this.talentContract && this.talentContract.talent.image) {
        return `${this.talentContract.talent.image}`;
      }
      else return require("@/assets/images/talent-avatar.png");
    },
    startDate() { return this.talentContract.renewal_terms.start_date},
    minDate() {
      const startDate = new Date(this.startDate);
      startDate.setMonth(startDate.getMonth() + 1);
      return startDate.toISOString().split('T')[0]; // format to 'yyyy-mm-dd'
    },
  },

  watch: {
    contractType(val) {
      if (val != this.talentContract.renewal_terms.contract_type) {
        this.hasRaise = false;
        this.raise = null;
      }
    },
    raise(val) {
      const raise = Number(val);
      if (raise > 0 && this.hasRaise && this.raiseChanged)
        this.price = this.talentContract.renewal_terms.salary.total_salary + raise + (raise * 0.05);
      else if (raise <= 0 && this.hasRaise && this.raiseChanged)
        this.price = this.talentContract.renewal_terms.salary.total_salary;
    },
  },
  mounted() {
    this.endDate = this.talentContract.renewal_terms.end_date;
    this.contractType = this.talentContract.renewal_terms.contract_type;
    this.price = this.talentContract.renewal_terms.salary.total_salary;
    this.changeDuration();
  },
  methods: {
    handleDatePickerLocation() {
      if (this.hasDatePickerLocationHandled) {
        return;
      }
      const datePicker = document.querySelector('.vd-menu__content');
      if (datePicker) {
        datePicker.style.top = datePicker.style.top.replace('px', '') - 70 + 'px';
        this.hasDatePickerLocationHandled = true;
      }
    },
    async confirm() {
      if (this.duration < 1 || (this.raise <= 0 && this.hasRaise) || this.notes.length > 1000 || (this.duration < 1 || this.duration > 120)) {
        this.raiseChanged = true;
        return; 
      }

      this.isLoading = true;
      try {
        await this.axios
          .post(`/api/client/contract-renewal/${this.talentContract.id}/terms`, {
            start_date: this.startDate,
            end_date: this.endDate,
            duration: this.duration,
            contract_type: this.contractType,
            has_raise: this.hasRaise,
            raise: this.raise,
            notes: this.notes,
          })
          .then((res) => {
            this.$emit('refresh', res.data)
            this.close()
          });
      } catch (error) {
        console.error(error);
        toast.error(error.response.data?.message || error.message);
      } finally {
        this.isLoading = false;
      }
    },
    changeDuration() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      const years = endDate.getFullYear() - startDate.getFullYear();
      const months = endDate.getMonth() - startDate.getMonth();
      let diffMonths = years * 12 + months;

      // If the end date is later in the month, count it as an extra month
      if (endDate.getDate() - startDate.getDate() >= 15) {
        diffMonths++;
      }

      this.duration = diffMonths;
    },
    changeEndDate() {
      if (!Number(this.duration) || this.duration < 0 || this.duration > 120) {
        this.endDate = this.talentContract.renewal_terms.end_date;
        return;
      }

      const startDate = moment(this.startDate);
      const endDate = startDate.clone().add(this.duration, 'months');

      // Adjust the day if it exceeds the number of days in the month
      if (endDate.date() < startDate.date()) {
        endDate.date(0); // Set to the last day of the previous month
      } else {
        endDate.subtract(1, 'day'); // Subtract one day to get the correct end date
      }

      this.endDate = endDate.format('YYYY-MM-DD');
    },
    getJobTitle(type) {
      const jobs = [
        {
          text: this.$t("contractsRenewal.contractType.remote"),
          value: "remote"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-accommodation"),
          value: "onsite-accommodation"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite"),
          value: "onsite"
        },
        {
          text: this.$t("contractsRenewal.contractType.onsite-business-visa"),
          value: "onsite-business-visa"
        },
        {
          text: this.$t("contractsRenewal.contractType.hybrid"),
          value: "hybrid"
        }
      ];

      return jobs.find(j => j.value == type).text;
    },
    getProfilePlaceholderImage() {
      if (this.miniProfile)
        return require("@/assets/images/ic_avatar-border.png");
      else return require("@/assets/images/talent-avatar.png");
    },
    imageLoadOnError(e) {
      e.target.src = this.miniProfile
        ? require("@/assets/images/ic_avatar-border.png")
        : require("@/assets/images/talent-avatar.png");
      e.target.style.border = "5px solid transparent";
    },
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>

<style scoped>
.modal-dialog-scrollable .modal-content {
    max-height: 90%;
    overflow: auto;
}
</style>

<style lang="scss" scoped>
.modal-open {
  overflow: hidden;
}

.contract-renewal-modal {
  hr {
    border: 1px solid rgba(127, 127, 127, 1);
    margin: 15px 0;
  }
}
.contract-renewal-modal .modal-dialog {
   width: 95%;
   margin: 4rem auto;
  @media (min-width: 992px) {
    width: 60%;
  }
  margin: 0 auto;
  .modal-body.no-border {
        overflow: auto;
  }

  .modal-content {
      @media (min-width: 576px) {
         padding: 20px 30px;
      }
  }
}
@media (min-width: 576px) {
  .contract-renewal-modal .modal-dialog {
    max-width: 811px;
  }
}
@media (max-width: 800px) {
  .contract-renewal-modal .modal-content {
    padding: 20px 19px;
  }
}

.form-control {
  height: 40px;
  border-radius: 4px;
  position: relative;
  box-shadow: none;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.has-error {
    border: 1px solid #FF6F59;
  }
}

textarea.form-control {
  height: auto;

  &.has-error {
    border: 1px solid #FF6F59;
  }
}

.floating-label {
  position: relative;
  span.info-message {
    position: absolute;
    bottom: 8px;
    right: 10px;
    font-size: 12px;
    color: #9f9d9d;
    padding-block: 3px;
    padding-inline-start: 10px;
    border-left: 1px solid #d9d9d9;
  }
  span:not(.info-message) {
    position: absolute;
    bottom: -20px;
    font-size: 12px;
  }
}

html[dir="rtl"] {
  .vd-wrapper {
    &::after {
      right: auto;
      left: 16px;
    }
  }

  .floating-label {
    span.info-message {
      right: auto;
      left: 10px;
      border-left: 0;
      border-right: 1px solid #d9d9d9;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

<style land="scss">
.mx-input-wrapper {
  position: relative;
  
  input {
    height: 40px;
    margin-top: 5px;
  }

  &::after {
    content: '▼';

    position: absolute;
    top: 15px;
    right: 10px;
    color: #9F9D9D;
    font-size: 14px;
  }

  .mx-icon-calendar {
    display: none
  }
}
</style>

