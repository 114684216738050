// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/large-shape-login.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-color-bg{background:#422e87 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100%;min-height:100vh}@media(max-width:768px){.mb-4{margin-bottom:1rem!important}.mt-5{margin-top:1rem!important}}", ""]);
// Exports
module.exports = exports;
