<template>
  <div
    class="tab-pane fade languagesTab"
    id="pills-languages"
    role="tabpanel"
    aria-labelledby="pills-languages-tab"
  >
    <div class="height-42">
      <TabHeader
        :title="$t('signUp.language')"
        id="languages"
        :iconAction="openModal"
        :isEdit="true"
      />
    </div>
    <div class="mb-4 project-box">
      <div
        class="mb-2 row time-skill skill-16"
        v-for="language in languages"
        :key="language.id"
      >
        <div class="col-8 col-md-10 text-start">
          <h4 class="mb-0 regularFont">
            {{ $t(`languages.${[language.name.toLowerCase()]}`) }}
          </h4>
        </div>
        <div class="col-4 col-md-2 text-start">
          <p class="mb-0">{{ getLevel(language.level) }}</p>
        </div>
      </div>
    </div>
    <Modal
      v-if="isModalOpen"
      @update="close($event)"
      :heading="$t('talentWizard.editLanguage')"
      className="language-modal"
      :handleSubmit="() => handleSubmitLanguage()"
      :isSubmitDisabled="
        !this.firstLang.first_lang ||
        !this.firstLang.first_lang_level ||
        isDisableSubmission()
      "
    >
      <!-- <div class="pb-5 mb-5"> -->
      <div class="mb-4 row">
        <div class="col-sm-6 col-12 talent-language-edit-wrapper">
          <Select
            :label="$t('talentWizard.whatIsYourFirstLanguage')"
            :placeholder="$t('talentWizard.select')"
            :validationField="$v.firstLang.first_lang"
            isAstrik="true"
            :selectOptions="
              removeSelectedLangFromOptions(secondeLang.seconde_lang)
            "
            @handleChange="(e) => handleFirstLangChange(e)"
          />
        </div>
        <div class="col-sm-6 col-12 marginT talent-language-edit-wrapper">
          <Select
            :placeholder="$t('talentWizard.selectLanguageLevel')"
            :validationField="$v.firstLang.first_lang_level"
            :selectOptions="levels"
          />
        </div>
      </div>  
      <div class="row">
        <div class="col-sm-6 col-12 talent-language-edit-wrapper">
          <Select
            :label="$t('talentWizard.whatIsYourSecondLanguage')"
            :placeholder="$t('talentWizard.select')"
            :validationField="$v.secondeLang.seconde_lang"
            :selectOptions="removeSelectedLangFromOptions(firstLang.first_lang)"
            @handleChange="(e) => handleSecondeLangChange(e)"
          />
        </div>
        <div class="col-sm-6 col-12 marginT talent-language-edit-wrapper">
          <Select
            :placeholder="$t('talentWizard.selectLanguageLevel')"
            :validationField="$v.secondeLang.seconde_lang_level"
            :selectOptions="levels"
            @handleChange="(e) => handleSecondeLangLevelChange(e)"
          />
        </div>
      </div>
      <!-- </div> -->
    </Modal>
  </div>
</template>

<script>
import "./languagesTab.scss";
import TabHeader from "../tabHeader/tabHeader.vue";
import { required } from "vuelidate/lib/validators";
import levels from "../../../../../static/LanguageLevel";
import LanguagesArr from "../../../../../static/Languages";
import Modal from "../../../../../components/shared/modal/modal.vue";
import Select from "../../../../../components/shared/formControls/select/select.vue";
export default {
  props: ["languages", "talentEmail"],
  components: { Modal, Select, TabHeader },
  data: () => {
    return {
      isModalOpen: false,
      isDisabled: true,

      firstLang: {
        first_lang: "",
        first_lang_level: "",
      },
      secondeLang: {
        seconde_lang: "",
        seconde_lang_level: "",
      },
    };
  },
  computed: {
    languagesComputed() {
      return this.languages.map(l => {
        return {
          id: l.id,
          name: this.$t(`languages.${[l.name]}`),
          level: l.level,
        };
      });
    },
    LanguagesList() {
      const LanguagesList = LanguagesArr?.map((lang) => ({
        id: lang,
        name: this.$t(`languages.${[lang]}`),
      }));
      return LanguagesList;
    },
    levels() {
      return levels.map((level) => {
        return { ...level, name: this.$t(`languageLevel.${[level.name]}`) };
      });
    },
  },
  mounted() {
    this.firstLang = {
      first_lang: this.languages?.[0]?.name,
      first_lang_level: this.languages?.[0]?.level,
    };
    this.secondeLang = {
      seconde_lang: this.languages?.[1]?.name,
      seconde_lang_level: this.languages?.[1]?.level,
    };
  },
  validations: {
    firstLang: {
      first_lang: { required },
      first_lang_level: { required },
    },
    secondeLang: {
      seconde_lang: { required },
      seconde_lang_level: { required },
    },
  },
  methods: {
    isDisableSubmission() {
      return (
        this.$v.secondeLang.seconde_lang?.$error ||
        this.$v.secondeLang.seconde_lang_level?.$error
      );
    },
    removeSelectedLangFromOptions(id) {
      if (id) {
        return this.LanguagesList?.filter((lang) => lang.id != id);
      } else {
        return this.LanguagesList;
      }
    },
    getLevel(id) {
      const filteredLevel = this.levels.find((level) => level.id == id);
      return filteredLevel?.name;
    },
    openModal() {
      this.isModalOpen = true;
      this.firstLang = {
        first_lang: this.languages?.[0]?.name,
        first_lang_level: this.languages?.[0]?.level,
      };
      this.secondeLang = {
        seconde_lang: this.languages?.[1]?.name,
        seconde_lang_level: this.languages?.[1]?.level,
      };
    },
    closeModal() {
      this.isModalOpen = false;
      this.$emit('languages-updated');
    },
    close() {
      this.isModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    handleSubmitLanguage() {
      const { first_lang, first_lang_level } = this.firstLang;
      const { seconde_lang, seconde_lang_level } = this.secondeLang;
      let payload = {
        languages: [{ name: first_lang, level: parseInt(first_lang_level) }],
      };
      seconde_lang &&
        seconde_lang_level &&
        payload.languages.push({
          name: seconde_lang,
          level: parseInt(seconde_lang_level),
        });

      

      if (window.gtag) {
        window.gtag("event", "talent_updated_languages", {
          talent_email: this.talentEmail,
        });
      }
      this.$store.dispatch("talentProfile/updateProfileLanguages", payload).then(() => {
        this.closeModal();
      });
    },
    handleSecondeLangChange(e) {
      if (e) {
        this.$v.secondeLang.$touch();
      } else {
        if (
          this.secondeLang.seconde_lang_level == "" ||
          this.secondeLang.seconde_lang_level == null
        ) {
          this.$v.secondeLang.$reset();
          this.$v.seconde_lang_level.$reset();
        }
      }
    },
    handleSecondeLangLevelChange(e) {
      if (e) {
        this.$v.secondeLang.$touch();
      } else {
        if (
          this.secondeLang.seconde_lang == "" ||
          this.secondeLang.seconde_lang == null
        ) {
          this.$v.secondeLang.$reset();
          this.$v.seconde_lang_level.$reset();
        }
      }
    },
    handleFirstLangChange(e) {
      if (e) {
        this.$v.firstLang.$touch();
      }
    },
  },

  //     watch: {
  //   firstLang: {
  //      handler(newVal, oldVal){
  //      this.removeSelectedLangFromOptions(newVal.first_lang)
  //      },
  //      deep: true
  //   }
  // }
};
</script>

<style lang="scss" scoped></style>
