// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/ic_edit-large.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".talent-profile-card-wrapper{padding-bottom:60px!important}.talent-profile-card-wrapper .mb-6{margin-bottom:3rem}.talent-profile-card-wrapper .edit-icon{border:1px solid #f2f2f2;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;border-radius:9px;display:inline-block;padding:20px;background-position:50%;transition:all .3s ease}.talent-profile-card-wrapper .edit-icon:hover{background-color:#f4f4f4;cursor:pointer;transform:scale(1.1)}.talent-profile-card-wrapper .country-flag{width:32px;height:32px}.talent-profile-card-wrapper .socialLinks li:last-child{border:unset;border-radius:unset;padding:unset}.talent-profile-card-wrapper .btn-outline-blue{font-size:12px;border:1px solid #422e87;border-radius:12px;color:#422e87;padding:10px 20px}.talent-profile-card-wrapper .btn-outline-blue:hover{color:#422e87;background:#f4f2fd}.talent-profile-card-wrapper .talent-details p{font-size:14px;margin-bottom:0}@media(max-width:500px){.talent-profile-card-wrapper .talent-details p{font-size:12px}}.talent-profile-card-wrapper .download-cv{text-decoration:none;color:#21252a}.talent-profile-card-wrapper .height-40{height:40px}.talent-profile-card-wrapper .cvErrMsgWrapperStyle{padding:20px 0}.talent-profile-card-wrapper .cameraImg{vertical-align:unset}.talent-profile-card-wrapper .cameraPhoto{background-color:#422e87;position:relative;z-index:15;left:70px;top:60px;border-radius:50%;width:27px;height:27px;text-align:center;border:2px solid #fff;cursor:pointer;display:flex;justify-content:center;align-items:center}.talent-profile-card-wrapper .errMsg{color:red;font-size:10px;display:block}.talent-profile-card-wrapper .profileImg{height:100%;-o-object-fit:cover;object-fit:cover}@media(min-width:768px){.talent-profile-card-wrapper .cameraPhoto{padding:0 4px}}.cvName{word-break:break-all}", ""]);
// Exports
module.exports = exports;
