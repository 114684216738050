<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <!-- <div class="border-0 modal-header justify-content-center">
             <button  v-if="failureStates == 'ABORTED'" type="button" class="btn-close" @click="close"></button>
          </div> -->

          <div class=" modal-body no-border">
            <div class="row">
              <div class="mb-4 text-center col-12 meetBox">
                <img
                  src="@/assets/images/payment/ic_payment-fail.svg"
                  class="mb-4 img-fluid tw-mx-auto md:tw-w-[110px] tw-w-[95.89px]"
                />

                <div v-if="invoiceData">

                
                 <h3
                  v-if="failureStates == 'isOffLine'|| failureStates == 'online'"
                  class="bold tw-text-[#1e2022]  tw-text-[24px] tw-font-bold tw-mb-[17px]"
                >
                   {{ $t('payment.paymentFailedWrong') }}
                </h3>
                <h3
                  v-if="failureStates == 'UNSUCCESSFUL'"
                  class="bold tw-text-[#1e2022]  tw-text-[24px] tw-font-bold tw-mb-[17px]"
                >
                   {{ $t('payment.paymentFailed') }}
                </h3>
                 <h3
                  v-if="failureStates == 'ABORTED'"
                  class="bold tw-text-[#1e2022]  tw-text-[24px] tw-font-bold tw-mb-[17px]"
                >
                   {{ $t('payment.paymentFailedABORTED') }}
                </h3>


              
                <p
                  class="px-0 px-md-5 tw-text-[#77838f] tw-text-[18px] md:tw-text-[24px] tw-font-normal tw-mb-4 ltr"
                >
                  #{{ invoiceData.invoice_number }}
                </p>
                <button
                  v-if="failureStates !== 'ABORTED'"
                  type="submit"
                  :disabled="failureStates == 'isOffLine'"
                  @click="$emit('tryAgain')"
                  class="btn btn-primary tw-rounded-[12px] tw-w-[276px] tw-h-[50px] !tw-flex tw-justify-center tw-items-center tw-m-auto "
                >
                  {{ $t('payment.tryAgain') }}
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "paymentFailedModal",
  props: ["isOpen", "invoiceData", "failureStates"],
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  methods: {
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.ltr {
  direction: ltr;
}

.modal-body.no-border {
      overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-dialog {
    width: 530px;
}


@media (max-width: 700px) {
  .modal-dialog {
    width: 90%;
  }
}
</style>


