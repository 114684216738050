// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/top-stroke.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/squadio-shape-2.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".client-home-wrapper{min-height:100vh;background:#422e87;overflow:hidden;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100% 80%;background-size:70%}@media(max-width:1000px){.client-home-wrapper{background-position:55% 70%;background-size:200%}}.client-home-wrapper:before{position:absolute;content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");left:-100px;top:60vh;width:400px;height:400px;background-repeat:no-repeat}@media(max-width:990px){.client-home-wrapper:before{display:none}}.client-home-wrapper h1{font-size:66px;font-weight:600;font-family:Poppins,sans-serif}.client-home-wrapper .dropdown-toggle{color:#fff!important}@media(max-width:768px){.client-home-wrapper .dropdown-toggle{color:inherit!important}}.client-home-wrapper .login-btn .btn-group button{border:unset!important}.client-home-wrapper .dropdown-toggle:after{font-family:bootstrap-icons!important;padding:0;display:inline-block;width:10px}.client-home-wrapper .dropdown-menu{box-shadow:0 2px 30px 0 rgba(0,0,0,.04);border:1px solid #f2f2f2;border-radius:20px!important}.client-home-wrapper .dropdown-menu:before{content:\"\";width:7px;height:7px;display:inline-block;position:absolute;top:-7px;right:20px;border-left:7px solid transparent;border-right:7px solid transparent;border-bottom:7px solid #fff}.client-home-wrapper .dropdown-item{font-size:13px;font-weight:400;color:#5e5e5e}.client-home-wrapper .dropdown-item i{font-size:16px}.client-home-wrapper .heading-cap{font-size:30px!important}@media(max-width:800px){.client-home-wrapper .heading-cap{font-size:20px!important}}@media(max-width:990px){.client-home-wrapper .hero-img{width:70%}}", ""]);
// Exports
module.exports = exports;
