<template>
  <div class="client-wizard-form">
    <!-- main-steps-body -->
    <div>
      <div class>
        <div class="">
          <Booking />
         <!--  <div v-for="(step, i) in stepper" :key="step.stepNum">
            <component
              v-if="currentStep == stepper[i].stepNum"
              :stepNum="stepper[i].stepNum"
              :is="stepper[i].content"
            ></component>
          </div> -->
        </div>
      </div>
    </div>
    <div></div>

    <!-- ./main-steps-body -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Booking from "../../../components/Booking/index.vue";
import clientSteps from "../../../enums/ClientWizzardSteps";

/* import AboutProject from "../../../components/AboutProject/index.vue";
import AboutProject1 from "../../../components/AboutProject/index1.vue";

import SelectServices from "../../../components/SelectServices/index.vue";
import SelectServices1 from "../../../components/SelectServices/index1.vue";
import Technologies from "../../../components/Technologies/index.vue";
import Technologies1 from "../../../components/Technologies/index1.vue";

import Details from "../../../components/Details/index.vue";
import Details1 from "../../../components/Details/index1.vue";
import Booking1 from "../../../components/Booking/index1.vue"; */

import "./style.scss";

export default {
  name: "Wizard",
  components: {
    Booking,
/*     AboutProject,
    AboutProject1,
    SelectServices,
    SelectServices1,
    Technologies,
    Technologies1,
    Details,
    Details1,
    Booking1 */
  },
  data() {
    return {
     
      stepper: [
/*         {
          stepNum: clientSteps.ABOUT_PROJECT,
          title: "About Project",
          content: AboutProject,
        },
        {
          stepNum: clientSteps.SELECT_SERVICE,
          title: "Select Service",
          content: SelectServices,
        },
        {
          stepNum: clientSteps.TECHNOLOGIES,
          title: "Technologies",
          content: Technologies,
        },
        {
          stepNum:  clientSteps.DETAILS,
          title: "Details",
          content: Details,
        }, */
        {
          stepNum: clientSteps.BOOK_MEETING,
          title: "Booking Meeting",
          content: Booking,
        },
      ],
      ProfileStep:  '7',
      hasError: true,
      featureId3: false,
    };
  },
  mounted () {
    this.checkFeatureflag();
  },
  computed: {
    ...mapGetters({
      currentStep: "auth/getUserStep",
      user: "auth/getUser",
    }),

  },
  watch: {
    currentStep: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.addCurrentStepAsQuery();
        if (newValue == 6 && !this.featureId3) {
          this.handleMatchingRedirection();
        }
      },
    },
  },
  methods: {
    checkFeatureflag() {
      this.featureId3 =
        localStorage.getItem("CRO_feature_flag") === "ID3_sitewide_rearranging_screens";


     /*    this.stepper = [
        {
          stepNum: clientSteps.ABOUT_PROJECT,
          title: "About Project",
          content: this.featureId3 ? AboutProject1 : AboutProject,
        },
        {
          stepNum: clientSteps.SELECT_SERVICE,
          title: "Select Service",
          content: this.featureId3 ? SelectServices1 : SelectServices,
        },
        {
          stepNum: clientSteps.TECHNOLOGIES,
          title: "Technologies",
          content: this.featureId3 ? Technologies1 : Technologies,
        },
        {
          stepNum: clientSteps.DETAILS,
          title: "Details",
          content: this.featureId3 ? Details1 :  Details,
        }, 
        {
          stepNum: clientSteps.BOOK_MEETING,
          title: "Booking Meeting",
          content: this.featureId3 ? Booking1 : Booking,
        },
       
      ] */
    },
    addCurrentStepAsQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          step: this.currentStep,
        },
      });
    },
    handleMatchingRedirection() {
      this.$store.dispatch("auth/setBookMeeting", false);



      let isMeetingReserved = JSON.parse(
        localStorage.getItem("meeting_reserved")
      );

      if (isMeetingReserved) {
        //-----------live-config-----------
/*         if (process.env.VUE_APP_AWS_BASE_URL.includes("production")) {
          this.twitterEvent();
          this.snapChatEvent();
        } */
        //-----------------------------------
        if (window.gtag) window.gtag("event", "client booked meeting", {
          user_type: "Client",
          step_number: 7,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
        });

        this.$router.push({
          name: "clientMatching",
          query: {
            meeting_reserved: "1",
          },
        });
      } else {
        this.$router.push({ name: "clientMatching" });
      }
    },
    log(value) {
      return console.log(value);
    },
    twitterEvent() {
      // Insert Twitter Event ID
      // eslint-disable-next-line no-undef
      if(!twq) return;
      // eslint-disable-next-line no-undef
      twq("event", "tw-odhvn-oednh", {
        conversion_id: this.user.id, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
        email_address: this.user.email, // use this to pass a user’s email address
        phone_number: null, // phone number in E164 standard
      });
    },
    snapChatEvent() {
      // eslint-disable-next-line no-undef
      if(!snaptr) return 
      // eslint-disable-next-line no-undef
      snaptr("track", "SIGN_UP", {
        conversion_id: this.user.id,
        email_address: this.user.email.toLowerCase(),
      });
    },
  },
};
</script>

<style scoped>
#stepper-items {
  padding: 10px 100px;
}
</style>