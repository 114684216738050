<template>
  <div
    class="tab-pane fade"
    id="pills-Certificates"
    role="tabpanel"
    aria-labelledby="pills-Certificates-tab"
  >
    <TabHeader
      :title="$t('signUp.certificates')"
      id="certificates"
      :iconAction="() => openModal(null)"
    />
    <div class="exp-boxes">
      <div
        class="exp-box mb-4"
        v-for="certificate in certificates"
        :key="certificate.id"
      >
        <div
          class="position-relative d-flex align-items-center justify-content-between"
        >
          <div class="position-relative">
            <span
              class="d-block text-grey mb-1"
              v-if="certificate.start_month && certificate.start_year"
            >
              (
              <span v-if="certificate.start_month && certificate.start_year">
                {{ getMonth(certificate.start_month) }}
                {{ certificate.start_year }} -
              </span>
              <span v-if="certificate.not_expire == 1"> 
              {{$t('talent.doesntExpire')}}
              </span>
              <span
                v-if="
                  certificate.not_expire == 0 &&
                  certificate.end_month &&
                  certificate.end_year
                "
                >{{ getMonth(certificate.end_month) }}
                {{ certificate.end_year }}</span
              >)
            </span>
            <div v-else>
              <h3 class="mb-2">
                {{ certificate.organization }}
                <br v-if="certificate.organization" />
                <span class="cert-name">{{ certificate.name }} </span>
              </h3>
              <p class="mb-0" v-if="certificate.identifier">
                <span class="text-purple">{{ $t('sharebleLink.credentialID') }} :</span>
                {{ certificate.identifier }}
              </p>

              <a
                :href="getProjectUrl(certificate.url)"
                target="_blank"
                v-if="certificate.url"
                > {{ $t('sharebleLink.viewCertificate') }}</a
              >
            </div>
          </div>
          <div class="ms-2">
            <button
              type="button"
              class="more-tab-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul class="dropdown-menu dropdown-menu-end small-more-pop">
              <li @click="openModal(certificate.id)">
                <button class="dropdown-item" type="button">
                 {{ $t('matchedTalents.edit') }}
                </button>
              </li>
              <li @click="openDeleteModal(certificate.id)">
                <button class="dropdown-item" type="button">
                 {{ $t('talentWizard.delete') }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="certificate.start_month && certificate.start_year">
          <h3 class="mb-2">
            {{ certificate.organization }}
            <br v-if="certificate.organization" />
            <span class="cert-name">{{ certificate.name }} </span>
          </h3>
          <p class="mb-0" v-if="certificate.identifier">
            <span class="text-purple">{{ $t('sharebleLink.credentialID') }} :</span>
            {{ certificate.identifier }}
          </p>

          <a
            :href="getProjectUrl(certificate.url)"
            target="_blank"
            v-if="certificate.url"
            >
            {{ $t('sharebleLink.viewCertificate') }}
            </a
          >
        </div>
      </div>
    </div>
    <Modal
      v-if="isModalOpen"
      @update="close($event)"
      :heading="modalHeading"
      className="certificate-modal modal-forms-responsive"
      :description="$t('talentWizard.fillInYourCertificatesDataToHighlightYourExpertise')"
      :handleSubmit="() => handleSubmitCertificatesForm()"
      :isSubmitDisabled="isDisabled"
    >
      <template :v-slot="certificate - form">
        <CertificatesForm
          ref="certificatesForm"
          :certificateDetails="certificateDetails"
          @handleCloseModal="handleCloseModal($event)"
          @handleIsDisabled="handleIsDisabled($event)"
      /></template>
    </Modal>
    <Modal
      v-if="isDeleteModalOpen"
      @update="closeDelete($event)"
      className="delete-projects-modal"
      :submitText="$t('talentWizard.delete')"
      :handleSubmit="() => handleDeleteCertificate()"
    >
      <template :v-slot="delete -modal - body"
        ><deleteModalBody
          ref="deleteCertificate"
          @handleCloseModal="handleCloseModal($event)"
      /></template>
    </Modal>
    <p class="no-data-text" v-if="certificates.length == 0">
      {{ $t("talent.noData") }}
    </p>
  </div>
</template>

<script>
import CertificatesForm from "../certificatesForm/certificatesForm.vue";
import deleteModalBody from "../deleteModalBody/deleteModalBody.vue";
import Modal from "../../../../../components/shared/modal/modal.vue";
import { getUrl } from "../../../../../functions/url";
import TabHeader from "../tabHeader/tabHeader.vue";
import months from "../../../../../static/Months";

export default {
  props: ["certificates", "talentEmail"],
  components: { TabHeader, Modal, CertificatesForm, deleteModalBody },
  data: () => {
    return {
      isDeleteModalOpen: false,
      months,
      modalHeading: "Edit Certificates",
      targetCertificateId: null,
      isModalOpen: false,
      isDisabled: false,
      certificateDetails: {
        name: "",
        organization: "",
        not_expire: 0,
        identifier: "",
        url: "",
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      },
    };
  },
  methods: {
    getMonth(id) {
      const filteredMonth = this.months.filter((month) => month.id == id);
      return filteredMonth[0].name;
    },
    getProjectUrl(url) {
      return getUrl(url);
    },
    openModal(id = null) {
      this.resetFormData();
      if (id !== null) {
        this.modalHeading = this.$t('talentWizard.editCertificatesInfo');
        this.getCertificateDetails(id);
        this.targetCertificateId = id;
        this.isModalOpen = true;
      } else {
        if (this.certificates?.length < 25) {
          this.modalHeading = this.$t('talentWizard.addCertificatesInfo');
          this.isModalOpen = true;
        }
      }
    },
    openDeleteModal(id) {
      this.targetCertificateId = id;
      this.isDeleteModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
      this.isDeleteModalOpen = false;
      this.$emit("certifications-updated");
    },
    close() {
      this.isModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    closeDelete() {
      this.isDeleteModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    handleSubmitCertificatesForm() {

      if (window.gtag) {
        window.gtag("event", "talent_updated_certificates", {
          talent_email: this.talentEmail,
        });
      }

      this.$refs.certificatesForm.handleSubmitCertificatesForm();
    },
    handleDeleteCertificate() {
      this.$refs.deleteCertificate.handleDelete(
        this.targetCertificateId,
        "certificate"
      );
    },
    getCertificateDetails(id) {
      this.$store
        .dispatch("talentProfile/getCertificateDetailsRequest", id)
        .then((res) => {
          const {
            id,
            name,
            organization,
            not_expire,
            identifier,
            url,
            start_month,
            start_year,
            end_month,
            end_year,
          } = res.data.data;
          this.certificateDetails.id = id;
          this.certificateDetails.name = name;
          this.certificateDetails.organization = organization;
          this.certificateDetails.not_expire = not_expire;
          this.certificateDetails.identifier = identifier;
          this.certificateDetails.url = url;
          this.certificateDetails.start_month = start_month;
          this.certificateDetails.start_year = start_year;
          this.certificateDetails.end_month = end_month;
          this.certificateDetails.end_year = end_year;
          this.isModalOpen = true;
        });
    },
    resetFormData() {
      this.certificateDetails = {
        name: "",
        organization: "",
        not_expire: 0,
        identifier: "",
        url: "",
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      };
    },
    handleIsDisabled(status) {
      this.isDisabled = status;
    },
  },
};
</script>

<style lang="scss" scoped></style>
