<template>
    <div class="tw-rounded-[20px] tw-bg-white tw-flex tw-flex-col  tw-p-4 tw-relative tw-mt-20 tw-w-[220px] tw-min-w-[220px]" 
        @click="$emit('open-mini-profile', talent.sharable_code)"
        >
        <img loading="lazy" :src="`${baseUrl}${talent?.image?.path}`" width="130px" height="130px"
            @error="imageLoadOnError" class=" tw-absolute tw-top-0 tw-left-[50%] tw-w-28 tw-h-28  tw-rounded-full tw-object-cover"
            :class="talent?.image?.path ? 'tw-border-8 tw-border-[#E4E4E4] tw-border-solid ' : ''"
            :style="imageError ? 'border: 5px solid transparent; transform: translate(-50%, -60%)' : 'transform: translate(-50%, -60%)'" />
        <div class=" tw-flex tw-flex-row tw-w-full tw-justify-around tw-mt-12 tw-items-center">

            <div>
                <div class="talent-detail-tooltip">
                    <img loading="lazy" class="" :src="!talent.unvetted
                            ? require('@/assets/images/ic_verifed.svg')
                            : require('@/assets/images/ic_unverifed.svg')
                        " />
                    <span class="tooltiptext" :class="{ 'tooltiptext-unvetted': talent.unvetted }">
                        {{
                            !talent.unvetted
                                ? $t("matchedTalents.verified")
                                : $t("matchedTalents.unverifiedTalent")
                        }}
                    </span>
                </div>
            </div>
            <div class="tw-font-bold tw-text-sm tw-text-[#292929]">{{ talent.full_name }} </div>
            <div class="country-flag" v-if="talent.nationality">
                <CountryFlag :countryCode="talent.nationality.code" />
            </div>
            <div class="country-flag" v-else-if="talent.country">
                <CountryFlag :countryCode="talent.country.code" />
            </div>

        </div>
        <div class=" tw-flex tw-flex-row tw-w-full tw-justify-center tw-whitespace-normal tw-text-center tw-text-sm tw-text-[#292929] tw-mt-4">
            {{ talent.role.name }}
        </div>
        <div class=" tw-flex tw-flex-col tw-items-center tw-w-full tw-justify-center"  v-if="talent.featured_company">
            <div class=" tw-text-[#969696] tw-text-xs tw-my-4 ">

                {{ $t('home.workedPreviouslyAt') }}
            </div>
            <div class=" tw-mb-2">
                <img class=" tw-object-contain tw-max-w-[6rem] tw-h-auto tw-min-h-[6rem] " :src="talent?.featured_company?.logo" :alt="talent?.featured_company?.name" />
            </div>
        </div>
    </div>
</template>

<script>
import CountryFlag from "../../../../../components/shared/countryFalg/countryFalg.vue";

export default {
    props: {
        talent: {
            type: Object,
            required: true,
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
        miniProfile: {
            type: Boolean,
            default: false,
        },
    },
    components: { CountryFlag },
    data() {
        return {
            baseUrl: process.env.VUE_APP_AWS_BASE_URL,
            imageError: false,
        };
    },
    watch: {
        talent: {
            immediate: true,
            deep: true,
            handler() {
                this.imageError = false;
                
            }
        }
    },
    methods: {
        imageLoadOnError(e) {
            e.target.src = this.miniProfile
                ? require("@/assets/images/ic_avatar-border.png")
                : require("@/assets/images/talent-avatar.png");

            this.imageError = true;

        },
    },
};
</script>

<style lang="scss" scoped>
.talent-detail-tooltip {
    position: relative;
}

.talent-detail-tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    color: #000000;
    text-align: center;
    padding: 5px 24px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    opacity: 0;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    transition: opacity 0.3s;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 24px 42px 0 rgb(0 0 0 / 11%), 0 -6px 22px 0 rgb(0 0 0 / 9%);
    display: flex;
    width: max-content;
    left: -30%;
    transform: translate(-50%, 0);
}

.talent-detail-tooltip .tooltiptext-unvetted {
    left: -94%;
}

.talent-detail-tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    right: 14%;
    margin-right: 0;
    border-width: 7px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent;
}

.talent-detail-tooltip>img:hover~.tooltiptext {
    visibility: visible;
    opacity: 1;
}
</style>