<template>
  <div class="bring-buddy-banner__wrapper">
    <div
      class="container d-flex align-items-center justify-content-center flex-column"
    >
     <div class="row justify-content-center">
       <div class="col-xl-7 col-lg-9">
          <h1 class="text-center">
            {{$t('bringBuddy.bringYourBuddy')}}
          </h1>
      <p class="text-center">
         {{$t('bringBuddy.the')}} <span>{{$t('bringBuddy.referralProgram')}} </span> 
        {{ $t('bringBuddy.nominateAFriendAndBeginYourDigitalNomadsJourneyTogetherReadTheCriteriaBelowAndReferAFriendToday') }}
      </p>
       </div>
     </div>
      <img loading="lazy"
        class="bring-buddy-img"
        :src="require($i18n.locale == 'en' ? '@/assets/images/bring-buddy.png' : '@/assets/images/talent/img_bring-your-buddy-ar.png')"
        alt="bring-buddy"
      />
      <div class="referral-email">
        <span>
                    {{ $t('bringBuddy.referralEmailReferralSquadiocom') }}
        </span> “Referral@Squadio.com“
      </div>
      <div class="terms-condition-link">
        <a @click="handleOpenModal">
           {{ $t('bringBuddy.termsConditions') }}
        </a>
      </div>
    </div>
    <Modal
      v-if="isModalOpen"
      @update="isModalOpen = $event"
      className="terms-and-conditions-modal"
    >
      <template :v-slot="terms-and-conditions"
        ><TermsAndConditionsModal

      /></template>
    </Modal>
  </div>
</template>

<script>
import TermsAndConditionsModal from "../termsAndConditionsModal/termsAndConditionsModal.vue"
import Modal from "../../../../components/shared/modal/modal.vue"
import "./bringBuddyBanner.scss";
export default {
  components: {TermsAndConditionsModal,Modal},
  data:()=>{
    return{
      isModalOpen:false
    }
  },
  methods:{
    handleOpenModal(){
      this.isModalOpen=true
    }
  }
};
</script>

<style lang="scss" scoped></style>
