<template>
  <div class="referral-proccess">
    <div class="steps pt-100 pb-100" id="steps">
      <div class="container">
        <div class="row">
          <div class="text-center heading">
            <h2 class="text-center text-white">
            <span>  {{ $t('bringBuddy.referralProcess') }}</span>
            <span class="text-yellow">  {{ $t('bringBuddy.procedures') }}</span>
          </h2>
          <p class="mx-auto text-center col-lg-6">
           
            {{ $t('bringBuddy.inCaseYouReferYourBuddyToSquadioTheFollowingStepsWillTakePlace') }}
            
          </p>
            <div class="mt-3 d-flex align-items-center justify-content-center">
              <img loading="lazy" src="../../../../assets/images/ic_line-title.svg" />
            </div>
          </div>
          
          <ul
            class="px-3 d-flex steps-ul pt-50 row"
            :class="{'text-end': $i18n.locale == 'ar', 'text-start': $i18n.locale == 'en'}"
          >
            <li class="col-lg-5">
              <span class="step-number"> 1 </span>
              <h4 class="text-white">
                  {{ $t('bringBuddy.cvReferring') }}
              </h4>
              <p class="mb-0">
                 {{ $t('bringBuddy.emailShouldBeSentTo') }}
                <span class="text-white">“Referral@Squadio.com“</span>
              </p>
              <p>
              {{ $t('bringBuddy.fromYourSideWithTheUpdatedCVOfTheTalentMentioningTheVacancySHeIsBeingReferredTo') }}
                
              </p>
            </li>
            <li class="col-lg-2 d-flex align-items-center steps-arrow">
              <div class="right-arrow">
                <img loading="lazy"
                  src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>
            <li class="col-lg-5">
              <span class="step-number"> 2 </span>
              <h4 class="text-white">
                   {{ $t('bringBuddy.receivesTheCvs') }}
              </h4>
              <p class="mb-0">
                
                  {{ $t('bringBuddy.once') }}
                <span class="text-white"
                  >
                {{ $t('bringBuddy.squadioEngineeringRecruitmentTeam') }}
                 </span
                >
              </p>
              <p>
               {{ $t('bringBuddy.theyWillCheckThatTalentDidntApplyBeforeOrHasBeenReferredOrAlreadyExistsInTheTalentPool') }}
                
              </p>
              <div class="right-arrow right-arrow-md">
                <img loading="lazy"
                  src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>

            <li class="col-lg-5">
              <span class="step-number"> 3 </span>
              <h4 class="text-white">
                 {{ $t('bringBuddy.theEngineeringRecruitmentTeam') }}
              </h4>
              <p>
               {{ $t('bringBuddy.willProceedWithKickingOffTheHiringProcess') }}
              </p>
            </li>
            <li class="col-lg-2 d-flex align-items-center steps-arrow">
              <div class="right-arrow">
                <img loading="lazy"
                  src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>
            <li class="col-lg-5">
              <span class="step-number"> 4 </span>
              <h4 class="text-white"> {{ $t('bringBuddy.invitation') }}</h4>
              <p>
               
              {{ $t('bringBuddy.theRefereeWillBeInvitedToCompleteOnlineTestsWithin3WorkingDays') }}
              </p>

              <div class="right-arrow right-arrow-md">
                <img loading="lazy"
                  src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>

            <li class="col-lg-5">
              <span class="step-number"> 5 </span>
              <h4 class="text-white">
                  

                  {{ $t('bringBuddy.cvReferring')  }}
               </h4>
              <p class="mb-0">
               {{ $t('bringBuddy.theEligibleRefereeWho') }}
                <span class="text-white">
                   {{ $t('bringBuddy.passedTheOnlineAssessments') }}
               </span>
               {{ $t('bringBuddy.willJoin') }}   
               <span class="text-white">
                 {{ $t('bringBuddy.squadioTalentPool') }} 
               </span>
              </p>
            </li>
            <li class="col-lg-2 d-flex align-items-center steps-arrow">
              <div class="right-arrow">
                <img loading="lazy"
                  src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>
            <li class="col-lg-5">
              <span class="step-number"> 6 </span>
              <h4 class="text-white">
                {{ $t('bringBuddy.theMatchingTeam') }} 
               </h4>

              <p>
              {{ $t('bringBuddy.shallFrontTheCandidatesProfileToDifferentAccountsUntilSHeGetsHiredByAPartner') }} 

              </p>
              <div class="right-arrow right-arrow-md right-last-arrow">
                <img loading="lazy"
                  src="../../../../assets/images/talent/homeScreen/Arrow_Right.svg"
                />
              </div>
            </li>
          </ul>

          <div class="text-center tw-mt-8">
            <ApplyViewBtn />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./referralProcess.scss";
import ApplyViewBtn from "../../../Talent/Home/components/applyViewBtn/applyViewBtn.vue"
export default {
  components: {ApplyViewBtn},
};
</script>

<style lang="scss" scoped></style>
