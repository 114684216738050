<template>
  <div
    class="d-flex align-items-center justify-content-between mt-4 mb-3 tabHeader"
  >
    <h3 class="headerBorder mb-0">
      <span> {{ title }} </span>
    </h3>
    <div
      :id="`${id}-edit-button`"
      :class="isEdit ? 'edit-tab-icon' : !hideAddIcon ? 'add-tab-icon' : ''"
      @click="iconAction"
    ></div>
  </div>
</template>

<script>
import "./tabHeader.scss";

export default {
  props: ["title", "id", "iconAction", "isEdit", "hideAddIcon"],
};
</script>
<style lang="scss" scoped></style>
