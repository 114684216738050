<template>
  <div :class="className">
    <button v-if="this.step != 1" class="btn btn-back me-auto" type="button" @click="previous()">
      {{ $t("talentWizard.back") }}
    </button>
    <button id="submitbutton" class="btn btn-next btnstyleUpdate" type="button" @click="submit()" :disabled="hasError">
      {{ label ? label : $t("talentWizard.next") }}
    </button>
  </div>
</template>

<script>
import Steps from "../../enums/WizzardSteps";
export default {
  props: [
    "className",
    "step",
    "hasError",
    "formData",
    "headers",
    "label",
    "isNextDisabled",
    "experiances",
    "educations",
    "certificates",
    "projectTypesOptions",
    "skillsOptions",
    "levelsOptions"
  ],
  data() {
    return {
      currentStep: this.step,
      stepsApis: {
        [Steps.BASIC_INFO]: "/api/users/basicInfo",
        [Steps.PROJECTS]: "/api/projects",
        [Steps.ACCOUNT]: "/api/accounts",
      },
    };
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && !this.isNextDisabled) {
          document.getElementById("submitbutton")? document.getElementById("submitbutton").disabled = false : null
        }
      },
    },
  },
  methods: {
    async submit() {
      document.getElementById("submitbutton").disabled = true;
      if (this.step == [Steps.IMPORTCV]) {
        try {
            if (window.gtag) window.gtag("event", "info submitted", {
              user_type: "Talent",
              step_number: "Step 3",
              step_name: "Import CV",
              imported_cv: this.formData.cv ? true : false,
            })
          await this.axios
            .post("/api/users/step", {
              current: this.currentStep - 1,
            })
            .finally(() => {
              this.next();
            });
        } catch (error) {
          console.log(error);
        }
      } else if (this.step == 1) {
        try {
          if (window.gtag) window.gtag("event", "info submitted", {
            user_type: "Talent",
            step_number: "Step 1",
            step_name: "Basic Info",
            job_title: this.formData.talent_role_id.name,
            skills: this.formData.skills.map(skil => this.skillsOptions.find(s => s.id == skil).name),
            years_of_experience: this.formData.experience_years,
            expected_salary: this.formData.salary,
            part_time_avalability: this.formData.part_time_availability ? true : false,
            first_language: this.formData.languages[0].name,
            first_language_level: this.levelsOptions.find(level => level.id == this.formData.languages[0].level ).name,
            second_language: this.formData.languages[1] ?  this.formData.languages[1] : null,
            second_language_level: this.formData.languages[1].level ? this.levelsOptions.find(level => level.id == this.formData.languages[1].level ).name : null,
          });

          await this.axios
            .post(this.stepsApis[this.step], {
              ...this.formData,

              talent_role_id:
                this.formData.talent_role_id.id || this.formData.talent_role_id,
            })
            .then(() => {
              this.next();
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.log(error);
        }
      } else if (this.step == 4) {
        if (window.gtag) window.gtag("event", "info submitted", {
          user_type: "Talent",
          step_number: "Step 4",
          step_name: "Experience",
          experience_numbers: this.experiances.length,
          experiences_added: this.experiances.length ? true : false,
        })
        await Promise.all(
          this.experiances.map(async (experiance) => {
            if (experiance && !experiance.id) {
              try {
                await this.axios
                  .post(`/api/experiences`, experiance)
                  .then(() => { })
                  .catch((err) => {
                    console.log(err);
                  });
              } catch (error) {
                console.log(error);
              }
            }
          })
        ).then(async () => {
          try {
            await this.axios
              .post("/api/users/step", {
                current: this.currentStep - 1,
              })
              .then(() => {
                let getParsing = JSON.parse(localStorage.getItem("getParsing"));

                getParsing.experiences = false;

                localStorage.setItem("getParsing", JSON.stringify(getParsing));
              });
          } catch (error) {
            console.log(error);
          }
          this.next();
        });
      } else if (this.step == 5) {
        if (window.gtag) window.gtag("event", "info submitted", {
          user_type: "Talent",
          step_number: "Step 5",
          step_name: "Education",
          education_numbers: this.educations.length,
          education_added: this.educations.length ? true : false,
        })
        await Promise.all(
          this.educations.map(async (education) => {
            if (education && !education.id) {
              try {
                await this.axios
                  .post(`/api/educations`, education)
                  .then(() => { })
                  .catch((err) => {
                    console.log(err);
                  });
              } catch (error) {
                console.log(error);
              }
            }
          })
        ).then(async () => {
          try {
            await this.axios
              .post("/api/users/step", {
                current: this.currentStep - 1,
              })
              .then(() => {
                let getParsing = JSON.parse(localStorage.getItem("getParsing"));

                getParsing.educations = false;

                localStorage.setItem("getParsing", JSON.stringify(getParsing));
              });
          } catch (error) {
            console.log(error);
          }
          this.next();
        });
      } else if (this.step == 6) {
        if (window.gtag) window.gtag("event", "info submitted", {
          user_type: "Talent",
          step_number: "Step 6",
          step_name: "Certificates",
          certificates_numbers: this.certificates.length,
          certificates_added: this.certificates.length ? true : false,
        })
        await Promise.all(
          this.certificates.map(async (certificate) => {
            if (certificate && !certificate.id) {
              try {
                await this.axios
                  .post(`/api/certifications`, certificate)
                  .then(() => { })
                  .catch((err) => {
                    console.log(err);
                  });
              } catch (error) {
                console.log(error);
              }
            }
          })
        ).then(async () => {
          try {
            await this.axios
              .post("/api/users/step", {
                current: this.currentStep - 1,
              })
              .then(() => {
                let getParsing = JSON.parse(localStorage.getItem("getParsing"));

                getParsing.certificates = false;

                localStorage.setItem("getParsing", JSON.stringify(getParsing));
              });
          } catch (error) {
            console.log(error);
          }
          this.next();
        });
      } else if (Object.keys(this.stepsApis).includes(this.step.toString())) {
        try {
          if (this.step == 2) {
            if (window.gtag) window.gtag("event", "info submitted", {
              user_type: "Talent",
              step_number: "Step 2",
              step_name: "Projects",
              projects_numbers: this.formData.projects.length,
              projects_name: this.formData.projects.map((project) => project.name),
              project_type: this.formData.projects.map((project) => this.projectTypesOptions.find((p) => p.id == project.project_type_id).name),
              project_urls: this.formData.projects.map((project) => project.link),
            });
          }
          
          if (this.step == 7) {
            let { behance, dribbble, gitHub, linkedIn } = this.formData
            
            let websites = this.formData.websites.length ? this.formData.websites.map((website) => {
              return website.url
            }) : []

            websites = [...websites, behance, dribbble, gitHub, linkedIn]
            websites = websites.filter((website) => website !== "")

              

             if (window.gtag) window.gtag("event", "info submitted", {
                user_type: "Talent",
                step_number: "Step 7",
                step_name: "Accounts",
                accounts_numbers: websites.length,
                accounts_added: websites.length ? true : false,
              })
          }
          await this.axios.post(this.stepsApis[this.step], this.formData);
          this.next();
        } catch (error) {
          document.getElementById("submitbutton").disabled = false;
          console.log(error);
        }
      } else {
        try {  
          await this.axios
            .post("/api/users/step", {
              current: this.currentStep,
            })
            .then(() => {
              if (this.step == 6) {
                let getParsing = JSON.parse(localStorage.getItem("getParsing"));

                getParsing.certificates = false;

                localStorage.setItem("getParsing", JSON.stringify(getParsing));
              }
            });
        } catch (error) {
          console.log(error);
        }
        this.next();
      }
    },
    async next() {
      ++this.currentStep;
      this.$store.dispatch("auth/setStep", this.currentStep);
      this.scrollToTop();
      if (this.currentStep == Steps.PROFILE) {
        let isNewTalent = JSON.parse(localStorage.getItem("new_talent"));
        if (isNewTalent) {
          this.$router.push({
            path: "/sq/talent/profile",
            query: {
              new_talent: "1",
            },
          });
        } else {
          this.$router.push({ name: "Profile" });
        }
      }
    },
    async previous() {
      if (this.currentStep != Steps.BASIC_INFO) {
        try {
          await this.axios.post("/api/users/step", {
            current: this.currentStep,
            previous: true,
          });
          --this.currentStep;
          this.$store.dispatch("auth/setStep", this.currentStep);
          this.scrollToTop();
        } catch (error) {
          console.log(error);
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped src="./NextPrevWizardBtn.css"></style>
