<template>
  <div>
    <div
      class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-p-4 tw-bg-white md:tw-gap-10 tw-rounded-3xl md:tw-p-14"
      style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)">
      <img class="tw-flex tw-justify-center tw-w-48 tw-h-40"
        src="@/assets/images/contract-renewal/ic_profile-popup_rejected.svg" alt="" />
      <h1
        class="md:tw-w-[58%] tw-w-full tw-text-center tw-text-[#1e2022] md:tw-text-2xl tw-text-xl tw-font-semibold tw-leading-9">

        {{ $t('requestInterview.profileUnavailableText') }}
      </h1>


    </div>  
    <div class="tw-flex tw-justify-between tw-items-center" :class="!isloading && talents.length > 0 ? 'md:tw-mt-8 tw-mt-4' : 'md:tw-mt-8 tw-mt-0'" >
      <h3 v-if="!isloading && talents.length > 0">
        <span
          class="tw-text-[#1e2022] tw-text-3xl tw-font-semibold tw-leading-10">{{$t('requestInterview.similar')}} </span><span
          class="tw-text-[#ffc628] tw-text-3xl tw-font-semibold tw-leading-10">{{$t('requestInterview.profiles')}}</span>
        </h3>

      <router-link class="md:tw-flex tw-hidden tw-flex-row   tw-text-[#422e87] tw-text-xl tw-font-bold tw-uppercase tw-leading-loose"
        :to="url">
         {{ $t('home.viewMoreTalents') }}
        <img class="tw-ms-2 tw-h-10 tw-w-10" :class="$i18n.locale == 'en' ? '' : ' tw-rotate-180 '"
          src="@/assets/images/arrow-purple.svg" alt="arrow">
       
      </router-link>
    </div>

    <div class="tw-w-full tw-flex">
      <talentsSkeltons v-if="isloading" />
      <Talents class="tw-w-full" :talents="talents" v-else-if="talents.length > 0 && !isloading" />
    </div>

      <div class="tw-flex md:tw-hidden tw-mt-8 tw-justify-between tw-items-center">
      
      <router-link class="tw-flex tw-flex-row   tw-text-[#422e87] tw-text-xl tw-font-bold tw-uppercase tw-leading-loose"
        :to="url">
        {{ $t('home.viewMoreTalents') }}
        <img class="tw-ms-2 tw-h-10 tw-w-10" :class="$i18n.locale == 'en' ? '' : ' tw-rotate-180 '"
          src="@/assets/images/arrow-purple.svg" alt="arrow">
        
      </router-link>
    </div>
    


  </div>
</template>

<script>
import Talents from "@//pages/Client/matching/components/talents/talents.vue";
import talentsSkeltons from "@//pages/Client/matching/components/talentSkeltons/talentSkeltons.vue";

export default {
  name: "ProfileUnavailable",
  props: {
    talent: {
      type: Object,
      default: () => ({})
    },
  },
  components: {
    Talents,
    talentsSkeltons
  },
  data() {
    return {
      isloading: false,
      talents: []
    }
  },
  computed: {
    url() {
      return `/sq/client/matching?role=${this.talent.role.id}`
    },
  },
  mounted () {
    this.getTalents();
  },
  methods: {
    async getTalents() {
      this.isloading = true;
      try {
        const url = this.talent.sharable_link
        const id = url.split('/').pop(); // Extracts the last segment

        const response = await this.axios.get(`/api/client/users/${id}/similar`);

        this.talents = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isloading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>