// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/client/bg_words-shadow-2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tw-snap-x[data-v-47b0f917]{overflow-x:scroll;scroll-snap-type:x mandatory;white-space:nowrap;margin:auto}.tw-snap-center[data-v-47b0f917]{scroll-snap-align:center;scroll-margin-right:1rem;width:88%;scroll-margin-left:1rem;min-width:88%}.world-card[data-v-47b0f917]{box-shadow:0 15px 30px 0 rgba(0,0,0,.03)}.testimonials2__word[data-v-47b0f917]:before{content:\"”\";color:#252525;font-size:35px}.testimonials2__word[data-v-47b0f917]:after{content:\"“\";color:#252525;font-size:35px;position:absolute}.rtl .testimonials2__word[data-v-47b0f917]:before{content:\"”\";color:#252525;font-size:38px}.rtl .testimonials2__word[data-v-47b0f917]:after{content:\"“\";color:#252525;font-size:38px;position:absolute}.testimonials[data-v-47b0f917]{padding:128px 0;background:linear-gradient(90deg,#faf9fe,#f2f0fb,#e0dbe8,#f2eae2);min-height:100vh;background-position:50%;background-size:cover}@media(max-width:700px){.testimonials[data-v-47b0f917]{padding:50px 0}}.testimonials2[data-v-47b0f917]{padding:128px 0;background:linear-gradient(90deg,#ededed,#ece8ff,#e3d3fb,#e1e1e1)!important}@media(max-width:700px){.testimonials2[data-v-47b0f917]{padding:50px 0}}.testimonials-bg[data-v-47b0f917]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", ""]);
// Exports
module.exports = exports;
