<template>
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4 tw-space-y-2 tw-bg-white tw-rounded-3xl md:tw-p-14"
        style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)" >
        <img class="tw-flex tw-justify-center tw-w-48 tw-h-40" src="@/assets/images/client/ic_profile-popup.svg" alt="Waiting Talent" />
        <h1 class="md:tw-w-[58%] tw-w-full tw-text-center tw-text-[#1e2022] tw-font-bold md:tw-text-2xl tw-text-xl tw-font-semibol tw-leading-9">
            {{ $t('requestInterview.waitingResponse') }}
        </h1>
        <p class="tw-text-center tw-text-[#9f9d9d] tw-text-xl tw-font-medium tw-leading-loose tw-pb-8 ">
             {{ $t('requestInterview.pleaseKeepLooking') }}
            
        </p>

             <div class="tw-flex tw-items-end md:tw-justify-end tw-justify-start tw-w-full tw-gap-4 tw-my-auto">
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' || interview.status.id === 'talent_pending' || interview.status.id === 'talent_pending_update'"
              @click="$emit('cancel')"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-auto md:tw-w-auto  md:tw-min-w-[10rem]  tw-rounded-lg  tw-justify-center tw-items-center tw-underline tw-gap-2.5 tw-flex tw-text-center tw-text-[#ff6f59] tw-text-[12px]  md:tw-text-base tw-font-medium">
              {{ $t('requestInterview.cancelProcess')}}
            </button>
            <button
              v-if="interview.status.id === 'client_pending_update' || interview.status.id === 'client_pending' && interview.status.id !== 'talent_pending'"
              @click="save"
              class="tw-px-4 tw-py-3 md:tw-py-3.5 tw-w-full tw-bg-[#ffc629] md:tw-w-auto  md:tw-min-w-[10rem] tw-rounded-lg tw-border-[#d7d7d7] tw-justify-center tw-items-center tw-gap-2.5 tw-flex text-[#292929] tw-text-[15px]  md:tw-text-base tw-font-bold">
              
               {{ $t('requestInterview.submit')}}
            </button>
          </div>

    </div>
</template>

<script>
export default {
    name: "WaitingTalent",
      props: {

    interview: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" scoped></style>