<template>
  <div class="referral-application">
    <div class="container">
      <div class="heading text-center">
        <h2><span> {{ $t('bringBuddy.for') }}</span> {{ $t('bringBuddy.referralBonus') }}<span>{{ $t('bringBuddy.application') }}</span></h2>
        <p>


          {{ $t('bringBuddy.talentToBeEnrolledOnProjectAndTheFollowingDurationToBeApplied') }}
        </p>
        <div class="d-flex align-items-center justify-content-center mt-3">
          <img loading="lazy" src="../../../../assets/images/ic_line-title.svg" />
        </div>
      </div>
      <div class="row justify-content-center m-auto referral-application-grid">
        <div class="col-lg-6">
          <div class="referral-details">
            <p class="mb-0">{{ $t('bringBuddy.shortTermProjects') }}:</p>
            <p class="mb-0"> {{ $t('bringBuddy.1MonthTo3Months') }} </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="referral-details">
            <p class="mb-0">{{ $t('bringBuddy.refereeToReceiveABonus') }} </p>
            <p class="mb-0">{{ $t('bringBuddy.afterProjectCompletion') }} </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="referral-details">
            <p class="mb-0"> {{ $t('bringBuddy.longTermProjects') }}</p>
            <p class="mb-0">{{ $t('bringBuddy.4MonthsTo12Months') }} </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="referral-details">
            <p class="mb-0">
            {{ $t('bringBuddy.refereeToReceiveABonus') }}
            </p>
            <p class="mb-0">{{ $t('bringBuddy.afterTheProbationaryPeriod') }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./referralApplication.scss";
export default {
  components: {},
};
</script>

<style lang="scss" scoped></style>
