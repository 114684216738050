<template>
  <div class="inquiry-send">
    <div class="inquiry-send-box">
      <div class="inquiry-send-box__text">
        <input
          dir="auto"
          :placeholder="$t('nomination.sendUsAnyQuestionHere')"
          v-model="$v.comment.$model"
          type="text"
          @keyup.enter="sendInquiry"
        />
      </div>
      <div class="inquiry-send-box__send">
        <button @click="sendInquiry" v-if="!isSending">
          <img loading="lazy" src="@/assets/images/send.svg" alt="" />
        </button>

        <div v-else class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div>
      <VulidateError
        class="mt-1 d-flex"
        :validationField="$v.comment"
        :params="['required']"
      />
    </div>
  </div>
</template>

<script>
import VulidateError from "@/components/shared/VulidateError/vulidateError.vue";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    currentTalent: {
      required: true,
    },
  },
  components: {
    VulidateError,
  },
  validations: {
    comment: {
      required,
    },
  },
  data() {
    return {
      comment: "",
      isSending: false,
    };
  },
  methods: {
    async sendInquiry() {
      this.$v.comment.$touch();
      if (this.$v.$anyError || this.isSending) {
        return;
      }

      this.isSending = true;
      await this.axios
        .post(`/api/client/nomination/${this.currentTalent}/ticket`, {
          message: this.comment ? this.comment : "",
        })
        .then(() => {
          this.comment = "";
          this.$v.$reset();
          this.$emit("inquirySent");
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.inquiry-send {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.nominated-list-card__questions .inquiry-send-box {
  background-color: #f3f3f3;
  box-shadow: unset;
}

.inquiry-send-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;

  background-color: transparent;
  border-radius: 35.5px;
  box-shadow: 11px 8px 56px 0 #422e8717;

  &__text {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;

    input {
      background-color: transparent;
      border: 1px solid transparent;
      outline: 1px solid transparent;
      width: 100%;
    }

    input:focus {
      border: 1px solid transparent;
      outline: 1px solid transparent;
    }

    p {
      margin-bottom: 0;
      @media (max-width: 576px) {
        font-size: 11px;
      }
    }
  }

  &__send {
    img {
      height: 22px;
      width: 22px;
      @media (max-width: 576px) {
        height: 18px;
        width: 18px;
      }
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
</style>