<template>
  <div>
    <div v-if="!isClient() && !isTalent()">
      <GuestLanding />
    </div>
    <div v-if="isClient()">
       <Client />
    </div>
    <div v-if="isTalent()">
       <Talent />
    </div>
  </div>
</template>

<script>
import "../style.css";
import { isClient, isTalent } from "../../utils/shared";
   const Talent = () => import("../../pages/Talent/Home/index.vue")
   const Client = () => import("../../pages/Client/home/index.vue")
   const GuestLanding = () => import("../../pages/landing/landing.vue")
export default {
  components: {
    Talent,
    Client,
    GuestLanding
  },
  data() {
    return {
    }
  },
  methods: {
    isTalent,
    isClient,
  },
};
</script>

<style lang="scss" scoped></style>