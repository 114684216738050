<template>
  <div
    class="tab-pane fade"
    id="pills-Education"
    role="tabpanel"
    aria-labelledby="pills-Education-tab"
  >
    <TabHeader
      :title="$t('signUp.education')"
      id="education"
      :iconAction="() => openModal(null)"
    />
    <div class="exp-boxes">
      <div
        class="exp-box mb-4"
        v-for="education in educations"
        :key="education.id"
      >
        <div class="position-relative">
          <div class="d-flex align-items-center justify-content-between">
            <span class="d-block text-grey mb-1">
              ({{ getMonth(education.start_month) }}
              {{ education.start_year }} -
              <span v-if="education.currently == 1">{{$t('sharebleLink.present')}}</span>
              <span v-if="education.currently == 0"
                >{{ getMonth(education.end_month) }}
                {{ education.end_year }}</span
              >)
            </span>
            <div class="ms-2">
              <button
                type="button"
                class="more-tab-icon"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></button>
              <ul class="dropdown-menu dropdown-menu-end small-more-pop">
                <li @click="openModal(education.id)">
                  <button class="dropdown-item" type="button">{{$t('matchedTalents.edit')}}</button>
                </li>
                <li @click="openDeleteModal(education.id)">
                  <button class="dropdown-item" type="button">
                    {{$t('talentWizard.delete')}}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <h3 class="mb-2">
            {{ education.university }}
            <span class="company-name" v-if="education.degree">
              {{ education.degree }}
            </span>
          </h3>
        </div>
        <p class="mb-0">{{ education.study_field }}</p>
      </div>
    </div>
    <Modal
      v-if="isModalOpen"
      @update="close($event)"
      :heading="modalHeading"
      className="education-modal  modal-forms-responsive"
      :description="$t('talentWizard.fillInEducationDegreesYouHaveEarnedAndAreCurrentlyPursuing')"
      :handleSubmit="() => handleSubmitEducationForm()"
      :isSubmitDisabled="isDisabled"
    >
      <template :v-slot="education - form">
        <EducationForm
          ref="educationForm"
          :educationDetails="educationDetails"
          @handleCloseModal="handleCloseModal($event)"
          @handleIsDisabled="handleIsDisabled($event)"
      /></template>
    </Modal>
    <Modal
      v-if="isDeleteModalOpen"
      @update="closeDelete($event)"
      className="delete-projects-modal"
      :submitText="$t('talentWizard.delete')"
      :handleSubmit="() => handleDeleteEducation()"
    >
      <template :v-slot="delete -modal - body"
        ><deleteModalBody
          ref="deleteEducation"
          @handleCloseModal="handleCloseModal($event)"
      /></template>
    </Modal>
    <p class="no-data-text" v-if="educations.length == 0">
      {{ $t("talent.noData") }}
    </p>
  </div>
</template>

<script>
import months from "../../../../../static/Months";
import TabHeader from "../tabHeader/tabHeader.vue";
import EducationForm from "../talentEducationForm/talentEducationForm.vue";
import Modal from "../../../../../components/shared/modal/modal.vue";
import deleteModalBody from "../deleteModalBody/deleteModalBody.vue";

export default {
  props: ["educations", "talentEmail"],
  components: { TabHeader, Modal, EducationForm, deleteModalBody },
  data: () => {
    return {
      months,
      isDeleteModalOpen: false,
      isModalOpen: false,
      isDisabled: false,
      targetEducationId: null,
      modalHeading: "Edit Education",
      educationDetails: {
        university: "",
        study_field: "",
        degree: "",
        currently: 0,
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      },
    };
  },
  methods: {
    getMonth(id) {
      const filteredMonth = this.months.filter((month) => month.id == id);
      return filteredMonth[0].name;
    },
    handleSubmitEducationForm() {

      if (window.gtag) {
        window.gtag("event", "talent_updated_education", {
          talent_email: this.talentEmail,
        });
      }

      this.$refs.educationForm.handleSubmitEducationForm();
    },
    openModal(id = null) {
      this.resetFormData();
      if (id !== null) {
        this.modalHeading = this.$t('talentWizard.editEducation')
        this.getEducationDetails(id);
        this.targetEducationId = id;
        this.isModalOpen = true;
      } else {
        if (this.educations?.length < 25) {
          this.modalHeading = this.$t('talentWizard.addEducation')
          this.isModalOpen = true;
        }
      }
    },
    handleCloseModal() {
      this.isModalOpen = false;
      this.isDeleteModalOpen = false;
      this.$emit("educations-updated");
    },
    close() {
      this.isModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    closeDelete() {
      this.isDeleteModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    openDeleteModal(id) {
      this.targetEducationId = id;
      this.isDeleteModalOpen = true;
    },
    getEducationDetails(id) {
      this.$store
        .dispatch("talentProfile/getEducationDetailsRequest", id)
        .then((res) => {
          const {
            id,
            university,
            study_field,
            degree,
            currently,
            start_month,
            start_year,
            end_month,
            end_year,
          } = res.data.data;
          this.educationDetails.id = id;
          this.educationDetails.university = university;
          this.educationDetails.study_field = study_field;
          this.educationDetails.degree = degree;
          this.educationDetails.currently = currently;
          this.educationDetails.start_month = start_month;
          this.educationDetails.start_year = start_year;
          this.educationDetails.end_month = end_month;
          this.educationDetails.end_year = end_year;
        });
    },
    handleDeleteEducation() {
      this.$refs.deleteEducation.handleDelete(
        this.targetEducationId,
        "education"
      );
    },
    resetFormData() {
      this.educationDetails = {
        university: "",
        study_field: "",
        degree: "",
        currently: 0,
        start_month: "",
        start_year: "",
        end_month: "",
        end_year: "",
      };
    },
    handleIsDisabled(status) {
      this.isDisabled = status;
    },
  },
};
</script>

<style lang="scss" scoped></style>
