<template>
  <div class="no-result-wrapper">
    <div class="locked-popUp">
      <img loading="lazy" class=" tw-mx-auto" src="../../../../../assets/images/ic_profile-locked.svg" />
      <h2 class="mt-5 mb-4" v-if="!isLoaded">
        {{ $t("matchedTalents.thisListOfProfilesIsLocked") }}
<!--         <span class="text-purple"> {{ $t("matchedTalents.locked") }} </span>
 -->      </h2>
      <div
        class="pb-3 mt-5 mb-5 d-flex align-items-center justify-content-center"
        v-if="isLoaded"
      >
        <skeleton-loader-vue
          type="rect"
          :width="800"
          :height="30"
          animation="fade"
          rounded
        />
      </div>
      <h3 class="mb-4">
        <template v-if="!isLoaded && false">
          <span class="text-grey d-block d-lg-inline">
            {{ $t("matchedTalents.wellReachOutOn") }}:
          </span>
          {{ getData() }} GMT
        </template>

        <div
          class="mb-3 d-flex align-items-center justify-content-center"
          v-if="isLoaded && false"
        >
          <skeleton-loader-vue
            type="rect"
            :width="600"
            :height="30"
            animation="fade"
            rounded
          />
        </div>

        <span class="mt-4 d-block" v-if="!isLoaded && false"
          ><span class="text-grey d-block d-lg-inline">
            {{ $t("matchedTalents.chatWithUs") }}:
          </span>
          <span v-if="isStage()">no-reply.stage@ibtikar.net.sa</span>
          <span v-if="!isStage()">Bd@squadio.com</span>
        </span>
        <div
          class="mt-4 mb-3 d-flex align-items-center justify-content-center"
          v-if="isLoaded && false"
        >
          <skeleton-loader-vue
            type="rect"
            :width="600"
            :height="30"
            animation="fade"
            rounded
          />
        </div>
      </h3>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import "./noResultFound.scss";
export default {
  components: {},
  data: function () {
    return {
      date: "",
      isLoaded: true,
    };
  },
  mounted() {
    this.getBookingData();
  },
  methods: {
    getData() {
      if (this.date)
        return `${moment(this.date).format(" DD MMMM YYYY")} at ${moment(
          this.date
        ).format(" h:mm ")} `;
    },
    async getBookingData() {
      if (this.$store.state.auth.token)
        try {
          await this.axios.get("/api/client/projects").then((res) => {
       setTimeout(() => {
              this.isLoaded = false;
            }, 300);         this.date = res.data.data.meeting_start_time;
        
          });
        } catch (error) {
          console.log(error);
          this.isLoaded = false;
        }
    },
    isStage() {
      return window.location.href.includes("fe-stage");
    },
  },
};
</script>

<style lang="scss" scoped></style>
