<template>
  <div class="matching-wrapper position-relative">
    <div class="container text-center innerPage position-relative">
      <Heading :isLoaded="isLoading" :nominationsCount="nominationsCount" />
      <Carousel
        @handleSkillClick="handleSkillClick($event)"
        :is-loading="isLoading"
        :skills="skills"
        :isResponse="isResponse"
      />
      <NominatedList
        :talents="talents"
        v-if="!isLoading || paginationActive"
        :currentSkillId="currentSkillId"
        @updatedNominationList="updatedNominationList()"
        @inquirySent="inquirySent()"
      />
      <NominatedListPlaceHolder v-if="isLoading && !paginationActive" />

      <template v-if="meta && meta.total">
        <div v-if="talents.length != meta.total && talents.length != 0" class="nominations__pagination">
          <span>
            {{ talents.length }} / {{ meta.total }}
          </span>
          <button @click="getNextPage()">
            <span>{{ $t("contractsRenewal.more") }}</span>
            <div v-if="isLoading" class="pagination-loader"></div>
          </button>
        </div>
      </template>

      <PlaceHolderCard />
    </div>
  </div>
</template>

<script>
import Heading from "./components/heading/heading.vue";
import Carousel from "@/pages/Client/matching/components/carousel/carousel.vue";
import NominatedList from "./components/nominatedList/nominatedList.vue";
import PlaceHolderCard from "@/pages/Client/nominatedProfiles/components/placeholderCard/placeholderCard.vue";
import NominatedListPlaceHolder from "@/pages/Client/nominatedProfiles/components/nominatedListPlaceHolder/nominatedListPlaceHolder.vue";
import { scrollToTop } from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";
import axiosCancelMixin from '@/mixins/axiosCancelMixin.js';



export default {
   mixins: [axiosCancelMixin],
  components: {
    Heading,
    Carousel,
    NominatedList,
    PlaceHolderCard,
    NominatedListPlaceHolder,
  },
  data() {
    return {
      isSkillsLoading: false,
      isLoading: true,
      paginationActive: false,
      cancelTokenSource: null,
      talents: [],
      skills: [],
      isResponse: false,
      page: 1,
      meta: null,
      paginator: { total: 0 },
      currentSkillId: null,
      nominationsCount: 0,
      projects: [],
    };
  },
   computed: {
    ...mapGetters({
      activeProject: "client/getActiveProject",
      getClientNominationData: "auth/getClientNominationData"
    }),
  },
  watch: {
    activeProject() {
     this.emailIsOpen();
     this.getSkills();
     this.scrollToTop();
    },
    getClientNominationData() {
      this.getSkills();
    },
    skills(newSkills) {
      if (newSkills.length > 0) {
        this.currentSkillId = this.currentSkillId
          ? this.currentSkillId
          : this.skills[0].id;
        this.getNominations(this.skills[0].id);
      } else {
        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions({
      handleSetActiveProject: "client/handleSetActiveProject"
    }),
    scrollToTop,
    handleSkillClick(id) {
      this.currentSkillId = id;
      this.talents = [];
      this.page = 1;
      this.scrollToTop();
      this.paginationActive = false;
      this.meta = null;
      this.updatedNominationList(id);
    },
    getNextPage() {
      this.page++;
      this.paginationActive = true;
      this.updatedNominationList();
    },
    getClientProjects() {
      if (this.$route.query && this.$route.query.project_id) { 
        const cancelToken = this.createCancelToken();
        this.axios.get(`/api/clients/projects/minimal`, {cancelToken}).then((result) => {
            
            let project = result.data.data.find((p) => p.id == this.$route.query.project_id)
             this.projects = result.data.data
             let payload = { projects: this.projects, project: project }
             this.handleSetActiveProject(payload)
         }).catch((err) => {
            console.log(err);
         });
      }
    },
    async emailIsOpen() {
      if (this.$route.query && this.$route.query.ref) {
        await this.axios
          .put(`/api/client/nomination/${this.$route.query.ref}`, {
            email_is_opened: true,
          })
          .then(() => {
            if (localStorage.getItem("redirect")) {
              localStorage.removeItem("redirect");
            }
          });
      }
    },
    updatedNominationList() {
      this.getNominations(this.currentSkillId);
    },
    async inquirySent() {
        try {
          ///
          const cancelToken = this.createCancelToken();
          const nominations = await this.axios
            .get(`/api/talent-nominations?role=${this.currentSkillId}`, {cancelToken})
            .then((res) => {
              this.talents = [];
              this.talents = res.data.data;

              if (this.talents.length == 0) {
               let payload = { projects: this.projects, project: this.activeProject }
                this.handleSetActiveProject(payload)
             }
            });

          Promise.all([nominations])
            .then(() => {
              this.isLoading = false;
            })
            .catch(() => {});
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }

    },
    async getNominations(id) {
      try {
        this.isLoading = true;

        const cancelToken = this.createCancelToken();
        const nominations = await this.axios
          .get(`/api/talent-nominations?role=${id}&page=${this.page}`, {cancelToken})
          .then((res) => {
            if (this.paginationActive) {
              this.talents = [...this.talents, ...res.data.data];
              
            } else {
              this.talents = res.data.data;
            }
            this.meta = res.data.meta;

          });

        Promise.all([nominations])
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {});
      } catch (error) {
        this.isLoading = false;

        console.log(error);
      }
    },
    async getSkills() {
      try {
        this.isResponse = false;
        const cancelToken = this.createCancelToken();
        await this.axios.get("/api/talent-nominations/info", {cancelToken}).then((res) => {
          this.skills = res.data.data.roles;
          this.nominationsCount = res.data.data.nominations_count;
          this.isResponse = true;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getClientProjects()
    this.emailIsOpen();
    this.getSkills();
    this.scrollToTop();

  },
};
</script>

<style lang="scss" scoped>
.nominations__pagination {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;

  span {
    font-size: 14px;
    color: #000;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    gap: 10px;
    padding: 10px 40px;
    border-radius: 12px;
    color: #fff;
    transition: box-shadow .3s ease;
    box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);

    img {
      width: 21px;
      height: 21px;
    }

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.pagination-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid rgba(255, 198, 41, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>