<template>
  <section :class="{'testimonials2': featureId4, 'testimonials testimonials-bg': !featureId4}" id="testimonials">
    <div class="container">

      <h1
        v-if="featureId4"
        class="tw-text-[#1e2022] md:!tw-text-[45px] !tw-text-[25px] md:!tw-leading-[63px] !tw-leading-[43px] md:tw-mb-[62px] tw-mb-[40px] tw-w-full tw-text-center"
      >
        {{ $t("home.recommendedByLeadingExpertsInTheDigitalWorld") }}
      </h1>
        <h1
        v-else
        class="tw-text-[#1e2022] md:!tw-text-[45px] !tw-text-[25px] md:!tw-leading-[63px] !tw-leading-[43px] md:tw-mb-[62px] tw-mb-[40px] tw-w-[67%]"
      >
        {{ $t("home.recommendedByLeadingExpertsInTheDigitalWorld") }}
      </h1>

      <div v-if="featureId4"
      class="tw-relative tw-w-11/12 tw-flex-row tw-flex tw-gap-4 tw-snap-x tw-snap-proximity tw-overflow-x-auto"
      >   

        <div class="tw-flex tw-flex-col tw-w-full tw-snap-center ">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4  "
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none tw-min-h-[418px]'
                : 'lg:tw-rounded-br-none tw-min-h-[360px]',
            ]"
          >
            
            <div>
              <p class="!tw-text-[#252525] testimonials2__word  !tw-leading-8  tw-whitespace-normal tw-text-xl">
                {{ $t("home.nawafAlmusaibeehWord") }}
              </p>
            </div>

            <div class="tw-flex-row tw-w-full tw-flex tw-gap-1 tw-my-2">
              <img loading="lazy" src="@/assets/images/start.svg" />
               <img loading="lazy" src="@/assets/images/start.svg" />
                <img loading="lazy" src="@/assets/images/start.svg" />
                 <img loading="lazy" src="@/assets/images/start.svg" />
                  <img loading="lazy" src="@/assets/images/start.svg" />
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"
                  alt=""
                />
              </div>
              <div class=" tw-w-3/4">
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.nawafAlmusaibeeh") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-[11px] tw-font-medium tw-whitespace-normal">
                  {{ $t("home.nawafAlmusaibeeh1") }}
                </h4>
              </div>
            </div>

            <div class="tw-mt-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-cyberME-2x.png"
                alt=""
              />
            </div>
            
          </div>

          
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-snap-center ">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4  "
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none tw-min-h-[418px]'
                : 'lg:tw-rounded-br-none tw-min-h-[360px]',
            ]"
          >
           
            <div>
              <p class="!tw-text-[#252525]  testimonials2__word  !tw-leading-8  tw-whitespace-normal tw-text-xl">
                {{ $t("home.mouniraJamjomWord") }}
              </p>
            </div>

             <div class="tw-flex-row tw-w-full tw-flex tw-gap-1 tw-my-2">
              <img loading="lazy" src="@/assets/images/start.svg" />
               <img loading="lazy" src="@/assets/images/start.svg" />
                <img loading="lazy" src="@/assets/images/start.svg" />
                 <img loading="lazy" src="@/assets/images/start.svg" />
                  <img loading="lazy" src="@/assets/images/start.svg" />
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/testimonials/img-4-2x.png"
                  alt=""
                />
              </div>
              <div class=" tw-w-3/4">
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.mouniraJamjom") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-[11px] tw-font-medium tw-whitespace-normal">
                  {{ $t("home.mouniraJamjom1") }}
                </h4>
              </div>
            </div>

             <div class="tw-mt-2.5">
              <img loading="lazy" src="@/assets/images/caseStudy/logo-aanaaab.png" alt="" />
            </div>
          </div>

        
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-snap-center ">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4 "
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none tw-min-h-[418px]'
                : 'lg:tw-rounded-br-none tw-min-h-[360px]',
            ]"
          >
           
            <div>
              <p class="!tw-text-[#252525]   testimonials2__word  !tw-leading-8 tw-whitespace-normal tw-text-xl">
                {{ $t("home.aliAlfehaidWord") }}
              </p>
            </div>

             <div class="tw-flex-row tw-w-full tw-flex tw-gap-1 tw-my-2">
              <img loading="lazy" src="@/assets/images/start.svg" />
               <img loading="lazy" src="@/assets/images/start.svg" />
                <img loading="lazy" src="@/assets/images/start.svg" />
                 <img loading="lazy" src="@/assets/images/start.svg" />
                  <img loading="lazy" src="@/assets/images/start.svg" />
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/ali-fhaid.png"
                  alt=""
                />
              </div>
              <div class=" tw-w-3/4">
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.aliAlfehaid") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-[11px] tw-font-medium tw-whitespace-normal">
                  {{ $t("home.aliAlfehaid1") }}
                </h4>
              </div>
            </div>

             <div class="tw-mt-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-telgani-2x.png"
                alt=""
              />
            </div>
          </div>

          
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-snap-center ">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4  "
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none tw-min-h-[418px]'
                : 'lg:tw-rounded-br-none tw-min-h-[360px]',
            ]"
          >

            <div>
              <p class="!tw-text-[#252525] testimonials2__word  !tw-leading-8    tw-whitespace-normal tw-text-xl">
                {{ $t("home.mohammedIbrahimWord") }}
              </p>
            </div>

             <div class="tw-flex-row tw-w-full tw-flex tw-gap-1 tw-my-2">
              <img loading="lazy" src="@/assets/images/start.svg" />
               <img loading="lazy" src="@/assets/images/start.svg" />
                <img loading="lazy" src="@/assets/images/start.svg" />
                 <img loading="lazy" src="@/assets/images/start.svg" />
                  <img loading="lazy" src="@/assets/images/start.svg" />
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image.png"
                  alt=""
                />
              </div>
              <div class=" tw-w-3/4">
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.mohammedIbrahim1") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-[11px] tw-font-medium tw-whitespace-normal">
                  {{ $t("home.mohammedIbrahim") }}
                </h4>
              </div>
            </div>

              <div class="tw-mt-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-sabbar-ar-2x.png"
                alt=""
              />
            </div>

          </div>

          
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-snap-center ">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4  "
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none tw-min-h-[418px]'
                : 'lg:tw-rounded-br-none tw-min-h-[360px]',
            ]"
          >
            
            <div>
              <p class="!tw-text-[#252525]   testimonials2__word  !tw-leading-8 tw-whitespace-normal tw-text-xl">
                {{ $t("home.sultanAlhaddaWord") }}
              </p>
            </div>

             <div class="tw-flex-row tw-w-full tw-flex tw-gap-1 tw-my-2">
              <img loading="lazy" src="@/assets/images/start.svg" />
               <img loading="lazy" src="@/assets/images/start.svg" />
                <img loading="lazy" src="@/assets/images/start.svg" />
                 <img loading="lazy" src="@/assets/images/start.svg" />
                  <img loading="lazy" src="@/assets/images/start.svg" />
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-items-center tw-flex lg:tw-hidden tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image-2.png"
                  alt=""
                />
              </div>
              <div class=" tw-w-3/4">
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.sultanAlhadda") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-[11px] tw-font-medium tw-whitespace-normal">
                  {{ $t("home.sultanAlhadda1") }}
                </h4>
              </div>
            </div>

            <div class="tw-mt-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-logo-haseel-2x.png"
                alt=""
              />
            </div>
            
          </div>
          
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-snap-center ">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4  "
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none tw-min-h-[418px]'
                : 'lg:tw-rounded-br-none tw-min-h-[360px]',
            ]"
          >
            
            <div>
              <p class="!tw-text-[#252525]  testimonials2__word  !tw-leading-8  tw-whitespace-normal tw-text-xl">
                {{ $t("home.aliHazmWord") }}
              </p>
            </div>

             <div class="tw-flex-row tw-w-full tw-flex tw-gap-1 tw-my-2">
              <img loading="lazy" src="@/assets/images/start.svg" />
               <img loading="lazy" src="@/assets/images/start.svg" />
                <img loading="lazy" src="@/assets/images/start.svg" />
                 <img loading="lazy" src="@/assets/images/start.svg" />
                  <img loading="lazy" src="@/assets/images/start.svg" />
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-items-center tw-flex lg:tw-hidden tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image-3.png"
                  alt=""
                />
              </div>
              <div class=" tw-w-3/4">
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.aliHazm") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-[11px] tw-font-medium tw-whitespace-normal">
                  {{ $t("home.aliHazm1") }}
                </h4>
              </div>
            </div>

            <div class="tw-mt-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/sapq-logo.png"
                alt=""
              />
            </div>
          </div>



        </div>

    
        
      </div>
      <div
        v-else
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-y-8 tw-gap-x-6">
      

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none '
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-cyberME-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525]    tw-whitespace-normal tw-text-xl">
                “{{ $t("home.nawafAlmusaibeehWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.nawafAlmusaibeeh") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                  {{ $t("home.nawafAlmusaibeeh1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img loading="lazy"
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/NawafAlmusaibeeh.94c725a1.jpg"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.nawafAlmusaibeeh") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                {{ $t("home.nawafAlmusaibeeh1") }}
              </h4>
            </div>
          </div>
        </div>

          <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img loading="lazy" src="@/assets/images/caseStudy/logo-aanaaab.png" alt="" />
            </div>
            <div>
              <p class="!tw-text-[#252525]    tw-whitespace-normal tw-text-xl">
                “{{ $t("home.mouniraJamjomWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/testimonials/img-4-2x.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.mouniraJamjom") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                  {{ $t("home.mouniraJamjom1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img loading="lazy"
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/testimonials/img-4-2x.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.mouniraJamjom") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                {{ $t("home.mouniraJamjom1") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-telgani-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525]    tw-whitespace-normal tw-text-xl">
                “{{ $t("home.aliAlfehaidWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/ali-fhaid.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.aliAlfehaid") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                  {{ $t("home.aliAlfehaid1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img loading="lazy"
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/ali-fhaid.png"
                alt=""
              />
            </div>
            <div >
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.aliAlfehaid") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                {{ $t("home.aliAlfehaid1") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-sabbar-ar-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525]    tw-whitespace-normal tw-text-xl">
                “{{ $t("home.mohammedIbrahimWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-flex lg:tw-hidden tw-items-center tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.mohammedIbrahim1") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                  {{ $t("home.mohammedIbrahim") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img loading="lazy"
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/image.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.mohammedIbrahim1") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                {{ $t("home.mohammedIbrahim") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/testimonials/words-logo-haseel-2x.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525]    tw-whitespace-normal tw-text-xl">
                “{{ $t("home.sultanAlhaddaWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-items-center tw-flex lg:tw-hidden tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image-2.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.sultanAlhadda") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                  {{ $t("home.sultanAlhadda1") }}
                </h4>
              </div>
            </div>
          </div>
          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img loading="lazy"
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/image-2.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.sultanAlhadda") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                {{ $t("home.sultanAlhadda1") }}
              </h4>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-flex-col">
          <div
            class="tw-bg-white tw-rounded-[20px] world-card tw-p-10 tw-relative tw-mb-4"
            :class="[
              $i18n.locale == 'en'
                ? 'lg:tw-rounded-bl-none'
                : 'lg:tw-rounded-br-none',
            ]"
          >
            <div class="tw-mb-2.5">
              <img loading="lazy"
                class="tw-h-[45px] tw-object-cover"
                src="@/assets/images/sapq-logo.png"
                alt=""
              />
            </div>
            <div>
              <p class="!tw-text-[#252525]    tw-whitespace-normal tw-text-xl">
                “{{ $t("home.aliHazmWord") }}”
              </p>
            </div>

            <div
              class="tw-hidden lg:tw-flex tw-start-0 tw--bottom-8 tw-w-0 tw-h-0 tw-absolute"
              :class="[
                $i18n.locale == 'en'
                  ? 'tw-border-l-[0px] tw-border-l-transparent tw-border-t-[40px] tw-border-t-white tw-border-r-[40px] tw-border-r-transparent'
                  : 'tw-border-r-[0px] tw-border-r-transparent tw-border-t-[40px] tw-border-t-white tw-border-l-[40px] tw-border-l-transparent',
              ]"
            ></div>

            <div class="tw-items-center tw-flex lg:tw-hidden tw-mt-4">
              <div class="tw-me-4">
                <img loading="lazy"
                  class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                  src="@/assets/images/image-3.png"
                  alt=""
                />
              </div>
              <div>
                <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                  {{ $t("home.aliHazm") }}
                </h3>
                <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                  {{ $t("home.aliHazm1") }}
                </h4>
              </div>
            </div>
          </div>

          <div class="tw-hidden lg:tw-flex tw-ms-8 tw-items-center">
            <div class="tw-me-4">
              <img loading="lazy"
                class="tw-border-2 tw-border-white tw-rounded-full tw-w-[54px] tw-h-[54px]"
                src="@/assets/images/image-3.png"
                alt=""
              />
            </div>
            <div>
              <h3 class="tw-text-[#474355] !tw-text-base tw-font-medium">
                {{ $t("home.aliHazm") }}
              </h3>
              <h4 class="tw-text-primary !tw-text-sm tw-font-medium tw-whitespace-normal">
                {{ $t("home.aliHazm1") }}
              </h4>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
      featureId4: {
            type: Boolean,
            default: false,
      },
  },
  
};

</script>

<style lang="scss" scoped>


.tw-snap-x {
  overflow-x: scroll;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
    margin: auto;

}


.tw-snap-center {
    scroll-snap-align: center;
        scroll-margin-right: 1rem;
    width: 88%;
    scroll-margin-left: 1rem;
    min-width: 88%;
}

.world-card {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.03);
}

.testimonials2__word:before {
  content: '”';
  color: #252525;
  font-size:35px;
}

.testimonials2__word:after {
  content: '“';
  color: #252525;
  font-size:35px;
  position: absolute;
}

.rtl .testimonials2__word:before {
  content: '”';
  color: #252525;
  font-size:38px;
}

.rtl .testimonials2__word:after {
  content: '“';
  color: #252525;
  font-size:38px;
   position: absolute;
}



.testimonials {
  padding: 128px 0;
  background: linear-gradient(to right, #FAF9FE, #F2F0FB, #E0DBE8, #F2EAE2);
  min-height: 100vh;
  background-position: center;
  background-size: cover;

  @media (max-width: 700px) {
    padding: 50px 0;
  }
}
.testimonials2 {
  padding: 128px 0;
 
  background: linear-gradient(to right, #ededed, #ece8ff, #e3d3fb, #e1e1e1) !important;
  @media (max-width: 700px) {
    padding: 50px 0;
  }
}

.testimonials-bg {
  background-image: url("../../../../assets/images/client/bg_words-shadow-2x.png");
}

</style>
