
<template>
    <div class="invoice-card">
        <div class="mb-3">
            <small class=" md:tw-ms-8">
                #{{ invoiceData.invoice_number }}
            </small>
            <h2  class=" md:tw-ms-8">
              {{ invoiceData.name }}
            </h2>
            <div 
            :class="{'ltr': $i18n.locale == 'ar'}"
            class="gap-20 tw-grid tw-grid-cols-1 md:tw-grid-cols-3 md:tw-divide-x invoice-card__details align-items-center tw-justify-center">
                <article class="tw-flex md:tw-justify-center tw-items-center "
                :class="{'rtl': $i18n.locale == 'ar'}">
                    <img src="@/assets/images/calendar.svg" alt="Calendar">
                    <small>{{ $t('payment.issueDate') }}:</small>
                    <span>{{ invoiceData.issue_date }}</span>
                </article>
                <article   :class="{'rtl': $i18n.locale == 'ar'}" class="tw-flex md:tw-justify-center tw-items-center">
                    <img src="@/assets/images/calendar.svg" alt="Calendar">
                    <small>{{ $t('payment.dueDate') }}:</small>
                    <span>{{ invoiceData.due_date }}</span>
                </article>
                <article  :class="{'rtl': $i18n.locale == 'ar'}" class="tw-flex md:tw-justify-center tw-items-center">
                    <img src="@/assets/images/finance.svg" alt="Finance">
                    <small>{{ $t('payment.totalAmount') }}:</small>
                    <span>{{invoiceData.total_amount_due}} {{invoiceData.currency}}</span>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        invoiceData: {
            type: Object,
            default: null,
        }
    },
};
</script>

<style lang="scss" scoped>

.ltr {
  direction: ltr;
}

.rtl {
    direction:  rtl;
}

.invoice-card {
    background-color: #fff;
    max-width: 900px;
    padding: 20px 45px;
    border-radius: 15px;
    margin: 15px auto;
    box-shadow: 5px 5px 15px 0 #eee;
    text-align: start;
     @media (max-width: 768px) { 
        padding: 20px;
     }

    h2 {
        font-size: 24px;
    }

    small {
        font-size: 15px;
        color: rgba(159, 157, 157, 1);
        padding-inline-end: 7px;
    }

    &__details {
        margin-top: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start!important;
        }

        img {
            width: 20px;
            display: inline;
            margin-inline-end: 5px;
        }
    }
}

.gap-20 {
    gap: 20px;
}
</style>
  