<template>
 <span class="required-field-wrapper pe-2">
    <img loading="lazy" class="me-1" src="../../../assets/images/ic_warning.svg" width="18"/>
    <span class="required-error mt-1 ">{{ text }}</span>
 </span>
</template>

<script>
import "./requiredField.scss"
export default {
  props: {
    text: {
      type: String,
      default: "Required field"
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>