<template>
  <div id="availability" class="availability-wrapper" tabindex="0">
    <div
      class="row time-skill font-size-14 mb-2 align-items-center justify-content-between tw-max-w-full"
    >
      <div class="col-4 text-start padding-r">
        <h4 class="mb-0 font-size-14 padding-r">{{ $t("signUp.fullTime") }}</h4>
      </div>
      <div class="col-8 text-end text-grey d-flex justify-content-end px-0">
        <div
          v-if="is_edit.fullTime"
          class="col-12 px-0 d-flex align-items-center"
        >
          <Select
            inputStyle="font-size-14"
            :placeholder="$t('talent.selectAvailability')"
            :validationField="$v.availability.full_time_status"
            :selectOptions="availabilityOptionsLocaliztion"
            hideLabel="true"
          />
          <p
            class="ok-button mb-0"
            @click="
              (e) =>
                handleValueSelected(e, 'fullTime', false, 'full_time_status')
            "
          >
            {{ $t('talent.ok') }}
          </p>
        </div>
        <div v-else class="d-flex align-items-center justify-content-end">
          <p class="mb-0 font-size-14" v-if="full_time_status !== null">
            {{ getAvailabilityTimeName(full_time_status) }}
          </p>
          <p class="mb-0 font-size-14" v-else> {{$t('talent.notSelected')}} </p>
          <i
            v-tooltip="{
              class: 'availability-tooltip',
              content: $t('talent.youAlreadyAssignedToFullTimeContract'),
              visible: !allow_edit_availability.allow_full_time,
            }"
            class="edit-icon"
            @click="
              (e) =>
                allow_edit_availability.allow_full_time
                  ? handleEditView(e, 'fullTime', true)
                  : false
            "
          >
            <EditIcon />
          </i>
        </div>
      </div>
    </div>

    <div class="row time-skill mb-2 align-items-center justify-content-between tw-max-w-full">
      <div class="col-4 padding-r text-start">
        <h4 class="mb-0 font-size-14 padding-r">{{ $t("signUp.partTime") }}</h4>
      </div>
      <div class="col-8 text-end text-grey d-flex justify-content-end px-0">
        <div
          v-if="is_edit.partTime"
          class="px-0 col-12 d-flex align-items-center"
        >
          <Select
            :placeholder="$t('talent.selectAvailability')"
            :validationField="$v.availability.part_time_status"
            :selectOptions="availabilityOptionsLocaliztion"
            hideLabel="true"
          />
          <p
            class="ok-button mb-0"
            @click="
              (e) =>
                handleValueSelected(e, 'partTime', false, 'part_time_status')
            "
          >
             {{ $t('talent.ok') }}
          </p>
        </div>
        <div v-else>
          <div class="d-flex align-items-center justify-content-end">
            <div v-if="part_time_availability">
              <p class="mb-0 font-size-14" v-if="part_time_status !== null">
                {{ getAvailabilityTimeName(part_time_status) }}
              </p>
              <p class="mb-0 font-size-14" v-else>
               {{$t('talent.notSelected')}}  
              </p>
            </div>
            <p class="mb-0 font-size-14" v-else>
               {{$t('availabilityOptionsTalantProfile.unavailable')}} 
            </p>
            <i
              v-tooltip="{
                content: $t('talent.youAlreadyAssignedToPartTimeContract'),
                visible: !allow_edit_availability.allow_part_time,
              }"
              class="edit-icon"
              @click="
                (e) =>
                  allow_edit_availability.allow_part_time
                    ? handleEditView(e, 'partTime', true)
                    : false
              "
            >
              <EditIcon />
            </i>
          </div>
        </div>
      </div>
    </div>
    <warningBeforeTalentUpdate v-if="showWarningModal && Number(profile.confirmed_selections_count) > 0" :is-open="showWarningModal" @close="showWarningModal = false" :is-profile="true" />
  </div>
</template>

<script>
import "./availability.scss";
import EditIcon from "../icons/editIcon.vue";
import { mapGetters } from 'vuex';
import { availabilityOptions } from "../../../../../static/AvailabilityOptions";
import Select from "../../../../../components/shared/formControls/select/select.vue";
import warningBeforeTalentUpdate from "@/components/warningBeforeTalentUpdate/index.vue";
export default {
  props: [
    "full_time_status",
    "part_time_status",
    "part_time_availability",
    "allow_edit_availability",
    "talentEmail",
  ],
  components: { EditIcon, Select, warningBeforeTalentUpdate },
  data() {
    return {
      availabilityOptions,
      showWarningModal: false,
      availability: {
        full_time_status: this.full_time_status,
        part_time_status: this.part_time_status,
      },
      is_edit: {
        fullTime: false,
        partTime: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: "talentProfile/getProfileData",
    }),
    availabilityOptionsLocaliztion() {
      return this.availabilityOptions.map( Option =>   { return {id: Option.id, name: this.$t(`availabilityOptions.${[Option.name]}`)} }  );
    }
  },
  validations: {
    availability: {
      full_time_status: {},
      part_time_status: {},
    },
  },
  methods: {
    handleHideField() {
      this.is_edit.fullTime = false;
    },
    handleEditView(e, availabilityType, isEdit) {
      this.is_edit[availabilityType] = isEdit;
      if (isEdit) {
        this.availability = {
          full_time_status: this.full_time_status,
          part_time_status: this.part_time_status,
        };
      }
    },
    getAvailabilityTimeName(id) {
      return  this.$t(`availabilityOptions.${[this.availabilityOptions.find((ele) => ele.id === id)?.name]}`) ;
   
    },
    handleValueSelected(e, availabilityType, isEdit, availabilityValue) {
      let payload = {};
      if (
        this.availability[availabilityValue] ||
        this.availability[availabilityValue] === 0
      ) {
        this.handleEditView(e, availabilityType, isEdit);
        payload[availabilityValue] = this.availability[availabilityValue];
        this.$store.dispatch(
          "talentProfile/updateProfileAvailability",
          payload
        ).then(() => {
          this.showWarningModal = true;
        });
      } else if (
        this.availability[availabilityValue] === null &&
        this[availabilityValue]
      ) {
        this.handleEditView(e, availabilityType, isEdit);

        payload[availabilityValue] = this[availabilityValue];


        if (window.gtag) {
          window.gtag("event", "talent_updated_availability", {
            talent_email: this.talentEmail,
          });
        }

        this.$store.dispatch(
          "talentProfile/updateProfileAvailability",
          payload
        ).then(() => {
          this.showWarningModal = true;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.availability-tooltip {
  z-index: 10000000001!important;
}
</style>
