<template>
    <div id="bringYourBuddy" class="bringYourBuddy">
        <div class="container">
            <div class="bringYourBuddy__wrapper">
                <div class="bringYourBuddy__content">
                    <h3>
                        <span>{{ $t('bringBuddy.bringYour') }}</span>
                        <span class="highlight">{{ $t('bringBuddy.buddy') }}</span>
                    </h3>
                    <button
                        type="button"
                        class="btn btn-primary btn-md !tw-hidden lg:!tw-flex tw-justify-center"
                        @click="() => handleRedirection('/bring-buddy')"
                    >
                        {{ $t("bringBuddy.viewMore") }}
                    </button>
                </div>
                <div
                    class="bringYourBuddy__poster tw-w-full "
                >
                    <img loading="lazy"
                        :src="require($i18n.locale == 'en' ? '@/assets/images/bring-buddy.png' : '@/assets/images/talent/img_bring-your-buddy-ar.png')"
                    />
                </div>
                  <button
                        type="button"
                        class="btn btn-primary btn-md !tw-mt-4  !tw-flex lg:!tw-hidden tw-justify-center !tw-rounded-full tw-mx-auto"
                        @click="() => handleRedirection('/bring-buddy')"
                    >
                        {{ $t("bringBuddy.viewMore") }}
                    </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        handleRedirection(path) {
            this.$router.push({ path });
        },
    },
};
</script>

<style lang="scss" scoped>
.bringYourBuddy {
    &__wrapper {
        padding: 100px;
        border-radius: 32px;
        background-color: #422E87;
        margin: 100px 0;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 50px;
        @media (max-width: 992px) {
            margin: 44px 0;
            padding: 30px;

         }

    }

    h3 span {
        display: block;
        font-size: 2.5rem;
        font-weight: 700;

        &.highlight {
          color: #FFC629;
        }
    }

    button {
        margin-top: 100px;
        width: 265px;
    }

    &__poster {
        img {
            max-width: 100%;
            object-fit: contain;
        }
    }
}

@media (max-width: 992px) {
    .bringYourBuddy__wrapper {
        flex-direction: column;
            align-items: start;
    }
}
</style>