<template>
  <div class="availability-wrapper">
    <div class="accordion" id="accordionAvailability" >
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed d-flex justify-content-center"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseAvailabilit"
            aria-expanded="false"
            aria-controls="collapseAvailabilit"
          >
            <span
              v-if="
                getAvalilabilityStatus(full_time_status, part_time_status) ==
                'locked'
              "
              class="text-center d-block"
            >
              <span class="available-text">
                {{ $t("sharebleLink.lockedByTheTalent") }}
              </span>
            </span>
            <span
              v-if="
                getAvalilabilityStatus(full_time_status, part_time_status) !=
                'locked'
              "
            >
              <span class="available-text">
                {{ $t("sharebleLink.available") }}</span
              >
              <span class="available-value">
                {{ getAvalilabilityStatus(full_time_status, part_time_status) }}
              </span>
            </span>
          </button>
        </h2>
        <div
          id="collapseAvailabilit"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionAvailability"
        >
          <div class="accordion-body">
            <div
              class="mb-2 d-flex justify-content-between time-skill"
              v-if="
                getAvailabilityTimeName(full_time_status) !== 'locked' ||
                getAvailabilityTimeName(part_time_status) == 'locked'
              "
            >
              <div class="text-start">
                <h4 class="mb-0">
                  {{ $t("sharebleLink.fullTime") }}
                </h4>
              </div>
              <div class="text-end text-grey">
                <p class="mb-0 text-end">
                  {{ getAvailabilityTimeName(full_time_status) }}
                </p>
              </div>
            </div>

            <div
              class="d-flex justify-content-between time-skill"
              v-if="
                getAvailabilityTimeName(part_time_status) !== 'locked' ||
                getAvailabilityTimeName(full_time_status) == 'locked'
              "
            >
              <div class="text-start">
                <h4 class="mb-0">
                  {{ $t("sharebleLink.partTime") }}
                </h4>
              </div>
              <div class="text-end text-grey">
                <p class="mb-0 text-end">
                  {{ getAvailabilityTimeName(part_time_status) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { availabilityOptions } from "@/static/AvailabilityOptions.js";
import "./availability.scss";
export default {
  components: {},
  props: ["full_time_status", "part_time_status"],
  data() {
    return {
      availabilityOptions,
    };
  },
  computed: {
    console: () => console,
  },
  methods: {
    getAvailabilityTimeName(id) {
      if (id !== null) {
        return this.$t(
          `availabilityOptions.${[
            this.availabilityOptions.find((ele) => ele.id === id)?.name,
          ]}`
        );
      } else {
        return this.$t(`availabilityOptions.locked`);
      }
    },
    getAvalilabilityStatus(fullTime, partTime) {
      if (fullTime == null && partTime == null) {
        return this.getAvailabilityTimeName(null);
      } else if (fullTime > partTime) {
        if (partTime != 0 && partTime != null) {
          return this.getAvailabilityTimeName(partTime);
        } else {
          return this.getAvailabilityTimeName(fullTime);
        }
      } else {
        if (fullTime != 0 && fullTime != null) {
          return this.getAvailabilityTimeName(fullTime);
        } else {
          return this.getAvailabilityTimeName(partTime);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.availability-wrapper {
  button.accordion-button {
    padding: 10px !important;
  }

  .availability-shadow{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 29px 16px;
  }

  .accordion {
    width: 80%;
    margin: auto;

    @media (max-width: 1024px) {
      width: 100%;

      .time-skill p {
        font-size: 14px;
      }

      .availability-wrapper .accordion-button,
      .availability-wrapper .collapsed {
        padding: 12px !important;
      }
    }

    @media (max-width: 576px) {
      width: 75%;

      .available-value {
        font-size: 15px;
        margin-left: 6px !important;
      }

      .availability-wrapper .accordion-button,
      .availability-wrapper .collapsed {
        padding: 10px !important;
      }
    }
  }

  .accordion-item {
    border: none;
    box-shadow: 0 2px 15px 0 #0000000f;
  }

  .available-value {
    margin-left: 6px !important;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }

  .available-text {
    font-size: 15px;
    color: #292929;
    font-weight: 500;
    @media (max-width: 1024px) {
      font-size: 15px;
    }
  }

  .accordion-button {
    background-color: #fff;
  }
}
.shareable-link__wrapper h4 {
  font-size: 15px;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
  @media (max-width: 576px) {
    font-size: 15px;
  }

  .time-skill p {
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}

.accordion-button:not(.collapsed)::after {
  top: -6px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17px' height='10px' viewBox='0 0 17 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eic_arrow-dropdown%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01.24.profile-mobile-version' transform='translate(-325.000000, -586.000000)' fill='%23121B21' fill-rule='nonzero'%3e%3cg id='Group-18-Copy' transform='translate(17.000000, 536.000000)'%3e%3cg id='Group-20' transform='translate(266.000000, 37.000000)'%3e%3cpolygon id='ic_arrow-dropdown' transform='translate(50.125000, 18.000000) scale(1, -1) translate(-50.125000, -18.000000) ' points='50.125 13 58.25 23 42 23'%3e%3c/polygon%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 14px;
  transition: all;
  top: 7px;
  transform: rotate(0deg);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17px' height='10px' viewBox='0 0 17 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eic_arrow-dropdown%3c/title%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01.24.profile-mobile-version' transform='translate(-325.000000, -586.000000)' fill='%23121B21' fill-rule='nonzero'%3e%3cg id='Group-18-Copy' transform='translate(17.000000, 536.000000)'%3e%3cg id='Group-20' transform='translate(266.000000, 37.000000)'%3e%3cpolygon id='ic_arrow-dropdown' transform='translate(50.125000, 18.000000) scale(1, -1) translate(-50.125000, -18.000000) ' points='50.125 13 58.25 23 42 23'%3e%3c/polygon%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
</style>
