<template>
  <div
    class="tab-pane fade"
    id="pills-Experience"
    role="tabpanel"
    aria-labelledby="pills-Experience-tab"
  >
    <div class="d-flex align-items-center justify-content-between mt-4 mb-3">
      <h3 class="headerBorder mb-0">
        <span>
          {{ $t("signUp.experience") }}
        </span>
      </h3>
      <span class="add-tab-icon" id="experiences-edit-button" @click="openModal(null)"> </span>
    </div>
    <div class="exp-boxes">
      <div
        class="exp-box mb-4"
        v-for="experience in experiences"
        :key="experience.id"
      >
        <div
          class="position-relative d-flex align-items-center justify-content-between"
        >
          <div>
            <span class="d-block text-grey mb-1">
              ({{ getMonth(experience.start_month) }}
              {{ experience.start_year }} -
              <span v-if="experience.currently_work_in == 1">{{$t('sharebleLink.present')}}</span>
              <span v-if="experience.currently_work_in == 0"
                >{{ getMonth(experience.end_month) }}
                {{ experience.end_year }}</span
              >
              )
            </span>
            <h3 class="mb-2">
              {{ experience.title }}
              <span class="company-name"> {{ experience.company }} </span>
            </h3>
          </div>
          <div class="ms-2">
            <button
              type="button"
              class="more-tab-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ></button>
            <ul class="dropdown-menu dropdown-menu-end small-more-pop">
              <li @click="openModal(experience.id)">
                <button class="dropdown-item" type="button">
                     {{ $t("matchedTalents.edit") }}
                </button>
              </li>
              <li @click="openDeleteModal(experience.id)">
                <button class="dropdown-item" type="button">
                

                 {{ $t("talentWizard.delete") }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <p class="mb-0">
          {{ experience.description }}
        </p>
      </div>
    </div>
    <Modal
      v-if="isModalOpen"
      @update="close($event)"
      :heading="modalHeading"
      className="experience-modal modal-forms-responsive"
      :description="$t('talentWizard.fillInDataRetaledToYourPreviousJobs') "
      :handleSubmit="() => handleSubmitExperienceForm()"
      :isSubmitDisabled="isDisabled"
    >
      <template :v-slot="experience - form">
        <ExperienceForm
          ref="experienceForm"
          :experienceDetails="experienceDetails"
          @handleCloseModal="handleCloseModal($event)"
          @handleIsDisabled="handleIsDisabled($event)"
      /></template>
    </Modal>

    <Modal
      v-if="isDeleteModalOpen"
      @update="closeDeleted($event)"
      className="delete-projects-modal"
      :submitText="$t('talentWizard.delete')"
      :handleSubmit="() => handleDeleteExperience()"
    >
      <template :v-slot="delete -modal - body"
        ><deleteModalBody
          ref="deleteExperience"
          @handleCloseModal="handleCloseModal($event)"
      /></template>
    </Modal>
    <p class="no-data-text" v-if="experiences.length == 0">
      {{ $t("talent.noData") }}
    </p>
  </div>
</template>

<script>
import deleteModalBody from "../deleteModalBody/deleteModalBody.vue";
import Modal from "../../../../../components/shared/modal/modal.vue";
import ExperienceForm from "../experienceForm/experienceForm.vue";
import months from "../../../../../static/Months";

export default {
  props: ["experiences", "talentEmail"],
  components: { Modal, deleteModalBody, ExperienceForm },
  data() {
    return {
      months,
      isModalOpen: false,
      isDeleteModalOpen: false,
      targetExperienceId: null,
      experienceStartDateError: "",
      experienceFutureDateError: "",
      experienceEndDateError: "",
      experienceDetails: {
        id: null,
        title: "",
        company: "",
        description: "",
        currently_work_in: 0,
        start_year: "",
        start_month: "",
        end_year: "",
        end_month: "",
      },
      modalHeading: "Edit Experience",
      isDisabled: false,
    };
  },
  methods: {
    getMonth(id) {
      const filteredMonth = this.months.filter((month) => month.id == id);
      return filteredMonth[0].name;
    },
    openModal(id = null) {
      this.resetFormData();
      if (id !== null) {
        this.modalHeading = this.$t('talentWizard.editExperience') 
        this.getExperienceDetails(id);
        this.targetExperienceId = id;
        this.isModalOpen = true;
      } else {
        if (this.experiences?.length < 25) {
          this.modalHeading = this.$t('talentWizard.addExperience') 
          this.isModalOpen = true;
        }
      }
    },
    openDeleteModal(id) {
      this.targetExperienceId = id;
      this.isDeleteModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
      this.isDeleteModalOpen = false;
      this.$emit('experiences-updated');
    },
    close() {
      this.isModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    closeDeleted() {
      this.isDeleteModalOpen = false;
      setTimeout(() => {
        this.$emit('closed'); 
      }, 100);
    },
    handleSubmitExperienceForm() {

      if (window.gtag) {
        window.gtag("event", "talent_updated_experiences", {
          talent_email: this.talentEmail,
        });
      }

      this.$refs.experienceForm.handleSubmitExperienceForm();
    },
    handleDeleteExperience() {
      this.$refs.deleteExperience.handleDelete(
        this.targetExperienceId,
        "experience"
      );
    },
    getExperienceDetails(id) {
      this.$store
        .dispatch("talentProfile/getExperiencDetailsRequest", id)
        .then((res) => {
          this.experienceDetails.id = res.data.data.id;
          this.experienceDetails.title = res.data.data.title;
          this.experienceDetails.company = res.data.data.company;
          this.experienceDetails.description = res.data.data.description;
          this.experienceDetails.currently_work_in =
            res.data.data.currently_work_in;
          this.experienceDetails.start_year = res.data.data.start_year;
          this.experienceDetails.start_month = res.data.data.start_month;
          this.experienceDetails.end_year = res.data.data.end_year;
          this.experienceDetails.end_month = res.data.data.end_month;
          this.isModalOpen = true;
        });
    },
    resetFormData() {
      this.experienceStartDateError = "";
      this.experienceFutureDateError = "";
      this.experienceEndDateError = "";
      this.experienceDetails = {
        id: null,
        title: "",
        company: "",
        description: "",
        currently_work_in: 0,
        start_year: "",
        start_month: "",
        end_year: "",
        end_month: "",
      };
    },
    handleIsDisabled(status) {
      this.isDisabled = status;
    },
  },
};
</script>

<style lang="scss" scoped></style>
