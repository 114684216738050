<template>
  <div class="payment-wrapper position-relative">
    <div class="container text-center innerPage position-relative">
      <Heading :is-loading="isloading" v-if="!isPaid" />
      <Invoice v-if="!isloading && !isPaid" :invoiceData="invoiceData" />

      <div class="noon__Wrapper tw-px-5 tw-py-9" v-if="isPaid">
        <div
          class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-my-[10rem]"
        >
          <img
            class="tw-w-[106px] tw-h-[106px]"
            src="@/assets/images/payment/ic_Celebrate.svg"
            alt=""
            srcset=""
          />

          <h2
            class="tw-text-[#1b1b1b] tw-text-[33px] tw-font-extrabold tw-mt-7"
          >
            {{ $t("payment.allIsDone") }}
          </h2>

          <p class="tw-text-[#1b1b1b] tw-text-[17px] tw-font-semibold tw-mt-2">
            {{ $t("payment.thereIsNoInvoiceToBePaid") }}
          </p>
        </div>
      </div>

      <div
        class="noon__Wrapper tw-flex tw-justify-center tw-items-center"
        v-if="!isPaid"
      >
        <skeleton-loader-vue
          v-if="!loadIframe"
          type="circle"
          :width="100"
          :height="100"
          animation="pulse"
          rounded
        />
        <iframe
          v-if="loadIframe"
          id="noonpayments"
          width="100%"
          height="100%"
        ></iframe>
      </div>

      <PaymentSuccess
        v-if="isOpenPaymentSuccess"
        :isOpen="isOpenPaymentSuccess"
        @update="handlePaymentSuccess($event)"
        :invoiceData="invoiceData"
      />
      <PaymentFailure
        v-show="isOpenPaymentFailure"
        :isOpen="isOpenPaymentFailure"
        :invoiceData="invoiceData"
        :failureStates="failureStates"
        @update="isOpenPaymentFailure = $event"
        @tryAgain="tryAgain"
      />
      <ProcessingPayment
        v-if="isOpenPaymentProcessing"
        :isOpen="isOpenPaymentProcessing"
        :invoiceData="invoiceData"
        @update="isOpenPaymentProcessing = $event"
      />
    </div>
  </div>
</template>

<script>
import Pusher from "pusher-js";
import Heading from "./components/heading";
import Invoice from "./components/invoice-card";
import PaymentSuccess from "./components/payment-success-model";
import PaymentFailure from "./components/payment-failed-model";
import ProcessingPayment from "./components/processing-payment";

import { scrollToTop } from "../../../utils/utils";
import toast from "@/services/toast";

import "./payment.scss";

export default {
  components: {
    Heading,
    Invoice,
    PaymentSuccess,
    PaymentFailure,
    ProcessingPayment,
  },
  created() {
    this.initializePusherInstance();
    this.getInvoices();
  },
  mounted() {
    // Listen for online/offline events
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  data: () => {
    return {
      isloading: false,
      loadIframe: false,
      invoiceData: null,
      orderId: null,
      isOpenPaymentSuccess: false,
      isOpenPaymentFailure: false,
      isOpenPaymentProcessing: false,
      isPaid: false,
      online: navigator.onLine,
      failureStates: "UNSUCCESSFUL",
      Pusher: null,
    };
  },
  watch: {
    online: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue == false) {
          this.loadIframe = false;
          this.failureStates = "isOffLine";
          this.isOpenPaymentFailure = true;
        } else {
          setTimeout(() => {
            this.failureStates = "online";
          }, 1000);
        }
      },
    },
    "$i18n.locale": {
      immediate: true,
      deep: true,
      handler() {
        this.loadIframe = false;
        this.tryAgain();
      },
    },
  },
  methods: {
    scrollToTop,
    initializePusherInstance() {
      try {
        const pusherOptions = {
          cluster: process.env.VUE_APP_PUSHER_CULSTER,
          wsHost: process.env.VUE_APP_PUSHER_HOST,
          wsPort: process.env.VUE_APP_PUSHER_PORT,
          forceTLS: process.env.VUE_APP_PUSHER_TLS === "true",
          encrypted: false,
          enabledTransports: ["ws", "wss"],
        };
        this.Pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, pusherOptions);
      } catch (error) {
        console.error("Error initializing Pusher:", error);
      }
    },
    updateOnlineStatus() {
      this.online = navigator.onLine;
    },
    tryAgain() {
      if (!this.online) {
        this.loadIframe = false;
        this.failureStates = "isOffLine";
        this.isOpenPaymentFailure = true;
        return;
      }
      this.isOpenPaymentFailure = false;
      this.failureStates = "UNSUCCESSFUL";
      this.isloading = true;
      this.getInvoices();
    },
    handleMessage(message) {
      if (this.orderId !== message.order_id) return;
      if (message.status === "UNSUCCESSFUL") {
        this.failureStates = "UNSUCCESSFUL";
        this.loadIframe = false;
        this.isOpenPaymentFailure = true;
      } else if (message.status === "PAID") {
        this.loadIframe = false;
        this.isOpenPaymentSuccess = true;
      } else if (message.status === "ONGOING") {
        this.loadIframe = false;
        this.isOpenPaymentProcessing = true;
      } else if (message.status === "ABORTED") {
        this.failureStates = "ABORTED";
        this.loadIframe = false;
        this.isOpenPaymentFailure = true;
      }
    },
    getInvoices() {
      this.isloading = true;
      const id = this.$route.params.id;
      this.axios
        .get(`/api/invoices/${id}`)
        .then((result) => {
          this.invoiceData = result.data.data;

          this.Pusher.subscribe("invoices." + this.invoiceData.invoice_id).bind(
            "order.updated",
            this.handleMessage
          );

          if (this.invoiceData.status === "AUTHORISED") {
            this.createNewPayment();
          } else if (this.invoiceData.status === "PAID") {
            this.isPaid = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isloading = false;
        });
    },
    loadExternalScript(src, callback) {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = callback;
      document.head.appendChild(script);
    },
    handleScriptLoad() {
      // External script is fully loaded and accessible
      console.log("External script is loaded.");

      // let self = this;
      const ResponseCallBack = function (argument) {
        console.log(argument);

        //self.getOrder(argument.orderId);
        // there is a PENDING status, will be handled later
      };

      const settings = {
        Checkout_Method: 3,
        SecureVerification_Method: 1,
        Call_Back: ResponseCallBack,
        Frame_Id: "noonpayments",
      };

      try {
        // eslint-disable-next-line no-undef
        ProcessCheckoutPayment(settings);
      } catch (error) {
        this.isOpenPaymentFailure = true;
      }
    },
    createNewPayment() {
      const id = this.$route.params.id;
      this.axios
        .post(`/api/invoices/${id}/orders`)
        .then((result) => {
          if (result.data.data.status === "NEW") {
            const checkoutURL = result.data.data.js_url;
            this.orderId = result.data.data.order_id;
            this.loadIframe = true;
            this.loadExternalScript(checkoutURL, this.handleScriptLoad);
          } else if (result.data.data.status === "ONGOING") {
            // handle pending screen
            this.isOpenPaymentProcessing = true;
          } else if (!result.data.success) {
            toast.error(result.data.message);
          }
        })
        .catch((err) => {
          err;
        });
    },
    getOrder(id) {
      const invoiceId = this.$route.params.id;
      this.axios
        .get(`api/invoices/${invoiceId}/orders/${id}`)
        .then((result) => {
          if (result.data.data.status === "UNSUCCESSFUL") {
            this.loadIframe = false;
            this.isOpenPaymentFailure = true;
          } else if (result.data.data.status === "PAID") {
            this.loadIframe = false;
            this.isOpenPaymentSuccess = true;
          } else if (result.data.data.status === "ONGOING") {
            this.loadIframe = false;
            this.isOpenPaymentProcessing = true;
          }
        })
        .catch((err) => {
          err;
        });
    },
    handlePaymentSuccess($event) {
      this.isOpenPaymentSuccess = $event;
      this.isPaid = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.innerPage {
  padding-top: 30px;
  padding-bottom: 100px;
}
</style>
