<template>
    <transition name="modal-fade">
        <div class="modal align-items-center" role="dialog" aria-labelledby="modalTitle"
            aria-describedby="modalDescription" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content tw-bg-primary">
                   

                    <div class="tw-pb-4 modal-body no-border">
                        <div class="row tw-flex tw-justify-center">
                            <div class=" tw-p-0">
                                <h1 class="tw-text-secondary tw-text-2xl tw-font-bold tw-leading-10"> {{ $t('requestInterview.startAnInterviewProcessTitle1')  }} <span
                                        class=" tw-text-white">{{ $t('requestInterview.startAnInterviewProcessTitle2')  }} </span> </h1>
                                <p class="tw-text-[#aba2c9] tw-text-sm tw-font-medium tw-leading-7 tw-mb-3 ">
                                    {{ $t('requestInterview.startAnInterviewProcessParagraph') }}
                                </p>
                            </div>
                            <hr class=" tw-border-white" />
                            <div class="tw-grid md:tw-grid-cols-3 tw-grid-cols-1 tw-w-full tw-gap-6 tw-p-0 tw-my-6 md:tw-my-0 md:tw-mb-6">

                                <div class=" tw-flex tw-flex-col tw-relative tw-z-10 tw-ps-3">
                                <img src="@/assets/images/talent/bg_howITWork-1.svg" :class="$i18n.locale == 'en'? 'tw-left-0 ' : 'tw-right-0 ' "  class=" tw-absolute  tw-rotate-0 tw--top-4 md:tw-top-[-1.5rem] tw-z-0" />
                                    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
                                         <div class=" tw-w-12 tw-border-b-2 tw-border-secondary tw-border-solid tw-relative">
                                            <span
                                                class=" tw-text-secondary tw-text-5xl tw-font-bold tw-leading-10">1</span>
                                        </div>
                                        <div>
                                            <img class="tw-hidden md:tw-flex tw-h-6 tw-w-6" :class="$i18n.locale == 'en'? '' : ' tw-rotate-180 ' " src="@/assets/images/talent/Arrow-circle.svg" />
                                        </div>
                                    </div>
                                    <div class=" tw-w-full tw-mt-3">
                                        <p
                                            class="tw-text-[#f8f8f8] tw-text-base tw-font-normal tw-leading-normal md:tw-w-3/4 tw-w-full  ">
                                           {{ $t('requestInterview.startAnInterviewProcessStep1') }}
                                        </p>
                                    </div>
                                    <div class="md:tw-hidden tw-flex tw-justify-center tw-mt-4">
                                        <img class="tw-h-6 tw-w-6 tw-rotate-90"  src="@/assets/images/talent/Arrow-circle.svg" />
                                    </div>
                                </div>

                                <div class=" tw-flex tw-flex-col tw-relative tw-z-10 tw-ps-3">
                                    <img src="@/assets/images/talent/bg_howITWork-1.svg"  :class="$i18n.locale == 'en'? 'tw-left-0 ' : 'tw-right-0 ' " class="tw-absolute  tw-z-0 tw-rotate-0 tw--top-4 md:tw-top-[-1.5rem]" />
                                    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
                                        <div class=" tw-w-12 tw-border-b-2 tw-border-secondary tw-border-solid tw-relative">
                                            <span
                                                class=" tw-text-secondary tw-text-5xl tw-font-bold tw-leading-10 tw-relative tw-z-10">2</span>
                                        </div>
                                        <div>
                                            <img class=" tw-hidden md:tw-flex tw-h-6 tw-w-6"  :class="$i18n.locale == 'en'? '' : 'tw-rotate-180 ' " src="@/assets/images/talent/Arrow-circle.svg" />
                                        </div>
                                    </div>
                                    <div class=" tw-w-full tw-mt-3">
                                        <p
                                            class="tw-text-[#f8f8f8] tw-text-base tw-font-normal tw-leading-normal md:tw-w-3/4 tw-w-full  ">
                                             {{ $t('requestInterview.startAnInterviewProcessStep2') }}
                                        </p>
                                    </div>
                                    <div class="md:tw-hidden tw-flex tw-justify-center tw-mt-4">
                                        <img class="tw-h-6 tw-w-6 tw-rotate-90 "  src="@/assets/images/talent/Arrow-circle.svg" />
                                    </div>
                                </div>


                                <div class=" tw-flex tw-flex-col tw-relative tw-z-10 tw-ps-3">
                                    <img src="@/assets/images/talent/bg_howITWork-1.svg"  :class="$i18n.locale == 'en'? 'tw-left-0 ' : 'tw-right-0 ' " class=" tw-absolute  tw--rotate-0 tw--top-4 md:tw-top-[-1.5rem] tw-z-0" />
                                    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between">
                                         <div class=" tw-w-12 tw-border-b-2 tw-border-secondary tw-border-solid tw-relative">
                                            <span
                                                class=" tw-text-secondary tw-text-5xl tw-font-bold tw-leading-10 tw-relative tw-z-10">3
                                            </span>
                                        </div>

                                    </div>
                                    <div class=" tw-w-full tw-mt-3">
                                        <p
                                            class="tw-text-[#f8f8f8] tw-text-base tw-font-normal tw-leading-normal md:tw-w-3/4 tw-w-full  ">
                                             {{ $t('requestInterview.startAnInterviewProcessStep3') }}
                                        </p>
                                    </div>
                                </div>


                            </div>
                            <hr class=" tw-border-white tw-mb-0" />

                        </div>
                    </div>
                    <div class="tw-justify-center  tw-gap-2 md:tw-justify-end tw-p-0 !tw-flex !tw-flex-row ">
                        <button type="button"
                            class="btn  !tw-flex tw-justify-center cancelbtn tw-min-w-[10rem] btnstyleUpdate tw-text-white hover:tw-text-white tw-w-auto "
                            data-bs-dismiss="modal" @click="close">

                            {{ $t('requestInterview.cancel') }}
                        </button>
                        <button :disabled="isLoader" type="button" class="btn tw-justify-center tw-min-w-[10rem] !tw-flex btn-primary saveBtn !tw-px-10 tw-w-auto" @click="save()">
                            
                            {{ $t('requestInterview.start') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: "StartInterviewProcessModal",
    props: ["isOpen",],
    data() {
        return {
            isLoader: false,
        };
    },
    watch: {
        isOpen: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue) {
                    document.body.classList.add("modal-open");
                } else {
                    document.body.classList.remove("modal-open");
                }
            },
        },
    },
    methods: {
        save() {
            const payload = {
                "sharable_code": this.$route.params.id,
            }
            this.isLoader = true;
            this.axios.post('api/client/selections', payload).then((res) => {
                
             this.$router.push({ path: '/sq/client/interview-details/' + res.data.data.id });
               // this.$emit("intervewRequested", payload);
                this.close();
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.isLoader = false;
            });

        },
        close() {
            document.body.classList.remove("modal-open");
            this.$emit("update", false);
        },
    },
};
</script>

<style scoped>
.modal-dialog {
    width: 92%;
}
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 55rem;
        margin: 1.75rem auto;
        width: 55rem;
    }
}

.modal-body.no-border {
      overflow-x: hidden;
}


</style>


<style>
.modal-open {
    overflow: hidden;
}

.book-meeting-mb {
    min-height: 500px;

    @media (max-width: 786px) {
        margin-bottom: 5rem;
    }
}

.book-meeting-mb .calendly {
    min-height: 500px;
    height: 500px !important;
}

.book-meeting-mb .calendly iframe {
    min-height: 500px;
}

.meetBox .w-full {
    width: 100% !important;
}
</style>
