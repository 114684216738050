<template>
  <div class="wizard-basicinfo">
    <div class="mb-5 row">
      <StepperNumbers
        :withError="
          $v.formData.talent_role_id.$error ||
          ($v.formData.BE_lang.$model == null &&
            $v.formData.BE_lang.$invalid) ||
          ($v.formData.FE_lang.$model == null && $v.formData.FE_lang.$invalid)
        "
        :currentPosition="currentPosition"
        :number="'1'"
      />

      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(1)">
        <h5 class="mb-3 required">
          {{ $t("talentWizard.whatIsYourCurrentRole") }}
        </h5>
        <v-select
          v-model="$v.formData.talent_role_id.$model"
          class="style-chooser"
          :placeholder="$t('talentWizard.selectCurrentRole')"
          :options="rolesOptions"
          label="name"
          :selectable="(option) => !option.hasOwnProperty('group')"
          :reduce="(name) => name.id"
        >
          <div slot="no-options">
            {{ $t("talent.sorryNoMatchingOptions") }}
          </div>
          <template #option="{ group, name }">
            <div v-if="group" class="group">{{ group }}</div>
            {{ name }}
          </template>
        </v-select>
        <!-- ********* Validation Error*********** -->
        <div class="error-select-validation-message">
          <VulidateError
            :validationField="$v.formData.talent_role_id"
            :params="[]"
          />
        </div>
        <!-- ************************************* -->
      </div>
    </div>

    <div v-if="isBackend() || isFrontend() || isFullStack()" class="mb-5 row">
      <div class="col-lg-1 col-sm-2 col-12 position-relative">
        <div class="vertical-border"></div>
      </div>
      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(1)">
        <div v-if="isBackend() || isFrontend()" class="row">
          <Select
            :placeholder="$t('talentWizard.selectLanguage')"
            :validationField="roleLangKey()"
            hideLabel="true"
            :selectOptions="isBackend() ? BEOptions : FEOptions"
            @handleChange="(e) => handleRoleLanguageChange(e)"
          />
        </div>
        <!-- Ful stack case -->
        <div v-if="isFullStack()" class="row">
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
            <Select
              :placeholder="'B.E language'"
              :validationField="$v.formData.BE_lang"
              hideLabel="true"
              :selectOptions="BEOptions"
            />
          </div>
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
            <Select
              :placeholder="'F.E language'"
              :validationField="$v.formData.FE_lang"
              hideLabel="true"
              :selectOptions="FEOptions"
            />
          </div>
          <!-- ****************************************** -->
        </div>
      </div>
    </div>

    <div class="mb-5 row">
      <StepperNumbers
        :withError="skillError || (isWorkable() && selectedSkills.length == 0)"
        :currentPosition="currentPosition"
        :number="'2'"
      />

      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(2)">
        <h5 class="mb-3 required">
          {{ $t("talentWizard.whatAreYourSkills") }}
        </h5>
        <input
          v-if="selectedSkills.length == 0"
          type="text"
          class="form-control"
          :placeholder="
            formData.talent_role_id
              ? `${$t('talentWizard.selectCurrentRole')}`
              : `${$t('talentWizard.pleaseSelectCurrentRoleToSelectSkills')}`
          "
          @click="
            (event) => {
              showModal(true);
              event.target.blur();
            }
          "
          :disabled="!formData.talent_role_id"
        />
        <span class="error" v-if="isWorkable() && selectedSkills.length == 0">
          {{ $t("validation.required") }}</span
        >
        <div
          class="form-control"
          v-if="selectedSkills.length > 0"
          @click="showModal(true)"
        >
          <span
            class="skills-label"
            v-for="skill in selectedSkills"
            :key="skill.id"
            >{{ skill.name }}</span
          >
        </div>
        <!-- <div class="error-select-validation-message">
          <span style="color: #ff6f59">{{ skillError }}</span>
          <span class="error" v-if="isWorkable() && selectedSkills.length == 0">
            This field is required.</span
          >
        </div> -->
      </div>
    </div>

    <div class="mb-5 row">
      <StepperNumbers
        :withError="$v.formData.experience_years.$error"
        :currentPosition="currentPosition"
        :number="'3'"
      />
      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(3)">
        <h5 class="mb-3 required">
          {{ $t("talentWizard.howManyYearsOfExperienceDoYouHave") }}
        </h5>
        <!-- No Full Stack Role -->
        <div v-if="!isFullStack()" class="row">
          <div class="mb-3 col-md-12 mb-lg-0 mb-sm-3">
            <input
              v-model="$v.formData.experience_years.$model"
              type="text"
              class="form-control"
              :placeholder="$t('talentWizard.enterYearsOfExperience')"
            />
            <!-- ********* Validation Error*********** -->
            <div class="error-select-validation-message">
              <VulidateError
                :validationField="$v.formData.experience_years"
                :params="[
                  'required',
                  'numeric',
                  { minValue: { number: '0' } },
                  { maxValue: { number: '50' } },
                ]"
              />
            </div>
          </div>
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3"  v-if="false">
            <input
              v-model="$v.formData.experience_months.$model"
              type="text"
              class="form-control"
              :placeholder="$t('talentWizard.enterMonthOfExperience')"
            />
            <!-- ********* Validation Error*********** -->
            <div class="error-select-validation-message">
              <VulidateError
                :validationField="$v.formData.experience_months"
                :params="[
                  'required',
                  'numeric',
                  { minValue: { number: '0' } },
                  { maxValue: { number: '11' } },
                ]"
              />
            </div>
          </div>

          <!-- ************************************* -->
        </div>

        <!-- ******************************************************************************** -->

        <!-- Full Stack role -->
        <div v-if="isFullStack()" class="row">
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
            <input
              v-model="$v.formData.experience_years.$model"
              type="text"
              class="form-control"
              placeholder="For Backend"
            />

            <!-- ********* Validation Error*********** -->
            <div class="error-select-validation-message">
              <span
                class="error"
                v-if="isWorkable() && !$v.formData.experience_years.$model"
                >{{ $t("validation.required") }}</span
              >
              <VulidateError
                :validationField="$v.formData.experience_years"
                :params="[
                  'required',
                  'numeric',
                  { minValue: { number: '0' } },
                  { maxValue: { number: '50' } },
                ]"
              />
            </div>
          </div>
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3" v-if="false">
            <input
              v-model="$v.formData.experience_months.$model"
              type="text"
              class="form-control"
              :placeholder="$t('talentWizard.enterMonthOfExperience')"
            />
            <!-- ********* Validation Error*********** -->
            <div class="error-select-validation-message">
              <VulidateError
                :validationField="$v.formData.experience_months"
                :params="[
                  'required',
                  'numeric',
                  { minValue: { number: '0' } },
                  { maxValue: { number: '11' } },
                ]"
              />
            </div>
          </div>
          
          <div v-if="isFullStack()" class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
            <input
              v-model="$v.formData.second_experience_years.$model"
              type="text"
              class="form-control"
              placeholder="For Frontend"
            />
            <!-- ********* Validation Error*********** -->
            <div class="error-select-validation-message">
              <span
                class="error"
                v-if="
                  isWorkable() && !$v.formData.second_experience_years.$model
                "
                >{{ $t("validation.required") }}</span
              >
              <VulidateError
                :validationField="$v.formData.second_experience_years"
                :params="[
                  'required',
                  'numeric',
                  { minValue: { number: '0' } },
                  { maxValue: { number: '50' } },
                ]"
              />
            </div>
          </div>
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3"  v-if="false">
            <input
              v-model="$v.formData.second_experience_months.$model"
              type="text"
              class="form-control"
              :placeholder="$t('talentWizard.enterMonthOfExperience')"
            />
            <!-- ********* Validation Error*********** -->
            <div class="error-select-validation-message">
              <VulidateError
                :validationField="$v.formData.second_experience_months"
                :params="[
                  'required',
                  'numeric',
                  { minValue: { number: '0' } },
                  { maxValue: { number: '11' } },
                ]"
              />
            </div>
          </div>
          <!-- ******************************************************************************** -->
        </div>
      </div>
    </div>

    <div class="mb-5 row">
      <StepperNumbers :currentPosition="currentPosition" :number="'4'" />
      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(4)">
        <h5 class="mb-3">
          {{ $t("talentWizard.whatIsYourExpectedSalaryInUSDollar") }}
        </h5>
        <div class="input-group flex-nowrap">
          <input
            v-model="$v.formData.salary.$model"
            type="number"
            class="form-control"
            placeholder="e,g 5000"
          />
          <span class="input-group-text" id="addon-wrapping">
            <i class="bi bi-coin"></i>
          </span>
        </div>
        <!-- ********* Validation Error*********** -->
        <div class="mt-1">
          <VulidateError
            :validationField="$v.formData.salary"
            :errorMsg="displyErrMsg($v.formData.salary.$model)"
          />
        </div>
        <!-- ************************************* -->
      </div>
    </div>

    <div class="mb-5 row">
      <StepperNumbers :currentPosition="currentPosition" :number="'5'" />
      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(5)">
        <h5
          class="mb-3"
          :class="{ required: allow_edit_availability?.allow_part_time }"
        >
          {{ $t("talentWizard.areYouAvailableForPartTimeJob") }}
        </h5>
        <div
          v-tooltip="{
            content: 'You already assigned to Part Time Contract',
            visible: !allow_edit_availability.allow_part_time,
          }"
          class="d-inline-block"
        >
          <YesNoQuestion
            v-model="formData.part_time_availability"
            :disable_part_time_availability="
              !allow_edit_availability?.allow_part_time
            "
          />
        </div>
      </div>
    </div>

    <div class="mb-5 row">
      <StepperNumbers :currentPosition="currentPosition" :number="'6'" />
      <div class="col-lg-11 col-sm-10 col-12" @click="slideTo(6)">
        <h5
          class="mb-3 required"
        >
          {{ $t("talentWizard.whatIsYourNationality") }}
        </h5>
        <div
          class="col-12"
          @click="slideTo(parseInt(6))"
        >
          <SelectCountry
            :placeholder="$t('signUp.nationality')"
            :validationField="$v.formData.nationality_id"
            hideLabel="true"
            :selectOptions="countryList"
          />
        </div>
      </div>
    </div>

    <div
      v-for="(lang, index) in $v.formData.languages.$each.$iter"
      class="mb-5 row"
      :key="index"
    >
      <StepperNumbers
        :withError="
          (lang.name.$dirty || lang.level.$dirty) &&
          (lang.level.$invalid || lang.name.$invalid)
        "
        :currentPosition="currentPosition"
        :number="parseInt(index) + parseInt(7)"
      />
      <div
        class="col-lg-11 col-sm-10 col-12"
        @click="slideTo(parseInt(index) + parseInt(7))"
      >
        <h5 class="mb-3 required" v-if="index == 0">
          {{ $t("talentWizard.whatIsYourFirstLanguage") }}
        </h5>
        <h5 class="mb-3" v-if="index == 1">
          {{ $t("talentWizard.whatIsYourSecondLanguage") }}
        </h5>
        <div class="row">
          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
            <v-select
              v-model="lang.name.$model"
              class="style-chooser"
              :options="
                index == 1 && formData.languages[0].name
                  ? languagesOptions.filter(
                      (la) => la != formData.languages[0].name
                    )
                  : index == 0 && formData.languages[1].name
                  ? languagesOptions.filter(
                      (la) => la != formData.languages[1].name
                    )
                  : languagesOptions
              "
              :placeholder="$t('talentWizard.select')"
              label="name"
              :reduce="(option) => option.lang"
            >
              <div slot="no-options">
                {{ $t("talent.sorryNoMatchingOptions") }}
              </div>
            </v-select>
            <div
              class="error-select-validation-message"
              v-if="lang.name.$dirty || lang.level.$dirty"
            >
              <span style="color: #ff6f59" v-if="!lang.name.isValid">{{
                $t("validation.required")
              }}</span>
            </div>
          </div>

          <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
            <v-select
              v-model="lang.level.$model"
              :reduce="(name) => name.id"
              class="style-chooser"
              :placeholder="$t('talentWizard.selectLanguageLevel')"
              :options="levelsOptions"
              label="name"
            >
              <div slot="no-options">
                {{ $t("talent.sorryNoMatchingOptions") }}
              </div>
            </v-select>
            <div class="error-select-validation-message">
              <span style="color: #ff6f59" v-if="!lang.level.requiredIf">{{
                $t("validation.required")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NextPrevBtn
      :className="'d-grid gap-2 d-flex justify-content-end'"
      :step="stepNum"
      :hasError="validForm"
      :skillsOptions="skillsOptions"
      :levelsOptions="levelsOptions"
      :formData="getSubmitedFormData()"
      :isNextDisabled="true"
    />
    <!-- Skills Model -->
    <SkillsModelForm
      v-show="isModalVisible"
      @close="showModal(false)"
      :options="skillsOptions"
      :selectedSkills="selectedSkills"
      @setSkills="setSkills"
    />
    <!-- ------------ -->
  </div>
</template>

<script>
import Levels from "../../static/LanguageLevel";
import Languages from "../../static/Languages";
import VulidateError from "../shared/VulidateError/vulidateError.vue";
import {
  required,
  minValue,
  maxValue,
  numeric,
  requiredIf,
  between,
  integer,
} from "vuelidate/lib/validators";
import Select from "../shared/formControls/select/select.vue";
import NextPrevBtn from "../NextPrevWizardBtn/NextPrevWizardBtn.vue";
import YesNoQuestion from "../shared/YesNoQuestion/YesNoQuestion.vue";
import SkillsModelForm from "./SkillsModalForm.vue";
import StepperNumbers from "./VerticalStepperNumbers.vue";
import SelectCountry from "@/pages/Talent/profile/components/editPersonalInfo/selectCountry.vue";
import "./BasicInfo.scss";
import { isWorkable } from "../../utils/shared";

export default {
  props: ["stepNum"],
  components: {
    VulidateError,
    NextPrevBtn,
    SkillsModelForm,
    StepperNumbers,
    YesNoQuestion,
    SelectCountry,
    Select,
  },
  computed: {
    countryList() {
      
      return this.$store.getters["shared/getCountryList"].map((country) => ({
        ...country,
        defaultCountryId: country.id,
        name: this.$i18n.locale == "en" ? country?.nationality_en : country?.nationality_ar,
      }));
    },
    languagesOptions() {
      return Languages.map((lang) => ({
        lang: lang,
        name: this.$t(`languages.${[lang]}`),
      }));
    },
    levelsOptions() {
      return Levels.map((level) => ({
        ...level,
        name: this.$t(`languageLevel.${[level.name]}`),
      }));
    },
    validForm() {
      return (
        this.$v.$invalid ||
        !this.formData.languages[0].name ||
        !this.formData.languages[0].level ||
        this.formData.skills.length == 0 ||
        !!this.skillError
      );
    },
  },
  data() {
    return {
      dataLoaded: false,
      currentPosition: 1,
      rolesOptions: [],
      skillsOptions: [],
      BEOptions: [],
      FEOptions: [],
      selectedSkills: [],
      skillError: "",
      isModalVisible: false,
      disable_part_time_availability: false,
      allow_edit_availability: {},
      formData: {
        experience_years: "",
        second_experience_years: "",
        experience_months: 0,
        second_experience_months: 0,
        salary: "",
        part_time_availability: "1",
        talent_role_id: "",
        BE_lang: "",
        FE_lang: "",
        nationality_id: null,
        skills: [],
        languages: [
          {
            id: 1,
            name: null,
            level: null,
          },
          {
            id: 2,
            name: null,
            level: null,
          },
        ],
      },
    };
  },
  validations: {
    formData: {
      experience_years: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(50),
        numeric,
      },
      nationality_id: {
        required,
      },
      second_experience_years: {
        required: requiredIf(function () {
          return this.isFullStack();
        }),
        minValue: minValue(0),
        maxValue: maxValue(50),
        numeric,
      },
      experience_months: {
      
        minValue: minValue(0),
        maxValue: maxValue(11),
        numeric,
      },
      second_experience_months: {
        minValue: minValue(0),
        maxValue: maxValue(11),
        numeric,
      },
      BE_lang: {
        required: requiredIf(function () {
          return this.isBackend() || this.isFullStack();
        }),
      },
      FE_lang: {
        required: requiredIf(function () {
          return this.isFrontend() || this.isFullStack();
        }),
      },
      salary: {
        integer,
        between: between(1, 1000000),
      },
      talent_role_id: { required },
      languages: {
        $each: {
          name: {
            isValid: (value, language) => {
              return language.id == 1
                ? !!value
                : !language.level || (!!language.level && !!value);
            },
          },
          level: {
            requiredIf: requiredIf(function (language) {
              return !!language.name;
            }),
          },
        },
      },
    },
  },
  updated() {
    this.isWorkable() && this.dataLoaded && this.$v.$touch();
  },
  mounted() {
    this.isWorkable() && this.dataLoaded && this.$v.$touch();
    this.getBasicInfo().then(() => {
      this.getTalentRole().then(() => {
        this.dataLoaded = true;
      });
    });
    this.getFrontendOptions();
    this.$store.dispatch("shared/getCountriesRequest");
    this.getBackendOptions();
  },
  methods: {
    isWorkable,
    slideTo(index) {
      return (this.currentPosition = index);
    },
    roleLangKey() {
      if (this.isBackend()) {
        this.$v.formData.BE_lang.$reset();
        return this.$v.formData.BE_lang;
      } else if (this.isFrontend()) {
        this.$v.formData.FE_lang.$reset();
        return this.$v.formData.FE_lang;
      }
      return "";
    },
    handleRoleLanguageChange(val) {
      if (this.isBackend()) {
        this.$v.formData.FE_lang = "";
        this.$v.formData.BE_lang = val;
      } else if (this.isFrontend()) {
        this.$v.formData.BE_lang = "";
        this.$v.formData.FE_lang = val;
      }
    },
    async getFrontendOptions() {
      try {
        await this.axios
          .get("/api/staticContent/frontendLanguages")
          .then((res) => {
            this.FEOptions = res.data.data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getBackendOptions() {
      try {
        await this.axios
          .get("/api/staticContent/backendLanguages")
          .then((res) => {
            this.BEOptions = res.data.data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getBasicInfo() {
      try {
        await this.axios.get("/api/users/basicInfo").then((res) => {
          let data = res.data.data;
          if (data.role) {
            this.formData.talent_role_id = data.role;
            this.getTalentSkills(data.role?.id);
            this.setSkills(data.skills, true);
          }
          this.formData.experience_years = data.experience_years;
          this.formData.second_experience_years = data.second_experience_years;
          this.formData.experience_months = data.experience_months || 0;
          this.formData.second_experience_months = data.second_experience_months || 0;
          this.formData.BE_lang = data.BE_lang;
          this.formData.FE_lang = data.FE_lang;
          this.formData.salary = data.salary;
          this.formData.nationality_id =data.nationality ?  { ...data.nationality, name: this.$i18n.locale == "en" ? data.nationality.nationality_en : data.nationality.nationality_ar } : null;
          
          this.allow_edit_availability = data.allow_edit_availability;
          this.disable_part_time_availability =
            data.part_time_availability !== null;
          this.formData.part_time_availability =
            data.part_time_availability ?? "1";
            
          data.languages.forEach((language, index) => {
          /*   this.formData.languages[index].name =   this.$t(`languages.${[this.languagesOptions.find(l => l.name == data.languages[index].name.toLowerCase())?.lang ? this.languagesOptions.find(l => l.name == data.languages[index].name.toLowerCase()).lang : data.languages[index].name.toLowerCase() ]}`
          ); */
            this.formData.languages[index].name = data.languages[index].name
            this.formData.languages[index].lang = data.languages[index].name;
            this.formData.languages[index].level = data.languages[index].level;
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getTalentRole() {
      try {
        await this.axios.get("/api/staticContent/roles").then((res) => {
          this.rolesOptions = res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getTalentSkills(roleId, options = null) {
      try {
        await this.axios
          .get(`/api/staticContent/roles/${roleId}/skills`)
          .then((res) => {
            options
              ? (this[options] = res.data.data)
              : (this.skillsOptions = res.data.data);
          });
      } catch (error) {
        console.log(error);
      }
    },
    showModal(val) {
      this.isModalVisible = val;
    },
    setSkills(skills, removeErrMsg) {
      this.selectedSkills = skills;
      this.formData.skills = skills.map((item) => item["id"]);
      this.skillError = removeErrMsg
        ? ""
        : skills.length == 0
        ? this.$t("validation.required")
        : "";
    },
    isBackend() {
      return (
        this.formData.talent_role_id == 1 ||
        this.formData.talent_role_id?.id == 1
      );
    },
    isFrontend() {
      return (
        this.formData.talent_role_id == 2 ||
        this.formData.talent_role_id?.id == 2
      );
    },
    isFullStack() {
      this.$v.formData.FE_lang.$reset();
      this.$v.formData.BE_lang.$reset();
      return (
        this.formData.talent_role_id == 3 ||
        this.formData.talent_role_id?.id == 3
      );
    },
    resetRoleDependencies() {
      this.formData.second_experience_years = "";
      this.$v.formData.second_experience_years.$reset();
      this.formData.experience_years = "";
      this.$v.formData.experience_years.$reset();
      this.formData.second_experience_months = 0;
      this.$v.formData.second_experience_months.$reset();
      this.formData.experience_months = 0;
      this.$v.formData.experience_months.$reset();
      this.formData.BE_lang = "";
      this.$v.formData.BE_lang.$reset();
      this.formData.FE_lang = "";
      this.$v.formData.FE_lang.$reset();
    },
    displyErrMsg(salaryValue) {
      if (salaryValue) {
        if (salaryValue <= 0) {
          return this.$t("validation.valueGreaterThanOne");
        } else if (JSON.stringify(salaryValue).includes(".")) {
          return this.$t("validation.onlyIntegerValues");
        } else if (salaryValue > 1000000) {
          return this.$t("validation.maxValue1000000");
        } else {
          return "";
        }
      }
    },
    getSubmitedFormData() {
      const languages = this.formData.languages.map((lang) => {
        return {
          ...lang,
          name: lang.name,
          lang:  lang.name,
          level: lang.level,
        };
      });
      return {
        ...this.formData,
        languages,
        nationality_id: this.formData.nationality_id ? this.formData.nationality_id.id ? this.formData.nationality_id.id : this.formData.nationality_id : null,
        be_lang_id:
          typeof this.formData.BE_lang == "number"
            ? this.formData.BE_lang
            : this.formData.BE_lang?.id,
        fe_lang_id:
          typeof this.formData.FE_lang == "number"
            ? this.formData.FE_lang
            : this.formData.FE_lang?.id,
      };
    },
  },

  watch: {
/*     "$i18n.locale": {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick();
        this.getBasicInfo();
        if (this.formData.languages[0].name) {
          this.formData.languages[0].name = this.$t(
            `languages.${[this.formData.languages[0].lang]}`
          );
        }
        if (this.formData.languages[1].name) {
          this.formData.languages[1].name = this.$t(
            `languages.${[this.formData.languages[1].lang]}`
          );

        }
      },
    }, */
    "formData.talent_role_id": function (v) {
      if (this.dataLoaded) {
        if (v) {
          this.getTalentSkills(v);
          this.resetRoleDependencies();
        } else {
          (this.skillsOptions = []), (this.skillError = "");
        }
        this.selectedSkills = [];
        this.formData.skills = [];
      }
    },
    selectedSkills: function () {
      //  (this.isWorkable()&&this.selectedSkills.length>0 )  ?this.skillError= "" : this.skillError=this.$t("validation.required")
      if (this.isWorkable()) {
        if (this.selectedSkills.length == 0) {
          this.skillError = this.$t("validation.required");
        }
      } else {
        this.skillError = "";
      }
    },
    dataLoaded: function () {
      this.isWorkable() && this.dataLoaded && this.$v.$touch();
    },
  },
};
</script>

<style lang="scss"></style>
