<template>
  <div class="navbar-wrapper">
    <header class="">
      <div class="container z-index-1">
        <nav class="navbar navbar-expand-xl">
          <a class="navbar-brand">
            <img loading="lazy"
              src="../../../../assets/images/logo-dark.svg"
              alt="squadio logo"
              width="120"
              @click="gotoHome()"
          /></a>
          
          
          <div class="btn-group login-btn loggedin-dropdown" style="order: 2">
            <LanguageSwitcher black class="language-switcher-white-navbar" />

            <client-dropdown-menu
              v-if="userName"
              :client-name="userName"
              :projects="projects"
              :active-project="activeProject"
              :has-nomination="isClientHasNomination"
              :isClientHasContractRenewal="isClientHasContractRenewal"
              @project-changed="changeCurrentProject($event)"
            />
          </div>
      
          <LanguageSwitcher separator-dark has-divider divider-at-start class="me-2 ms-auto" />

          <button
            class="navbar-toggler collapsed"
            type="button"
            @click="triggerMobileMenu = !triggerMobileMenu"
          >
            <!-- <span class="navbar-toggler-icon"> -->
            <NavbarIcon />
            <!-- </span> -->
          </button>
          <div
            class="collapse navbar-collapse"
            id="navbarSupportedContent"
            v-if="userName"
          >
            <ul
              class="mb-2 nav navbar-nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
            >
              <li v-if="userName">
                <a
                  class="px-3 nav-link"
                  :class="
                    $route.name == 'clientMatching'
                      ? 'link-secondary navbar-secondary'
                      : 'nav-link'
                  "
                  @click="matchingNavigator('clientMatching')"
                  > {{ $t("matchedTalents.matchedTalents") }}</a
                >
              </li>
              <li v-if="userName && isClientHasNomination">
                <a
                  class="px-3 nav-link"
                  :class="
                    $route.name == 'Nominated profiles'
                      ? 'link-secondary navbar-secondary'
                      : 'nav-link'
                  "
                  @click="matchingNavigator('Nominated profiles')"
                  >
                  {{  $t('matchedTalents.nominations') }}
                  </a
                >
              </li>

              <li v-if="userName && isClientHasContractRenewal">
                <router-link
                  class="px-3 nav-link"
                  :class="
                    $route.name == 'clientContractsRenewal'
                      ? 'link-secondary navbar-secondary'
                      : 'nav-link'
                  "
                  to="/sq/client/contracts-renewal"
                >
                    {{ $t("matchedTalents.squadRenewal") }}
                </router-link>
              </li>
              <li v-if="userName && isClientHasInterviews">
                <router-link
                  class="px-3 nav-link"
                  :class="
                    $route.name == 'interviewsList'
                      ? 'link-secondary navbar-secondary'
                      : 'nav-link'
                  "
                  to="/sq/client/interviews-list"
                >
                    {{ $t("matchedTalents.interviewsList") }}
                </router-link>
              </li>
            </ul>
          </div>
          
          <mobile-menu
            :is-open="triggerMobileMenu"
            logged-in-client
            :projects="projects"
            :active-project="activeProject"
            @changeCurrentProject="changeCurrentProject"
            @close-menu="menuClosed"
          >
            <li class="mobileMenu__item" v-if="userName">
              <a
                class="px-3 nav-link"
                :class="
                  $route.name == 'clientMatching'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                @click="matchingNavigator('clientMatching')"
                > {{ $t("matchedTalents.matchedTalents") }}</a
              >
            </li>
            <li v-if="userName && isClientHasNomination" class="mobileMenu__item">
              <a
                class="px-3 nav-link"
                :class="
                  $route.name == 'Nominated profiles'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                @click="matchingNavigator('Nominated profiles')"
                >
                {{  $t('matchedTalents.nominations') }}
                </a
              >
            </li>
            <li v-if="userName && isClientHasContractRenewal" class="mobileMenu__item">
              <router-link
                class="px-3 nav-link"
                :class="
                  $route.name == 'contractsRenewal'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                to="/sq/client/contracts-renewal"
              >
                  {{ $t("matchedTalents.squadRenewal") }}
              </router-link>
            </li>
            
            <li v-if="userName && isClientHasInterviews" class="mobileMenu__item">
              <router-link
                class="px-3 nav-link"
                :class="
                  $route.name == 'interviewsList'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link'
                "
                to="/sq/client/interviews-list"
              >
                  {{ $t("matchedTalents.interviewsList") }}
              </router-link>
            </li>
            
            <li class="mobileMenu__item" v-if="user.id">
              <button  @click="logOut">
              {{ $t("home.logout") }}
              </button>
            </li>
            <li class="mobileMenu__item" v-else>
              <router-link to="/login">
              {{ $t("home.login") }}
              </router-link>
            </li>
          </mobile-menu>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import "./navbar.scss";
import NavbarIcon from "../icons/navbar.vue";
import { mapActions, mapGetters } from "vuex";

import { isClient } from "@/utils/shared.js";

import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
import MobileMenu from "@/components/MobileMenu";
import ClientDropdownMenu from "@/components/ClientDropdownMenu";

export default {
  components: { NavbarIcon, LanguageSwitcher, MobileMenu, ClientDropdownMenu },
  data() {
    return {
      userName: "",
      projects: [
      ],
      triggerMobileMenu: false,
    };
  },
  mounted() {
    this.getClientProjects()
    const body = document.querySelector('body');
    body.classList.remove('is-fixed');
    this.userName = JSON.parse(localStorage.getItem("user"))?.first_name;
    if (this.userName && isClient()) {
      this.setClientCounters();
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      activeProject: "client/getActiveProject",
      isClientHasContractRenewal: "auth/getClientHasContractRenewal",
      isClientHasNomination: "auth/getClientNominationState",
      isClientHasInterviews: "auth/getClientHasInterviews"
    }),
  },
  methods: {
    ...mapActions({
      handleSetActiveProject: "client/handleSetActiveProject",
      setClientCounters: "auth/setClientCounters"
    }),
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector('body');
      body.classList.remove('is-fixed');
    },
    getClientProjects() {
      if (isClient()) {
         this.axios.get(`/api/clients/projects/minimal`).then((result) => {
           this.projects = result.data.data
           let payload = {projects: this.projects, project:  result.data.data[0] }
           if (!this.activeProject && result.data.data[0]) {
             this.handleSetActiveProject(payload)
             this.setClientCounters()
           }
         }).catch((err) => {
            console.log(err);
         });
      }
    },
    changeCurrentProject(project) {
      let payload = { projects: this.projects, project }
      this.handleSetActiveProject(payload)
      isClient() && this.setClientCounters()
    },
    gotoHome() {
      this.$router.push({ path: "/" });
    },
    logOut() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ path: "/login" });
        // window.location.reload();
      });
    },
    matchingNavigator(to) {
      if (to == "clientMatching") {
        this.$router.push({ path: `/sq/client/matching` });
      } else if (to == "Nominated profiles") {
        this.$router.push({ path: `/sq/client/nomination` });
      }
    },
  },
};
</script>

<style lang="scss" >
.login-btn .language-switcher-white-navbar {
  color: rgba(154, 153, 153, 1) !important;
  border-color: transparent !important;
  font-family: "Tajawal", sans-serif !important;   

}

.login-btn .language-switcher-white-navbar.btn-white-outline:hover {
  border: 1px solid transparent !important;
}

.btn-project-switcher {
    background-color: #F1F1F1;
    padding: 0px 15px;
    border-radius: 8px !important;
    height: 40px;
    display: flex;
    align-items: center;
}
</style>
