<template>
  <div class="tw-w-full tw-flex ">
    <div
      class="assessmentWrapper"
      style="margin-top: 0"
    >
      <div class="assessment-header">
        <div class="assessment-header-title">
          {{ item.assessmentData.name }}
        </div>
        <div
          class="assessment-experience"
          v-if="item.assessmentData.experience_years_text">

           {{ item.assessmentData.experience_years_text }}

           {{ item.assessmentData.experience_years_text == "0-1" ? $t('matchedTalents.yearExperience') : $t('matchedTalents.yearExperiences') }}



        </div>
      </div>

      <div class="d-flex assessments-cards w-100">
        <div
          class="assessment-card"
          v-for="(assessment, i) in item.assessments"
          :key="i"
          :class="{ 'mr-2': i == 0 && item.assessments.length > 1 , 'card-50': item.assessments.length > 1 }"
        >
          <div class="assessment-card-number">0 {{ i + 1 }}</div>
          <div :class="{ 'assessment-card-data': !assessment.is_taken }">
            <div class="tw-flex tw-gap-2 tw-items-center">
              <div class="assessment-card-name">
                <span v-if="assessment.type == 'mcq'"> {{ $t('matchedTalents.MCQExam') }} </span>
                <span v-else> {{ $t('matchedTalents.problemSolving') }} </span>
  
                <span v-if="false">
                  {{
                    assessment.type == "mcq"
                      ? handleAssessmentName(item, i).length > 25
                        ? handleAssessmentName(item, i).substring(0, 25) + "..."
                        : handleAssessmentName(item, i)
                      : assessment.name
                  }}
                </span>
              </div>
              <div class="assessment-card-score" v-if="assessment.is_taken || assessment.is_retake">
                <span class="assessment-card-score-number">{{
                  assessment.percentage || assessment.last_failed_percentage
                }}</span>
                <span class="assessment-card-score-number-total">/100 </span>
                <span class="assessment-card-score-text">
                  {{ $t("talent.totalScore") }}
                </span>
              </div>
              <p
                class="assessment-card-waiting-time"
                v-if="
                  isWaitingTime &&
                  assessment.type == 'code' &&
                  !assessment.is_taken
                "
              >
                {{
                  $t(
                    "talent.weAreReviewingAnswersToCalculateTheResultPleaseWait10min"
                  )
                }}
              </p>
            </div>
            <div class="assessment-card-hint">
              <span
                v-if="
                  !(
                    isWaitingTime &&
                    assessment.type == 'code' &&
                    !assessment.is_taken
                  ) &&
                  !assessment.is_passed &&
                  assessment.is_taken
                "
              >
                {{ $t('talent.noTrial') }}
              </span>
            </div>
            <div class="assessment-card-hint">
              <span
                v-if="
                  !(
                    isWaitingTime &&
                    assessment.type == 'code' &&
                    !assessment.is_taken
                  ) &&
                  !assessment.is_passed &&
                  assessment.is_retake
                "
              >
                {{ $t('talent.haveTrial') }}
              </span>
            </div>
          </div>

          <div class="assessment-card-action">
            <img loading="lazy"
              v-if="
                isWaitingTime &&
                assessment.type == 'code' &&
                !assessment.is_taken
              "
              src="@/assets/images/waiting-time.svg"
            />
            <img loading="lazy"
              v-else-if="
                !(
                  isWaitingTime &&
                  assessment.type == 'code' &&
                  !assessment.is_taken
                ) && assessment.is_passed
              "
              src="@/assets/images/icons/ic_success.svg"
            />
            <img loading="lazy"
              v-else-if="
                !(
                  isWaitingTime &&
                  assessment.type == 'code' &&
                  !assessment.is_taken
                ) &&
                !assessment.is_passed &&
                assessment.is_taken
              "
              src="@/assets/images/icons/ic_cancel.svg"
            />
            <button
              v-else-if="
                !(
                  isWaitingTime &&
                  assessment.type == 'code' &&
                  !assessment.is_taken
                ) &&
                !assessment.is_passed &&
                assessment.is_retake
              "
              class="btn btn-retake"
              :disabled="!assessment.will_be_taken"
              @click="goToTalentProfile(assessment.link)"
            >
              {{ $t("talent.retake") }}
            </button>
            <button
              v-else-if="
                !(
                  isWaitingTime &&
                  assessment.type == 'code' &&
                  !assessment.is_taken
                ) && !assessment.is_taken
              "
              class="btn"
              :disabled="!assessment.will_be_taken"
              :id="`${i == 0 ? 'first-assessment' : ''}`"
              @click="goToTalentProfile(assessment.link)"
            >
              {{ $t("talent.start") }}
            </button>
          </div>
        </div>
      </div>

        <div  class="assessment-statement"
         v-if="!item.assessmentData.is_passed">
                <img loading="lazy"
                  src="@/assets/images/ic_verifed.svg"
                  class="assessment-statement__img"
                />
                <p>{{ $t('talent.passAssesmentVerifiedTalent') }}</p>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AssessmentVelents",
  props: ["item", "list" ],
  mounted() {
    this.handleAssessmentWaitingTime();
  },
  computed: {
     profile() {
      return this.$store.getters["talentProfile/getProfileData"];
    },
    isWaitingTime() {
      let isWaitingTime = localStorage.getItem("isAssessmentHasWaitingTime");
      return this.$route.query.s == "v" ? true : isWaitingTime;
    },
  },
  methods: {
    handleAssessmentWaitingTime() {
      if (this.$route.query.s == "v") {
        localStorage.setItem("isAssessmentHasWaitingTime", true);
      }
    },
    goToTalentProfile(link) {
      this.$emit("assessment-opened")
      window.open(link, "_blank");
    },
    seletAssessment(assessments) {
      return [...assessments].sort((a, b) =>
        a.percentage < b.percentage ? 1 : -1
      )[0];
    },
    handleAssessmentName(assessmentObject, index) {
      let { assessments, name } = assessmentObject;
      let assessmentName = "";
      let selectTechStack = assessments.find(
        (assessment) => assessment.type == "mcq"
      );
      if (assessments[index]) {
        if (
          assessmentObject.type == 1 &&
          (assessmentObject.id == 1 ||
            assessmentObject.id == 2 ||
            assessmentObject.id == 3)
        ) {
          assessmentName = `${name} ( ${selectTechStack.tech_stack?.join(
            ","
          )} )  "V${assessments[index].version}"`;

          return assessmentName;
        } else {
          assessmentName = `${name} "V${assessments[index].version}"`;
          return assessmentName;
        }
      }
    },
  },
};
</script>

<style lang="scss" >
.owl-carousel .owl-item {
  visibility: hidden;
  height: 100px;
}

.owl-carousel .owl-item.active {
  visibility: visible;
  height: auto;
}

.assessment-experience {
    font-size: 11px;
    color: rgba(73, 77, 85, 1);
    letter-spacing: -0.22px;

    padding: 4px 15px;
    border-radius: 5px;
    color: #494d55;
    font-weight: 500;
    background-color: rgba(125,103,201,.1019607843);
    border-radius: 15px;
    color: #373a3b;
    margin-left:  0.5rem;
    @media (max-width: 576px) {
        font-size: 11px;
    }

}

.assessment-statement {
  background: #F7F6FB;
  width: 100%;
  border-radius: 8px;
  position: relative;
  padding: 8px 10px;
  margin: 0px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;


  &__img {
    height: 26px;
    width: 25px !important;
    margin-right: 0.5rem;
  }

  p {
    font-size: 12px;
    color: #292929;
    font-weight: 600;
    display: flex;
    align-items: center;
    text-align: left;

     @media (max-width: 576px) {
        font-size: 11px;
    }
  }
}

.btn-retake {
  background-color: #fff!important;
  border: 1px solid #422e87;
  color: #422e87!important;
  &:hover {
    background-color: #422e87!important;
    color: #fff!important;
  }
}
</style>