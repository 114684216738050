<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class=" modal-body no-border">
            <div class="row">
              <div class="mb-4 text-center col-12 meetBox">
                <img
                  src="@/assets/images/payment/processing-payment.svg"
                  class="mb-4 img-fluid tw-mx-auto md:tw-w-[110px] tw-w-[95.89px]"
                />

                <p
                  class="ltr px-0 px-md-5 tw-text-[#77838f] tw-text-[18px] md:tw-text-[24px] tw-font-normal tw-mb-4"
                >
                    #{{ invoiceData.invoice_number }}
                </p>

                <h3
                  class="bold tw-text-[#1e2022] tw-text-[24px] tw-font-bold tw-mb-[17px]"
                >
                  {{ $t('payment.processingPayment') }}
                </h3>

                <p
                  class="px-0 px-md-5 tw-text-[#77838f] tw-text-[18px] md:tw-text-[24px] tw-font-normal tw-mb-4"
                >
                    {{ $t('payment.willKeepYouUpdatedWithPayment') }}
                </p>

                <hr class="tw-border-[#DBDBDB] tw-my-4" />

                <span class="tw-text-[#9f9d9d] tw-text-[17px] tw-block">
                    {{ $t('payment.amountPaid') }}
                </span>

                <strong class="tw-text-primary tw-text-[26px] tw-font-medium">
                   {{invoiceData.total_amount_due }} {{invoiceData.currency}}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "paymentProcessingModal",
  props: ["isOpen", "invoiceData"],
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  methods: {
    close() {
      document.body.classList.remove("modal-open");
      this.$emit("update", false);
    },
  },
};
</script>


<style lang="scss" scoped>

.ltr {
  direction: ltr;
}

.modal-body.no-border {
      overflow: hidden;
}

.modal-open {
  overflow: hidden;
}

.modal-dialog {
    width: 530px;
}

@media (max-width: 700px) {
  .modal-dialog {
    width: 90%;
  }
}
</style>



