// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/caseStudy/bg_case-study-2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".case-studies2[data-v-312a3fd0]{background:#f0eef8!important}.case-study[data-v-312a3fd0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@media(max-width:768px){.case-study[data-v-312a3fd0]{background-position-x:center}}", ""]);
// Exports
module.exports = exports;
