var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-wrapper"},[_c('header',{},[_c('div',{staticClass:"container z-index-1"},[_c('nav',{staticClass:"navbar navbar-expand-xl"},[_c('a',{staticClass:"navbar-brand"},[_c('img',{attrs:{"loading":"lazy","src":require("../../../../assets/images/logo-dark.svg"),"alt":"squadio logo","width":"120"},on:{"click":function($event){return _vm.gotoHome()}}})]),_c('div',{staticClass:"btn-group login-btn loggedin-dropdown",staticStyle:{"order":"2"}},[_c('LanguageSwitcher',{staticClass:"language-switcher-white-navbar",attrs:{"black":""}}),(_vm.userName)?_c('client-dropdown-menu',{attrs:{"client-name":_vm.userName,"projects":_vm.projects,"active-project":_vm.activeProject,"has-nomination":_vm.isClientHasNomination,"isClientHasContractRenewal":_vm.isClientHasContractRenewal},on:{"project-changed":function($event){return _vm.changeCurrentProject($event)}}}):_vm._e()],1),_c('LanguageSwitcher',{staticClass:"me-2 ms-auto",attrs:{"separator-dark":"","has-divider":"","divider-at-start":""}}),_c('button',{staticClass:"navbar-toggler collapsed",attrs:{"type":"button"},on:{"click":function($event){_vm.triggerMobileMenu = !_vm.triggerMobileMenu}}},[_c('NavbarIcon')],1),(_vm.userName)?_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"mb-2 nav navbar-nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"},[(_vm.userName)?_c('li',[_c('a',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'clientMatching'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link',on:{"click":function($event){return _vm.matchingNavigator('clientMatching')}}},[_vm._v(" "+_vm._s(_vm.$t("matchedTalents.matchedTalents")))])]):_vm._e(),(_vm.userName && _vm.isClientHasNomination)?_c('li',[_c('a',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'Nominated profiles'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link',on:{"click":function($event){return _vm.matchingNavigator('Nominated profiles')}}},[_vm._v(" "+_vm._s(_vm.$t('matchedTalents.nominations'))+" ")])]):_vm._e(),(_vm.userName && _vm.isClientHasContractRenewal)?_c('li',[_c('router-link',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'clientContractsRenewal'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link',attrs:{"to":"/sq/client/contracts-renewal"}},[_vm._v(" "+_vm._s(_vm.$t("matchedTalents.squadRenewal"))+" ")])],1):_vm._e(),(_vm.userName && _vm.isClientHasInterviews)?_c('li',[_c('router-link',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'interviewsList'
                    ? 'link-secondary navbar-secondary'
                    : 'nav-link',attrs:{"to":"/sq/client/interviews-list"}},[_vm._v(" "+_vm._s(_vm.$t("matchedTalents.interviewsList"))+" ")])],1):_vm._e()])]):_vm._e(),_c('mobile-menu',{attrs:{"is-open":_vm.triggerMobileMenu,"logged-in-client":"","projects":_vm.projects,"active-project":_vm.activeProject},on:{"changeCurrentProject":_vm.changeCurrentProject,"close-menu":_vm.menuClosed}},[(_vm.userName)?_c('li',{staticClass:"mobileMenu__item"},[_c('a',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'clientMatching'
                  ? 'link-secondary navbar-secondary'
                  : 'nav-link',on:{"click":function($event){return _vm.matchingNavigator('clientMatching')}}},[_vm._v(" "+_vm._s(_vm.$t("matchedTalents.matchedTalents")))])]):_vm._e(),(_vm.userName && _vm.isClientHasNomination)?_c('li',{staticClass:"mobileMenu__item"},[_c('a',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'Nominated profiles'
                  ? 'link-secondary navbar-secondary'
                  : 'nav-link',on:{"click":function($event){return _vm.matchingNavigator('Nominated profiles')}}},[_vm._v(" "+_vm._s(_vm.$t('matchedTalents.nominations'))+" ")])]):_vm._e(),(_vm.userName && _vm.isClientHasContractRenewal)?_c('li',{staticClass:"mobileMenu__item"},[_c('router-link',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'contractsRenewal'
                  ? 'link-secondary navbar-secondary'
                  : 'nav-link',attrs:{"to":"/sq/client/contracts-renewal"}},[_vm._v(" "+_vm._s(_vm.$t("matchedTalents.squadRenewal"))+" ")])],1):_vm._e(),(_vm.userName && _vm.isClientHasInterviews)?_c('li',{staticClass:"mobileMenu__item"},[_c('router-link',{staticClass:"px-3 nav-link",class:_vm.$route.name == 'interviewsList'
                  ? 'link-secondary navbar-secondary'
                  : 'nav-link',attrs:{"to":"/sq/client/interviews-list"}},[_vm._v(" "+_vm._s(_vm.$t("matchedTalents.interviewsList"))+" ")])],1):_vm._e(),(_vm.user.id)?_c('li',{staticClass:"mobileMenu__item"},[_c('button',{on:{"click":_vm.logOut}},[_vm._v(" "+_vm._s(_vm.$t("home.logout"))+" ")])]):_c('li',{staticClass:"mobileMenu__item"},[_c('router-link',{attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t("home.login"))+" ")])],1)])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }