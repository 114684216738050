<template>
  <div class="sharable-link-wrapper">
    <slot name="sharable-link">
      <div class="modal-body no-border pt-0">
        <div class="w-100">
          <div class="d-flex align-items-center justify-content-between copylink">
            <div class="d-flex align-items-center">
            <img loading="lazy" class="ms-2 " src="../../../../../assets/images/ic_copy.svg" />
            <h5 class="mb-0 ms-2 py-3">{{ truncate(sharableLink, 31)  }}</h5>
            </div>
            <button type="button" class="btn btn-primary " @click="handleCopy">

              {{$t('sharebleLink.copyLink')}}
            </button>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import {copyClipboard,truncate} from "../../../../../utils/utils"
import "./sharableLink.scss";
export default {
  components: {},

  data() {
    return {
      sharableLink: window.location.href,
    };
  },
  methods:{
    copyClipboard,
    truncate,
    handleCopy(){
      this.copyClipboard(this.sharableLink , this.$t('sharebleLink.urlLinkCopied') );
      this.$emit("handleCloseModal", false);
    }
  }
};
</script>
