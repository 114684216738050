<template>
  <div>
    <slot name="delete-modal-body">
      <div class="w-100 text-center border-bottom">
        <img loading="lazy"
          src="../../../../../assets/images/delete-icon-animate.gif"
          alt="delete icon"
          width="200"
        />
        <h4 class="pb-2">
          {{$t("talentWizard.areYouSureYouWantToDeletThisSection")}}
          
        </h4>
      </div>
    </slot>
  </div>
</template>

<script>
import "./deleteModalBody.scss";
export default {
  components: {},
  methods: {
    handleDelete(id, type = "project") {
      switch (type) {
        case "project":
          this.$store
            .dispatch("talentProfile/deleteProjectRequest", id)
            .then(() => {
              this.$emit("handleCloseModal", false);
            });
          break;
        case "experience":
          this.$store
            .dispatch("talentProfile/deleteExperienceRequest", id)
            .then(() => {
              this.$emit("handleCloseModal", false);
            });
          break;
        case "certificate":
          this.$store
            .dispatch("talentProfile/deleteCertificateRequest", id)
            .then(() => {
              this.$emit("handleCloseModal", false);
            });
          break;
            case "education":
          this.$store
            .dispatch("talentProfile/deleteEducationRequest", id)
            .then(() => {
              this.$emit("handleCloseModal", false);
            });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
