<template>
    <div
      class="tw-flex tw-flex-col tw-justify-center tw-p-4 tw-bg-white tw-rounded-3xl md:tw-px-14 md:tw-py-4 tw-mb-4"
      style="box-shadow: 11px 8px 56px rgba(66, 46, 135, 0.09)"
    >
      <div class="tw-flex tw-items-center">
        <img
          class="tw-flex tw-justify-center tw-w-12 tw-h-12 tw-me-2"
          src="@/assets/images/icons/Group 10 Copy.svg"
          alt=""
        />
        <span
          class="tw-text-[#292929] tw-text-lg tw-font-bold tw-leading-[31px]"
          >{{ $t('requestInterview.engagementInfo') }}</span
        >
      </div>
      
      
      <div
        class="tw-flex tw-w-full tw-flex-col md:tw-flex-row tw-divide-y md:tw-divide-y-0 md:tw-divide-x"
        :class="$i18n.locale !== 'en' ? 'md:tw-divide-x-reverse' : 'md:tw-divide-x'"
      >
        <div
          class="tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-1/3 md:tw-py-3 tw-pt-3 md:tw-pe-6"
        >
          <div class="text-[#292929] tw-text-sm tw-font-medium tw-leading-7">
            {{ $t('requestInterview.EngagementInfo') }} :
          </div>
          <div
            class="tw-text-right tw-text-[#1b1b1b] tw-text-sm tw-font-semibold tw-leading-[22.96px]"
          >
            {{  availabilitiesList.find(i => i.id == interview.required_resource.contract_type.id )?.name }}
          </div>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-1/3 md:tw-py-3 tw-py-3 md:tw-px-6"
        >
          <div class="tw-text-[#292929] tw-text-sm tw-font-medium tw-leading-7">
             {{ $t('requestInterview.salary') }} : 
          </div>
          <div
            class="tw-text-right tw-text-[#1b1b1b] tw-text-sm tw-font-semibold tw-leading-[22.96px]"
          >
            {{ interview.required_resource.contract_type.id == 2 ? interview.full_time_salary.total_salary : interview.part_time_salary.total_salary }}   <span>$</span>
          </div>
        </div>
        <div
          class="tw-flex tw-items-center tw-justify-between tw-w-full md:tw-w-1/3 md:tw-py-3 tw-pt-3 md:tw-ps-6"
        >
          <div class="tw-text-[#292929] tw-text-sm tw-font-medium tw-leading-7">
            {{ $t('requestInterview.duration') }} :  
          </div>
          <div
            class="tw-text-right text-[#1b1b1b] tw-text-sm tw-font-semibold tw-leading-[22.96px]"
          >
            {{
              durationsList.find(
                (i) => i.id == interview.required_resource.duration
              ).name
            }}
          </div>
        </div>
      </div>

    </div>
</template>

<script>

export default {
  name: "EngagementInfo",
  props: {
    interview: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      durationsList: [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ],
    };
  },
  computed: {
      availabilitiesList() {
      return [
        {
          id: 2,
          name: this.$t("clientWizard.fullTime"),
        },
        {
          id: 1,
          name: this.$t("clientWizard.partTime"),
        },
      ]      
    }
  },
  watch: {
    "$i18n.locale"() {
      this.durationsList = [
        {
          id: 6,
          name: this.$t("clientWizard.sixMonths"),
        },
        {
          id: 12,
          name: this.$t("clientWizard.twelveMonths"),
        },
        {
          id: 24,
          name: this.$t("clientWizard.twentyFourMonths"),
        },
        {
          id: 36,
          name: this.$t("clientWizard.thirtySixMonths"),
        },
      ];
    },
  },
  methods: {
  },
};
</script>

