<template>
  <div class="salary-wrapper" id="salary">
    <div
      class="mb-2 row skill-14 align-items-center tw-max-w-full"
      v-for="(item, i) in handleSalaryDataView"
      :key="i"
    >
      <div class="col-7 padding-r text-start d-flex align-items-center">
        <h4 class="mb-0 flex-grow-1 d-flex tw-ms-3">
          <img loading="lazy" src="../../../../../assets/images/ic_salary.svg" class="me-2" />
          <span>
            {{ $t(`signUp.${item.label}`) }}
          </span>
        </h4>
      </div>
      <div
        v-if="is_edit[item.salaryType]"
        class="px-0 col-5 d-flex justify-content-end editSalaryWrapper"
      >
        <Input
          type="number"
          :placeholder="$t('talent.enterSalary')"
          :validationField="$v.salaryInput[item.salaryKey]"
          errMsgStyle="errMsgStyle"
          wrapperStyle="h-100"
          isExternalErrMsg="true"
        />
        <div
          class="mb-0 ok-button"
          @click.prevent.stop="handleSubmitSalary(item.salaryType, false, item.salaryKey)"
        >
         {{ $t('talent.ok') }}
        </div>
      </div>
      <div class="px-0 col text-end text-grey" v-if="!is_edit[item.salaryType]">
        <div
          class="mb-0 font-size-14 d-flex align-items-center justify-content-end"
        >
          <div>
            <span v-if="item.salaryValue">{{ item.salaryValue }} $</span>
            <span v-if="!item.salaryValue">
              {{ $t('talent.incomplete') }}
            </span>
          </div>
          <div>
            <i
              class="edit-icon"
              @click.prevent.stop="handleEditView(item.salaryType, true)"
            >
              <EditIcon />
            </i>
          </div>
        </div>
      </div>
      <p class="pt-1 mb-0 col-12 errMsgStyle">
        {{ displyErrMsg(salaryInput[item.salaryKey]) }}
      </p>
    </div>
    <warningBeforeTalentUpdate v-if="showWarningModal && Number(profile.confirmed_selections_count) > 0" :is-open="showWarningModal" @close="showWarningModal = false" :is-profile="true" />
  </div>
</template>

<script>
import "./salary.scss";
import EditIcon from "../icons/editIcon.vue";
import { mapGetters } from 'vuex';
import { between, integer } from "vuelidate/lib/validators";
import Input from "../../../../../components/shared/formControls/input/input.vue";
import warningBeforeTalentUpdate from "@/components/warningBeforeTalentUpdate/index.vue";
export default {
  props: ["full_time_salary", "part_time_salary", "talentEmail"],
  components: { EditIcon, Input, warningBeforeTalentUpdate },

  data() {
    return {
      errorMessage: "",
      showWarningModal: false,
      salaryInput: {
        full_time_salary: this.full_time_salary,
        part_time_salary: this.part_time_salary,
      },
      is_edit: {
        fullTimeSalary: false,
        partTimeSalary: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      profile: "talentProfile/getProfileData",
    }),
    handleSalaryDataView() {
      const salaryDataList = [
        {
          label: "fullTimeSalary",
          salaryType: "fullTimeSalary",
          salaryKey: "full_time_salary",
          salaryValue: this.full_time_salary,
        },
        {
          label: "partTimeSalary",
          salaryType: "partTimeSalary",
          salaryKey: "part_time_salary",
          salaryValue: this.part_time_salary,
        },
      ];
      return salaryDataList;
    },
  },
  validations: {
    salaryInput: {
      full_time_salary: {
        integer,
        between: between(1, 1000000),
      },
      part_time_salary: {
        integer,
        between: between(1, 1000000),
      },
    },
  },
  methods: {
    displyErrMsg(salaryValue) {
      if (salaryValue) {
        if (salaryValue <= 0) {
          return this.$t("validation.valueGreaterThanOne");
        } else if (JSON.stringify(salaryValue).includes(".")) {
          return this.$t("validation.onlyIntegerValues");
        } else if (salaryValue > 1000000) {
          return  this.$t("validation.maxValue1000000");
        } else {
          return "";
        }
      }
    },
    handleEditView(salaryType, isEdit) {
      this.is_edit[salaryType] = isEdit;
    },
    handleSubmitSalary(salaryType, isEdit, salaryValue) {
      let payload = {};
      if (!this.$v.salaryInput[salaryValue].$invalid) {
        this.handleEditView(salaryType, isEdit);
        payload.salary = this.salaryInput.full_time_salary
          ? parseInt(this.salaryInput.full_time_salary)
          : "";
        payload.part_time_salary = this.salaryInput.part_time_salary
          ? parseInt(this.salaryInput.part_time_salary)
          : "";

        
        if (window.gtag) {
          window.gtag("event", "talent_updated_salary", {
            talent_email: this.talentEmail,
          });
        }
        this.$store.dispatch("talentProfile/updateProfileSalary", payload)
          .then(() => {
            this.showWarningModal = true;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.salary-wrapper.introjs-relativePosition {
  position: relative;
  z-index: 100000001 !important;
}
</style>
