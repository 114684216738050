<template>
  <div class="navbar-wrapper">
    <header class="position-relative">
      <div class="container z-index-1 position-relative">
        <nav class="navbar navbar-expand-xl">
          <a class="navbar-brand">
            <img loading="lazy"
              src="../../../../assets/images/logo-dark.svg"
              alt="squadio logo"
              width="120"
              @click="gotoHome()"
          /></a>

          <div class="btn-group loggedin-dropdown" style="order: 2">
            <div class="btn-group">
              <button
                type="button"
                class="border-0 btn dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ truncate(userName, 12) }}
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <button
                    class="border-white dropdown-item"
                    type="button"
                    @click="logOut"
                  >
                    {{ $t("home.logout") }}
                  </button>
                </li>
              </ul>
            </div>
            <LanguageSwitcher class="language-switcher-navbar" black />
          </div>
      
          <LanguageSwitcher separator-dark has-divider divider-at-start class="me-2 ms-auto" />

          <button
            class="navbar-toggler collapsed"
            type="button"
            @click="triggerMobileMenu = !triggerMobileMenu"
          >
            <!-- <span class="navbar-toggler-icon"> -->
            <NavbarIcon />
            <!-- </span> -->
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              class="mb-2 nav navbar-nav col-12 col-lg-auto me-lg-auto justify-content-center mb-md-0"
            >
              <li>
                <router-link
                  to="/"
                  :class="
                    isBringBuddy || isCodeChallenge
                      ? 'nav-link px-3'
                      : 'nav-link px-3'
                  "
                >
                  {{ $t("talent.talentHome") }}
                </router-link>
              </li>
              <li>
                <router-link
                  to="/bring-buddy"
                  :class="
                    isBringBuddy
                      ? 'nav-link px-3'
                      : 'nav-link px-3 '
                  "
                >
                  {{ $t("talent.bringYourBuddyAgain") }}
                </router-link>
              </li>
               <li v-if="isTalenttHasContractRenewal">
                <router-link
                  to="/sq/talent/contracts-renewal"
                  :class="$route.path.includes('renewal') ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' ">
                  {{ $t("talent.contractRenewal") }} 
                </router-link>
              </li>
            </ul>
          </div>
          <mobile-menu
            :is-open="triggerMobileMenu"
            logged-in-talent
            @close-menu="menuClosed"
          >
            <li class="mobileMenu__item">
              <router-link
                to="/"
                :class="!$route.path.includes('renewal') && !$route.path.includes('buddy')  ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' ">
                {{ $t("talent.talentHome") }}
              </router-link>
            </li>
            <li class="mobileMenu__item">
              <router-link
                to="/bring-buddy"
               :class="$route.path.includes('bring-buddy') ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' "
              >
                {{ $t("talent.bringYourBuddyAgain") }}
              </router-link>
            </li>
             <li class="mobileMenu__item" v-if="isTalenttHasContractRenewal">
              <router-link
               to="/sq/talent/contracts-renewal"
               :class="$route.path.includes('renewal') ? 'nav-link px-3 link-secondary' : 'nav-link px-3 ' "
              >
                 {{ $t("talent.contractRenewal") }}
              </router-link>
            </li>
          </mobile-menu>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
import "./navbar.scss";
import { mapActions, mapGetters } from "vuex";
import NavbarIcon from "../icons/navbar.vue";
import { truncate } from "../../../../utils/utils";
import MobileMenu from "@/components/MobileMenu";
import LanguageSwitcher from "@/components/shared/languageSwitcher/languageSwitcher.vue";
export default {
  components: { NavbarIcon, MobileMenu, LanguageSwitcher },
  data() {
    return {
      userName: "",
      triggerMobileMenu: false,
      isBringBuddy: window.location.href.includes("buddy"),
      isCodeChallenge: window.location.href.includes("challenge"),
    };
  },
  mounted() {
    const body = document.querySelector('body');
    body.classList.remove('is-fixed');
    this.userName = JSON.parse(localStorage.getItem("user")).first_name;
    this.setTalenttHasContractRenewal()
  },
  computed: {
      ...mapGetters({
      isTalenttHasContractRenewal: "auth/getTalentHasContractRenewal"
    }),
  },
  methods: {
       ...mapActions({
      setTalenttHasContractRenewal: "auth/setTalentHasContractRenewal"
    }),
    menuClosed() {
      this.triggerMobileMenu = false;
      const body = document.querySelector('body');
      body.classList.remove('is-fixed');
    },
    truncate,
    handleRedirection(path) {
      this.$router.push({ path });
    },
    gotoHome() {
      this.$router.push({ path: "/" });
    },
    logOut() {
      this.$store.dispatch("auth/logOut").then(() => {
        this.$router.push({ path: "/" });
        // window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.link-secondary {
    color: #ffce44 !important;
}
</style>
