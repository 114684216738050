<template>
  <div class="talentRolesDDL">
    <!-- <Select
      placeholder="Select role"
      :label="label ? label : 'Role'"
      :hideLabel="hideLabel ? hideLabel : false"
      :validationField="validationField"
      :selectOptions="talentRolesList"
      :inputStyle="`${inputStyle} w-100`"
      keySelected="id"
    /> -->
    <label v-if="!hideLabel">Role</label>
    <v-select
      v-model="validationField.$model"
      class="style-chooser"
      :placeholder="$t('signUp.role')"
      :options="talentRolesList"
      label="name"
      :selectable="(option) => !option.hasOwnProperty('group')"
      :reduce="(name) => name.id"
    >
       <div slot="no-options">
        {{ $t('talent.sorryNoMatchingOptions')}}
      </div>
      <template #option="{ group, name }">
        <div v-if="group" class="group">{{ group }}</div>
        {{ name }}
      </template>
    </v-select>
  </div>
</template>
<script>
import "./talentRolesDDL.scss";

// import Select from "../../shared/formControls/select/select.vue";

export default {
  // components: { Select },
  props: ["validationField", "hideLabel", "label", "inputStyle"],

  mounted() {
    this.getTalentRolesRequest();
  },

  computed: {
    console: () => console,
    talentRolesList() {
      return this.$store.getters["shared/getTalentRolesList"];
    },
  },
  methods: {
    async getTalentRolesRequest() {
      this.$store.dispatch("shared/getTalentRolesRequest");
    },
  },
};
</script>
<style scoped lang="scss"></style>
